import { h } from "preact"
import { useEffect } from "preact/hooks"
import { useRateTableFormSubmit } from "@hooks"
import {
  LoanDetailsFormFields,
  RatesFormContextProps,
  RootStates
} from "@types"
import { calcDownPayment, calcDownPercentage, onClickAnchor } from "@helpers"
import LoanDetailsForm from "../LoanDetailsForm"
import { useAppContext, useRatesFormContext } from "@contexts"
import { closeLoanDetailsMobileFilters } from "@iframeCommunication/utils"
import { useSelector } from "react-redux"
import { useIframeCommunication } from "@iframeCommunication"
import { SUBMIT_LOAN_DETAILS_FORM } from "@constants"

const LoanDetailsFormWrapper = () => {
  const { hasARMTerms } = useSelector(({ rateTable }: RootStates) => {
    return {
      hasARMTerms:
        rateTable?.rateTableConfig.mortgage_terms?.some(
          term => term?.type === "arm"
        ) ?? true
    }
  })

  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  useEffect(() => {
    if (!isMobile) {
      closeLoanDetailsMobileFilters()
    }
  }, [isMobile])

  const ratesFormContext = useRatesFormContext()
  const {
    formData,
    lastUpdateField,
    updateFields,
    handleOnChange,
    setFormData,
    setFormDirty,
    setUpdateFields,
    initialFormData
  } = ratesFormContext as RatesFormContextProps

  const { loan_type, loan_term, arm_term, selectedTerm } = initialFormData

  const handleSubmit = useRateTableFormSubmit()

  const updateRatesForm = (modalFormData = formData) => {
    handleSubmit(modalFormData)
    setFormDirty(false)
  }

  // Listen for messages from the mobile filter modal
  useIframeCommunication(message => {
    if (message.action === SUBMIT_LOAN_DETAILS_FORM) {
      const modalFormData = message.params?.formData as LoanDetailsFormFields
      setFormData(modalFormData)
      updateRatesForm(modalFormData)
      onClickAnchor("rates-toolbar")
    }
  })

  // Update formData on change from toolbar
  useEffect(() => {
    const update: Partial<LoanDetailsFormFields> = {}
    switch (true) {
      case loan_term !== formData.loan_term && loan_type !== "arm":
        update.loan_term = loan_term
        update.selectedTerm = loan_term
        break
      case arm_term !== formData.arm_term && loan_type === "arm":
        update.arm_term = arm_term
        update.selectedTerm = arm_term
        break
      case selectedTerm === "all":
        update.loan_term = undefined
        update.arm_term = undefined
        update.selectedTerm = selectedTerm
        break
      default:
        break
    }

    if (loan_type !== formData.loan_type) {
      update.loan_type = loan_type
    }

    if (formData.loan_purpose && Object.keys(update).length) {
      setFormData(update)
    }
  }, [loan_term, arm_term, loan_type, selectedTerm])

  // Check if we need to update any field
  useEffect(() => {
    if (!updateFields) {
      return
    }

    const value = formData[lastUpdateField as keyof LoanDetailsFormFields]

    let dp

    const { list_price, loan_amount } = formData
    const lp = lastUpdateField === "list_price" ? (value as number) : list_price
    const la =
      lastUpdateField === "loan_amount" ? (value as number) : loan_amount
    dp = (lp as number) - (la as number)

    updateCalcForm(dp)
    setUpdateFields(false)
  }, [updateFields])

  // Update formData when  "down_payment", "list_price", "down_percentage", "loan_amount", or "loan_purpose" changes
  const updateCalcForm = (newdp: number | undefined) => {
    const { list_price, down_percentage, down_payment, loan_purpose } = formData
    const value = formData[lastUpdateField as keyof LoanDetailsFormFields]

    let newState: {
      down_payment?: number
      down_percentage?: number
      loan_amount?: number
    } = {}
    switch (lastUpdateField) {
      case "list_price":
        newState.down_payment = calcDownPayment({
          homePrice: value as number,
          downPercentage: down_percentage as number
        })
        break
      case "loan_purpose":
        newState.down_payment = newdp
        newState.down_percentage = calcDownPercentage({
          homePrice: list_price as number,
          down_payment: newdp as number
        })
        break
      case "down_payment":
        newState.down_percentage = calcDownPercentage({
          homePrice: list_price as number,
          down_payment: value as number
        })
        break
      case "down_percentage":
        newState.down_payment = calcDownPayment({
          homePrice: list_price ?? 0,
          downPercentage: value as number
        })
        break
      default:
        break
    }

    if (loan_purpose === "purchase" && lastUpdateField !== "loan_purpose") {
      const dp =
        lastUpdateField === "down_payment"
          ? (value as number)
          : newState.down_payment ?? newdp ?? down_payment
      const lp =
        lastUpdateField === "list_price" ? (value as number) : list_price
      newState.loan_amount = (lp as number) - (dp as number)
    } else {
      newState.down_payment =
        lastUpdateField !== "loan_purpose"
          ? (value as number)
          : newState.down_payment ?? newdp ?? down_payment
    }

    setFormData(newState)
  }

  const numericInputChange = (value: string, e: Event) => {
    const fieldName = (e.target as HTMLInputElement)
      .name as keyof LoanDetailsFormFields
    handleOnChange(value, fieldName)
  }

  return (
    <LoanDetailsForm
      handleOnChange={handleOnChange}
      numericInputChange={numericInputChange}
      handleSubmit={() => updateRatesForm()}
      formData={formData}
      hasARMTerms={hasARMTerms}
    />
  )
}

export default LoanDetailsFormWrapper
