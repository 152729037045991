import { h } from "preact"
import { LoanDetailsFormFields, OnChangeValue } from "@types"
import LoanDetailsFields from "../LoanDetailsFields"
import { Form } from "@ui"
import { LOAN_DETAILS_FORM_ID } from "@constants"
import "@styles/components/_loan-details-form.scss"

interface Props {
  formData: LoanDetailsFormFields
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
  numericInputChange: (value: string, e: Event) => void
  handleSubmit?: () => void
  isVertical?: boolean
  toggleLabel?: string
  submitLabel?: string
  showFullForm?: boolean
  hasARMTerms?: boolean
  disabledFields?: Array<keyof LoanDetailsFormFields>
}

const LoanDetailsForm = ({
  formData,
  handleOnChange,
  numericInputChange,
  handleSubmit,
  isVertical,
  toggleLabel,
  submitLabel = "Update rates",
  showFullForm,
  hasARMTerms,
  disabledFields = []
}: Props) => {
  return (
    <div className="loan-details-form" id={LOAN_DETAILS_FORM_ID}>
      <div
        className={`loan-details-form__toggle-form ${
          isVertical ? "vertical" : ""
        }`}
      >
        <Form.LoanTypeToggle
          label={toggleLabel}
          handleOnChange={handleOnChange}
          value={formData.loan_purpose}
        />
      </div>
      <LoanDetailsFields
        hasARMTerms={hasARMTerms}
        disabledFields={disabledFields}
        isVertical={isVertical}
        handleOnChange={handleOnChange}
        numericInputChange={numericInputChange}
        formData={formData}
        submitLabel={submitLabel}
        showFullForm={showFullForm}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

export default LoanDetailsForm
