export const decoupleAddress = address_components => {
  let hascity = false
  let hassub = false
  let haszipcode = false
  let state, city, subloc, nbhd, zipcode
  address_components.forEach(component => {
    var types = component.types
    types.forEach(type => {
      if (type == "locality") {
        city = component.long_name
        hascity = true
      }
      if (type == "postal_code") {
        zipcode = component.long_name
        haszipcode = true
      }
      if (type == "administrative_area_level_1") {
        state = component.short_name
      }
      if (type == "neighborhood") {
        nbhd = component.long_name
      }
      if (type == "sublocality") {
        subloc = component.long_name
        hassub = true
      }
    })
  })

  const addressObj = {
    state,
    city: hascity ? city : hassub ? subloc : nbhd,
    ...(haszipcode
      ? {
          zipcode
        }
      : {})
  }
  // if (haszipcode) {
  //   addressObj.zipcode = zipcode
  // }
  return addressObj
}

export const parseAddress = address_components => {
  const address = {}
  const componentForm = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    sublocality_level_1: "long_name",
    administrative_area_level_1: "short_name",
    administrative_area_level_2: "short_name",
    country: "long_name",
    postal_code: "short_name"
  }
  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  for (let i = 0; i < address_components?.length; i++) {
    const addressType = address_components[i].types[0]
    if (componentForm[addressType]) {
      const val = address_components[i][componentForm[addressType]]
      address[addressType] = val
    }
  }
  return address
}
