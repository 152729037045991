import { h } from "preact"
import Box from "../../Box"
import TxtLoading from "../Txt"
import { useIsMobile } from "@contexts"

const BannerSkeleton = () => {
  const isMobile = useIsMobile()

  const txtHeight = isMobile ? 24 : 36
  return (
    <Box hasGutter hasShadow={false} className="tw-mt-28">
      <div className="tw-flex tw-items-center tw-gap-36 tw-p-4">
        <div className="tw-w-2/3">
          <TxtLoading height={txtHeight} />
          <TxtLoading height={txtHeight} width="45%" margin="8px 0 0 0" />
        </div>
        <div className="tw-w-1/3 tw-hidden md:tw-block">
          <TxtLoading height={65} />
        </div>
      </div>
    </Box>
  )
}

export default BannerSkeleton
