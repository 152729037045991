import { h } from "preact"
import { LogoLinkBtn } from "@ui"

interface Props {
  assumptions: string[]
}

const AssumptionsInner = ({ assumptions }: Props) => (
  <div class="tw-flex tw-flex-col">
    <div
      class="tw-flex tw-flex-col tw-space-y-4"
      dangerouslySetInnerHTML={{
        __html: assumptions.join("")
      }}
    ></div>
    <LogoLinkBtn className="tw-mt-12 md:tw-mt-6 tw-ml-auto" size="sm" />
  </div>
)

export default AssumptionsInner
