import { ComponentChildren, Fragment, h } from "preact"
import { useState } from "preact/hooks"
import { NumericInputMaterial, NumericInput } from "../../inputs"

interface Props {
  name: string
  value: number
  maxlength: number
  max: number
  min: number
  makeFocus?: boolean
  isMaterial?: boolean
  onChangeComplete: (value: any, name: string) => void
  submitOnEnter?: () => void
  onBlur?: () => void
  label: ComponentChildren
  ariaDescribedby: string
  className?: string
  placeholder?: string
  error?: { evalStatus: (val: number) => boolean; message: string }
}

const CreditScoreRangeNum = ({
  maxlength,
  name,
  value,
  max,
  min,
  makeFocus,
  isMaterial,
  onBlur,
  onChangeComplete,
  submitOnEnter,
  label,
  className = "",
  placeholder = "",
  ariaDescribedby,
  error
}: Props) => {
  /** we should re-factor the below component */
  const Tag = isMaterial ? NumericInputMaterial : NumericInput

  const [initialValue] = useState(value)

  const doOnBlur = (e: InputEvent) => {
    const target = e.target as HTMLInputElement

    let newValue = Number(target.value) ?? 0
    newValue = newValue === 0 ? initialValue : newValue
    if (newValue > max) {
      newValue = max
    } else if (newValue < min) {
      newValue = min
    }
    onBlur?.()
    if (newValue !== value) {
      onChangeComplete(newValue, name)
    }
  }

  return (
    <Fragment>
      {/* @ts-ignore */}
      <Tag
        ariaDescribedby={ariaDescribedby}
        label={label}
        isMaterial={isMaterial}
        name={name}
        value={value === 0 ? "" : value}
        min={min}
        max={max}
        doOnBlur={doOnBlur}
        submitOnEnter={submitOnEnter}
        makeFocus={makeFocus}
        className={className}
        placeholder={placeholder}
        maxlength={maxlength}
        error={error}
        allowEmptyWhileTyping
      />
    </Fragment>
  )
}

export default CreditScoreRangeNum
