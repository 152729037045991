import { h } from "preact"
import Tooltip from "../Tooltip"
import { If } from "@ui"
import { LINE_CHART_FIELDS, LINE_CHART_FIELDS_KEYS } from "@constants"

interface Props {
  pi: number
  homeInsurance: number
  propertyTaxAmount: number
  monthly_debt: number
  mortgageInsurance: number | string
  hoaDues: number
  percentages: Record<string, number>
  includePMI?: boolean
  includeHOA?: boolean
}

const Line = (props: Props) => {
  const { percentages, includePMI = false, includeHOA = false } = props

  const shouldSkipField = (field: string) => {
    if (percentages[field] < 1) return true
    if (!includePMI && field === "mortgageInsurance") return true
    if (!includeHOA && field === "hoaDues") return true
    if (field === "monthly_debt" && Number(props[field]) < 1) return true

    return false
  }

  return (
    <div className="linear-chart__draw-line">
      {LINE_CHART_FIELDS_KEYS.map(field => {
        if (shouldSkipField(field)) {
          return null
        }
        return (
          <Tooltip
            ttPlacement="top"
            className="linear-chart__draw-paint"
            eventTrigger="hover"
            style={{ width: `${percentages[field]}%` }}
            reference={<div className={`linear-chart__draw-paint--${field}`} />}
          >
            {LINE_CHART_FIELDS[field]} <strong>${props[field]}</strong>
          </Tooltip>
        )
      })}
    </div>
  )
}

export default Line
