import { h } from "preact"
import RowLoader from "./RowLoader"
import ColLoader from "./ColLoader"
import { If } from "@ui"
import "@styles/components/_rate-table.scss"

interface Props {
  pricingIsLoading: boolean
  isNotMobile?: boolean
  rows?: number
  cols?: number
}

const RateTableItemsSkeleton = ({
  pricingIsLoading,
  isNotMobile = true,
  rows = 3,
  cols = 1
}: Props) => (
  <If
    condition={!!pricingIsLoading}
    then={isNotMobile ? <RowLoader rows={rows} /> : <ColLoader cols={cols} />}
  />
)

export default RateTableItemsSkeleton
