import { h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import { numberWithCommas, numberWithoutCommas, debounce } from "@helpers"

const NumericInputMaterial = ({
  ariaDescribedby,
  forceEmpty,
  forceMinMax,
  handleInputChange,
  forceTwoDecimal = true,
  keyDownTimming = 1000,
  max,
  min,
  onClick,
  doOnFocus,
  doOnBlur,
  value,
  name,
  placeholder,
  readonly,
  className = "",
  classNameInput = "",
  hasPattern,
  pattern,
  children,
  makeFocus,
  styles = {},
  label,
  compact,
  suffix,
  affix,
  hiddenLabel,
  submitOnEnter,
  showRequired,
  size = "md",
  ...rest
}) => {
  const [val, setVal] = useState()
  const [actualFocus, setFocus] = useState()
  const [materialClassName, setMaterialClassName] = useState([])
  const [inputClassnm, setInputClassnm] = useState([])

  const inputEle = useRef(null)

  useEffect(() => {
    setInputClassnm(
      `form__input ${classNameInput} ${affix ? "form__input--affix" : ""}  ${
        suffix ? "form__input--suffix" : ""
      }`
    )
  }, [])

  useEffect(() => {
    if (actualFocus === null && makeFocus) {
      inputEle?.current.focus()
    }
  })

  useEffect(() => {
    // Don't update if we are editing the field
    if (actualFocus === name) {
      return
    }

    let valueTemp
    if (value) {
      valueTemp = Number(value)
      if (!Number.isInteger(valueTemp) && forceTwoDecimal) {
        valueTemp = valueTemp.toFixed(2)
      }
      valueTemp = numberWithCommas(valueTemp)
    } else if (forceEmpty) {
      valueTemp = ""
    } else {
      valueTemp = 0
    }
    toggleMaterialClassName("is-typed", checkVal(valueTemp))

    setVal(valueTemp)
  }, [value])

  const checkVal = v => {
    return v?.length || typeof v === "number"
  }

  // Cancel non-numeric characters
  const handleKeyDown = e => {
    const charCode = e.which
    if (charCode === 13) {
      inputEle?.current.blur()
      submitOnEnter?.()
      return
    }

    // Allow numbers, backspace, delete, arrows and dot keys
    if (
      (charCode >= 48 && charCode <= 57) ||
      [8, 9, 46, 37, 38, 39, 40, 190, 110].indexOf(charCode) >= 0
    ) {
      // Check if the number already has a dot
      const val = e.target.value + ""
      // Count the number of occurrences of a character in a string
      const count = (str, char) => str.split(char).length - 1
      const hasDot = count(val, ".")
      // Allow only one dot
      if (hasDot > 0 && charCode === 190) {
        e.preventDefault()
      }
      return
    } else {
      e.preventDefault()
    }
  }

  const toggleMaterialClassName = (className, add) => {
    const materialClassNameTemp = [...materialClassName]
    const index = materialClassNameTemp.indexOf(className)
    if (add && index === -1) {
      materialClassNameTemp.push(className)
    } else if (!add && index > -1) {
      materialClassNameTemp.splice(index, 1)
    }
    setMaterialClassName([...materialClassNameTemp])
  }

  /**
   * if forceMinMax is true we force the min and max values
   */
  const checkValue = v => {
    let newValue = v | 0
    if (newValue > max) {
      newValue = max
    } else if (newValue < min) {
      newValue = min
    }
    return newValue
  }

  const handleChange = debounce(event => {
    let newValue = event.target.value
    const notForceValue = event.target.value

    newValue =
      typeof newValue === "number" && isNaN(newValue)
        ? 0
        : isNaN(Number(newValue))
        ? numberWithoutCommas(newValue)
        : Number(newValue)

    if (forceMinMax) {
      newValue = checkValue(newValue)
    }
    handleInputChange?.(newValue, event, notForceValue)
    newValue = actualFocus ? newValue : numberWithCommas(newValue)

    if (forceEmpty && !newValue) {
      newValue = ""
    }

    setVal(newValue)
  }, keyDownTimming)

  const handleBlur = event => {
    let newValue = event.target.value

    if (forceMinMax) {
      newValue = checkValue(newValue) + ""
    }
    newValue = newValue.replace(/^0+/, "")

    if (forceEmpty && !newValue) {
      newValue = ""
    } else if (!newValue) {
      newValue = 0
    } else {
      newValue = numberWithCommas(newValue)
    }

    setVal(newValue)
    setFocus(null)
    doOnBlur?.(event)
  }

  const handleFocus = event => {
    let newValue = event.target.value
    newValue = numberWithoutCommas(newValue)
    setVal(newValue)
    setFocus(event.target.name)
    toggleMaterialClassName("has-focus", true)
    doOnFocus?.(event)
  }

  return (
    <div
      className={`form__field is-material is-${size} ${
        compact ? "is-compact" : ""
      } ${className} ${materialClassName.join(" ")}`}
    >
      {label && (
        <label
          className={`form__label ${hiddenLabel ? "d-none" : ""}`}
          htmlFor={name}
        >
          <span className="form__label-txt">{label}</span>
          {showRequired && <span className="text-danger"> *</span>}
        </label>
      )}

      <input
        readonly={readonly}
        {...(ariaDescribedby && { "aria-describedby": ariaDescribedby })}
        ref={inputEle}
        className={`numeric-input ${inputClassnm}`}
        pattern={hasPattern && pattern}
        type="text"
        name={name}
        placeholder={placeholder}
        value={val}
        onInput={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        style={styles}
        {...rest}
      />

      {children}
    </div>
  )
}

export default NumericInputMaterial
