import { h } from "preact"
import { RatesFormContext } from "./context"
import { LoanDetailsFormFields } from "@types"
import { useRatesFormChange } from "@hooks"
import { useRef } from "preact/hooks"

interface RatesFormContextProviderProps {
  children: preact.ComponentChildren
  initialFormData: LoanDetailsFormFields
  defaultFormData?: Partial<LoanDetailsFormFields>
  handleSubmit?: (data: Partial<LoanDetailsFormFields>) => void
  syncForm?: (newFormData: Partial<LoanDetailsFormFields>) => void
}

const RatesFormContextProvider: preact.FunctionComponent<RatesFormContextProviderProps> =
  ({ children, initialFormData, defaultFormData, syncForm, handleSubmit }) => {
    const defaultFormDataRef = useRef(defaultFormData ?? initialFormData)

    const hookParams = useRatesFormChange(
      initialFormData,
      defaultFormDataRef.current,
      syncForm,
      handleSubmit
    )

    const contextValue = {
      initialFormData,
      defaultFormData: defaultFormDataRef.current,
      children,
      ...hookParams
    }

    return (
      <RatesFormContext.Provider value={contextValue}>
        {children}
      </RatesFormContext.Provider>
    )
  }

export default RatesFormContextProvider
