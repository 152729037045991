import { Fragment, h, render } from "preact"
import Widget from "./Widget"
import API from "./api"
import queryString from "query-string"
import { bbProcessEnv } from "@helpers"
;(function () {
  if (!window.BB) {
    const BB = window.BB || {}

    const API_EXTRAS = {}

    BB.addAPI = config => {
      delete BB.addAPI // remove this function
      // here we get the config from the iframe and
      // merge it with the basic config(wrapperID, type)
      BB.config = { ...config, ...BB.config }
      // Add API and extras
      BB.api = {
        ...API,
        ...API_EXTRAS[config.app_key],
        app_key: config.app_key
      }
    }

    const optionsDefault = {
      type: "boomtown"
    }

    BB.init = (app_key, el = document.body, options = {}) => {
      // Embed options
      const embedOptions = {
        ...optionsDefault, // default options
        ...options // user options
      }

      // Types: boomtown/widget/api
      const params = queryString.parse(window.location.search)

      if (typeof app_key === "undefined") {
        console.error("Missing App key parameter. Cannot initialize BB widget")
        return
      }

      if (typeof gtag != "undefined" && gtag) {
        console.log("parent gtag detected")
      }
      const version = bbProcessEnv.BB_APP_VERSION
      let bigText = [
        "  ____              _    _             ____       _     _            ",
        " |  _ \\            | |  (_)           |  _ \\     (_)   | |           ",
        " | |_) | __ _ _ __ | | ___ _ __   __ _| |_) |_ __ _  __| | __ _  ___ ",
        " |  _ < / _` | '_ \\| |/ | | '_ \\ / _` |  _ <| '__| |/ _` |/ _` |/ _ \\",
        " | |_) | (_| | | | |   <| | | | | (_| | |_) | |  | | (_| | (_| |  __/",
        " |____/ \\__,_|_| |_|_|\\_|_|_| |_|\\__, |____/|_|  |_|\\__,_|\\__, |\\___| v." +
          version,
        "                                  __/ |                    __/ |     ",
        "                                 |___/                    |___/      "
      ]
      console.log(
        "%c" +
          bigText[0] +
          "\n" +
          bigText[1] +
          "\n" +
          bigText[2] +
          "\n" +
          bigText[3] +
          "\n" +
          bigText[4] +
          "\n" +
          bigText[5] +
          "\n" +
          bigText[6] +
          "\n" +
          bigText[7],
        "color: #4268fa;"
      )

      const storageVersion = localStorage.getItem("BB-version")

      // check if the version is the same, if not and storageVersion is not undefined log a warning
      if (storageVersion && storageVersion !== version) {
        console.warn(
          "BankingBridge Widget version mismatch with the iframe version."
        )
      }

      const config = {
        app_key: app_key,
        wrapperID: el.id,
        ...embedOptions
      }

      if (params.loid) {
        config.loid = parseInt(params.loid, 10)
      }

      let wrapperClass = "bankingbridge-widget"

      if (embedOptions.position) {
        wrapperClass += "--fixed"
      }

      BB.config = {}
      if (config.type === "api") {
        el.style.setProperty("display", "none", "important")
      }
      // Store the config in the BB object
      let { wrapperID, type } = config
      BB.config = { wrapperID, type }

      el.classList.add(wrapperClass)

      API_EXTRAS[app_key] = {}

      render(
        <Fragment>
          <Widget
            ref={WidgetComponent => {
              // Add the handleFrameTasks function to the API based on the app_key
              API_EXTRAS[app_key].handleFrameTasks =
                WidgetComponent.handleFrameTasks
              API_EXTRAS[app_key].getState = WidgetComponent.getState
              API_EXTRAS[app_key].setWidgetConfig =
                WidgetComponent.setWidgetConfig
            }}
            app_key={app_key}
            config={config}
          />
          <style>
            {`html.bankingbridge-page-scroll-locked {overflow: hidden}`}
          </style>
        </Fragment>,
        el
      )
    }

    window.BB = BB
  }
})()
