import { h, ComponentChildren, Fragment } from "preact"
import { useMemo } from "preact/hooks"
import clsx from "clsx"
import { If } from "@ui"
import { Loader } from "../loaders"
import { UISize } from "@types"
import "@styles/components/_button.scss"

/**
 * TODO: This shouold be the a low level component that is used by other components like FlatButton, OutlineButton, etc.
 */
type Props = {
  flat?: boolean
  noShadow?: boolean
  outline?: boolean
  outlineHover?: boolean
  size?: UISize
  adapt?: boolean
  link?: boolean
  disabled?: boolean
  inline?: boolean
  label?: string
  href?: string
  children?: ComponentChildren
  pill?: boolean
  success?: boolean
  noAction?: boolean
  onKeyDown?: (e: KeyboardEvent) => void
  onClick?: (e: Event) => void
  type?: HTMLButtonElement["type"]
  isCTA?: boolean
  isLoading?: boolean
  target?: HTMLAnchorElement["target"]
  isLite?: boolean
  isAlt?: boolean
  isWhite?: boolean
  isBlack?: boolean
  className?: string
  labelledby?: string
  ariaExpanded?: boolean
  ariaControls?: string
  id?: string
}

const buildClassName = (props: Props): string => {
  const {
    className,
    flat,
    noShadow,
    outline,
    outlineHover,
    adapt,
    link,
    pill,
    success,
    inline,
    noAction,
    isCTA,
    isLite,
    isAlt,
    isWhite,
    isBlack,
    disabled,
    size
  } = props
  return clsx("button", className, {
    "button--flat": flat,
    "button--no-shadow": noShadow,
    "button--outline": outline,
    "button--outline-h": outlineHover,
    "button--md": size === "md",
    "button--sm": size === "sm",
    "button--lg": size === "lg",
    "button--xl": size === "xl",
    "button--2xl": size === "2xl",
    "button--adapt": adapt,
    "button--link": link,
    "button--pill": pill,
    "button--success": success,
    "button--inline": inline,
    "button--off": noAction,
    "button--cta": isCTA,
    "button--lite": isLite,
    "button--alt": isAlt,
    "button--white": isWhite,
    "button--black": isBlack,
    "button--isDisabled": disabled,
    "is-default":
      !isAlt && !isLite && !isCTA && !success && !link && !disabled && !outline
  })
}

const buildExtraProps = (
  props: Props,
  newClassName: string,
  handleClick: (e: Event) => void
): any => {
  const {
    disabled,
    isLoading,
    ariaExpanded,
    ariaControls,
    labelledby,
    id,
    onKeyDown
  } = props
  const extraProps: any = {
    tabIndex: 0,
    className: newClassName,
    onClick: handleClick,
    disabled: disabled || isLoading
  }

  if (ariaExpanded) {
    extraProps["aria-expanded"] = ariaExpanded
  }
  if (ariaControls) {
    extraProps["aria-controls"] = ariaControls
  }
  if (labelledby) {
    extraProps["aria-labelledby"] = labelledby
  }
  if (id) {
    extraProps["id"] = id
  }
  if (onKeyDown) {
    extraProps["onKeyDown"] = onKeyDown
  }
  if (isLoading) {
    extraProps["aria-disabled"] = true
    extraProps["aria-busy"] = true
    extraProps["aria-label"] = "Loading"
    extraProps["aria-hidden"] = true
    extraProps["tabIndex"] = -1
    extraProps["role"] = "button"
    extraProps["onClick"] = () => {}
    extraProps["onKeyDown"] = () => {}
  }

  return extraProps
}

const Button = (props: Props) => {
  const {
    disabled,
    isLoading,
    href,
    target,
    children,
    label,
    type = "button"
  } = props

  const handleClick = (e: Event) => {
    if (!disabled) {
      props.onClick?.(e)
    }
  }

  const newClassName = useMemo(() => buildClassName(props), [props])

  const extraProps = useMemo(
    () => buildExtraProps(props, newClassName, handleClick),
    [props, newClassName, handleClick]
  )

  const innerChildren = useMemo(() => {
    return (
      <Fragment>
        <If
          condition={!!isLoading}
          then={<Loader className="button__spinner" />}
          else={label || children}
        />
      </Fragment>
    )
  }, [isLoading, children, label])

  return (
    <If
      condition={!!href}
      then={
        <a {...extraProps} href={href} {...(target ? { target: target } : {})}>
          {innerChildren}
        </a>
      }
      else={
        <button {...extraProps} type={type}>
          {innerChildren}
        </button>
      }
    />
  )
}

export default Button
