import { Fragment, h } from "preact"
import { useSelector } from "react-redux"
import { Button, If, Icon, LeadWorkflowEndStepHeaderSkeleton } from "@ui"
import { applyNowClick } from "./wizard-ga"
import { bbProcessEnv, sprintf } from "@helpers"
import { RootStates, WizardInterface } from "@types"
import { useLeadWorkflowEmailTo } from "@hooks"
import { LEAD_WORKFLOW_TXT } from "@content"
import arrowRight from "@assets/icons/arrow-right.svg"

const { finalStep } = LEAD_WORKFLOW_TXT

interface Props {
  app_link: string
  showApplyBtn: boolean
}

const StepEndHeader = ({ app_link, showApplyBtn }: Props) => {
  const {
    disable_pricing,
    first_name,
    isCreditScoreHigh,
    pricingGridIsLoading,
    lead_id,
    noCards
  } = useSelector(({ wizard }: RootStates) => {
    const {
      values,
      disable_pricing,
      card_details,
      pricingGridIsLoading,
      lead_id
    } = wizard as WizardInterface
    return {
      disable_pricing,
      first_name: values.login_form?.form?.first_name ?? "",
      isCreditScoreHigh: (values?.steps?.credit_score ?? 0) > 659, // 07/06 change from 600 to 659 requested by Jimmy
      pricingGridIsLoading,
      noCards:
        !pricingGridIsLoading &&
        card_details !== null &&
        (!card_details?.cards || card_details?.cards.length === 0),
      lead_id
    }
  })

  const emailTo = useLeadWorkflowEmailTo()

  const title = sprintf(
    noCards || disable_pricing
      ? finalStep.noResults.title
      : finalStep.hasResults.title,
    first_name
  )

  const subtitle =
    noCards || disable_pricing
      ? finalStep.noResults.subtitle
      : finalStep.hasResults.subtitle

  return (
    <Fragment>
      <If
        condition={pricingGridIsLoading}
        then={
          <LeadWorkflowEndStepHeaderSkeleton
            hasResults={!noCards && !disable_pricing}
          />
        }
        else={
          <div className="wizard-end__header txt-dark">
            <div className="tw-text-center">
              <h1 className="tw-text-2xl tw-font-bold tw-mb-2 tw-mt-0 tw-mx-auto">
                {title}
              </h1>
              <h2 className="tw-m-0 tw-text-base tw-font-normal">{subtitle}</h2>

              <If
                condition={noCards || disable_pricing}
                then={
                  <Fragment>
                    <If
                      condition={(showApplyBtn || noCards) && isCreditScoreHigh}
                      then={
                        <div className="sm:tw-flex tw-justify-center tw-items-center gap-base">
                          <Button
                            onClick={applyNowClick}
                            className="wizard-end__apply-btn"
                            label="Apply now"
                            href={app_link}
                            target="_blank"
                            adapt
                            noShadow
                          />
                          <Button
                            className="wizard-end__apply-btn"
                            outline
                            label="Contact us"
                            href={emailTo}
                            adapt
                            noShadow
                          />
                        </div>
                      }
                    />
                  </Fragment>
                }
                else={
                  <Fragment>
                    <Button
                      className="wizard-end__apply-btn tw-m-6"
                      href={`${bbProcessEnv.DASHBOARD_URL}/new-subscription?lead_id=${lead_id}&active=0&source=embed`}
                      target="_blank"
                      adapt
                    >
                      Go to my rate dashboard
                      <Icon
                        className="tw-ml-2"
                        originalSize={12}
                        size={12}
                        src={arrowRight}
                      />
                    </Button>
                  </Fragment>
                }
              />
            </div>
          </div>
        }
      />
    </Fragment>
  )
}

export default StepEndHeader
