import { h } from "preact"
import { useEffect } from "preact/hooks"
import { Loader, Rating, If, ScreenReaderOnly } from "@ui"
import { Tooltip } from "@composite"
import { numberWithCommas } from "@helpers"
import { useAppContext } from "@contexts"
import { ProviderType, ReviewType } from "@types"
import "@styles/components/_review-rating-block.scss"

type Props = {
  isLogoWhite?: boolean
  isLogoOriginal?: boolean
  ratingColor?: string
  accentColor?: boolean
  className?: string
  iconSize?: "sm" | "md" | "lg"
  TTiconBG?: string
  TTicon?: string
  TTiconColor?: string
  ratingSize?: number
  type: ReviewType
  hideTT?: boolean
  hideTotal?: boolean
  provider: ProviderType
}

const ReviewRatingBlock = ({
  isLogoWhite,
  isLogoOriginal,
  ratingColor,
  accentColor = true,
  className = "",
  iconSize = "md",
  TTiconBG = "var(--gray-color)",
  TTicon = "?",
  TTiconColor = "#fff",
  ratingSize = 16,
  type,
  hideTT,
  hideTotal,
  provider
}: Props) => {
  const {
    reviews: { getReviews, lo, brand }
  } = useAppContext()

  useEffect(() => {
    getReviews(type)
  }, [])

  const reviewsData = type === "brand" ? brand : lo
  const currentProviderReviews = reviewsData?.[provider]

  const isLoading = reviewsData?.isLoading

  const TagName = currentProviderReviews?.reviewURL ? "a" : "span"

  let strRating = currentProviderReviews?.rating?.toString() ?? ""

  strRating =
    strRating.length === 1 ? strRating + ".0" : strRating.substring(0, 3)

  return (
    <If
      condition={(currentProviderReviews?.reviews?.length ?? 0) > 0}
      then={
        <div
          className={`review-rating-block ${className} ${
            isLogoWhite ? "is-logo-white" : ""
          } ${
            isLogoOriginal ? "is-logo-original" : ""
          } is-${provider} icon-${iconSize} ${
            isLoading
              ? "is-loading"
              : currentProviderReviews && currentProviderReviews.reviews
              ? "d-flex align-items-center"
              : "d-none"
          }`}
        >
          {isLoading && (
            <Loader className="d-flex align-items-center rrb__loader" />
          )}
          {!isLoading && reviewsData && reviewsData.reviews ? (
            <div
              className={`review-rating-block__inner ${
                iconSize === "sm"
                  ? "d-flex align-items-end"
                  : "d-flex align-items-baseline"
              }`}
            >
              <ScreenReaderOnly>
                {`${provider}, ${strRating} star rating`}
              </ScreenReaderOnly>
              <span
                aria-hidden="true"
                className={`${
                  accentColor ? "accent-color" : ""
                } rrb__number pr-1`}
              >
                {strRating}
              </span>
              <Rating
                rating={currentProviderReviews?.rating ?? 0}
                size={iconSize === "lg" ? 20 : ratingSize}
                color={ratingColor}
              />

              {!hideTotal && (
                <TagName
                  className="rrb__txt ml-1"
                  {...(TagName === "a"
                    ? {
                        rel: "noreferrer noopener",
                        tabIndex: 0,
                        href: currentProviderReviews?.reviewURL,
                        target: "_blank"
                      }
                    : {})}
                >
                  {numberWithCommas(currentProviderReviews?.totalReviews ?? 0)}
                  &nbsp;
                  {(currentProviderReviews?.totalReviews ?? 0) > 1 ||
                  currentProviderReviews?.totalReviews === 0
                    ? "reviews"
                    : "review"}
                  <If
                    condition={TagName === "a"}
                    then={
                      <ScreenReaderOnly>, opens in new tab</ScreenReaderOnly>
                    }
                  />
                </TagName>
              )}

              {!hideTT && (
                <Tooltip
                  iconBG={TTiconBG}
                  iconColor={TTiconColor}
                  icon={TTicon}
                >
                  This is the average rating over all available reviews.
                </Tooltip>
              )}
            </div>
          ) : null}
        </div>
      }
    />
  )
}

export default ReviewRatingBlock
