import { h } from "preact"
import { Form } from "@ui"

const DynamicFormTextarea = ({ name, value, onInput, label, required }) => {
  const handleOnInput = e => {
    onInput(e.target.value, name)
  }
  return (
    <Form.Row>
      <Form.Col>
        <Form.Textarea
          name={name}
          value={value}
          onInput={handleOnInput}
          label={label}
          required={required}
          isMaterial
        />
      </Form.Col>
    </Form.Row>
  )
}

export default DynamicFormTextarea
