import { h, Fragment } from "preact"
import { Badge } from "@ui"

const InitalMsg = ({ limit }: { limit: number }) => (
  <p className="text-center">
    {limit > 1 ? (
      <Fragment>
        You have <Badge theme="secondary">{limit}</Badge> rate views left.
      </Fragment>
    ) : (
      <Fragment>
        This is your <Badge theme="secondary">last</Badge> rate view.
      </Fragment>
    )}{" "}
    Go unlimited for free.
  </p>
)

export default InitalMsg
