import { h } from "preact"
import APRRate from "./APRRate"

interface Props {
  className?: string
  rate: number
  apr: number
}

const APRRateBlock = ({ apr, rate, className = "" }: Props) => (
  <div className={className}>
    <APRRate apr={apr} rate={rate} />
    <small className="pricing-item__label">Rate / APR</small>
  </div>
)

export default APRRateBlock
