interface GtagInfo {
  action: string
}

export default function (info: GtagInfo) {
  if (typeof gtag != "undefined" && gtag) {
    gtag("event", info.action)
  } else {
    console.log("gtag not found")
  }
}
