import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import Rows from "./Rows"
import { If } from "@ui"
import DashboardLink from "./DashboardLink"
import { PricingItems } from "@types"
import "@styles/components/_pricing-rows.scss"

const limitDefault = 0
const baseH = 100

type Cards = {
  cards?: PricingItems
  brand?: string
}

type Props = {
  card_details: Cards
  isLoading: boolean
  rowsLimit?: number
}

const LeadWorkflowPricingRows = ({
  card_details,
  isLoading,
  rowsLimit = limitDefault
}: Props) => {
  const [currentItems, setItems] = useState<PricingItems>([])
  const [hasLimit, setLimit] = useState(false)

  useEffect(() => {
    if (card_details?.cards?.[0]) {
      setItems(card_details.cards)
      setLimit(rowsLimit > 0 && card_details.cards.length >= rowsLimit)
    } else {
      setItems([])
    }
  }, [card_details])

  return (
    <div className={`pricing-rows no-action ${hasLimit ? "has-limit" : ""}`}>
      <Rows isLoading={isLoading} currentItems={currentItems} />
      <If condition={!isLoading} then={<DashboardLink className="mt-3" />} />
    </div>
  )
}

export default LeadWorkflowPricingRows
