import { h, Fragment } from "preact"
import { useEffect } from "preact/hooks"
import { numberWithCommas, onClickAnchor } from "@helpers"
import { Rating, ReviewLineSkeleton, ScreenReaderOnly, If } from "@ui"
import { ReviewType } from "@types"
import { useAppContext } from "@contexts"
import "@styles/components/_average-rating.scss"

type Props = {
  className?: string
  hideLabel?: boolean
  type?: ReviewType
  starSize?: number
  anchor?: string
}

const AverageRating = ({
  className = "",
  type = "brand",
  starSize = 12,
  anchor,
  hideLabel
}: Props) => {
  const {
    reviews: { getReviews, brand, lo }
  } = useAppContext()

  useEffect(() => {
    getReviews(type)
  }, [])

  const { isLoading, noReviews, reviewsByProvider, ratings, numberOfReviews } =
    type === "brand" ? brand : lo

  const sumRatings =
    reviewsByProvider?.reduce(
      (acc: number, current: { rating: number; totalReviews: number }) =>
        acc + current.rating * current.totalReviews,
      0
    ) ?? 0
  const sumTotalReviews = reviewsByProvider?.reduce(
    (acc: any, current: { totalReviews: any }) => acc + current.totalReviews,
    0
  )
  const hasReviews = sumTotalReviews > 0

  const ratingsWithAll = {
    ...ratings,
    all: hasReviews ? sumRatings / sumTotalReviews : 0
  }
  const numberOfReviewsWithAll = { ...numberOfReviews, all: sumTotalReviews }

  return (
    <div className={`mrr ${className}`}>
      <If
        condition={isLoading}
        then={<ReviewLineSkeleton />}
        elseCondition={!noReviews}
        else={
          <Fragment>
            {!hideLabel && (
              <div className="mrr__title d-none d-md-block txt-dark">
                Reviews
              </div>
            )}
            <ScreenReaderOnly>
              Average rating, {ratingsWithAll.all.toFixed(1)} star rating
            </ScreenReaderOnly>
            <div className="d-flex align-items-baseline">
              <strong className="average-rating__number">
                {ratingsWithAll.all.toFixed(1)}
                {/* {mostRatingReview?.rating?.toString()?.length === 1
                  ? mostRatingReview?.rating + ".0"
                  : mostRatingReview?.rating?.toString()?.substr(0, 3)} */}
              </strong>
              <Rating
                rating={ratingsWithAll.all ?? 0}
                color="#F3B20A"
                size={starSize}
              />
              <span
                className="average-rating__number__txt"
                {...(anchor
                  ? {
                      onClick: () => onClickAnchor(anchor),
                      role: "button",
                      tabIndex: 0
                    }
                  : {})}
              >
                ({numberWithCommas(numberOfReviewsWithAll.all)}{" "}
                {numberOfReviewsWithAll.all > 1 ||
                numberOfReviewsWithAll.all === 0
                  ? "reviews"
                  : "review"}
                )
              </span>
            </div>
          </Fragment>
        }
      />
    </div>
  )
}

export default AverageRating
