import { h } from "preact"
import { useSelector } from "react-redux"
import LineChart from "../LineChart"
import { MORTGAGE_TXT } from "@content"
import { RootStates, MortgageCalcInterface } from "@types"
import { useAppContext } from "@contexts"

const tooltipTxt = MORTGAGE_TXT.tooltip

const Chart = () => {
  const {
    downPercentage = 0,
    fullState,
    loan_type,
    showChartLoading,
    isBoomtown
  } = useSelector(({ mortgageCalc, app }: RootStates) => {
    const isBoomtown = app?.type === "boomtown"
    return {
      ...mortgageCalc,
      fullState: mortgageCalc,
      isBoomtown
    }
  })

  const {
    media: {
      flags: { isTablet, isMobile }
    }
  } = useAppContext()

  const tabletViewport = isTablet || (isBoomtown && !isMobile)

  return (
    <LineChart
      className={`${tabletViewport ? "is-tablet-chart" : ""}`}
      oneColumnLabels={tabletViewport}
      isLoading={showChartLoading}
      totalTooltipTxt={tooltipTxt.chart}
      data={fullState as MortgageCalcInterface}
      includePMI={downPercentage < 20 || loan_type === "FHA"}
    />
  )
}

export default Chart
