import { h } from "preact"
import If from "../../../If"

interface Props {
  label?: string
  hiddenLabel?: boolean
  showRequired?: boolean
}

const Label = ({ label, hiddenLabel, showRequired }: Props) => (
  <If
    condition={!!label}
    then={
      <label
        className={`form__label ${hiddenLabel ? "d-none" : ""}`}
        htmlFor={name}
      >
        <span className="form__label-txt">{label}</span>
        {showRequired && <span className="text-danger"> *</span>}
      </label>
    }
  />
)

export default Label
