/**
 * Simple local storage wrapper
 */
import {
  setParentStorage,
  clearParentStorage,
  getParentStorage,
  checkParentVersion,
  setParentVersion
} from "@message-bridge"
import { bbProcessEnv } from "./bbProcessEnv"

const hasLS = (() => {
  const test = "test"
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    console.warn("No localStorage available")
    return false
  }
})()

/**
 * This is used to clear the store to
 * avoid conflicts when there is change
 * on what is stored.
 */
export const checkVersion = (app_key: string) => {
  if (hasLS) {
    const version = bbProcessEnv.BB_APP_VERSION as string
    const storageVersion = localStorage.getItem("BB-version")

    if (storageVersion !== version) {
      localStorage.clear()
      setVersion(version)
    }

    setParentVersion(version)
  } else {
    checkParentVersion(app_key)
  }
}

// Set version to local storage
export const setVersion = (version: string) => {
  localStorage.setItem("BB-version", version)
}

// Clear data from local storage
// storageKey is the key to be used to store the data
export const clearStorage = (storageKey = "widget") => {
  if (hasLS) {
    localStorage.removeItem(`BB-${storageKey}`)
  } else {
    clearParentStorage(storageKey)
  }
}

function isQuotaExceededError(err: unknown): boolean {
  if (err instanceof DOMException) {
    return (
      // everything except Firefox
      err.code === 22 ||
      // Firefox
      err.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      err.name === "QuotaExceededError" ||
      // Firefox
      err.name === "NS_ERROR_DOM_QUOTA_REACHED"
    )
  }
  return false
}

// Set data to local storage
// storageKey is the key to be used to store the data
export const setStorage = (data: any, storageKey = "widget") => {
  if (hasLS) {
    const currentStrg = getStorage(storageKey)
    const newStrg = Object.assign({}, currentStrg, data)

    try {
      localStorage.setItem(`BB-${storageKey}`, JSON.stringify(newStrg))
    } catch (err) {
      const isValidQuotaExceededError = isQuotaExceededError(err)
      if (isValidQuotaExceededError) {
        localStorage.clear()
        localStorage.setItem(`BB-${storageKey}`, JSON.stringify(newStrg))
      }
    }
  } else {
    setParentStorage(data, storageKey)
  }
}

// Get data from local storage
// storageKey is the key to be used to store the data
export const getStorage = (storageKey = "widget") => {
  if (hasLS) {
    const lsJson = localStorage.getItem(`BB-${storageKey}`) || "{}"
    return JSON.parse(lsJson)
  } else {
    return getParentStorage(storageKey, window)
  }
}
