import { Fragment, h } from "preact"
import ReviewsWrapper from "../ReviewsWrapper"
import { FULL_REVIEWS_ID } from "@constants"
import ReviewTypeSwitch from "../ReviewTypeSwitch"
import { ReviewsProvider, useReviews } from "../context"
import { If, ReviewBlockSkeleton } from "@ui"

interface Props {
  className?: string
  ratingColor?: string
  hideProvider?: boolean
}

const FullReviews = ({
  className = "",
  ratingColor,
  hideProvider = false
}: Props) => {
  const {
    reviewType,
    isLoLoading,
    isBrandLoading,
    hasBrandReviews,
    hasLoReviews
  } = useReviews()

  if (!(isLoLoading || isBrandLoading) && !(hasLoReviews || hasBrandReviews)) {
    return null
  }

  return (
    <div id={FULL_REVIEWS_ID} className={`full-reviews ${className}`}>
      <div className="tw-flex-col md:tw-flex-row tw-flex tw-items-center md:tw-justify-between tw-gap-6">
        <h3 className="full-reviews__title">Recent Reviews</h3>
        <ReviewTypeSwitch />
      </div>

      <If
        condition={isLoLoading || isBrandLoading}
        then={<ReviewBlockSkeleton className="tw-mt-12" />}
        else={
          <Fragment>
            <If
              condition={(hasLoReviews || isLoLoading) && reviewType === "lo"}
              then={
                <div
                  className={
                    reviewType === "lo" || !hasBrandReviews ? "" : "tw-hidden"
                  }
                >
                  <ReviewsWrapper
                    type="lo"
                    excerpt={75}
                    ratingColor={ratingColor}
                    hideProvider={hideProvider}
                  />
                </div>
              }
            />

            <If
              condition={
                (hasBrandReviews || isBrandLoading) && reviewType === "brand"
              }
              then={
                <div
                  className={
                    reviewType === "brand" || !hasLoReviews ? "" : "tw-hidden"
                  }
                >
                  <ReviewsWrapper
                    type="brand"
                    excerpt={75}
                    ratingColor={ratingColor}
                    hideProvider={hideProvider}
                  />
                </div>
              }
            />
          </Fragment>
        }
      />
    </div>
  )
}

export default ({ className, ratingColor, hideProvider }: Props) => (
  <ReviewsProvider>
    <FullReviews
      className={className}
      ratingColor={ratingColor}
      hideProvider={hideProvider}
    />
  </ReviewsProvider>
)
