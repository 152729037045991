import { h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { useSelector } from "react-redux"
import { Button, Icon, If } from "@ui"
import BtnHeader from "./BtnHeader"
import Table from "./Table"
import { RootStates } from "@types"
import editIcon from "@assets/icons/edit.svg"

// TODO: review types

const LoanProfileView = () => {
  const { values, icon, disable_pricing, noCards, lead_id } = useSelector(
    ({ wizard }: RootStates) => {
      const { values, steps, pricingGridIsLoading, card_details } = wizard ?? {}
      //find in object steps the item with id property_type
      //then find the item with value equal to values.property_type
      //then get the icon from that item
      const icon = steps
        ?.find((step: { id: string }) => step.id === "property_type")
        ?.items?.find(
          (item: { value: any }) => item.value === values?.steps.property_type
        )?.icon

      return {
        values,
        icon,
        disable_pricing: !!wizard?.disable_pricing,
        noCards:
          !pricingGridIsLoading &&
          (!card_details?.cards || card_details?.cards.length === 0),
        lead_id: wizard?.lead_id
      }
    }
  )
  const [open, setOpen] = useState(false)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
  const [loanProfileClassName, setLPClassName] = useState("d-none")

  const toggle = () => {
    setOpen(!open)
  }

  const {
    property_type = "",
    location = "",
    residency_type = ""
  } = values?.steps ?? {}

  const splitString = (string: string) => {
    return string ? string.replace(/_/g, " ") : ""
  }

  useEffect(() => {
    if (open) {
      setLPClassName("d-md-flex")
      timer && clearTimeout(timer)
    } else {
      setTimer(
        setTimeout(() => {
          setLPClassName(open ? "d-md-flex" : "d-none")
        }, 1500)
      )
    }
  }, [open])

  useEffect(() => {
    if (noCards || disable_pricing) {
      setOpen(true)
    }
  }, [noCards])

  return (
    <div className={`wizard__loan-profile is-${open ? "open" : "close"}`}>
      <BtnHeader
        property_type={splitString(property_type)}
        residency_type={splitString(residency_type)}
        location={location}
        open={open}
        onClick={toggle}
        hideToggle={noCards}
      />

      <div
        aria-labelledby="loanProfileToggle"
        id="loanProfile"
        className={`wizard__loan-profile-details ${loanProfileClassName}`}
      >
        <div>
          <img src={icon} className="wizard__loan-profile-details-icon" />
          <ul className="wizard__loan-profile-details-main">
            <li>
              <strong>{splitString(property_type)}</strong>
            </li>
            <li>{splitString(residency_type)}</li>
            <li>{location}</li>
          </ul>

          <If
            condition={!noCards}
            then={
              <Button
                href={`${process.env.DASHBOARD_URL}/new-subscription?lead_id=${lead_id}&active=0&source=embed`}
                link
                className="wizard__loan-profile-details-btn d-none d-md-block"
              >
                <Icon
                  originalSize={20}
                  size={20}
                  src={editIcon}
                  className="mr-2"
                />
                Edit scenario
              </Button>
            }
          />
        </div>
        <Table />
        <If
          condition={!noCards}
          then={
            <Button
              href={`${process.env.DASHBOARD_URL}/new-subscription?lead_id=${lead_id}&active=0&source=embed`}
              link
              className="wizard__loan-profile-details-btn d-block d-md-none mt-3"
            >
              <Icon
                originalSize={20}
                size={20}
                src={editIcon}
                className="mr-2"
              />
              Edit scenario
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default LoanProfileView
