import { Fragment, h } from "preact"
import { useAffordabilityCalc } from "@hooks"
import { HeaderComponent } from "@composite"
import CalcCTA from "../../CalcCTA"
import CalcSummary from "./CalcSummary"
import CalcLineChart from "./CalcLineChart"
import CalcFields from "./CalcFields"
import CalcFooter from "./CalcFooter"
import Skeleton from "./Skeleton"
import { If, Loader } from "@ui"
import CalcMainSlide from "./CalcMainSlide"

interface Props {
  config: any
  hideCTA?: boolean
}

const CalcWrapper = ({ config, hideCTA = false }: Props) => {
  const {
    state,
    isLoading,
    isLoadingInitials,
    handleChange,
    handleMainSliderChange,
    handleCalculate,
    handleEditChartField
  } = useAffordabilityCalc({ nmls: config.nmls })

  const handleInputChange = (
    value: string | number,
    { target: { name } }: { target: { name: string } },
    calculate = false
  ) => {
    handleChange(value, name, calculate)
  }

  const onEditChartField = (value: number | string, fieldName: string) => {
    handleEditChartField(value, fieldName)
  }

  const {
    data,
    form,
    form: { list_price, monthly_payment, mort_pp, monthly_debt },
    marks,
    zipcodePlcHolder
  } = state

  return (
    <div className="afford-calc">
      <HeaderComponent title="Affordability Calculator" />

      <If condition={!hideCTA} then={<CalcCTA />} />

      <If
        condition={isLoadingInitials}
        then={<Skeleton />}
        else={
          <Fragment>
            <div className="afford-calc__body">
              <div className="afford-calc__form">
                <CalcMainSlide
                  mort_pp={mort_pp}
                  handleSliderChange={handleMainSliderChange}
                  handleCalculate={handleCalculate}
                  marks={marks}
                  monthly_payment={monthly_payment}
                />

                <CalcFields
                  zipcodePlcHolder={zipcodePlcHolder}
                  handleInputChange={handleInputChange}
                  handleChange={handleChange}
                  {...form}
                />
              </div>

              <div className="afford-calc__summary-blocks">
                <If
                  condition={isLoading}
                  then={<Loader className="block-loader" />}
                />
                <CalcSummary list_price={list_price} />

                <CalcLineChart
                  onEdit={onEditChartField}
                  data={{
                    ...data,
                    monthly_payment: monthly_payment,
                    monthly_debt: monthly_debt
                  }}
                />
              </div>
            </div>

            <CalcFooter />
          </Fragment>
        }
      />
    </div>
  )
}

export default CalcWrapper
