import { useMemo } from "preact/hooks"

const roundAndConvert = (value: any) => Number(Math.round(value)) || 0

interface Args {
  data: {
    propertyTaxAmount: number
    homeInsurance: number
    pi: number
    mortgageInsurance: number
    hoaDues?: number
    loanTerm: number
    monthly_debt?: number
    loanAmount?: number
    fee?: number
  }
  includePMI: boolean
}

const useCalc = ({ data, includePMI }: Args) => {
  const monthlyPayment = useMemo(() => {
    const {
      pi,
      homeInsurance,
      propertyTaxAmount,
      hoaDues = 0,
      mortgageInsurance
    } = data

    return Math.round(
      pi +
        homeInsurance +
        propertyTaxAmount +
        hoaDues +
        (includePMI ? mortgageInsurance : 0)
    )
  }, [data])

  const chartData = useMemo(() => {
    const {
      pi,
      homeInsurance,
      propertyTaxAmount,
      hoaDues,
      mortgageInsurance,
      monthly_debt
    } = data

    return {
      pi: roundAndConvert(pi),
      homeInsurance: roundAndConvert(homeInsurance),
      propertyTaxAmount: roundAndConvert(propertyTaxAmount),
      mortgageInsurance: mortgageInsurance
        ? roundAndConvert(mortgageInsurance)
        : "…",
      hoaDues: roundAndConvert(hoaDues),
      monthly_debt: roundAndConvert(monthly_debt)
    }
  }, [data])

  const getPercentages = (labelData: { [key: string]: number }) => {
    const tmpLabelData = { ...labelData }
    if (!includePMI) {
      delete tmpLabelData.mortgageInsurance
    }

    // get total
    const total: number = Object.values(tmpLabelData).reduce((a, b) => a + b, 0)

    // calculate percentages
    const percentages = Object.fromEntries(
      Object.entries(tmpLabelData).map(([key, value]) => [
        key,
        (value * 100) / total
      ])
    )

    return percentages
  }

  return { chartData, monthlyPayment, getPercentages }
}

export default useCalc
