import { Fragment, h } from "preact"
import Icon from "../../../Icon"
import If from "../../../If"

interface Option {
  icon?: string
  iconSize?: number | number[]
  iconOriginalSize?: number | number[] | undefined
  value: string
  label: string
  helpTxt?: string
}

interface Props {
  value?: string | number | string[] | number[]
  option: Option | string
  index: number
  handleKeyDown: (code: string, index: number) => void
  optionSelected: (e: Event, option: Option | string) => void
}

const SelectCustomOption = ({
  value,
  option,
  index,
  handleKeyDown,
  optionSelected
}: Props) => {
  const optionClassName = () => {
    if (!value) return ""
    const val =
      typeof value === "number" || Array.isArray(value)
        ? value
        : value.toLowerCase()

    const isObject = typeof option === "object"
    let opt = isObject ? option.value : option
    opt =
      typeof opt === "number" || typeof opt === "boolean"
        ? opt
        : (opt.toLowerCase() as string) // type assertion

    let optLabel = isObject ? option.label?.toLowerCase() : ""

    if (Array.isArray(val)) {
      val.forEach((v, i) => {
        val[i] = typeof v === "string" ? v.toLowerCase() : v
      })
      return val.includes(opt as never) || val.includes(optLabel as never)
        ? "is-selected"
        : ""
    }

    return val === opt || val === optLabel || val === index.toString()
      ? "is-selected"
      : ""
  }

  return (
    <li
      tabIndex={0}
      onKeyDown={(e: KeyboardEvent) => {
        handleKeyDown(e.code, index + 1)
      }}
      className={`form__select-option ${optionClassName()}`}
      onClick={(e: Event) => optionSelected(e, option)}
    >
      <If
        condition={typeof option === "object"}
        then={
          <Fragment>
            {(option as Option)?.icon && (
              <Icon
                className="form__select-icon"
                src={(option as Option).icon as string}
                size={(option as Option)?.iconSize ?? 20}
                originalSize={(option as Option).iconOriginalSize}
              />
            )}
            {(option as Option)?.label}
            <If
              condition={(option as Option)?.helpTxt !== undefined}
              then={
                <span className="form__select-help-txt">
                  {(option as Option)?.helpTxt}
                </span>
              }
            />
          </Fragment>
        }
        else={option}
      />
    </li>
  )
}

export default SelectCustomOption
