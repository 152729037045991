import { h, Fragment } from "preact"
import { Review, If, SimpleSlides } from "@ui"
import { ReviewsViewMode, FormattedReview } from "@types"
import { useIsMobile } from "@contexts"

interface Props {
  excerpt?: number
  reviewsViewMode?: ReviewsViewMode
  ratingColor?: string
  hideProvider?: boolean
  hideReviewBorder?: boolean
  reviews: FormattedReview[]
  mobileMode?: boolean
}

const ReviewsTable = ({
  excerpt = 150,
  reviewsViewMode = "auto",
  ratingColor,
  hideProvider,
  hideReviewBorder,
  reviews,
  mobileMode
}: Props) => {
  const isMobile = useIsMobile()

  return reviews.length ? (
    <If
      condition={reviewsViewMode === "list"}
      then={
        <Fragment>
          {reviews.map((row, index) => {
            return (
              <Review
                row={row}
                index={index}
                key={index}
                excerpt={excerpt * 1.5}
                reviewsViewMode={reviewsViewMode}
                ratingColor={ratingColor}
                hideProvider={hideProvider}
                hideReviewBorder={hideReviewBorder}
              />
            )
          })}
        </Fragment>
      }
      else={
        <SimpleSlides
          className="reviews__table reviews__table-grid"
          hasArrows
          hasDots={!isMobile}
        >
          {(mobileMode ? reviews.slice(0, 10) : reviews).map((row, index) => {
            return (
              <Review
                row={row}
                index={index}
                key={index}
                excerpt={excerpt}
                reviewsViewMode={reviewsViewMode}
                ratingColor={ratingColor}
                hideProvider={hideProvider}
                hideReviewBorder={hideReviewBorder}
              />
            )
          })}
        </SimpleSlides>
      }
    />
  ) : (
    <div className="reviews__table tw-text-center tw-pb-12">
      No reviews found.
    </div>
  )
}

export default ReviewsTable
