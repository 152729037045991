import { Fragment, h } from "preact"
import Icon from "../Icon"
import Button from "../Button"
import { If } from "@ui"
import { ScreenReaderOnly } from "@ui"
import svgLogo from "@assets/logos/bb/one-color-logo.svg"
import "@styles/components/_logo-link-btn.scss"

interface Props {
  className?: string
  size?: "sm" | "md"
  onlyLogo?: boolean
}

const LogoLinkBtn = ({
  className = "",
  size = "md",
  onlyLogo = false
}: Props) => (
  <Button
    outline
    adapt
    {...(!!size ? { size } : {})}
    className={`logo-link-btn ${className}`}
    target="_blank"
    href="https://www.bankingbridge.com/"
  >
    <If
      condition={onlyLogo}
      then={<ScreenReaderOnly>Powered by BankingBridge</ScreenReaderOnly>}
      else={
        <Fragment>
          Powered by <ScreenReaderOnly>BankingBridge</ScreenReaderOnly>
        </Fragment>
      }
    />

    <Icon
      size={size === "sm" ? 20 : 24}
      originalSize={24}
      className={onlyLogo ? "" : "tw-ml-2"}
      src={svgLogo}
    />
  </Button>
)

export default LogoLinkBtn
