import { h, cloneElement } from "preact"
import { ModalClose, ModalBack } from "./"

const ModalBox = ({
  children,
  handleCloseButtonClick,
  handleBackButtonClick,
  hideClose,
  hideBackBtn,
  size,
  closeIsCross,
  hasTopBorder
}) => {
  const props = {
    ...children.props,
    handleCloseButtonClick,
    handleBackButtonClick
  }
  const clonedChild = cloneElement(children, props)
  return (
    <div
      className={`modal box modal--box box--small-gutter ${size} ${
        hasTopBorder ? "box--color-top-border" : ""
      }`}
    >
      <div className="box__content">{clonedChild}</div>
      {!hideBackBtn && (
        <ModalBack handleBackButtonClick={handleBackButtonClick} />
      )}

      <ModalClose
        className={`modal--box__close-btn ${hideClose ? "d-none" : ""}`}
        handleCloseButtonClick={handleCloseButtonClick}
        isCross={closeIsCross}
      />
    </div>
  )
}

export default ModalBox
