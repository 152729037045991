import { Fragment, h } from "preact"
import { Button } from "@ui"
import Icon from "../../Icon"
import { useRateTableToggleSubscription } from "@hooks"
import CHECK_THIN from "@assets/icons/check-thin.svg"
import BELL from "@assets/icons/bell-simple.svg"

const GetAlertsBtn = () => {
  const { subscribed, updateSubscription } = useRateTableToggleSubscription()
  return (
    <Button
      className={`gap-sm font-lg ${
        subscribed ? "tw-pointer-events-none success" : ""
      }`}
      noShadow
      adapt
      link
      noAction={subscribed}
      success={subscribed}
      onClick={() => updateSubscription(true)}
    >
      {subscribed ? (
        <Fragment>
          <Icon size={24} originalSize={24} src={CHECK_THIN} /> Subscribed to
          rate alerts
        </Fragment>
      ) : (
        <Fragment>
          <Icon src={BELL} size={24} originalSize={22} />
          Get rate alerts
        </Fragment>
      )}
    </Button>
  )
}

export default GetAlertsBtn
