import { h } from "preact"
import { useMemo } from "preact/hooks"
import { MORTGAGE_TERMS_CONFIG } from "@constants"
import { OnChangeValue } from "@types"
import { SelectCustom } from "../../inputs"

interface Props {
  value: string | number
  isMaterial?: boolean
  isOnTooltip?: boolean
  name?: string
  onChange: (option: { value: OnChangeValue; name: string }) => void
  label?: string
  size?: string
  options?: Partial<typeof MORTGAGE_TERMS_CONFIG>
}

const LoanTermDropDown = ({
  value,
  isMaterial,
  isOnTooltip,
  name = "loan_term",
  onChange,
  label = "Loan Term",
  size = "md",
  options = MORTGAGE_TERMS_CONFIG
}: Props) => {
  // loop options and change the key term to value
  const filteredOptions = useMemo(
    () =>
      options.map(item => {
        return {
          value: item?.term,
          label: item?.label
        }
      }),
    [options]
  )

  return (
    <SelectCustom
      optionsClassName={isOnTooltip ? "is-on-tooltip" : ""}
      name={name}
      options={filteredOptions}
      value={value}
      onChange={onChange}
      label={label}
      isMaterial={isMaterial}
      size={size}
    />
  )
}
export default LoanTermDropDown
