import { h } from "preact"
import { bbProcessEnv } from "@helpers"
import { SimpleSlides } from "@ui"

const ExitCtaSlider = () => (
  <div className="exit-cta__slider-inner">
    <SimpleSlides hasDots autoplay autoplayInfinite>
      <div className="exit-cta__slide slide1">
        <div className="img-wrapper">
          <img
            className="exit-cta__slide-img"
            src={`${bbProcessEnv.PUBLIC_PATH}assets/exit-cta/slide1.svg`}
            alt="Dashboard preview"
          />
        </div>
        <div className="exit-cta__slide-text">
          Access to your own personalized dashboard
        </div>
      </div>

      <div className="exit-cta__slide slide2">
        <div className="img-wrapper">
          <img
            className="exit-cta__slide-img"
            src={`${bbProcessEnv.PUBLIC_PATH}assets/exit-cta/slide2.svg`}
            alt="Dashboard preview"
          />
        </div>
        <div className="exit-cta__slide-text">
          Accurate real-time rates delivered to your inbox
        </div>
      </div>

      <div className="exit-cta__slide slide3">
        <div className="img-wrapper">
          <img
            className="exit-cta__slide-img"
            src={`${bbProcessEnv.PUBLIC_PATH}assets/exit-cta/slide3.svg`}
            alt="Dashboard preview"
          />
        </div>
        <div className="exit-cta__slide-text">
          Compare multiple loan programs
        </div>
      </div>
    </SimpleSlides>
  </div>
)

export default ExitCtaSlider
