import { h } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"
import { Props, PropsAccent } from "./type"
import "@styles/components/_switch_labeled.scss"

const SwitchLabeled = <T extends string | number>({
  options,
  value,
  label,
  handleClick,
  rounded,
  size = "md",
  theme = "light"
}: Props<T>) => {
  const optionRefs = [
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null)
  ]

  const [style, setStyle] = useState({
    width: 0,
    left: 0
  })

  const handleInteraction = (
    event: MouseEvent | KeyboardEvent,
    newValue: T
  ) => {
    if (
      (event as KeyboardEvent).type === "keydown" &&
      (event as KeyboardEvent).code !== "Enter"
    )
      return
    if (newValue !== value) handleClick(newValue)
  }

  const updateWidth = () => {
    const newStyle = { width: 0, left: 0 }
    const selectedButton =
      value === options[0].value ? optionRefs[0].current : optionRefs[1].current
    if (selectedButton) {
      newStyle.width = selectedButton.offsetWidth
    }

    newStyle.left =
      value === options[0].value ? 0 : selectedButton?.offsetLeft ?? 0

    setStyle(newStyle)
  }

  useEffect(() => {
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => {
      window.removeEventListener("resize", updateWidth)
    }
  }, [value, options])

  return (
    <div
      className={`switch-labeled tw-inline-block ${
        rounded ? "tw-rounded-full tw-p-2" : "tw-rounded-xl tw-p-1"
      } is-${size} is-${theme}`}
    >
      <label className="tw-sr-only">{label}</label>
      <div
        className="switch-labeled__options tw-h-full tw-relative tw-inline-flex tw-cursor-pointer tw-items-center gap-xs"
        role="tablist"
        aria-label={label}
      >
        {options.map((option, index) => (
          <button
            className={`switch-labeled__option ${
              size === "lg" ? "tw-px-12" : "tw-px-3"
            } tw-py-2`}
            tabIndex={0}
            aria-selected={value === option.value}
            onKeyDown={e => handleInteraction(e, option.value)}
            onClick={e => handleInteraction(e, option.value)}
            ref={optionRefs[index]}
            key={option.value}
            type="button"
          >
            {option.label}
          </button>
        ))}

        <span
          aria-hidden="true"
          className={`switch-labeled__option-bg tw-h-full ${
            rounded ? "tw-rounded-full" : "tw-rounded-lg"
          }`}
          style={{
            width: `${style.width}px`,
            left: `${style.left}px`
          }}
        />
      </div>
    </div>
  )
}

const SwitchLabeledAccentColor = <T extends string | number>(
  props: PropsAccent<T>
) => <SwitchLabeled<T> {...props} theme="accent" />

export { SwitchLabeledAccentColor, SwitchLabeled }
