import { h } from "preact"
import { useSelector } from "react-redux"
import { RootStates } from "@types"
import { If } from "@ui"

const LiveRates = () => {
  const showLiveRates = useSelector(({ wizard }: RootStates) => {
    const showLiveRates =
      !wizard?.pricingGridIsLoading &&
      (wizard?.card_details?.cards.length ?? 0) > 0 &&
      !wizard?.disable_pricing

    return showLiveRates
  })

  return (
    <If
      condition={showLiveRates}
      then={
        <div className="wizard__form-live-rates">
          <span>live rates</span>
        </div>
      }
    />
  )
}

export default LiveRates
