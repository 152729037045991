/**
 * Redux actions constant
 */

export const GET_WIZARDS_STEPS_API = "GET_WIZARDS_STEPS_API"
export const SET_STORAGE_KEY = "SET_STORAGE_KEY"
export const STEPS_LOADED = "STEPS_LOADED"
export const API_ERRORED = "API_ERRORED"
export const UPDATE_FORM_VALUES = "UPDATE_FORM_VALUES"
export const UPDATE_CURRENT_STEP = "UPDATE_CURRENT_STEP"
export const GET_PRICING_API = "GET_PRICING_API"
export const GET_PRICING = "GET_PRICING"
export const PRICING_LOADED = "PRICING_LOADED"
export const SAVE_RT_STATE = "SAVE_RT_STATE"
export const SAVE_RT_STATE_TO_STORE = "SAVE_RT_STATE_TO_STORE"
export const RESET_STATE = "RESET_STATE"
export const SEND_FORM = "SEND_FORM"
export const LEAD_MANAGE_API = "LEAD_MANAGE_API"
export const FORM_SENT = "FORM_SENT"
export const SEND_CODE = "SEND_CODE"
export const RESEND_CODE = "RESEND_CODE"
export const LOAN_TERM_CHANGE = "LOAN_TERM_CHANGE"
export const HANDLE_MEDIA_CHANGE = "HANDLE_MEDIA_CHANGE"
export const GET_RATE_TABLE_CONFIG_API = "GET_RATE_TABLE_CONFIG_API"
export const RATE_TABLE_CONFIG_LOADED = "RATE_TABLE_CONFIG_LOADED"
export const GET_RATE_TABLE_PRICING = "GET_RATE_TABLE_PRICING"
export const INIT_RATE_TABLE_PRICING = "INIT_RATE_TABLE_PRICING"
export const UPDATE_RATE_FORM = "UPDATE_RATE_FORM"
export const RATE_FORM_IS_DIRTY = "RATE_FORM_IS_DIRTY"
export const RATE_TABLE_DATA_LOADED = "RATE_TABLE_DATA_LOADED"
export const TOGGLE_LOADING = "TOGGLE_LOADING"
export const ON_LOAN_TYPE_CHANGE = "ON_LOAN_TYPE_CHANGE"
export const UPDATE_RATE_SUBSCRIPTION = "UPDATE_RATE_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const TOGGLE_RATE_TABLE_FORM = "TOGGLE_RATE_TABLE_FORM"
export const RATE_FORM_SUBMITTED = "RATE_FORM_SUBMITTED"
export const RATE_FORM_SUBMITTED_ALL = "RATE_FORM_SUBMITTED_ALL"
export const INIT_DASH = "INIT_DASH"
export const GET_SUBSCRIPTION_DATA_API = "GET_SUBSCRIPTION_DATA_API"
export const DASH_DATA_LOADED = "DASH_DATA_LOADED"
export const UPDATE_DASH_FORM = "UPDATE_DASH_FORM"
export const SAVE_SUBSCRIPTION_DATA_API = "SAVE_SUBSCRIPTION_DATA_API"
export const UPDATE_MORTGAGE_CALC = "UPDATE_MORTGAGE_CALC"
export const UPDATE_SUBSCRIBE_SUBMITTED = "UPDATE_SUBSCRIBE_SUBMITTED"
export const SET_FORM_UPDATED = "SET_FORM_UPDATED"
export const GET_LO_DATA_API = "GET_LO_DATA_API"
export const TOGGLE_CONTACT_MODAL = "TOGGLE_CONTACT_MODAL"
export const TOGGLE_OFFER_DETAILS_MODAL = "TOGGLE_OFFER_DETAILS_MODAL"
export const TOGGLE_RATE_TABLE_MODAL = "TOGGLE_RATE_TABLE_MODAL"
export const TOGGLE_SUBSCRIBE_MODAL = "TOGGLE_SUBSCRIBE_MODAL"
export const SAVE_DASH_STATE = "SAVE_DASH_STATE"
export const ON_MORTGAGE_PROFILE_SELECT = "ON_MORTGAGE_PROFILE_SELECT"
export const SAVE_MORTGAGE_STATE = "SAVE_MORTGAGE_STATE"
export const ON_RT_SAVE_SELECTED_ROW = "ON_RT_SAVE_SELECTED_ROW"
export const SAVE_STATE_FROM_PARENT_MIDDLE = "SAVE_STATE_FROM_PARENT_MIDDLE"
export const GET_DASH_CARDS = "GET_DASH_CARDS"
export const DASH_PRICING_LOADED = "DASH_PRICING_LOADED"
export const GET_SUBS_QUOTE_API = "GET_SUBS_QUOTE_API"
export const SAVE_DASH_CARDS = "SAVE_DASH_CARDS"
export const PUSH_TOAST_ITEM = "PUSH_TOAST_ITEM"
export const SPLICE_TOAST_ITEM = "SPLICE_TOAST_ITEM"
export const SAVE_WIZARD_STATE = "SAVE_WIZARD_STATE"
export const GET_OFFER_DETAILS = "GET_OFFER_DETAILS"
export const GET_OFFER_DETAILS_API = "GET_OFFER_DETAILS_API"
export const OFFER_DETAILS_LOADED = "OFFER_DETAILS_LOADED"
export const SET_CURRENT_DETAILS_CARD = "SET_CURRENT_DETAILS_CARD"
export const GET_PRICING_BEFORE_LEAD = "GET_PRICING_BEFORE_LEAD"
export const UPDATE_LEAD_DATA = "UPDATE_LEAD_DATA"
export const WIZARD_LEAD_DATA_UPDATED = "WIZARD_LEAD_DATA_UPDATED"
export const SAVE_BASE_STATE = "SAVE_BASE_STATE"
export const GET_TAXES_AND_INSURANCE_API = "GET_TAXES_AND_INSURANCE_API"
export const GET_TAXES_AND_INSURANCE = "GET_TAXES_AND_INSURANCE"
export const TAXES_AND_INSURANCE_LOADED = "TAXES_AND_INSURANCE_LOADED"
export const GET_LOS_BY_BRAND_ID = "GET_LOS_BY_BRAND_ID"
export const GET_LOS_BY_BRAND_ID_API = "GET_LOS_BY_BRAND_ID_API"
export const LOS_LOADED = "LOS_LOADED"
export const GET_USER_LOCATION_API = "GET_USER_LOCATION_API"
export const LOCATION_LOADED = "LOCATION_LOADED"
export const SAVE_WIZARD_VALUES = "SAVE_WIZARD_VALUES"
export const SAVE_BASE_STATE_TO_STORE = "SAVE_BASE_STATE_TO_STORE"
export const TOGGLE_WELCOME_MODAL = "TOGGLE_WELCOME_MODAL"
export const SAVE_WIZARD_DETAILS = "SAVE_WIZARD_DETAILS"
export const SET_CLASS_NAMES = "SET_CLASS_NAMES"
export const SAVE_ASSUMPTIONS = "SAVE_ASSUMPTIONS"
export const SET_FEATURED_RATE = "SET_FEATURED_RATE"
