import { h } from "preact"

interface DotsProps {
  numScrollPositions: number
  currentIndex: number
  scrollToIndex: (index: number) => void
}

const Dots = ({
  numScrollPositions,
  currentIndex,
  scrollToIndex
}: DotsProps) => (
  <div className="simple-slides__dots" role="tablist" aria-label="Slider Dots">
    {Array.from({ length: numScrollPositions }).map((_, index) => (
      <button
        key={index}
        className={`simple-slides__dot ${
          currentIndex === index ? "is-active" : ""
        }`}
        onClick={() => scrollToIndex(index)}
        aria-label={`Go to position ${index + 1}`}
        role="tab"
        aria-selected={currentIndex === index}
      >
        ●
      </button>
    ))}
  </div>
)

export default Dots
