import { h } from "preact"
import { useMemo } from "preact/hooks"
import CountUp from "react-countup"
import Line from "./Line"
import ChartLegend from "./ChartLegend"
import Tooltip from "../Tooltip"
import { If, LineChartLoading } from "@ui"
import { toFixed } from "@helpers"
import { FIELD_TYPE, FIELD_TYPES, PARENT_TYPE } from "@types"
import { useCalc } from "@hooks"
import "@styles/components/_line-chart.scss"

interface Props {
  data: {
    propertyTaxAmount: number
    homeInsurance: number
    pi: number
    mortgageInsurance: number
    hoaDues: number
    loanTerm: number
    apr: number
    monthly_payment: number
    monthly_debt?: number
  }
  includePMI?: boolean
  hasMonthlyPayment?: boolean
  includeHOA?: boolean
  totalTooltipTxt?: string
  isLoading?: boolean
  isVertical?: boolean
  isChartOnTop?: boolean
  oneColumnLabels?: boolean
  parent?: PARENT_TYPE
  title?: string
  className?: string
  editableFields?: FIELD_TYPES
  onEdit?: (value: number | string, fieldName: FIELD_TYPE) => void
}

const LineChart = ({
  data: {
    propertyTaxAmount = 0,
    homeInsurance = 0,
    pi = 0,
    mortgageInsurance = 0,
    hoaDues = 0,
    loanTerm = 0,
    apr = 0,
    monthly_payment = 0,
    monthly_debt = 0
  },
  includePMI,
  hasMonthlyPayment,
  includeHOA = true,
  totalTooltipTxt,
  isLoading = false,
  isVertical,
  isChartOnTop,
  parent = "general",
  oneColumnLabels = true,
  title,
  className = "",
  editableFields = [],
  onEdit
}: Props) => {
  const labels = {
    pi,
    homeInsurance,
    propertyTaxAmount,
    mortgageInsurance,
    monthly_debt,
    hoaDues: 0
  }

  labels.hoaDues = includeHOA ? hoaDues : 0

  const { monthlyPayment, chartData, getPercentages } = useCalc({
    data: {
      pi,
      homeInsurance,
      propertyTaxAmount,
      hoaDues,
      mortgageInsurance,
      loanTerm,
      monthly_debt
    },
    includePMI: !!includePMI
  })

  const percentages = useMemo(() => getPercentages(labels), [labels])

  // Affordability has a slider for monthly payment
  const total = hasMonthlyPayment
    ? Number(toFixed(monthly_payment, 0))
    : monthlyPayment

  const isInOfferDetailsPayment = parent === "offer-details-payment"

  return (
    <If
      condition={isLoading}
      then={<LineChartLoading />}
      else={
        <div
          className={`linear-chart ${
            isVertical ? "is-vertical flex-column" : ""
          } in-${parent} ${className}`}
        >
          <div className="linear-chart__total-wrapper">
            <div className="linear-chart__total">
              $
              <CountUp duration={1} end={total} separator="," />
              <If
                condition={isInOfferDetailsPayment}
                then={<div className="linear-chart__total-sub">Per month</div>}
                elseCondition={!title}
                else={<span className="linear-chart__total-detail">/mo</span>}
              />
            </div>

            <If
              condition={!!title}
              then={<h3 className="linear-chart__total-title">{title}</h3>}
            />

            {loanTerm ? (
              <div className="linear-chart__total-description">
                {loanTerm == 360 ? "30-Year Fixed" : "15-Year Fixed"}, {apr}%
                APR
                {totalTooltipTxt && (
                  <Tooltip ttPlacement="right">{totalTooltipTxt}</Tooltip>
                )}
              </div>
            ) : null}
          </div>

          <div
            className={`linear-chart__draw ${
              isVertical
                ? isChartOnTop
                  ? "d-flex flex-column is-chart-on-top"
                  : "d-flex flex-column-reverse"
                : ""
            }`}
          >
            <Line
              percentages={percentages}
              includePMI={includePMI}
              includeHOA={includeHOA}
              {...chartData}
            />
            <ChartLegend
              editableFields={editableFields}
              onEdit={onEdit}
              isVertical={oneColumnLabels}
              {...chartData}
              includePMI={includePMI}
              includeHOA={includeHOA}
            />
          </div>
        </div>
      }
    />
  )
}

export default LineChart
