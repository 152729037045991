import { useSelector } from "react-redux"
import { RootStates, WizardInterface } from "@types"
import { numberToUSD } from "@helpers"

const splitString = (string: string) => {
  let newString = string ? string.replace(/_/g, " ") : ""
  return newString.charAt(0).toUpperCase() + newString.slice(1)
}

const useLeadWorkflowEmailTo = () => {
  const { loEmail, bank, formData, userInfo } = useSelector(
    ({ app, wizard }: RootStates) => {
      const { values } = wizard as WizardInterface

      return {
        loEmail: app?.lo?.email,
        bank: app?.lo?.bank,
        formData: values.steps,
        userInfo: values.login_form.form
      }
    }
  )

  const subject = `My recent ${bank} website submission`

  const property_type = splitString(formData?.property_type ?? "")
  const residency_type = splitString(formData?.residency_type ?? "")
  const loan_purpose = splitString(formData?.loan_purpose ?? "")

  const body =
    `Hi, I just submitted a form on your website and I wanted to share my information with you. Here are my details: \n\n` +
    `Loan Purpose: ${loan_purpose}\n` +
    `Property Type: ${property_type}\n` +
    `Residency Type: ${residency_type}\n` +
    `List Price: ${numberToUSD(formData?.list_price ?? 0)}\n` +
    `${
      formData?.loan_purpose === "refinance"
        ? `Cash Out: ${numberToUSD(formData?.cash_out ?? 0)}\n`
        : `Down Payment: ${numberToUSD(formData?.down_payment ?? 0)}\n`
    }` +
    `Credit Score: ${formData?.credit_score}\n\n` +
    `Thanks,\n\n` +
    `${userInfo?.first_name} ${userInfo?.last_name}\n` +
    `${userInfo?.email}\n` +
    `${userInfo?.phone}`

  const formattedEmailTo = loEmail
    ? `mailto:${loEmail}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`
    : ""

  return formattedEmailTo
}

export default useLeadWorkflowEmailTo
