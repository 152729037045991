import { h } from "preact"
import { Icon } from "@ui"
import Padlock from "@assets/icons/padlock.svg"

const PadlockIcon = () => (
  <span className="wizard__form-lock" aria-hidden={true}>
    <Icon size={[15, 18]} originalSize={[15, 18]} src={Padlock} />
  </span>
)

export default PadlockIcon
