import { Fragment, h } from "preact"
import SelectCustom from "../SelectCustom"
import Icon from "../../../Icon"

interface Option<T> {
  label: string
  value: T
}

interface Props<T> {
  value?: T
  onChange: (value: T) => void
  options: readonly Option<T>[]
  className?: string
  name?: string
  label?: string
  placeholder?: string
  icon: string
  iconSize?: number[]
}

const IconDropDown = <T extends string>({
  value,
  onChange,
  options,
  className = "",
  name,
  label,
  placeholder,
  icon,
  iconSize = [20, 20]
}: Props<T>) => {
  return (
    <SelectCustom
      showPlaceholder
      isIconLabel
      popperPlacement="bottom-end"
      notStacked
      className={className}
      labelClassName="tw-cursor-pointer"
      name={name}
      placeholder={placeholder}
      label={
        <Fragment>
          <span className="tw-sr-only">{label}</span>

          <Icon src={icon} size={iconSize[0]} originalSize={iconSize[1]} />
        </Fragment>
      }
      options={options}
      value={value}
      onChange={(option: Option<T>) => {
        onChange(option.value)
      }}
    />
  )
}

export default IconDropDown
