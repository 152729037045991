import { h } from "preact"

interface Props {
  src: string
  className?: string
  width: number
  height: number
  viewBox?: string
}

const SVG = ({ src, className = "", width, height, viewBox }: Props) => {
  const svgContainer = document.createElement("div")
  svgContainer.innerHTML = src
  const svg = svgContainer.firstElementChild?.innerHTML ?? ""

  return (
    <svg
      {...(viewBox ? { viewBox } : {})}
      className={className}
      width={width}
      height={height}
      dangerouslySetInnerHTML={{ __html: svg }}
      aria-hidden="true"
    />
  )
}

export default SVG
