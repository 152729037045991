import googleIcon from "@assets/logos/google-logo-color.svg"
import appleIcon from "@assets/logos/apple-logo.svg"
import facebookIcon from "@assets/logos/facebook-logo-color.svg"

export const OAUTH_PROVIDERS = {
  google: {
    icon: googleIcon,
    iconSize: [24, 24],
    iconOriginalSize: [24, 24]
  },
  apple: {
    icon: appleIcon,
    iconSize: [20, 24],
    iconOriginalSize: [20, 24]
  },
  facebook: {
    icon: facebookIcon,
    iconSize: [24, 24],
    iconOriginalSize: [24, 24]
  }
}
