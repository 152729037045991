/**
 * GTAG functions used on the wizard workflow
 */
import { eventDispatch } from "@helpers"

const gtagFormInit = () => {
  let category = "contact_form"
  let event_name = "contact_form_loaded"
  eventDispatch({
    name: event_name,
    category: category
  })
}

const gtagFormStart = () => {
  let category = "contact_form"
  let event_name = "contact_form_started"
  eventDispatch({
    name: event_name,
    category: category
  })
}

const gtagFormSubmitted = () => {
  let category = "contact_form"
  let event_name = "lead_captured"
  eventDispatch({
    name: event_name,
    category: category
  })
}

export { gtagFormInit, gtagFormStart, gtagFormSubmitted }
