import { h } from "preact"
import { If } from "@ui"

type Props = {
  title: string
  subTitle?: string
}

const BoxHeader = ({ title, subTitle }: Props) => (
  <header className="box__header">
    <h3 className="txt-dark font-bold tw-m-0 tw-text-xl">{title}</h3>
    <If condition={!!subTitle} then={<h4 className="tw-m-0">{subTitle}</h4>} />
  </header>
)

export default BoxHeader
