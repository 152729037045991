import { Fragment, h } from "preact"
import { LoanDetailsFormFields, LocationObj, OnChangeValue } from "@types"
import { If, Form } from "@ui"
import { useAppContext } from "@contexts"

interface Props {
  hasARMTerms: boolean
  isOnTooltip?: boolean
  showFields: boolean
  hasLoanTerm?: boolean
  formData: LoanDetailsFormFields
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
  numericInputChange: (value: string, e: Event) => void
  disabledFields?: Array<keyof LoanDetailsFormFields>
}

const ResponsiveRefinanceFields = ({
  hasARMTerms,
  isOnTooltip,
  showFields,
  hasLoanTerm = false,
  formData: {
    loan_type,
    loan_term,
    va_funding_fee_type,
    loan_purpose,
    cash_out
  },
  formData,
  handleOnChange,
  numericInputChange,
  disabledFields = []
}: Props) => {
  const {
    media: {
      flags: { isMobile, isTablet }
    }
  } = useAppContext()

  return (
    <If
      condition={showFields}
      then={
        <Fragment>
          <If
            condition={isMobile && !disabledFields.includes("zipcode")}
            then={
              <Form.AutoZipcode
                onChange={(value: LocationObj) => {
                  handleOnChange(value, "location")
                }}
                showErrorWhenEmpty
                initialValue={
                  (formData.location as LocationObj)?.zipcode ||
                  formData.zipcode
                }
              />
            }
          />

          <If
            condition={isTablet}
            then={
              <Form.CashOutField
                onChange={numericInputChange}
                value={cash_out}
              />
            }
          />

          <If
            condition={hasLoanTerm}
            then={
              <Form.LoanTermDropDown
                isOnTooltip={isOnTooltip}
                isMaterial
                value={loan_term ?? 30}
                onChange={(option: { value: OnChangeValue }) =>
                  handleOnChange(option.value, "loan_term")
                }
              />
            }
          />

          <Form.LoanTypeDropDown
            optionsClassName={isOnTooltip ? "is-on-tooltip" : ""}
            value={loan_type}
            va_funding_fee_type={va_funding_fee_type}
            onChange={handleOnChange}
            hasARMOption={hasARMTerms}
            loan_purpose={loan_purpose}
          />
        </Fragment>
      }
    />
  )
}

export default ResponsiveRefinanceFields
