import { h } from "preact"
import TxtLoading from "./Txt"
import { useIsMobile } from "@contexts"
import StarLoader from "./Star"
import SVG from "../SVG"
import AVATAR from "@assets/lo-page/avatar.svg"

const HeaderLoSkeleton = () => {
  const isMobile = useIsMobile()

  const txtHeight = isMobile ? 24 : 36

  return (
    <div>
      <TxtLoading height={isMobile ? 155 : 240} borderRadius={0} />

      <div className="container tw-flex-col tw-z-10 tw-relative !tw-overflow-visible tw-px-4 lg:tw-px-6 xl:tw-px-0">
        <SVG
          className={
            isMobile ? "tw-mt-[-95px] tw-ml-4" : "tw-mt-[-142px] tw-ml-5"
          }
          src={AVATAR}
          width={isMobile ? 152 : 226}
          height={isMobile ? 152 : 226}
          viewBox="0 0 227 228"
        />
        <div className="tw-flex tw-justify-between tw-w-full tw-pt-9">
          <div>
            <TxtLoading height={txtHeight} width="330px" />
            <TxtLoading height={txtHeight} width="208px" margin="8px 0 0 0" />
          </div>
          <div className="tw-hidden md:tw-flex tw-flex-col tw-items-end">
            <TxtLoading height={txtHeight} width="330px" />
            <TxtLoading height={txtHeight} width="208px" margin="8px 0 0 0" />
          </div>
        </div>
        <StarLoader
          className={isMobile ? "tw-mt-9" : "tw-mt-14"}
          width={215}
          size={txtHeight}
        />
        <div>
          <TxtLoading
            height={txtHeight}
            margin={isMobile ? "48px 0 0" : "116px 0 0"}
          />
          <TxtLoading height={txtHeight} width="45%" margin="8px 0 0 0" />
        </div>
      </div>
    </div>
  )
}

export default HeaderLoSkeleton
