import { h } from "preact"
import { OptionButton } from "@types"
import If from "../../../If"
import AccentColor from "../../../AccentColor"
import "@styles/components/_option-buttons.scss"

interface ButtonsProps {
  onInput: (e: InputEvent) => void
  label?: string
  options: OptionButton[]
  value: string | number | boolean | undefined
  name: string
  className?: string
  buttonClassName?: string
}

const DEFAULT_STRING = ""

const Buttons = ({
  onInput,
  label = DEFAULT_STRING,
  options,
  value,
  name,
  className = DEFAULT_STRING,
  buttonClassName = DEFAULT_STRING
}: ButtonsProps) => {
  const onClick = (value: string) => {
    const event = new InputEvent("input", { bubbles: true })
    Object.defineProperty(event, "target", {
      writable: false,
      value: { name, value }
    })
    onInput(event)
  }

  const handleKeyDown = (code: string, value: string) => {
    if (code === "Enter") {
      onClick(value)
    }
  }

  return (
    <div
      className={`option-buttons d-flex flex-column flex-md-row justify-content-center align-items-center ${className}`}
    >
      {label}
      {options.map(option => {
        return (
          <button
            onKeyDown={(e: KeyboardEvent) =>
              handleKeyDown(e.code, option.value)
            }
            onClick={() => onClick(option.value)}
            tabIndex={0}
            className={`option-buttons__item d-flex align-items-center justify-content-center ${buttonClassName} ${
              value === option.value ? "is-checked" : ""
            } `}
            key={option.value}
            type="button"
          >
            <If
              condition={!!option.icon}
              then={
                <img
                  className="option-buttons__icon"
                  src={option.icon}
                  alt=""
                />
              }
            />
            <AccentColor>{option.label}</AccentColor>
          </button>
        )
      })}
    </div>
  )
}

export default Buttons
