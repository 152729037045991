import { h } from "preact"
import TxtLoading from "./Txt"
import "@styles/components/_lead-workflow-loader.scss"

const LeadWorkflowSkeleton = () => (
  <div className="lead-workflow-loader">
    <div className="lead-workflow-loader__header">
      <TxtLoading height={20} className="lead-workflow-loader__header-title" />
      <TxtLoading
        height={20}
        className="lead-workflow-loader__header-subtitle"
      />
    </div>
    <div className="lead-workflow-loader__content">
      <TxtLoading height={75} className="lead-workflow-loader__content-block" />
      <TxtLoading
        height={75}
        className="lead-workflow-loader__content-block d-none d-md-block"
      />
    </div>
    <div className="lead-workflow-loader__footer">
      <div className="lead-workflow-loader__footer-item">
        <TxtLoading height={20} />
        <TxtLoading height={20} width="55%" />
      </div>
      <div className="lead-workflow-loader__footer-item d-none d-md-block">
        <TxtLoading height={20} />
        <TxtLoading height={20} width="55%" />
      </div>
      <div className="lead-workflow-loader__footer-item d-none d-md-block">
        <TxtLoading height={20} />
        <TxtLoading height={20} width="55%" />
      </div>
    </div>
  </div>
)

export default LeadWorkflowSkeleton
