import { h, Fragment, JSX } from "preact"
import { useEffect, useRef } from "preact/hooks"
import { useDispatch } from "react-redux"
import clsx from "clsx"
import {
  PricingItemHeaderCompact,
  PricingItemBodyCompact,
  PricingItemBodyRTM,
  ApplyBtn,
  CompareCheckbox
} from "./partials"
import { debounce, openModalFromBtn, gtagWorkflowFeesClick } from "@helpers"
import { PricingItem as PricingItemInterface, PricingItemType } from "@types"
import { If } from "@ui"
import DashboardLink from "../LeadWorkflowPricingRows/DashboardLink"
import { useLenderFees } from "@hooks"
import GetStartedButton from "./partials/GetStartedButton"
import DetailsButton from "./partials/DetailsButton"
import {
  getOfferDetails,
  setCurrentOfferDetailsCard,
  toggleOfferDetailsModal
} from "@actions"
import { useAppContext } from "@contexts"
import PricingItemBodyDashMobile from "./partials/PricingItemBodyDashMobile"
import PricingItemBodyRTMobile from "./partials/PricingItemBodyRTMobile"
import "@styles/components/_pricing-item.scss"

type Props = {
  item: PricingItemInterface
  index?: number
  onClick?: () => void
  hideFeesModal?: boolean
  type: PricingItemType
  className?: string
  role?: JSX.HTMLAttributes["role"]
}

const PricingItem = ({
  item,
  index = 0,
  onClick,
  hideFeesModal,
  type,
  className = "",
  role
}: Props) => {
  const {
    comparisonTool,
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  const eleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    handleElementsWidth()
  }, [])

  const setTermEleHeight = () => {
    const row = eleRef.current
    if (!row) return
    const ele = row.getElementsByClassName("is-term")[0] as HTMLElement
    if (!ele) return
    const eleFees = row.getElementsByClassName("is-fees")[0] as HTMLElement
    const eleInner = Array.from(
      row.getElementsByClassName(
        "is-term-inner"
      ) as HTMLCollectionOf<HTMLElement>
    )[0]
    ele.style.marginLeft = window.getComputedStyle(eleFees).marginLeft
    ele.style.height = window.getComputedStyle(eleFees).height
    if (eleInner) eleInner.style.left = `calc(100% - ${eleInner.clientWidth}px)`
  }

  const handleElementsWidth = debounce(() => {
    setTermEleHeight()
  }, 20)

  const handleClick = (e: Event) => {
    // If the user clicks the info button to summon the tooltip, this
    // function should be a no-op.
    if ((e.target as HTMLElement).className === "pricing-item__tooltip") {
      return
    }
    onClick?.()
  }

  const lenderFees = useLenderFees(item)

  const dispatch = useDispatch()

  const handleDetailsButtonClick = () => {
    dispatch(setCurrentOfferDetailsCard(item))

    if (type === "dash") {
      dispatch(toggleOfferDetailsModal({ visible: true }))
      dispatch(getOfferDetails({ quote_id: item.quote_id }))
    } else {
      dispatch(
        getOfferDetails({
          quote_id: item.quote_id,
          callback: () => {
            openModalFromBtn(`${location.origin}/external/offer_details_modal`)
          }
        })
      )
    }
    if (type === "wizard") {
      gtagWorkflowFeesClick()
    }
  }

  let checked = false

  if (comparisonTool) {
    const { isItemInComparison } = comparisonTool
    checked = isItemInComparison(item)
  }

  const classNames = clsx(`pricing-item is-${type}`, className, {
    "is-checked": checked
  })

  return (
    <div
      ref={eleRef}
      className={classNames}
      onClick={(e: any) => handleClick(e)}
      {...(onClick ? { tabIndex: 0 } : {})}
      {...(role ? { role: role } : {})}
    >
      {type === "rtm" ? (
        <PricingItemBodyRTM item={item} fees={lenderFees} />
      ) : (
        <Fragment>
          <PricingItemHeaderCompact rate={item.rate} apr={item.apr} />

          <If
            condition={type === "dash"}
            then={
              <PricingItemBodyDashMobile
                quote_id={item.quote_id}
                item={item}
                hideFeesModal={hideFeesModal}
                fees={lenderFees}
              />
            }
            elseCondition={type === "rt"}
            else={
              <PricingItemBodyRTMobile
                hideFeesModal={hideFeesModal}
                item={item}
                fees={lenderFees}
                type={type}
              />
            }
            default={
              <PricingItemBodyCompact
                hideFeesModal={hideFeesModal}
                item={item}
                quote_id={item.quote_id}
                fees={lenderFees}
                type={type}
              />
            }
          />

          <If
            condition={type === "wizard"}
            then={
              <div className="pricing-item__apply-block d-flex">
                <ApplyBtn
                  type={type}
                  className="pricing-item__apply-btn ml-auto"
                />
              </div>
            }
          />

          <If
            condition={type === "rt" && !!onClick}
            then={
              <div className="pricing-item__apply-block d-flex">
                <GetStartedButton className="pricing-item__apply-btn ml-auto" />
              </div>
            }
          />

          <If
            condition={type === "dash"}
            then={
              <div className="pricing-item__apply-block d-flex align-items-center">
                <If
                  condition={item.arm_term === ""}
                  then={<CompareCheckbox item={item} />}
                />
                <DetailsButton
                  className="pricing-item__apply-btn ml-auto font-bolder"
                  outline={false}
                  isLite
                  md={false}
                  handleClickFees={handleDetailsButtonClick}
                />
              </div>
            }
          />

          <If
            condition={type === "wizard" && index >= 2}
            then={<DashboardLink className="flex-column-reverse" />}
          />
        </Fragment>
      )}
    </div>
  )
}

export default PricingItem
