import { createContext } from "preact"
import {
  AppBaseState,
  ComparisonTool,
  MediaFlags,
  MediaSize,
  ReviewsState
} from "@types"

interface AppContextProps {
  app?: Partial<AppBaseState>
  media: {
    currentMedia: MediaSize
    flags: MediaFlags
  }
  reviews: ReviewsState
  globalActions: {
    closeGlobalModalFromWithin: () => void
  }
  comparisonTool?: ComparisonTool
}

const AppContext = createContext<AppContextProps | undefined>(undefined)

export default AppContext
