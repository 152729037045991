import axios from "axios"
import { bbProcessEnv } from "@helpers"

interface MortgageTerm {
  type: "fixed" | "arm"
  term: string
  label: string
}

interface RateTableSettings {
  api_mode: string
  prepaid_interest_days: number
  api_config: {
    fee_engine: string
  }
}

interface RateTableDefaults {
  lock_period: number
  loan_type: string
  credit_score: number
}

interface RateTableCta {
  header: string
  subHeader: string
  button: string
}

interface RateTableDetails {
  subscription_cta_enabled: boolean
  exclude_govt_programs: boolean
  featured_rates_tab_enabled: boolean
  settings: RateTableSettings
  mortgage_terms_old: MortgageTerm[]
  mortgage_terms: MortgageTerm[]
  defaults: RateTableDefaults
  usage_limit: number
  rateflow_cta: RateTableCta
  disabled_fields: string[]
  fees_modal_disabled: boolean
  sort_by_default_: string
}

interface RateTableResponse {
  id: string
  brand_id: string
  name: string
  details: RateTableDetails
  is_default: string
}

export default {
  getConfig: async () => {
    try {
      const response = await axios.get<RateTableResponse>(
        `${bbProcessEnv.API_URL}/api/v1/rate-table-config`
      )
      return response?.data?.details ?? null
    } catch (error) {
      console.log(error)
    }
  }
}
