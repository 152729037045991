import { h } from "preact"
import { PricingItemType } from "@types"
import Fees from "./Fees"

interface Props {
  type: PricingItemType
  fees: number
  hideFeesModal?: boolean
  handleClickFees: ({ e, openModal }: { e: Event; openModal?: boolean }) => void
}

const FeesBlock = ({ type, fees, hideFeesModal, handleClickFees }: Props) => {
  const clickProps =
    type !== "wizard" && !hideFeesModal
      ? {
          role: "button" as h.JSX.AriaRole,
          tabIndex: 0,
          onClick: (e: Event) => handleClickFees({ e })
        }
      : {}

  return (
    <div {...clickProps} className="pricing-item__details is-fees">
      <Fees fees={fees} />
      <small className="pricing-item__label-notooltip accent-color">Fees</small>
    </div>
  )
}

export default FeesBlock
