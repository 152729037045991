/**
 * GTAG functions used on the wizard workflow
 */
import eventDispatch from "../eventDispatch"

let initGTAGFlag = false

export const initGTAG = () => {
  if (!initGTAGFlag) {
    eventDispatch({
      name: "lead_workflow_loaded",
      category: "lead_workflow"
    })
    initGTAGFlag = true
  }
}

export const gtagWorkflowStart = () => {
  eventDispatch({
    name: "lead_workflow_started",
    category: "lead_workflow"
  })
}

export const gtagWorkflowProgress = (percent: number) => {
  let percentStr = ""
  switch (percent) {
    case 25:
      percentStr = "twentyFive"
      break
    case 50:
      percentStr = "fifty"
      break
    case 75:
      percentStr = "seventyFive"
      break
  }
  percentStr += "Percent"
  const name = "progress_" + percentStr
  eventDispatch({
    name,
    category: "lead_workflow"
  })
}

export const gtagWorkflowEnd = () => {
  eventDispatch({
    name: "lead_workflow_completed",
    category: "lead_workflow"
  })
}

export const gtagGoogleLoginClicked = (embed: string = "lead_workflow") => {
  eventDispatch({
    name: "google_login_attempt",
    category: embed
  })
}

export const gtagGoogleLoginSuccess = (embed: string = "lead_workflow") => {
  eventDispatch({
    name: "google_login_success",
    category: embed
  })
}

export const gtagWorkflowFeesClick = () => {
  eventDispatch({
    name: "fees_click",
    category: "lead_workflow"
  })
}

export const gtagAppleLoginClicked = (embed: string = "lead_workflow") => {
  eventDispatch({
    name: "apple_login_attempt",
    category: embed
  })
}

export const gtagAppleLoginSuccess = (embed: string = "lead_workflow") => {
  eventDispatch({
    name: "apple_login_success",
    category: embed
  })
}

export const gtagFacebookLoginClicked = (embed: string = "lead_workflow") => {
  eventDispatch({
    name: "facebook_login_attempt",
    category: embed
  })
}

export const gtagFacebookLoginSuccess = (embed: string = "lead_workflow") => {
  eventDispatch({
    name: "facebook_login_success",
    category: embed
  })
}
