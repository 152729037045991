import { h } from "preact"
import { Icon } from "@ui"
import crossIcon from "@assets/icons/cross.svg"
import "@styles/components/_modal-close.scss"

interface Props {
  handleCloseButtonClick: () => void
  isCross?: boolean
  className?: string
}

const ModalClose = ({
  handleCloseButtonClick,
  isCross,
  className = ""
}: Props) => (
  <button
    className={`modal__close ${className} ${isCross ? "is-cross" : ""}`}
    onClick={handleCloseButtonClick}
    tabIndex={0}
    aria-label="Close"
  >
    {isCross && (
      <Icon
        className="modal__close-icon"
        src={crossIcon}
        size={14}
        originalSize={16}
      />
    )}
  </button>
)

export default ModalClose
