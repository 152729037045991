import { h } from "preact"
import { useSelector } from "react-redux"
import { Button } from "@ui"
import { MORTGAGE_TXT } from "@content"
import {
  openLeadWorkflowFromWithin,
  openMortgageCalcFromWithin
} from "@iframeCommunication/utils"
import { selectGlobalModalIframeID } from "@selectors"

const { calc_CTA, CTA } = MORTGAGE_TXT.inline.footer

const MortgageInlineFooter = () => {
  const iframeID = useSelector(selectGlobalModalIframeID)

  const handleOnClick = () => {
    openLeadWorkflowFromWithin(iframeID)
  }

  const openMortgageCalcModal = () => {
    openMortgageCalcFromWithin(iframeID)
  }

  return (
    <div className="mortgage-inline-footer">
      <div className="mortgage-inline-footer__cta">
        <Button
          onClick={openMortgageCalcModal}
          className="font-bolder"
          size="xl"
          noShadow
          isLite
        >
          {calc_CTA}
        </Button>
        <Button
          onClick={handleOnClick}
          className="font-bolder"
          size="xl"
          noShadow
        >
          {CTA}
        </Button>
      </div>
    </div>
  )
}

export default MortgageInlineFooter
