import { h } from 'preact';
import { css } from 'emotion';
import styled from 'preact-emotion';

export default () => (
  <div className="loading-placeholder loading-placeholder--animated-background">
    <AvatarMask />
    <AvatarWindow />
    <AvatarMask />

    <AvatarBottomMask />

    <TitleMargin />
    <Title />
    <TitleMargin />

    <TitleBottomMargin />

    <NameMargin />
    <Name />
    <NameMargin />

    <TitleBottomMargin />

    <TitleMargin />
    <Title />
    <TitleMargin />

    <TitleBottomMargin />

    <TitleMargin />
    <Title />
    <TitleMargin />

    <SeparatorTopMargin />
    <Separator className="modal__separator" />
    <SeparatorBottomMargin />

    <CompanyLogoMargin />
    <CompanyLogo />
    <CompanyLogoMargin />

    <AvatarBottomMask />

    <TitleMargin />
    <Title />
    <TitleMargin />

    <TitleBottomMargin />

    <TitleMargin />
    <Title />
    <TitleMargin />

    <TitleBottomMargin />

  </div>
)

/*
 *  Classes
 */
const mask = css`
  background: #fff;
`
const element = css`
  background: transparent;
`
const hMargin = css`
  flex: 1 0 10%;
`
const vMargin = css`
  flex: 1 0 100%;
`

const avatarRow = css`
  height: 220px;
`
const titleRow = css`
  height: 14px;
`
const nameRow = css`
  height: 28px;
`
const companyLogoRow = css`
  height: 100px;
`

/*
 *  Components
 */
const AvatarMask = styled('div')`
  ${mask};
  ${hMargin};
  ${avatarRow};
`
const AvatarWindow = styled('div')`
  ${element};
  ${avatarRow};
  width: 150px;
`
const AvatarBottomMask = styled('div')`
  ${mask};
  ${vMargin};
  height: 20px;
`

const TitleMargin = styled('div')`
  ${mask};
  ${hMargin};
  ${titleRow};
`
const Title = styled('div')`
  ${element};
  ${titleRow};
  width: 120px;
`

const TitleBottomMargin = styled('div')`
  ${mask};
  ${vMargin};
  height: 5px;
`

const NameMargin = styled('div')`
  ${mask};
  ${hMargin};
  ${nameRow};
`
const Name = styled('div')`
  ${element};
  ${nameRow};
  width: 130px;
`

const SeparatorTopMargin = styled('div')`
  ${mask};
  ${vMargin};
  height: 40px;
`
const SeparatorBottomMargin = styled('div')`
  ${mask};
  ${vMargin};
  height: 30px;
`
const Separator = styled('hr')`
  margin-top: 0;
  margin-bottom: 0;
`

const CompanyLogo = styled('div')`
  ${element};
  ${companyLogoRow};
  width: 150px;
`
const CompanyLogoMargin = styled('div')`
  ${mask};
  ${hMargin};
  ${companyLogoRow};
`
