import { h } from "preact"

const RoundedRowsSkeleton = ({ rows = 3 }) => (
  <div className="tw-space-y-4 tw-pt-8">
    {[...Array(rows)].map(() => (
      <div className="datagrid__row md:tw-flex tw-rounded-3xl md:tw-rounded-full tw-items-center tw-justify-between tw-bg-white tw-p-6 tw-border tw-border-zinc-300">
        <div className="md:tw-w-1/6 tw-pl-0 md:tw-pl-4">
          <div className="datagrid__loader tw-mx-0"></div>
          <div className="datagrid__loader tw-mx-0 tw-w-2/3 tw-mt-2"></div>
        </div>
        <div className="md:tw-w-2/6 tw-flex tw-space-x-2 tw-pt-5 md:tw-pt-0">
          <div className="tw-w-1/3">
            <div className="datagrid__loader tw-w-2/3 tw-mx-auto"></div>
            <div className="datagrid__loader tw-w-4/5 tw-mx-auto tw-mt-2"></div>
          </div>
          <div className="tw-w-1/3">
            <div className="datagrid__loader tw-w-2/3 tw-mx-auto"></div>
            <div className="datagrid__loader tw-w-4/5 tw-mx-auto tw-mt-2"></div>
          </div>
          <div className="tw-w-1/3">
            <div className="datagrid__loader tw-w-2/3 tw-mx-auto"></div>
            <div className="datagrid__loader tw-w-4/5 tw-mx-auto tw-mt-2"></div>
          </div>
        </div>
        <div className="md:tw-w-1/6 tw-pt-6 md:tw-pt-0">
          <div className="datagrid__loader tw-mx-auto md:tw-mx-0 tw-h-12 tw-rounded-3xl"></div>
        </div>
      </div>
    ))}
  </div>
)

export default RoundedRowsSkeleton
