import { h } from "preact"
import { Form } from "@ui"

const OPTIONS = [
  {
    label: "Yes",
    value: true,
    helpTxt:
      "I'm a first-time homebuyer and earn under 80% of the county's median income."
  },
  {
    label: "No",
    value: false
  }
]

interface Props {
  value?: boolean
  onChange: (option: { value: string }) => void
}

const FirstTimeHomebuyer = ({ value = false, onChange }: Props) => (
  <Form.SelectCustom
    hasHelpTxt
    optionsClassName="is-on-tooltip"
    isMaterial
    name="first_time_homebuyer"
    options={OPTIONS}
    value={value}
    onChange={onChange}
    label="Eligible for lower rate"
    showErrorWhenEmpty
  />
)

export default FirstTimeHomebuyer
