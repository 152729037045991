import { h } from "preact"
import { ReviewsProvider } from "../context"
import InnerGridReviews from "./InnerGridReviews"

const GridReviews = () => (
  <ReviewsProvider>
    <InnerGridReviews />
  </ReviewsProvider>
)

export default GridReviews
