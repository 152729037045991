import { Icon, DescriptionList, Button } from "@ui"
import { LEAD_WORKFLOW_TXT } from "@content"
import { h } from "preact"
import Pencil from "@assets/icons/pencil.svg"
import { DescriptionListItems } from "@types"

interface Props {
  toggleView: () => void
  items: DescriptionListItems
}

const View = ({ toggleView, items }: Props) => {
  return (
    <div className="tw-pt-4">
      <DescriptionList items={items} dark />
      <Button link className="tw-text-blue-400 tw-mt-12" onClick={toggleView}>
        <Icon className="tw-mr-1" src={Pencil} originalSize={20} size={20} />
        <span className="tw-font-bold">
          {LEAD_WORKFLOW_TXT.STEPS_ANSWERS.EDIT_BTN}
        </span>
      </Button>
    </div>
  )
}

export default View
