import { h } from "preact"
import RateTableToolbar from "../RateTableToolbar"
import { If, SortBy, ScreenReaderOnly, Button, Icon } from "@ui"
import { Tooltip } from "@composite"
import { RootStates, SortByOption } from "@types"
import { useIsDesktop, useIsTablet } from "@contexts"
import { useSelector } from "react-redux"
import bell from "@assets/icons/bell.svg"

interface Props {
  updateSubscription: () => void
  showGetRateAlertsButton: boolean
  className?: string
  sortValue: SortByOption
  onSortChange: (value: SortByOption) => void
}

const Toolbar = ({
  updateSubscription,
  showGetRateAlertsButton,
  className,
  sortValue,
  onSortChange
}: Props) => {
  const isTablet = useIsTablet()

  const forceTabletViewPort = useSelector(({ app }: RootStates) => {
    return app?.type === "boomtown"
  })

  return (
    <div className={`rt-toolbar ${className}`}>
      <RateTableToolbar />
      <If
        condition={showGetRateAlertsButton}
        then={
          <Tooltip
            className="rt-subscribe-btn-wrapper"
            ttPlacement="top"
            small
            eventTrigger="hover"
            reference={
              <Button
                onClick={updateSubscription}
                className="rt-subscribe-btn"
                outline
                adapt
                noShadow
              >
                <Icon src={bell} size={20} originalSize={21} />
                <ScreenReaderOnly>Get rate alerts</ScreenReaderOnly>
              </Button>
            }
          >
            Rate alerts
          </Tooltip>
        }
      />
      <SortBy
        isIcon={isTablet && !forceTabletViewPort}
        value={sortValue}
        onChange={onSortChange}
      />
    </div>
  )
}

export default Toolbar
