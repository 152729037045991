import { h } from "preact"
import { useSelector } from "react-redux"
import { Button, Icon } from "@ui"
import { RootStates } from "@types"
import linkIcon from "@assets/icons/external-link.svg"

const DashboardLink = ({ className = "" }) => {
  // get from redux store the lead_id
  const leadId = useSelector(({ wizard }: RootStates) => wizard?.lead_id)

  return (
    <Button
      href={`${process.env.DASHBOARD_URL}/new-subscription?lead_id=${leadId}&active=0&source=embed`}
      link
      className={`dash-link ${className}`}
      target="_blank"
    >
      <strong>View all rates in dashboard</strong>
      <Icon originalSize={16} size={16} src={linkIcon} className="ml-2" />
    </Button>
  )
}

export default DashboardLink
