import { h } from "preact"
import { Alert, If } from "@ui"
import { LEAD_WORKFLOW_TXT } from "@content"
import { useSelector } from "react-redux"
import { RootStates } from "@types"

const { errorMsg } = LEAD_WORKFLOW_TXT

const ErrorMsg = () => {
  const { validateError } = useSelector(({ wizard }: RootStates) => {
    return {
      validateError: wizard?.validateError ?? false
    }
  })
  return (
    <If
      condition={validateError}
      then={
        <Alert.Danger className="wizard__form-alert">{errorMsg}</Alert.Danger>
      }
    />
  )
}

export default ErrorMsg
