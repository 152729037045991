import { h } from "preact"
import TxtLoading from "../Txt"
import { useIsMobile } from "@contexts"
import RateCardsRowsSkeleton from "../RateCardsRows"

interface Props {
  rows?: number
  cols?: number
}

const RateCardsSkeleton = ({ rows = 4 }: Props) => {
  const isMobile = useIsMobile()

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-items-center">
        <TxtLoading
          height={isMobile ? 54 : 74}
          width={isMobile ? 300 : 365}
          borderRadius={50}
        />
        <div className="tw-flex tw-flex-wrap gap-sm tw-mt-10">
          <TxtLoading
            height={40}
            width={isMobile ? 80 : 110}
            borderRadius={5}
          />
          <TxtLoading
            height={40}
            width={isMobile ? 80 : 110}
            borderRadius={5}
          />
          <TxtLoading
            height={40}
            width={isMobile ? 80 : 110}
            borderRadius={5}
          />
        </div>
      </div>
      <div className="tw-pt-12">
        <RateCardsRowsSkeleton rows={rows} />
      </div>
    </div>
  )
}

export default RateCardsSkeleton
