import { Fragment, h } from "preact"
import { numberWithCommas } from "@helpers"
import { Icon } from "@ui"
import { ProviderType } from "@types"

interface Props {
  currentProvider: ProviderType | "all"
  rating?: number
  totalReviews?: number
  reviewURL?: string
  icon?: {
    icon: string
    iconSize?: number | number[]
    iconOriginalSize?: number | number[]
  }
  hideTotalReviews?: boolean
}

const ReviewTabItem = ({
  currentProvider,
  rating = 0,
  totalReviews = 0,
  reviewURL,
  icon,
  hideTotalReviews
}: Props) => {
  const validProviders = ["all", "zillow", "google", "experience", "yelp"]
  const formattedTotalReviews = numberWithCommas(totalReviews)

  if (!validProviders.find(p => p === currentProvider)) return null

  if (currentProvider === "all") {
    return (
      <Fragment>
        <div className="tw-flex tw-items-center tw-pt-0.5">
          All Reviews
          <span className="reviews__header-tab-rating">
            {rating.toFixed(1)}
          </span>
        </div>
        {!hideTotalReviews && (
          <span className="reviews__header-tab-totalreviews">
            {formattedTotalReviews} reviews
          </span>
        )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="tw-flex tw-items-center">
        {icon ? (
          <Icon
            src={icon?.icon}
            size={icon?.iconSize}
            originalSize={icon?.iconOriginalSize}
          />
        ) : null}

        <span className="reviews__header-tab-provider-name">
          {currentProvider}
        </span>
        <span className="reviews__header-tab-rating">{rating.toFixed(1)}</span>
      </div>
      {!hideTotalReviews &&
        (reviewURL ? (
          <a
            href={reviewURL}
            className="reviews__header-tab-totalreviews"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {formattedTotalReviews} reviews
          </a>
        ) : (
          <span className="reviews__header-tab-totalreviews">
            {formattedTotalReviews} reviews
          </span>
        ))}
    </Fragment>
  )
}

export default ReviewTabItem
