import { h } from "preact"
import Icon from "../Icon"
import svgIcon from "@assets/icons/checkmark-square.svg"
import { If } from "@ui"
import "@styles/components/list/_list.scss"

type Props = {
  items: string[]
  className?: string
  iconSize?: number
  originalIconSize?: number
  dots?: boolean
  svg?: string
  iconClassName?: string
}

const List = ({
  items,
  svg = svgIcon,
  iconSize = 17,
  originalIconSize = 17,
  dots = false,
  className = "",
  iconClassName = ""
}: Props) => (
  <ul className={`${className} ${!dots ? "notDot" : ""}`}>
    <If
      condition={items.length > 0}
      then={items.map(item => (
        <li>
          {svg && (
            <Icon
              className={`customIcon ${iconClassName}`}
              src={svg}
              size={iconSize}
              originalSize={originalIconSize}
            />
          )}
          {item}
        </li>
      ))}
    />
  </ul>
)

export default List
