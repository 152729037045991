import { h, Component } from "preact"
import InputRange from "../InputRange"
import InputMasked from "../InputMasked"
import { Tooltip } from "@composite"

class InputRangeGroupMasked extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMinMaxError: false,
      minMaxErrorTxt: ""
    }
  }

  render(
    {
      label,
      name,
      classNameInput = "",
      classNameLabel = "",
      className = "",
      value,
      max,
      min = 0,
      step,
      onChange,
      onChangeComplete,
      amountPercent = 0,
      amountPercentName = "",
      hasAmountPercent,
      isPercentSlider,
      amountPercentMax = 100,
      helpTxt = "",
      showHelpTxt = true,
      errorTxt = "",
      showErrorTxt,
      tt,
      prefix = "$",
      suffix = "",
      inputAutoWidth
    },
    { showMinMaxError, minMaxErrorTxt }
  ) {
    const slideName = isPercentSlider ? amountPercentName : name

    return (
      <div className={`range-group ${className}`}>
        <div className="d-flex justify-content-between align-items-center mortgage-calculator__label-wrapper">
          <div className={classNameLabel}>
            {label} {tt && <Tooltip ttPlacement="bottom">{tt}</Tooltip>}
          </div>
          <div className="d-flex align-items-center">
            {/** Input Amount */}
            <InputMasked
              inputAutoWidth={inputAutoWidth}
              name={name}
              className={`${classNameInput} range-group__main-input`}
              value={value}
              prefix={prefix}
              suffix={suffix}
              onBlur={(rawVal, e) => {
                // Check if the input value changed
                if (value !== rawVal) {
                  onChangeComplete(rawVal, e.target.name)
                }
              }}
              onChange={rawVal => {
                // This is used to check is the error message must be shown
                if (rawVal > max || rawVal < 0) {
                  let minMaxErrorTxt =
                    rawVal > max
                      ? `The max value is $${max}`
                      : `The min value is $0`

                  if (isPercentSlider) {
                    minMaxErrorTxt =
                      rawVal > max
                        ? `The max value can't be more than %${amountPercentMax} ($${max})`
                        : `The min value is %0`
                  }

                  this.setState({
                    showMinMaxError: true,
                    minMaxErrorTxt
                  })
                } else if (showMinMaxError) {
                  this.setState({
                    showMinMaxError: false
                  })
                }
              }}
            />

            {/** Input Amount Percent */}
            {hasAmountPercent && (
              <div className="mortgage-calculator__percentage">
                <span className="accent-color inline-block pl-1">|</span>
                <InputMasked
                  inputAutoWidth={inputAutoWidth}
                  className={`${classNameInput} is-small range-group__secondary-input`}
                  name={amountPercentName}
                  value={amountPercent}
                  suffix="%"
                  onBlur={(rawVal, e) => {
                    // Check if the input value changed
                    if (amountPercent !== rawVal) {
                      onChangeComplete(rawVal, e.target.name)
                    }
                  }}
                  onChange={rawVal => {
                    // This is used to check is the error message must be shown
                    if (rawVal > amountPercentMax || rawVal < 0) {
                      const minMaxErrorTxt =
                        rawVal > amountPercentMax
                          ? `The max value is ${amountPercentMax}%`
                          : `The min value is 0%`
                      this.setState({
                        showMinMaxError: true,
                        minMaxErrorTxt
                      })
                    } else if (showMinMaxError) {
                      this.setState({
                        showMinMaxError: false
                      })
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div>
          {/** Slider */}
          <InputRange
            min={min}
            max={isPercentSlider ? amountPercentMax : max}
            name={slideName}
            step={step}
            value={isPercentSlider ? amountPercent : value}
            onChange={val => {
              onChange(val, slideName)
            }}
            onChangeComplete={val => {
              onChangeComplete(val, slideName)
            }}
          />
        </div>
        {helpTxt.length > 0 && showHelpTxt && (
          <div
            className="mortgage-calculator__help-txt"
            dangerouslySetInnerHTML={{ __html: helpTxt }}
          ></div>
        )}
        {errorTxt.length > 0 && showErrorTxt && (
          <div
            className="text-warning mortgage-calculator__help-txt"
            dangerouslySetInnerHTML={{ __html: errorTxt }}
          ></div>
        )}
        {showMinMaxError && (
          <div
            className="txt-danger mortgage-calculator__help-txt"
            dangerouslySetInnerHTML={{ __html: minMaxErrorTxt }}
          ></div>
        )}
      </div>
    )
  }
}

export default InputRangeGroupMasked
