import { h } from "preact"
import TxtLoading from "./Txt"
import { If } from "@ui"

interface Props {
  hasResults?: boolean
}

const LeadWorkflowEndStepHeader = ({ hasResults = true }: Props) => (
  <div className="wizard-end__header">
    <div className="lead-workflow-end-step-loader__header">
      <TxtLoading
        height={20}
        className="lead-workflow-end-step-loader__title"
      />
      <TxtLoading
        height={20}
        className="lead-workflow-end-step-loader__subtitle"
      />
      <If
        condition={hasResults}
        then={
          <TxtLoading
            height={45}
            className="lead-workflow-end-step-loader__cta"
          />
        }
        else={
          <div className="lead-workflow-end-step-loader__ctas tw-flex tw-justify-center tw-items-center gap-md">
            <TxtLoading
              height={45}
              className="lead-workflow-end-step-loader__cta"
            />
            <TxtLoading
              height={45}
              className="lead-workflow-end-step-loader__cta"
            />
          </div>
        }
      />
    </div>
  </div>
)

export default LeadWorkflowEndStepHeader
