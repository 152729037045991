import { h } from "preact"
import { useSelector } from "react-redux"
import { Button, Icon } from "@ui"
import { AFFORD_CALC_TXT } from "../../../../content"
import { openLeadWorkflowFromWithin } from "@iframeCommunication/utils"
import { selectGlobalModalIframeID } from "../../../../selectors"
import eohIcon from "@assets/icons/equal-housing-opportunity.svg"

const { cta, disclaimer } = AFFORD_CALC_TXT.footer

const CalcFooter = () => {
  const iframeID = useSelector(selectGlobalModalIframeID)
  const handleOnClick = () => {
    openLeadWorkflowFromWithin(iframeID)
  }
  return (
    <div className="afford-calc-footer">
      <div className="afford-calc-footer__disclaimer d-flex flex-column-reverse flex-md-row align-items-center justify-content-center my-3 px-4">
        <div className="d-flex flex-column flex-md-row">
          <Icon
            className="afford-calc-footer__disclaimer__icon"
            src={eohIcon}
            size={[52, 53]}
            originalSize={192.756}
          />

          <p>{disclaimer}</p>
        </div>
        <div className="afford-calc-footer__disclaimer__button">
          <Button
            onClick={handleOnClick}
            className="font-bolder"
            size="xl"
            noShadow
            label={cta}
          />
        </div>
      </div>
    </div>
  )
}

export default CalcFooter
