import axios, { AxiosResponse } from "axios"
import { bbProcessEnv, APICallWithCache } from "@helpers"
import {
  AmortizationRequest,
  AmortizationResponse,
  DownloadReportRequest,
  DownloadReportResponse
} from "@types"
import { ONE_DAY_IN_MILLISECONDS } from "@constants"

export default {
  getAmortizationSchedule: async (
    params: AmortizationRequest
  ): Promise<AmortizationResponse> => {
    try {
      const response: AmortizationResponse = await APICallWithCache({
        url: `${bbProcessEnv.API_URL}/api/v1/amort_sched`, // Replace with your actual API endpoint
        method: "post",
        params: {
          ...params,
          mode: 2,
          data: 1
        },
        cacheEnabled: false,
        // five days in milliseconds
        expiresIn: 5 * ONE_DAY_IN_MILLISECONDS
      })

      return response
    } catch (error) {
      console.error("Error fetching amortization schedule:", error)
      throw error // Re-throw the error for handling in the calling code
    }
  },
  getCompareReport: async (params: DownloadReportRequest) => {
    try {
      const response: AxiosResponse<DownloadReportResponse> = await axios.post(
        `${bbProcessEnv.API_URL}/api/v1/rate-compare-report-pdf`,
        {
          ...params,
          detailed: true
        }
      )

      const { file, label } = response.data

      const pdfResponse = await axios({
        url: file,
        method: "GET",
        responseType: "blob"
      })

      const url = window.URL.createObjectURL(new Blob([pdfResponse.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `${label}.pdf`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error("Error downloading amortization report:", error)
      throw error // Re-throw the error for handling in the calling code
    }
  }
}
