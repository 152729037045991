import { h } from "preact"
import { Form } from "@ui"
import { numberWithCommas, toFixed } from "@helpers"
import { Marks as MarksType } from "@types"

interface Props {
  mort_pp: number
  handleSliderChange: (e: any) => void
  handleCalculate: () => void
  marks: MarksType
  monthly_payment: number
}

const CalcMainSlide = ({
  mort_pp,
  handleSliderChange,
  handleCalculate,
  marks,
  monthly_payment
}: Props) => (
  <div className="afford-calc__slide-wrapper">
    <div className="afford-calc__slider">
      <Form.InputRange
        draggableTrack={false}
        min={15}
        max={100}
        value={mort_pp * 2}
        marks={marks}
        onChange={handleSliderChange}
        onChangeComplete={handleCalculate}
        hideTooltip
      />

      <div className="summary__afford-monthly">
        ${numberWithCommas(toFixed(monthly_payment, 0))}
        <small>/month</small>
      </div>
    </div>
  </div>
)

export default CalcMainSlide
