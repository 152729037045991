import { createContext } from "preact"
import { BrandReviews, LoReviews, ReviewType } from "@types"

interface ReviewTypeContextProps {
  reviewType: ReviewType
  setReviewType: (reviewType: ReviewType) => void
  isLoLoading: boolean
  isBrandLoading: boolean
  hasLoReviews: boolean
  hasBrandReviews: boolean
  lo: LoReviews
  brand: BrandReviews
}

const ReviewTypeContext = createContext<ReviewTypeContextProps | undefined>(
  undefined
)

export default ReviewTypeContext
