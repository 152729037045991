import { ComponentChildren, h } from "preact"
import { useState } from "preact/hooks"
import CreditScoreRangeNum from "./CreditScoreRangeNum"
import { TooltipFormWrapper } from "@composite"
import { Input } from "../../inputs"
import Chevron from "../../../Chevron"
import { CREDIT_SCORE_OPTIONS } from "@constants"
import "@styles/components/_credit-score-full.scss"

interface Props {
  name: string
  value: number
  max?: number
  min?: number
  showErrorWhenEmpty?: boolean
  onChangeComplete: (value: any, name: string) => void
  label?: ComponentChildren
  tooltipLabel?: string
  className?: string
}

const CreditScoreFull = ({
  name,
  value,
  max = 850,
  min = 500,
  showErrorWhenEmpty,
  onChangeComplete,
  label = "Credit Score",
  tooltipLabel = "Customize your score",
  className = ""
}: Props) => {
  const [forceClose, setForceClose] = useState(false)
  const [makeFocusToInput, setMakeFocusToInput] = useState(false)

  const forceCloseTooltip = (status: boolean) => {
    setForceClose(status)
    setMakeFocusToInput(false)
  }

  const onOptionSelected = (value: number) => {
    onChangeComplete(value, name)
    setMakeFocusToInput(false)
    setForceClose(true)
  }

  const onChangeCompleteHandler = (value: number) => {
    makeFocusToInput && onChangeComplete(value, name)
  }

  return (
    <div className={`credit-score-full ${className}`}>
      <TooltipFormWrapper
        keepParentWidth
        onClick={() => setMakeFocusToInput(true)}
        forceClose={{ state: forceClose, set: forceCloseTooltip }}
        triggerIsButton={false}
        ttClassName="credit-score-full__tooltip"
        ttPlacement="bottom-end"
        trigger={
          <div className="credit-score-full__placeholer">
            <Input
              isMaterial
              label={label}
              value={value}
              showErrorWhenEmpty={showErrorWhenEmpty}
            />
            <div className="form__select-chevron">
              <Chevron />
            </div>
          </div>
        }
        ctaClassName="button--md"
      >
        <div className="credit-score-full__tooltip">
          <label className="credit-score-label" htmlFor={name}>
            {tooltipLabel}
          </label>

          <CreditScoreRangeNum
            className="credit-score-full__input"
            name={name}
            value={value}
            max={max}
            min={min}
            placeholder="Enter credit score"
            makeFocus={makeFocusToInput}
            onBlur={() => setMakeFocusToInput(false)}
            onChangeComplete={onChangeCompleteHandler}
            submitOnEnter={() => setForceClose(true)}
            label={label}
            maxlength={3}
            // error={{
            //   evalStatus: (val: number) => val.toString().length !== 3,
            //   message: "Credit score should be 3 digits"
            // }}
            ariaDescribedby={`${name}-helpblock`}
          />

          <span id={`${name}-helpblock`} className="d-block mt-1">
            Enter a score between 500-850
          </span>

          <div className="credit-score-options">
            <label className="credit-score-label">Or choose from below</label>

            {CREDIT_SCORE_OPTIONS.map(option => (
              <button
                key={option.value}
                className="credit-score-option"
                onClick={() => {
                  onOptionSelected(option.value)
                }}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      </TooltipFormWrapper>
    </div>
  )
}

export default CreditScoreFull
