import { h, Fragment } from "preact"
import { useState } from "preact/hooks"
import { useDispatch, useSelector } from "react-redux"
import { updateSubscription } from "@actions"
import { Loader, Button, Icon } from "@ui"
import successIcon from "@assets/icons/success-icon.svg"
import { RootStates } from "@types"
import { subscriptionAPI } from "@api"

const WizardSubscription = () => {
  const dispatch = useDispatch()
  const { subscribed, lead_id, lo, values } = useSelector(
    ({ wizard, app: { lo } }: RootStates) => {
      const { values, lead_id, subscribed } = wizard || {}
      return { subscribed, lead_id, lo, values }
    }
  )
  const [isLoading, toggleLoading] = useState(false)

  const subscribe = () => {
    toggleLoading(true)

    let subscriptionData = {}
    if (values?.locations) {
      subscriptionData = {
        loid: lo && lo.uid,
        lead_id: lead_id,
        email: values.login_form.form.email,
        quote_info: {
          credit_score: values?.steps.credit_score,
          loan_purpose: values?.steps.loan_purpose,
          loan_amount:
            (values?.steps.list_price ?? 0) - (values?.steps.down_payment ?? 0),
          down_payment: values?.steps.down_payment,
          loan_term: values?.steps.loan_term || 360,
          loan_type:
            values?.steps.military_eligible == "yes" ? "va" : "conventional",
          list_price: values?.steps.list_price,
          state: values.locations["location"].administrative_area_level_1,
          zipcode: values.locations["location"].postal_code
        }
      }
    }

    subscriptionAPI.add(subscriptionData).then(() => {
      toggleLoading(false)
      dispatch(updateSubscription(true))
    })
  }

  return (
    <div className="wizard__info-block tw-relative is-col">
      {isLoading && (
        <Loader className="wizard__info-block-loader block-loader" />
      )}

      <div className="wizard__info-block-title">
        {subscribed ? (
          <Fragment>
            <Icon src={successIcon} size={14} originalSize={507.2} /> Success!
            You'll get your first email soon.
          </Fragment>
        ) : (
          "Keep track of rate changes."
        )}
      </div>
      <p>
        {subscribed
          ? "We'll send you emails when your rate changes."
          : "Get personalized rate updates sent directly to your inbox."}
      </p>

      {!subscribed && (
        <div className="tw-mt-auto">
          <Button label="Track this quote" onClick={subscribe} adapt outline />
        </div>
      )}
    </div>
  )
}

export default WizardSubscription
