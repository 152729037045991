import { Fragment, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useSelector } from "react-redux"
import { If, LogoLinkBtn } from "@ui"
import { ReviewsLine } from "@composite"
import { useAuthCode } from "@hooks"
import { RootStates } from "@types"
import { useIsMobile } from "@contexts"
import UserForm from "./UserForm"
import CodeForm from "./CodeForm"
import LiveRates from "./LiveRates"
import Title from "./Title"
import StepsAnswers from "../StepsAnswers"

interface Props {
  isVisible?: boolean
  isCode?: boolean
  disclaimer: string
  stepKey: string
  fields: any[]
  goBack: () => void
  onSentCallback: () => void
  submitLabel: string
}

const WizardStepForm = ({
  isVisible = false,
  isCode = false,
  disclaimer,
  stepKey,
  fields,
  goBack,
  onSentCallback,
  submitLabel
}: Props) => {
  const isMobile = useIsMobile()

  const { validationRequired, auth_request_id } = useSelector(
    ({ wizard }: RootStates) => {
      return {
        validationRequired: wizard?.validationRequired,
        auth_request_id: wizard?.auth_request_id
      }
    }
  )

  const [isResend, setIsResend] = useState(false)

  const {
    getCode,
    validateCode,
    authCodeResponse,
    isLoadingAuthCode,
    isVerifyingAuthCode,
    authCodeType
  } = useAuthCode()

  useEffect(() => {
    if (
      validationRequired &&
      authCodeResponse?.status === "success" &&
      !isResend
    ) {
      onSentCallback()
    }
  }, [authCodeResponse, validationRequired])

  useEffect(() => {
    if (!auth_request_id && isCode && isVisible) {
      goBack()
    }
  }, [])

  return (
    <div className="wizard__form wizard__animated-items wizard__animated">
      <div className="md:tw-flex md:tw-gap-4 ">
        <div className="tw-w-full has-confetti">
          <LiveRates />
          <Title />
          <If
            condition={isCode}
            then={
              <CodeForm
                makeFocus={isCode && isVisible}
                disclaimer={disclaimer}
                getAuthCode={getCode}
                validateAuthCode={validateCode}
                isVerifying={isVerifyingAuthCode}
                authCodeType={authCodeType}
                setIsResend={setIsResend}
              />
            }
            else={
              <UserForm
                disclaimer={disclaimer}
                getAuthCode={getCode}
                stepKey={stepKey}
                fields={fields}
                onSentCallback={onSentCallback}
                submitLabel={submitLabel}
                isLoadingAuthCode={isLoadingAuthCode}
              />
            }
          />

          <If
            condition={!isMobile}
            then={
              <Fragment>
                <ReviewsLine />
                <div className="bb-link tw-flex">
                  <LogoLinkBtn className="tw-ml-auto" />
                </div>
              </Fragment>
            }
          />
        </div>
        <If
          condition={!isCode}
          then={
            <StepsAnswers
              isVisible={isVisible}
              className="tw-order-first -tw-mx-8 md:tw-mx-0"
            />
          }
        />

        <If
          condition={isMobile}
          then={
            <Fragment>
              <ReviewsLine limit={2} className="tw-mt-6" />
              <div className="bb-link tw-flex">
                <LogoLinkBtn className="tw-ml-auto" />
              </div>
            </Fragment>
          }
        />
      </div>
    </div>
  )
}

export default WizardStepForm
