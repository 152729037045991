import axios from "axios"
import { APICallWithCache, bbProcessEnv } from "@helpers"
import { ONE_DAY_IN_MILLISECONDS } from "@constants"
import { getRateCards, getAssumptions, logPublic } from "./methods"

export default {
  get: getRateCards,
  getAssumptions,
  logPublic,
  log: async ({ quote_id, include_request = false, include_cards = false }) => {
    try {
      let id = ""
      if (Array.isArray(quote_id)) {
        id = quote_id.join(",")
      } else {
        id = quote_id
      }
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/rateflow-log`,
        {
          params: {
            id,
            include_request,
            include_cards
          },
          withCredentials: true
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  getRequest: async ({ id }) => {
    try {
      const response = await APICallWithCache({
        url: `${bbProcessEnv.API_URL}/api/v1/rateflow-log-request`,
        params: {
          id
        },
        cacheEnabled: true,
        // five days in milliseconds
        expiresIn: 5 * ONE_DAY_IN_MILLISECONDS
      })

      return response
    } catch (error) {
      console.log(error)
    }
  }
}
