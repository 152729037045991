import { h } from "preact"
import { useMemo } from "preact/hooks"
import MaskedInput from "react-text-mask"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import { numberWithoutCommas } from "@helpers"

const getLength = (value: string | number) => {
  if (value === null || value === undefined) {
    return 0
  }
  if (typeof value === "number") {
    // round to 2 decimal places and convert to string
    value = Number.isInteger(value)
      ? value.toString()
      : value.toFixed(2).toString()
  }
  return value.length
}

interface Props {
  className?: string
  name: string
  value: string | number
  required?: boolean
  min?: number
  max?: number
  prefix?: string
  suffix?: string
  allowDecimal?: boolean
  onChange: (value: string | number, event: Event) => void
  onBlur?: (value: string | number, event: Event) => void
  isNumber?: boolean
  integerLimit?: number
  inputAutoWidth?: boolean
}

const InputMasked = ({
  className,
  name,
  value,
  required,
  min,
  max,
  prefix = "",
  suffix = "",
  allowDecimal = true,
  onChange,
  onBlur,
  isNumber = true,
  integerLimit = 8,
  inputAutoWidth = false
}: Props) => {
  const cleanValue = (val: string) => {
    const newVal = val.replace(/^\D+|\D+$/g, "")

    return isNumber ? Number(numberWithoutCommas(newVal)) : newVal
  }
  //create the `numberMask` with configurations
  const mask = useMemo(
    () =>
      createNumberMask({
        prefix,
        suffix,
        allowDecimal,
        integerLimit
      }),
    [prefix, suffix, allowDecimal]
  )

  const handleChange = (e: Event) => {
    let rawVal = cleanValue((e.target as HTMLInputElement)?.value)
    onChange(rawVal, e)
  }

  const handleBlur = (e: Event) => {
    let rawVal = cleanValue((e.target as HTMLInputElement)?.value)
    onBlur?.(rawVal, e)
  }

  const handleKeyUpEnter = (e: KeyboardEvent) => {
    if (e?.code === "Enter") {
      ;(e.target as HTMLInputElement)?.blur()
    }
  }

  const inputWidth = inputAutoWidth
    ? getLength(value) + prefix.length + suffix.length
    : 1

  return (
    <MaskedInput
      // @ts-ignore
      size={inputWidth}
      className={`${className} ${inputAutoWidth ? "input-auto-width" : ""}`}
      name={name}
      value={value}
      required={required}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyUp={handleKeyUpEnter}
      mask={mask}
      // if min and max prop are defined, add then conditionaly to the input
      {...(min && { min })}
      {...(max && { max })}
    />
  )
}

export default InputMasked
