import { Fragment, h } from "preact"
import { useState } from "preact/hooks"
import { useSelector } from "react-redux"
import { If } from "@ui"
import MobileButtons from "./Buttons"
import { useRatesFormContext } from "@contexts"
import { RatesFormContextProps, RootStates } from "@types"
import { useDashFormSubmit, useRateTableFormSubmit } from "@hooks"
import { onClickAnchor } from "@helpers"
import { DashMobileOverlay, MobileLoanDetailsFieldsForm } from "@composite"
import { closeMobileOverlay } from "@message-bridge"
import { openLoanDetailsMobileFilters } from "@iframeCommunication/utils"
import "@styles/components/_loan-details-form-mobile.scss"

interface Props {
  hasARMTerms: boolean
}

const FormWrapper = ({ hasARMTerms }: Props) => {
  const ratesFormContext = useRatesFormContext()
  const { formData, setFormDirty, handleOnChange, formIsDirty } =
    ratesFormContext as RatesFormContextProps

  const rootState = useSelector((rootState: RootStates) => rootState)

  const isDashboard = !!rootState.subscriptionDash

  const submit = isDashboard ? useDashFormSubmit() : useRateTableFormSubmit()

  const updateRatesForm = () => {
    submit(formData)
    setFormDirty(false)
  }

  const [show, setShow] = useState(false)

  const showMenu = () => {
    setShow(true)
    if (!isDashboard) {
      const iframeID = `bb-global-modal-${rootState?.app.wrapperID}-${rootState?.app.type}-${rootState?.app.app_key}`
      openLoanDetailsMobileFilters(iframeID)
    }
  }

  const closeMenu = () => {
    setShow(false)
    if (!isDashboard) closeMobileOverlay()
  }

  const onClickOverlaySubmitBtn = () => {
    closeMenu()

    onClickAnchor("rates-toolbar")

    // set a time out to allow the scroll to the anchor to complete
    setTimeout(() => {
      updateRatesForm()
    }, 500)
  }

  return (
    <Fragment>
      <If
        condition={isDashboard}
        then={
          <DashMobileOverlay
            title="Filters"
            closeOverlay={closeMenu}
            show={show}
          >
            <MobileLoanDetailsFieldsForm
              hasARMTerms={hasARMTerms}
              formData={formData}
              formIsDirty={formIsDirty}
              onClickOverlaySubmitBtn={onClickOverlaySubmitBtn}
              handleOnChange={handleOnChange}
            />
          </DashMobileOverlay>
        }
      />

      <MobileButtons showMenu={showMenu} onClickSubmitBtn={updateRatesForm} />
    </Fragment>
  )
}

export default FormWrapper
