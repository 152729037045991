import { Fragment, h } from "preact"
import { If, NoRatesMessage, RateCardsRowsSkeleton } from "@ui"
import NationalAverage from "../NationalAverage"
import { PricingCards, PricingItems } from "@types"
import Card from "../Card"
import Assumptions from "../Assumptions"
import { useEffect, useState } from "preact/hooks"
import { Button } from "@ui"
import { useMediaFlags } from "@contexts"
import "@styles/components/_rate-cards-body.scss"

interface Props {
  cards: PricingItems
  items: PricingCards
  isFetching: boolean
  loanPurpose: "purchase" | "refinance"
  term: string
  nationalAverage?: number
  disableCTA: boolean
}

const RateCardsBody = ({
  cards,
  items,
  isFetching,
  loanPurpose,
  term,
  disableCTA
}: Props) => {
  const [expanded, setExpanded] = useState(false)
  const { isDesktop, isTablet } = useMediaFlags()

  useEffect(() => {
    setExpanded(cards.length <= 4)
  }, [cards, isFetching])

  return (
    <div className="tw-pt-10 tw-max-w-6xl tw-mx-auto">
      <If
        condition={isFetching}
        then={<RateCardsRowsSkeleton rows={3} />}
        elseCondition={cards.length > 0}
        else={
          <Fragment>
            <If
              condition={
                items[0]?.national_average !== undefined &&
                items[0]?.national_average > 0
              }
              then={
                <NationalAverage
                  term={term}
                  average={items[0]?.national_average ?? 0}
                />
              }
            />
            <div
              className={`load-more__content ${
                expanded
                  ? "tw-max-h-full"
                  : `is-${isDesktop ? "lg" : isTablet ? "md" : "sm"}`
              } tw-overflow-hidden tw-space-y-4 `}
            >
              {cards.map(item => (
                <Card
                  disableCTA={disableCTA}
                  term={term}
                  key={item.hash}
                  item={item}
                  label={loanPurpose === "purchase" ? "purchase" : "refinance"}
                />
              ))}
            </div>
            <If
              condition={cards.length > 4}
              then={
                <div
                  className={`load-more__btn-wrapper tw-text-center tw-relative tw-z-10 tw-py-4 is-${
                    expanded ? "expanded" : "collapsed"
                  }`}
                >
                  <Button
                    noShadow
                    link
                    adapt
                    className="tw-mx-auto tw-py-4 tw-text-xl"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? "Show less" : "Show more"}
                  </Button>
                </div>
              }
            />
            <Assumptions
              items={items}
              className={cards.length <= 4 ? "tw-pt-4" : ""}
            />
          </Fragment>
        }
        default={
          <NoRatesMessage
            title="No rates available"
            message="Try changing the options to view different rate results"
          />
        }
      />
    </div>
  )
}
export default RateCardsBody
