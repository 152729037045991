import { Fragment, h } from "preact"
import Frame from "./Frame"
import { bbProcessEnv } from "@helpers"
import { useEffect, useRef, useState } from "preact/hooks"
import { createPortal } from "preact/compat"
import { initGlobalModal } from "@iframeCommunication/utils"
import { AppBaseState } from "@types"
import useGlobalModalFrame from "./useGlobalModalFrame"

interface Props {
  appState?: AppBaseState
  config: any
  hasRateTable?: boolean
  onMessage?: (data: any, e: any) => void
}

const GlobalModalFrame = ({
  config,
  appState,
  hasRateTable = false,
  onMessage
}: Props) => {
  if (!appState) return null

  const [initialized, setInitialized] = useState(false)
  const iframeID = `bb-global-modal-${config?.wrapperID}-${config?.type}-${config.app_key}`

  const globalExitPopUpEnable = appState?.globalExitPopUpEnable ?? false

  const shouldModalBeEnabled =
    globalExitPopUpEnable || hasRateTable || appState?.exposeAPI

  useEffect(() => {
    if (initialized) {
      return
    } else if (shouldModalBeEnabled) {
      initGlobalModal(
        {
          config: { ...appState, ...config }
        },
        iframeID
      )
      setInitialized(true)
    }
  }, [shouldModalBeEnabled, appState, initialized, config])

  const handleFrameTasks = (data: any, e: any) => {
    onMessage?.(data, e)
  }

  const frameRef = useRef<HTMLIFrameElement>(null)

  const { modalStyles } = useGlobalModalFrame(iframeID, shouldModalBeEnabled)

  if (shouldModalBeEnabled) {
    return (
      <Fragment>
        {createPortal(
          <Frame
            iframeRef={frameRef}
            url={`${bbProcessEnv.API_URL}/external/global_modal?app_key=${config.app_key}`}
            onMessage={handleFrameTasks}
            config={config}
            className={`bankingbridge-modal-iframe ${modalStyles}`}
            title="BankingBridge global modal"
            isModal
            IDPrefix="bb-global-modal-"
          />,
          document.body
        )}
      </Fragment>
    )
  }

  return null
}

export default GlobalModalFrame
