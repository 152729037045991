import { useDispatch } from "react-redux"
import { rateFormSubmitted, rateFormSubmittedAll } from "@actions"
import { dispatchRateTableUpdateClickedEvent } from "@helpers"
import { LoanDetailsFormFields } from "@types"

function useRateTableFormSubmit() {
  const dispatch = useDispatch()

  const handleSubmit = (data: Partial<LoanDetailsFormFields>) => {
    dispatchRateTableUpdateClickedEvent() // send event

    if (data.selectedTerm === "all") {
      dispatch(rateFormSubmittedAll())
    } else {
      dispatch(rateFormSubmitted())
    }
  }

  return handleSubmit
}

export default useRateTableFormSubmit
