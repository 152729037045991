import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { Typeahead, Highlighter } from "react-bootstrap-typeahead"

export default ({
  name,
  placeholder,
  value,
  label,
  compact,
  hiddenLabel,
  showRequired,
  size = "md",
  className = "",
  options = [],
  hasPicture,
  onInput,
  multiple
}) => {
  const [selected, setSelected] = useState([])
  const [props, setProps] = useState({})

  useEffect(() => {
    var optionsSelected = options.filter(function (el) {
      return el.id === value
    })
    setSelected(optionsSelected)

    if (hasPicture) {
      setProps({
        renderMenuItemChildren: (option, { text }, index) => (
          <span className="d-flex align-items-center">
            {option.picture && (
              <img
                className="rbt-menu__img"
                src={option.picture}
                alt={option.label}
              />
            )}
            <span>
              <Highlighter search={text}>{option.label}</Highlighter>
            </span>
          </span>
        )
      })
    }
  }, [value, options])

  return (
    <div
      className={`form__field is-${size} ${
        compact ? "is-compact" : ""
      } ${className}`}
    >
      {label && (
        <label
          className={`form__label ${hiddenLabel ? "d-none" : ""}`}
          htmlFor={name}
        >
          <span className="form__label-txt">{label}</span>
          {showRequired && <span className="text-danger"> *</span>}
        </label>
      )}
      <Typeahead
        id={name ?? label}
        {...props}
        onChange={selected => {
          onInput(multiple ? selected : selected.length ? selected[0] : "")
        }}
        options={options}
        placeholder={placeholder ? placeholder : null}
        selected={selected}
      />
    </div>
  )
}
