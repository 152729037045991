import { h } from "preact"
import { ScreenReaderOnly, Icon } from "@ui"
import arrowIcon from "@assets/icons/arrow-back.svg"
import "@styles/components/_modal-back.scss"

interface Props {
  handleBackButtonClick: () => void
}

const ModalBack = ({ handleBackButtonClick }: Props) => (
  <button className="modal__back" onClick={handleBackButtonClick} tabIndex={0}>
    <Icon
      className="modal__back-icon"
      src={arrowIcon}
      size={20}
      originalSize={1000}
    />
    <ScreenReaderOnly>back</ScreenReaderOnly>
  </button>
)

export default ModalBack
