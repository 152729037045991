import { Fragment, h } from "preact"
import { useSelector } from "react-redux"
import { If, Button, ContactBtn } from "@ui"
import { LO, RootStates } from "@types"

interface Props {
  isMobile?: boolean
}

const LOCTAButtons = ({ isMobile = false }: Props) => {
  const {
    lo: { calendar_link, app_link }
  } = useSelector(({ app }: RootStates) => {
    return {
      lo: app?.lo as LO
    }
  })

  const btnSize = isMobile ? "xl" : "2xl"

  return (
    <Fragment>
      <If
        condition={!!calendar_link && isMobile}
        then={
          <a
            href={calendar_link}
            target="_blank"
            className="tw-mt-4 accent-color tw-text-lg tw-no-underline font-bold"
          >
            Schedule a call
          </a>
        }
      />
      <div
        className={`header-lo__btns tw-items-center tw-flex ${
          isMobile ? "is-mobile tw-justify-center" : ""
        }`}
      >
        <If
          condition={!!calendar_link && !isMobile}
          then={
            <Button
              link={isMobile}
              href={calendar_link}
              target="_blank"
              adapt
              size="2xl"
            >
              Schedule call
            </Button>
          }
        />

        <Button
          href={app_link}
          target="_blank"
          adapt
          isWhite={!isMobile && !!calendar_link}
          size={btnSize}
          noShadow={isMobile}
        >
          Apply now
        </Button>

        <ContactBtn
          outline={isMobile}
          size={btnSize}
          noShadow={isMobile}
          adapt
          isWhite
        />
      </div>
    </Fragment>
  )
}

export default LOCTAButtons
