import { Fragment, h } from "preact"
import DynamicFormInput from "./DynamicFormInput"
import DynamicFormInputNumber from "./DynamicFormInputNumber"
import DynamicFormLocation from "./DynamicFormLocation"
import DynamicFormSelect from "./DynamicFormSelect"
import DynamicFormSubscribe from "./DynamicFormSubscribe"
import DynamicFormTextarea from "./DynamicFormTextarea"
import DynamicFormEmail from "./DynamicFormEmail"
import { If } from "@ui"
import { useDynamicForm } from "./DynamicFormProvider"
import { DynamicFormData, DynamicFormField } from "@types"

type Props = {
  form: DynamicFormData
  updateFormState: (value: string, name: string) => void
  handleSubscribeChange: (name: string) => void
  updateLocation: (val: any, full_location: string, name: string) => void
  setLocationFieldStatus: (val: boolean) => void
}

const FieldMapper = ({
  form,
  updateFormState,
  handleSubscribeChange,
  updateLocation,
  setLocationFieldStatus
}: Props) => {
  const { fields } = useDynamicForm()

  return (
    <Fragment>
      {fields.map((item: DynamicFormField) => {
        const ID = item.id
        const TYPE = item.type
        const LABEL = item.label
        const REQUIRED = item.required
        const VALUE = form[ID as keyof DynamicFormData]

        return (
          <Fragment key={ID}>
            <If
              condition={TYPE === "text" && ID === "email"}
              then={
                <DynamicFormEmail
                  name={ID}
                  value={VALUE as string}
                  onInput={updateFormState}
                  label={LABEL}
                  required={REQUIRED}
                />
              }
              elseCondition={TYPE === "text"}
              else={
                <DynamicFormInput
                  name={ID}
                  value={VALUE as string}
                  onInput={updateFormState}
                  label={LABEL}
                  required={REQUIRED}
                />
              }
            />

            <If
              condition={TYPE === "number"}
              then={
                <DynamicFormInputNumber
                  name={ID}
                  value={VALUE as string}
                  onInput={updateFormState}
                  label={LABEL}
                  required={REQUIRED}
                />
              }
            />

            <If
              condition={TYPE === "select"}
              then={
                <DynamicFormSelect
                  name={ID}
                  value={VALUE as string}
                  onChange={updateFormState}
                  label={LABEL}
                  required={REQUIRED}
                  options={item.options}
                />
              }
            />

            <If
              condition={TYPE === "textarea"}
              then={
                <DynamicFormTextarea
                  name={ID}
                  value={VALUE as string}
                  onInput={updateFormState}
                  label={LABEL}
                  required={REQUIRED}
                />
              }
            />

            <If
              condition={TYPE === "checkbox"}
              then={
                <DynamicFormSubscribe
                  name={ID}
                  value={VALUE}
                  onChange={handleSubscribeChange}
                  label={LABEL}
                />
              }
            />

            <If
              condition={TYPE === "location_autocomplete"}
              then={
                <DynamicFormLocation
                  name={ID}
                  value={VALUE as string}
                  onChange={updateLocation}
                  label={LABEL}
                  required={REQUIRED}
                  setValidation={setLocationFieldStatus}
                />
              }
            />
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default FieldMapper
