import { h } from "preact"
import { contactValidationAPI } from "@api"
import Input from "../inputs/Input"
import { validateEmailFormat } from "@helpers"
import { useEffect, useRef, useState } from "preact/hooks"
import Alert from "../../Alert"
import { If } from "@ui"
import FadeInOut from "../../FadeInOut"
import AccentColor from "../../AccentColor"
import DotAnimation from "../../DotAnimation"

const errorMsg = "Please enter a valid e-mail address"

interface EmailProps {
  className?: string
  value: string
  onBlur?: (e: FocusEvent) => void
  onInput: (e: Event) => void
  onFocus?: (e: FocusEvent) => void
  onValidationError?: (error: string) => void
  onValidationSuccess?: () => void
  onValidationStart?: () => void
  placeholder?: string
  name: string
  label?: string
  isMaterial?: boolean
  isSpecial?: boolean
  required?: boolean
  triggerValidation?: boolean
  valiateOnBlur?: boolean
  showRequired?: boolean
  size?: "sm" | "md" | "lg"
  showValidationIndicator?: boolean
  APIValidationEnabled?: boolean
}

const Email = ({
  size,
  className = "",
  value,
  onBlur,
  onInput,
  onFocus,
  onValidationError,
  onValidationSuccess,
  onValidationStart,
  valiateOnBlur = true,
  triggerValidation = false,
  placeholder,
  isMaterial,
  isSpecial,
  required,
  showRequired,
  label,
  name,
  showValidationIndicator = true,
  APIValidationEnabled = false
}: EmailProps) => {
  const inputEle = useRef<HTMLInputElement>(null)

  const [validatedEmail, setValidatedEmail] = useState("")
  const [showError, setShowError] = useState(false)
  const [notValid, setNotValid] = useState(false)
  const [isValidating, setIsValidating] = useState(false)

  useEffect(() => {
    if (triggerValidation) {
      validateEmail()
    }
  }, [triggerValidation])

  const errorHandler = (validationError: string = errorMsg) => {
    setNotValid(true)
    setShowError(true)
    inputEle.current?.setCustomValidity(validationError)
    onValidationError?.(validationError)
  }

  const resetStatus = () => {
    inputEle.current?.setCustomValidity("")
    setShowError(false)
  }

  const handleBlur = async (e: FocusEvent) => {
    if (valiateOnBlur) {
      await validateEmail()
    }

    onBlur?.(e)
  }

  const handleFocus = (e: FocusEvent) => {
    resetStatus()
    onFocus?.(e)
  }

  const handleInput = (e: Event) => {
    onInput?.(e)
  }

  const successHandler = () => {
    setNotValid(false)
    resetStatus()
    onValidationSuccess?.()
  }

  const validateEmail = async () => {
    const validEmail = validateEmailFormat(value)
    onValidationStart?.()
    setValidatedEmail(value)

    if (!validEmail) {
      errorHandler()
    } else if (!APIValidationEnabled) {
      successHandler()
    } else if (value !== validatedEmail) {
      setIsValidating(true)
      try {
        const response = await contactValidationAPI.email(value)
        if (response.status === "error") {
          errorHandler()
        } else {
          successHandler()
        }
        setIsValidating(false)
      } catch (error) {
        setIsValidating(false)
        console.log(error)
        onValidationError?.("An error occurred with your e-mail validation")
      }
    } else if (notValid) {
      errorHandler()
    }
  }

  return (
    <Input
      size={size}
      className={className}
      inputRef={inputEle}
      isMaterial={isMaterial}
      isSpecial={isSpecial}
      type="email"
      value={value}
      onBlur={handleBlur}
      onInput={handleInput}
      onFocus={handleFocus}
      placeholder={placeholder}
      name={name}
      aria-describedby="email"
      required={required}
      showRequired={showRequired}
      label={label}
    >
      <If
        condition={showValidationIndicator}
        then={
          <FadeInOut
            className="form__field__loader-container"
            show={isValidating}
            defaultComponent=" "
            alternateComponent={
              <div className="tw-inline-flex tw-items-center tw-justify-center">
                <AccentColor>Validating</AccentColor>
                <DotAnimation className="accent-color" />
              </div>
            }
          />
        }
      />
      <If
        condition={showError}
        then={
          <Alert.Danger size="sm" className="tr-mt-2 tr-mb-0">
            {errorMsg}
          </Alert.Danger>
        }
      />
    </Input>
  )
}

export default Email
