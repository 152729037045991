import { RefObject } from "preact"
import { useState } from "preact/hooks"

const useCodeInput = (
  codeLength: number,
  inputRef: RefObject<HTMLInputElement>
) => {
  const [cursorPosition, setCursorPositionState] = useState(0)

  const [focusClassName, setFocusClassName] = useState(
    Array.from({ length: codeLength }, () => "")
  )

  const setCursorPosition = (position: number, updateValue = true) => {
    const newFocusClassName = Array.from({ length: codeLength }, () => "")
    let newPosition = position < codeLength ? position : codeLength - 1
    newPosition = newPosition < 0 ? 0 : newPosition

    newFocusClassName[newPosition] = "is-focused"
    setFocusClassName(newFocusClassName)
    setCursorPositionState(newPosition)

    if (inputRef.current) {
      inputRef.current.setSelectionRange(newPosition + 1, newPosition + 1)
      if (updateValue) {
        inputRef.current.focus()
      }
    }
  }

  const handleOnFocus = () => {
    setCursorPosition(cursorPosition)
  }

  const handleOnBlur = () => {
    setFocusClassName(Array.from({ length: codeLength }, () => ""))
  }

  return {
    cursorPosition,
    setCursorPosition,
    focusClassName,
    handleOnBlur,
    handleOnFocus
  }
}

export default useCodeInput
