import { h } from "preact"
import BoxHeader from "../../ui/Box/BoxHeader"
import { If } from "@ui"
import RateTableFormRating from "../RateTableFormRating"
import LoanDetailsFormWrapper from "../LoanDetailsFormWrapper"
import { useAppContext } from "@contexts"
import "@styles/components/_rate-table-form.scss"

interface Props {
  anchor?: string
}

const RateTableForm = ({ anchor }: Props) => {
  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  return (
    <div className="rt-form">
      <div className="tw-flex tw-items-center">
        <If
          condition={!isMobile}
          then={
            <BoxHeader
              title="Your Rates. On Demand."
              subTitle="Edit to view rates."
            />
          }
        />
        <RateTableFormRating anchor={anchor} />
      </div>

      <LoanDetailsFormWrapper />
    </div>
  )
}

export default RateTableForm
