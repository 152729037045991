import { h } from "preact"
import "@styles/components/_rate-table-skeleton.scss"
import ReviewBlockSkeleton from "./ReviewBlock"
import TxtLoading from "./Txt"
import StarLoader from "./Star"
import RateTableItemsSkeleton from "./RateTableItems"
import { useMediaFlags } from "@contexts"
import { If } from "@ui"

interface Props {
  className?: string
  noHeader?: boolean
  hideReviews?: boolean
}

const RateTableSkeleton = ({
  className = "",
  noHeader,
  hideReviews
}: Props) => {
  const { isMobile, isTablet, isDesktop } = useMediaFlags()

  return (
    <div className={`rate-table-skeleton ${className}`}>
      <If
        condition={!noHeader}
        then={
          <div className="rate-table-skeleton__header">
            <div className="rate-table-skeleton__header-title">
              <TxtLoading height={16} className="mb-2" />
              <TxtLoading height={16} width="55%" />
            </div>
            <div className="rate-table-skeleton__header-rating">
              <TxtLoading
                height={16}
                width={isMobile ? "25%" : "100%"}
                className="align-self-center"
              />
              <StarLoader />
            </div>
          </div>
        }
      />
      <div className="rate-table-skeleton__filters gap-sm">
        {[...Array(4)].map(() => (
          <div className="rate-table-skeleton__filter">
            <TxtLoading height={16} width="75%" margin="0 0 8px" />
            <TxtLoading height={16} width="55%" />
            <TxtLoading height={16} width="75%" margin="52px 0 8px" />
            <TxtLoading height={16} width="55%" />
          </div>
        ))}
      </div>
      <div className="rate-table-skeleton__rows">
        <RateTableItemsSkeleton
          pricingIsLoading
          rows={4}
          isNotMobile={isDesktop}
          cols={isMobile ? 1 : 3}
        />
      </div>

      <If
        condition={!hideReviews}
        then={
          <div className="rate-table-skeleton__reviews">
            <ReviewBlockSkeleton cols={isMobile ? 1 : isTablet ? 2 : 3} />
          </div>
        }
      />
    </div>
  )
}

export default RateTableSkeleton
