import { h, Component } from "preact"

class Textarea extends Component {
  constructor(props) {
    super(props)

    const typed = this.checkVal(props.value) ? "is-typed" : ""

    this.state = {
      materialClassName: [typed]
    }
  }

  checkVal = val => {
    return val && val.length
  }

  handleChange = event => {
    this.toggleMaterialClassName("is-typed", this.checkVal(event.target.value))
    this.props.onInput && this.props.onInput(event)
  }

  handleFocus = event => {
    this.toggleMaterialClassName("has-focus", true)
    this.props.onFocus && this.props.onFocus(event)
  }
  handleBlur = event => {
    this.toggleMaterialClassName("has-focus")
    this.props.onBlur && this.props.onBlur(event)
  }

  toggleMaterialClassName = (className, add) => {
    const materialClassName = [...this.state.materialClassName]
    const index = materialClassName.indexOf(className)
    if (add && index === -1) {
      materialClassName.push(className)
    } else if (!add && index > -1) {
      materialClassName.splice(index, 1)
    }
    this.setState({ materialClassName: [...materialClassName] })
  }

  render(
    {
      name,
      placeholder,
      value,
      label,
      hiddenLabel,
      compact,
      inputClass = "",
      showRequired,
      isMaterial,
      ...rest
    },
    { materialClassName }
  ) {
    const { onInput, ...tempRest } = rest
    return (
      <div
        className={`form__field${isMaterial ? " is-material" : ""}${
          compact ? " is-compact" : ""
        } ${materialClassName.join(" ")}`}
      >
        {label && (
          <label
            htmlFor={name}
            className={`form__label ${hiddenLabel ? "tw-hidden" : ""}`}
          >
            <span className="form__label-txt">{label}</span>
            {showRequired && <span className="text-danger"> *</span>}
          </label>
        )}
        <div className="tw-relative">
          <textarea
            id={name}
            name={name}
            className={`form__input is-textarea ${inputClass}`}
            placeholder={!isMaterial && placeholder ? placeholder : null}
            value={value}
            onInput={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            {...tempRest}
          />
        </div>
      </div>
    )
  }
}

export default Textarea
