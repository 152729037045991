import { APICallWithCache, bbProcessEnv } from "@helpers"
import {
  GetPricingResponse,
  Assumptions,
  LoanTypeKey,
  GetRateCardsParams,
  LogPublicResponse,
  GetPricingAPIResponse
} from "@types"
import { ONE_DAY_IN_MILLISECONDS, ONE_HOUR_IN_MILLISECONDS } from "@constants"

export const getRateCards = async ({
  loid,
  nmls,
  pricing_grid_defaults,
  program_preference,
  profile_preference,
  max_results,
  cacheEnabled = false,
  expiresIn = ONE_HOUR_IN_MILLISECONDS
}: GetRateCardsParams): Promise<GetPricingResponse | undefined> => {
  const params: {
    loid: number
    include_trend: boolean
    max_results?: number
    nmls?: string
    program_preference?: string
    profile_preference?: LoanTypeKey
  } = {
    loid,
    include_trend: false,
    max_results,
    ...pricing_grid_defaults
  }
  if (nmls) {
    params.nmls = nmls
  }

  if (program_preference) {
    params.program_preference = program_preference
  }

  if (profile_preference) {
    params.profile_preference = profile_preference
  }

  try {
    const url = `${bbProcessEnv.API_URL}/rateflow/price.json`
    const response: GetPricingAPIResponse = await APICallWithCache({
      url,
      params,
      method: "get",
      cacheEnabled,
      expiresIn
    })

    return {
      pricing: response.results
    }
  } catch (error) {
    console.log(error)
  }
}

export const getAssumptions = async ({
  log_id,
  fields = false,
  cacheEnabled = true,
  expiresIn = ONE_DAY_IN_MILLISECONDS * 7
}: {
  log_id: number
  fields?: boolean
  cacheEnabled?: boolean
  expiresIn?: number
}) => {
  try {
    const url = `${bbProcessEnv.API_URL}/loan-assumptions.json`
    const response: Assumptions = await APICallWithCache({
      url,
      params: {
        fields,
        rateflow_log_id: log_id
      },
      method: "get",
      cacheEnabled,
      expiresIn
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

interface LogPublicParams {
  quote_id: number | number[]
  include_request?: boolean
  include_cards?: boolean
  cacheEnabled?: boolean
  expiresIn?: number
}

export const logPublic = async ({
  quote_id,
  include_request = false,
  include_cards = false,
  cacheEnabled = false,
  expiresIn = ONE_HOUR_IN_MILLISECONDS
}: LogPublicParams): Promise<LogPublicResponse | undefined> => {
  try {
    let id = ""
    if (Array.isArray(quote_id)) {
      id = quote_id.join(",")
    } else {
      id += quote_id
    }

    const url = `${bbProcessEnv.API_URL}/api/v1/rateflow-log-public`
    const response: LogPublicResponse = await APICallWithCache({
      url,
      params: {
        id,
        include_request,
        include_cards
      },
      method: "get",
      cacheEnabled,
      expiresIn,
      withCredentials: true
    })

    return response
  } catch (error) {
    console.log(error)
  }
}
