import { useContext } from "preact/hooks"
import ReviewTypeContext from "./context"

// Custom hook for consuming the context
const useReviews = () => {
  const context = useContext(ReviewTypeContext)
  if (!context) {
    throw new Error("useReviews must be used within a ReviewsProvider")
  }
  return context
}

export default useReviews
