export default () => {
  console.log(
    "%cBB API Methods",
    "font-weight: bold; font-size: 16px; color: #4268fa;"
  )

  const apiMethods = {
    "Open modals": [
      {
        method: "openModal('mortgageCalc)",
        description:
          "Open Mortgage Calculator modal. Deprecated, use `openMortgageCalc()` instead."
      },
      {
        method: "openModal('rateSubscription')",
        description: "Open Rate Subscription Modal"
      },
      {
        method: "openModal('contactForm')",
        description: "Open Contact Form Modal"
      },
      {
        method: "openModal('dynamicForm')",
        description: "Open Dynamic Form Modal"
      },
      {
        method: "openModal('leadWorkflow')",
        description:
          "Open Lead Workflow Modal. Deprecated, use `openLeadWorkflowModal()` instead."
      }
    ],
    Lead: [
      {
        method: "leadSubmit(formData: object)",
        description: "Submit raw leads"
      }
    ],
    "Lead workflow": [
      {
        method:
          "workflowInit(formData?: object, goToLastAnswer?: boolean, step?: number)",
        description: "Open lead workflow modal with passed values & step."
      },
      {
        method: "getLeadWorkflowValues(app_key?: string)",
        description: "Get lead workflow values based on `app_key`."
      },
      {
        method: "openLeadWorkflowModal()",
        description: "Open lead workflow modal."
      }
    ],
    Rateflow: [
      {
        method: "price(formData: object)",
        description: "Get rateflow data, returns a promise."
      },
      {
        method: "scenarios(pricing_grid_defaults: object)",
        description: "Get rateflow scenarios, returns a promise."
      },
      {
        method:
          "log(quote_id: number, include_request?: boolean, include_cards?: boolean)",
        description: "Get rateflow log data, returns a promise."
      },
      {
        method:
          "assumptions({log_id: number, fields?: boolean, cacheEnabled?: boolean, expiresIn?: number})",
        description: "Get assumptions for rateflow log ID, returns a promise."
      }
    ],
    "Loan calculator": [
      {
        method: "loanCalc(params: object)",
        description: "Loan calculation API, returns a promise."
      }
    ],
    "Mortgage calculartor": [
      {
        method: "openMortgageCalc()",
        description: "Open Mortgage Calculator modal."
      }
    ],
    "Exit popup": [
      {
        method: "openExitPopUp(force?: boolean)",
        description:
          "Open exit pop-up modal. If `force = true`, it will override local storage data."
      },
      {
        method: "cleanExitPopUpStorage()",
        description: "Clear exit pop-up stored data (for debugging)."
      },
      {
        method: "logExitPopUpStorage()",
        description: "Log exit pop-up stored data (for debugging)."
      }
    ]
  }

  // Dynamic logging
  Object.entries(apiMethods).forEach(([group, methods]) => {
    console.group(`%c${group}`, "color: #7a6fff; font-weight: bold;")
    methods.forEach(methodObj => {
      console.log(
        `%cMethod: ${methodObj.method}`,
        "color: #35cac5",
        `→ ${methodObj.description}`
      )
    })
    console.groupEnd()
  })
}
