import { h } from "preact"
import { useEffect } from "preact/hooks"
import { LoanDetailsFormFields, LoanType } from "@types"
import {
  MORTGAGE_TERMS_CONFIG,
  MORTGAGE_TERMS_CONFIG_WITH_FEATURED
} from "@constants"
import { Button } from "@ui"
import { Toolbar } from "@composite"
import "@styles/components/_rates-toolbar.scss"

interface Props {
  loan_type?: LoanType
  options?: Partial<typeof MORTGAGE_TERMS_CONFIG_WITH_FEATURED>
  selectedTerm?: string | number | undefined
  handleOnChangeTerm: (quote_info: Partial<LoanDetailsFormFields>) => void
}

const DEFAULT_TERM_OPTIONS = MORTGAGE_TERMS_CONFIG

const RatesToolbar = ({
  loan_type,
  options = DEFAULT_TERM_OPTIONS,
  handleOnChangeTerm,
  selectedTerm = DEFAULT_TERM_OPTIONS[0].term
}: Props) => {
  const handleOnChangeTermLocal = (term: string | number, type: string) => {
    if (term === "all") {
      handleOnChangeTerm({
        isAllTerms: true,
        selectedTerm: term
      })
    } else {
      const isArmType = type === "arm"
      const quote_info: Partial<LoanDetailsFormFields> = {
        isAllTerms: false,
        loan_term: isArmType ? undefined : (term as number),
        arm_term: isArmType ? (term as string) : undefined,
        loan_type: isArmType
          ? "arm"
          : loan_type === "arm"
          ? "conventional"
          : loan_type,
        selectedTerm: term
      }

      handleOnChangeTerm(quote_info)
    }
  }

  useEffect(() => {
    if (options?.length) {
      // selectedTerm is in the options list we don't change anything
      if (options.some(option => option?.term === selectedTerm)) {
        return
      }

      handleOnChangeTermLocal(options[0]!?.term, options[0]!?.type)
    }
  }, [options])

  return (
    <div className="rates-toolbar" id="rates-toolbar">
      <Toolbar flexWrap={false}>
        {(options as typeof MORTGAGE_TERMS_CONFIG_WITH_FEATURED).map(
          ({ label, term, type }, index: number) => (
            <Button
              key={index}
              noShadow
              adapt
              size="sm"
              label={label}
              isAlt={selectedTerm != term}
              className={selectedTerm == term ? "is-selected" : ""}
              onClick={() => {
                handleOnChangeTermLocal(term, type)
              }}
            />
          )
        )}
      </Toolbar>
    </div>
  )
}

export default RatesToolbar
