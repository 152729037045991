import { h } from "preact"
import Now from "../Now"

const RateTableTime = () => (
  <div className="rates-block__time ml-auto">
    Rates current as of <Now />
  </div>
)

export default RateTableTime
