import { h } from "preact"
import { RATE_TABLE_TXT } from "@content"
import { useRatesFormContext } from "@contexts"
import { RatesFormContextProps } from "@types"
import { If, NoRatesMessage } from "@ui"

interface Props {
  pricingIsLoading: boolean
  noResults: boolean
}

const RateTableNoResults = ({ pricingIsLoading, noResults }: Props) => {
  const ratesFormContext = useRatesFormContext()
  const { cleanForm } = ratesFormContext as RatesFormContextProps

  return (
    <If
      condition={!pricingIsLoading && noResults}
      then={
        <NoRatesMessage
          onReset={cleanForm}
          title={RATE_TABLE_TXT.noResults.title}
          message={RATE_TABLE_TXT.noResults.msg}
        />
      }
    />
  )
}

export default RateTableNoResults
