import { h } from "preact"
import { useSelector } from "react-redux"
import { LO, RootStates } from "@types"
import PhoneNumbers from "./PhoneNumbers"
import ExpandableContent from "../../ExpandableContent"
import LOSocialLinks from "../../LOSocialLinks"
import { If, GetAlertsBtn } from "@ui"
import { useIsDesktop } from "@contexts"
import LOCTAButtons from "../LOCTAButtons"
import Reviews from "./Reviews"
import { ABOUT_LO } from "@constants"

const HeaderLoProfile = () => {
  const {
    mountInlineRateTable,
    aboutMe,
    lo: { name, position, nmls, brand_nmls },
    mountReviews
  } = useSelector(({ app }: RootStates) => {
    let aboutMe =
      app?.extraDetails?.aboutMe?.replace(/<[^>]*>?/gm, "").trim() || ABOUT_LO

    return {
      mountReviews:
        (app?.widgets?.loReviews || app?.widgets?.brandReviews) ?? false,
      mountInlineRateTable: app?.widgets?.mountInlineRateTable,
      mobilePhone: app?.extraDetails?.mobilePhone,
      officePhone: app?.extraDetails?.officePhone,
      aboutMe,
      lo: app?.lo as LO
    }
  })

  const isDesktop = useIsDesktop()

  return (
    <div className="header-lo__profile tw-text-black tw-pt-8 md:tw-pt-6">
      <div className="tw-flex tw-items-center tw-justify-between">
        <h1 className="header-lo__title font-bold tw-mb-0">{name}</h1>
        <If
          condition={isDesktop}
          then={
            <div className="tw-flex tw-items-center gap-lg">
              <If condition={!!mountInlineRateTable} then={<GetAlertsBtn />} />
              <LOSocialLinks />
            </div>
          }
        />
      </div>

      <p className="tw-text-xl tw-mb-0">{position}</p>
      <p className="tw-text-neutral-500 tw-mt-2 tw-mb-0">
        NMLS #{nmls} | Company NMLS #{brand_nmls}
      </p>
      <PhoneNumbers />

      <If condition={!isDesktop} then={<LOCTAButtons isMobile />} />
      <If condition={mountReviews} then={<Reviews />} />

      <ExpandableContent className="tw-pt-6" text={aboutMe} />
    </div>
  )
}

export default HeaderLoProfile
