import { Fragment, h } from "preact"
import { useSelector } from "react-redux"
import { RootStates } from "@types"
import { Col, Group, Row } from "../../layout"
import { ChangeEvent } from "preact/compat"
import If from "../../../If"
import Checkbox from "../../inputs/Checkbox"

interface Props {
  className?: string
  label?: string
  onChange: (e: ChangeEvent) => void
  checked: boolean
  required?: boolean
  type?: "lead_capture" | "lead_verification"
}

const Disclaimer = ({
  className = "",
  label,
  checked,
  required = false,
  onChange,
  type = "lead_capture"
}: Props) => {
  const { lc_disclaimer, jornayaId, lv_disclaimer, brand_id } = useSelector(
    ({ app: { disclaimers, jornayaId, lo }, wizard }: RootStates) => ({
      lc_disclaimer: disclaimers?.lead_capture,
      jornayaId: jornayaId,
      lv_disclaimer: wizard?.disclaimers?.lead_verification,
      brand_id: lo?.bank_nid
    })
  )

  const text =
    label ?? (type === "lead_verification" && lv_disclaimer)
      ? lv_disclaimer
      : lc_disclaimer

  return (
    <Fragment>
      <Row
        compact
        className={`disclaimer-field form__checkbox-row ${className}`}
      >
        <Col>
          <Group>
            <Checkbox
              required={required}
              name="disclaimer"
              id="disclaimer"
              checked={checked}
              onChange={onChange}
              solid
            >
              <div>
                {text}
                <div>
                  <a
                    href={`https://app.bankingbridge.com/privacy-policy?brand_id=${brand_id}`}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="accent-color"
                  >
                    Privacy Policy
                  </a>{" "}
                  |{" "}
                  <a
                    href={`https://app.bankingbridge.com/terms-conditions?brand_id=${brand_id}`}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="accent-color"
                  >
                    Terms and Conditions
                  </a>
                </div>
              </div>
            </Checkbox>
          </Group>
        </Col>
      </Row>
      <If
        condition={!!jornayaId}
        then={
          <Fragment>
            <input
              id="leadid_token"
              name="universal_leadid"
              type="hidden"
              value=""
            />
          </Fragment>
        }
      />
    </Fragment>
  )
}

export default Disclaimer
