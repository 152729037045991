import { h } from "preact"
import { Button, Chevron, If } from "@ui"

type Props = {
  property_type: string
  residency_type: string
  location: string
  open: boolean
  hideToggle: boolean
  onClick: () => void
}

const BtnHeader = ({
  property_type,
  residency_type,
  location,
  open,
  hideToggle,
  onClick
}: Props) => (
  <Button
    id="loanProfileToggle"
    ariaExpanded={open}
    ariaControls="loanProfile"
    link
    onClick={() => {
      if (!hideToggle) {
        onClick()
      }
    }}
    className={`wizard__loan-profile-title tw-flex tw-items-center ${
      hideToggle ? "tw-cursor-default" : ""
    }`}
  >
    <div className="tw-mr-auto wizard__loan-profile-title-label">
      Your loan scenario
      <If
        condition={!open}
        then={
          <div className="wizard__loan-profile-title-info tw-hidden md:tw-block">
            {property_type} <small>●</small> {residency_type} <small>●</small>{" "}
            in {location}
          </div>
        }
      />
    </div>
    <If
      condition={!hideToggle}
      then={
        <div className="wizard__loan-profile-title-toggle accent-color">
          <span className=" tw-hidden md:tw-inline">
            {open ? "Close " : "Detailed summary "}
          </span>
          <Chevron animated isActive={open} theme="primary" />
        </div>
      }
    />
  </Button>
)

export default BtnHeader
