import { h } from "preact"
import { SelectCustom } from "../../inputs"
import { RESIDENCY_TYPE_OPTIONS } from "@constants"

interface Props {
  value?: typeof RESIDENCY_TYPE_OPTIONS[number]["value"]
  onChange: (
    value: typeof RESIDENCY_TYPE_OPTIONS[number]["value"],
    name: string
  ) => void
  isMaterial?: boolean
  showErrorWhenEmpty?: boolean
  name?: string
  label?: string
  size?: "sm" | "md" | "lg"
}

const ResidencyType = ({
  value,
  isMaterial = true,
  name = "residency_type",
  onChange,
  label = "Residency Usage",
  size = "md",
  showErrorWhenEmpty = true
}: Props) => (
  <SelectCustom
    isMaterial={isMaterial}
    name={name}
    options={RESIDENCY_TYPE_OPTIONS}
    value={value}
    onChange={option => onChange(option.value, name)}
    label={label}
    size={size}
    showErrorWhenEmpty={showErrorWhenEmpty}
  />
)

export default ResidencyType
