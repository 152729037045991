import { h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import clsx from "clsx"
import { formatLocationOption, debounce as debounceFN } from "@helpers"
import { If, DropdownSkeleton, Icon } from "@ui"
import { useEventListener } from "@hooks"
import InputDesc from "./InputDesc"
import useMaterialClassName from "./hooks/useMaterialClassName"
import usePlacesAutocomplete from "./hooks/usePlacesAutocomplete"
import useGooglePlacesService from "./hooks/useGooglePlacesService"
import pinIcon from "@assets/icons/map-marker-alt-solid.svg"

interface AutocompleteInputProps {
  apiOptions?: Partial<google.maps.places.AutocompletionRequest>
  debounce?: number
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void
  isMaterial?: boolean
  notStacked?: boolean
  className?: string
  size?: "sm" | "md" | "lg"
  label?: string
  placeholder?: string
  forceError?: boolean
  showErrorWhenEmpty?: boolean
  hasMetaDataUI?: boolean
  required?: boolean
  initialValue?: string
}

const checkVal = (val: string | undefined) => {
  return val ? val.length > 0 : false
}

const AutocompleteInput: preact.FunctionalComponent<AutocompleteInputProps> = ({
  apiOptions = {
    componentRestrictions: {
      country: "us"
    },
    types: ["(regions)"]
  },
  debounce = 400,
  onPlaceSelected,
  isMaterial = false,
  notStacked = false,
  className = "",
  size = "md",
  label = "ZIP code",
  placeholder,
  initialValue = "",
  hasMetaDataUI = false,
  showErrorWhenEmpty = false,
  forceError = false,
  required = false
}) => {
  const { autocompleteService } = useGooglePlacesService()

  const metaEle = useRef<HTMLDivElement>(null)
  const wrapperEle = useRef<HTMLDivElement>(null)
  const [placeClassName, setPlaceClassName] = useState("")

  const isInternalChange = useRef(false) // Track if the last change was internal
  const {
    inputValue,
    setInputValue,
    suggestions,
    focusedSuggestionIndex,
    handleInputChange,
    handleSuggestionClick,
    handleKeyDown,
    isLoading,
    currentPlace,
    showError,
    setShowError
  } = usePlacesAutocomplete(
    autocompleteService?.current,
    apiOptions,
    debounce,
    place => {
      isInternalChange.current = true // Mark as internal change when a place is selected
      onPlaceSelected(place)
    },
    initialValue
  )

  const { materialClassName, toggleMaterialClassName } = useMaterialClassName()

  useEffect(() => {
    toggleMaterialClassName({
      "is-typed": checkVal(inputValue)
    })
  }, [inputValue])

  // Handle external changes to initialValue
  useEffect(() => {
    if (!isInternalChange.current) {
      // Update inputValue only if the change is external
      setInputValue(initialValue || "")
    }
    isInternalChange.current = false // Reset the flag after processing
  }, [initialValue])

  // check if an metaElem width is more than 55% width of wrapperEle
  const isElementOverflown = () => {
    if (metaEle.current && wrapperEle.current) {
      return metaEle.current.offsetWidth > wrapperEle.current.offsetWidth * 0.55
    }
    return false
  }

  const updatePlaceClassName = debounceFN(() => {
    setPlaceClassName(isElementOverflown() ? "invisible" : "")
  }, 500)

  useEventListener("resize", updatePlaceClassName)

  const handleBlur = () => {
    if (suggestions.length === 1) {
      handleSuggestionClick(suggestions[0])
    }
    toggleMaterialClassName({
      "has-focus": false
    })
  }

  const wrapperClassNames = clsx(
    "gp-input form__field has-loader",
    {
      "is-material": isMaterial,
      [`is-${size}`]: size,
      "is-stacked": !notStacked || isMaterial,
      "tw-flex tw-items-center tw-justify-between": notStacked && !isMaterial
    },
    className,
    materialClassName
  )

  useEffect(() => {
    setShowError(forceError)
  }, [forceError])

  return (
    <div ref={wrapperEle} className={wrapperClassNames}>
      <label htmlFor="location" class="form__label">
        <span class="form__label-txt">{label}</span>
      </label>
      <div className="tw-relative">
        <input
          required={required}
          role="combobox"
          placeholder={isMaterial ? " " : placeholder}
          aria-describedby="input-desc"
          type="text"
          autocomplete="new-location"
          name="new-location"
          value={inputValue}
          onInput={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className={`form__input form__input-gp ${
            (showErrorWhenEmpty && !inputValue) || showError ? "is-invalid" : ""
          }`}
        />
        <div className="gp-input__wrapper">
          <If
            condition={isLoading}
            then={<DropdownSkeleton className="gp-input__loader" />}
            elseCondition={suggestions.length > 0}
            else={suggestions.map((suggestion, index) => (
              <div
                role="option"
                className={clsx("gp-input__item d-flex align-items-center", {
                  "is-selected": index === focusedSuggestionIndex
                })}
                key={suggestion.place_id}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatLocationOption(
                      inputValue,
                      suggestion.description
                    )
                  }}
                />
              </div>
            ))}
          />
        </div>
      </div>

      <If
        condition={hasMetaDataUI}
        then={
          <div className="gp-input__meta" ref={metaEle}>
            <span className={placeClassName}>
              {(currentPlace?.formatted_address?.length ?? 0) > 12
                ? currentPlace?.formatted_address?.substring(0, 12) + "…"
                : currentPlace?.formatted_address}
            </span>
            <Icon
              className="tw-ml-1"
              src={pinIcon}
              originalSize={[384, 512]}
              size={[16, 20]}
            />
          </div>
        }
      />
      <InputDesc />
    </div>
  )
}

export default AutocompleteInput
