import { h } from "preact"
import LeadCaptureForm from "../LeadCaptureForm"

const CardsLeadForm = ({ config, hideLeadForm }) => (
  <div className="tw-min-w-80">
    <LeadCaptureForm
      config={config}
      autohide={true}
      hideLeadForm={hideLeadForm}
    />
  </div>
)

export default CardsLeadForm
