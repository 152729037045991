import { h, ComponentChildren } from "preact"
import "@styles/components/_tabs.scss"

interface Props {
  options: Array<{ label: ComponentChildren; value: string }>
  onClickHandle: (value: string) => void
  active: string
}

const Tabs = ({ options, onClickHandle, active }: Props) => (
  <ul className="tabs tw-flex tw-gap-4 md:tw-gap-8" role="tablist">
    {options.map(option => {
      return (
        <li
          role="tab"
          aria-selected={active === option.value}
          className={`tabs__tab ${active === option.value ? "active" : ""}`}
          onClick={() => {
            onClickHandle(option.value)
          }}
        >
          <div className="tabs__tab-label">{option.label}</div>
        </li>
      )
    })}
  </ul>
)

export default Tabs
