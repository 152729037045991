import { Fragment, h } from "preact"
import { Icon, If } from "@ui"
import { Tooltip } from "@composite"
import questionMark from "@assets/icons/question-mark.svg"
import "@styles/components/_va-tooltip.scss"

interface Props {
  loan_purpose?: "purchase" | "refinance"
}

const VATooltip = ({ loan_purpose = "purchase" }: Props) => (
  <Tooltip
    textAlignment="left"
    ttClassName="va-tooltip"
    theme="light"
    ttPlacement="top"
    reference={
      <Icon
        className="txt-dark"
        src={questionMark}
        size={20}
        originalSize={20}
      />
    }
  >
    <h3>What are these options?</h3>
    <dl>
      <If
        condition={loan_purpose === "purchase"}
        then={
          <Fragment>
            <div className="d-flex">
              <dt>1st</dt>
              <dd>initial VA loan use</dd>
            </div>
            <div className="d-flex">
              <dt>2nd</dt>
              <dd>subsequent VA loan use</dd>
            </div>
          </Fragment>
        }
      />
      <div className="d-flex">
        <dt>Exempt</dt>
        <dd>exempt from funding fee</dd>
      </div>
    </dl>
  </Tooltip>
)

export default VATooltip
