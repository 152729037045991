import { useSelector } from "react-redux"
import { PricingItem, RootStates } from "@types"

const useGetMonthlyPayment = () => {
  const { homeInsurance, propertyTaxAmount } = useSelector(
    ({ app }: RootStates) => {
      return {
        homeInsurance: app?.taxesAndInsurance?.insurance ?? 0,
        propertyTaxAmount: app?.taxesAndInsurance?.taxes ?? 0
      }
    }
  )

  const getMonthlyPayment = ({
    principalAndInterest,
    monthlyMI
  }: PricingItem) => {
    return Math.round(
      principalAndInterest + homeInsurance + propertyTaxAmount + monthlyMI
    )
  }

  return getMonthlyPayment
}

export default useGetMonthlyPayment
