import { h } from "preact"
import LeadWorkflow from "../../../../entry/leadWorkflow"

const LeadWorkflowWrapper = () => (
  <LeadWorkflow
    isInline
    className="tw-max-w-4xl	tw-mx-auto tw-mt-8 tw-shadow-xl"
  />
)

export default LeadWorkflowWrapper
