import { h } from "preact"
import { useSelector } from "react-redux"
import { PricingItem, RootStates } from "@types"
import { formatFeeValue } from "@helpers"

interface Props {
  currentDetailsCard: PricingItem
}

const FundingFee = ({ currentDetailsCard }: Props) => {
  const { loan_type, VATypeText } = useSelector(
    ({ app: { formData }, subscriptionDash, rateTable }: RootStates) => {
      let VATypeText = ""

      const loan_type =
        subscriptionDash?.quote_info?.loan_type ??
        formData?.loan_type ??
        rateTable?.formData?.loan_type ??
        ""
      const va_funding_fee_type =
        subscriptionDash?.quote_info?.va_funding_fee_type ??
        formData?.va_funding_fee_type ??
        rateTable?.formData?.va_funding_fee_type ??
        ""

      if (loan_type === "va")
        switch (va_funding_fee_type) {
          case "exempt":
            VATypeText = "- Exempt"
            break

          case "second_time":
            VATypeText = "- 2nd Time Use"
            break

          default:
            VATypeText = "- 1st Time Use"
            break
        }

      return {
        loan_type,
        VATypeText
      }
    }
  )

  return (
    <div className="offer-details__funding-fee">
      <strong className="offer-details__funding-fee-title">
        Fees included in loan amount
      </strong>
      <div className="list__item">
        <div className="list__item--label">
          {loan_type.toUpperCase()} Funding Fee {VATypeText}
        </div>
        <div className="list__item--value">
          {formatFeeValue(currentDetailsCard?.fundingFee ?? 0)}
        </div>
      </div>
    </div>
  )
}

export default FundingFee
