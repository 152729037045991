import { h } from "preact"
import { useEffect } from "preact/hooks"
import { SelectCustom } from "../../inputs"
import { LOAN_TERM_OPTIONS } from "@constants"
import { BasicUISize } from "@types"

interface Props {
  value?: string
  isMaterial?: boolean
  name?: string
  handleOnChange: (value: string, name: string) => void
  label?: string
  size?: BasicUISize
}

const LoanTermFixedType = ({
  value,
  isMaterial,
  name = "loan_term",
  handleOnChange,
  label = "Loan Term",
  size = "md"
}: Props) => {
  useEffect(() => {
    if (value === undefined) {
      handleOnChange("30", name) // set default value
    }
  }, [])

  return (
    <SelectCustom
      name={name}
      options={LOAN_TERM_OPTIONS}
      value={value ?? "30"} // set default value
      onChange={option => {
        handleOnChange(option.value, name)
      }}
      label={label}
      isMaterial={isMaterial}
      size={size}
    />
  )
}

export default LoanTermFixedType
