import { h } from "preact"
import { useSelector } from "react-redux"
import { If } from "@ui"
import WizardNextSteps from "../WizardNextSteps"
import WizardSubscription from "../WizardSubscription"
import { RootStates } from "@types"

const InfoBlocks = () => {
  const { subscribeEnabled, disable_pricing, noCards } = useSelector(
    ({ wizard }: RootStates) => ({
      subscribeEnabled: true,
      disable_pricing: !!wizard?.disable_pricing,
      noCards:
        !wizard?.pricingGridIsLoading &&
        wizard?.card_details !== null &&
        (!wizard?.card_details?.cards ||
          wizard?.card_details?.cards.length === 0)
    })
  )

  return (
    <If
      condition={!noCards && !disable_pricing}
      then={
        <div className="wizard__info-blocks">
          {subscribeEnabled && !disable_pricing && <WizardSubscription />}
          <WizardNextSteps
            className={
              subscribeEnabled && !disable_pricing ? "is-col" : "is-full"
            }
          />
        </div>
      }
    />
  )
}

export default InfoBlocks
