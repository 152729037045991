import { Fragment, h } from "preact"
import { If, Form } from "@ui"
import { LoanDetailsFormFields, LocationObj, OnChangeValue } from "@types"
import FirstTimeHomebuyer from "./FirstTimeHomebuyer"
import { useIsMobile } from "@contexts"

interface Props {
  hasARMTerms: boolean
  isOnTooltip?: boolean
  hasLoanTerm?: boolean
  showFields: boolean
  formData: LoanDetailsFormFields
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
  disabledFields?: Array<keyof LoanDetailsFormFields>
}

const ResponsivePurchaseFields = ({
  hasARMTerms,
  isOnTooltip,
  hasLoanTerm = false,
  showFields,
  formData,
  formData: {
    va_funding_fee_type,
    loan_type,
    first_time_homebuyer,
    loan_term,
    loan_purpose
  },
  handleOnChange,
  disabledFields = []
}: Props) => {
  const isMobile = useIsMobile()

  return (
    <If
      condition={showFields}
      then={
        <Fragment>
          <If
            condition={isMobile && !disabledFields.includes("zipcode")}
            then={
              <Form.AutoZipcode
                onChange={(value: LocationObj) => {
                  handleOnChange(value, "location")
                }}
                showErrorWhenEmpty
                initialValue={
                  (formData.location as LocationObj)?.zipcode ||
                  formData.zipcode
                }
              />
            }
          />
          <If
            condition={hasLoanTerm}
            then={
              <Form.LoanTermDropDown
                isOnTooltip={isOnTooltip}
                isMaterial
                value={loan_term ?? 30}
                onChange={(option: { value: OnChangeValue }) =>
                  handleOnChange(option.value, "loan_term")
                }
              />
            }
          />

          <Form.LoanTypeDropDown
            optionsClassName={isOnTooltip ? "is-on-tooltip" : ""}
            value={loan_type}
            va_funding_fee_type={va_funding_fee_type}
            onChange={handleOnChange}
            hasARMOption={hasARMTerms}
            loan_purpose={loan_purpose}
          />

          <If
            condition={!disabledFields.includes("first_time_homebuyer")}
            then={
              <FirstTimeHomebuyer
                value={first_time_homebuyer}
                onChange={(option: { value: OnChangeValue }) =>
                  handleOnChange(option.value, "first_time_homebuyer")
                }
              />
            }
          />

          {/* <Points
            value={points}
            handleOnChange={handleOnChange}
            optionsClassName={isOnTooltip ? "is-on-tooltip" : ""}
          /> */}
        </Fragment>
      }
    />
  )
}

export default ResponsivePurchaseFields
