import { ComponentChildren, Fragment, h } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"
import { createPortal } from "preact/compat"
import { If, CrossBtn } from "@ui"
import { addScrollLock, removeScrollLock } from "@helpers"
import "@styles/components/_dash-mobile-overlay.scss"

interface Props {
  children: ComponentChildren
  title?: string
  closeOverlay: () => void
  show?: boolean
}

const MobileOverlayInner = ({ children, title, closeOverlay, show }: Props) => {
  const [animationClassName, setAnimationClassName] = useState("")
  const [showClassName, setShowClassName] = useState("d-none")

  const modalElement = useRef<HTMLDivElement>(null)

  let timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleAnimationEnd = () => {
    closeOverlay()

    // Clean up the event listener
    modalElement.current?.removeEventListener(
      "animationend",
      handleAnimationEnd
    )

    setShowClassName("d-none")

    timeoutId.current && clearTimeout(timeoutId.current)
  }

  const onClose = () => {
    modalElement.current?.addEventListener("animationend", handleAnimationEnd)

    setAnimationClassName("slide-out-bottom")

    // animationend fallback
    timeoutId.current = setTimeout(() => {
      closeOverlay()
    }, 450)
  }

  useEffect(() => {
    // Clean animationend fallback
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current)
    }
  }, [])

  useEffect(() => {
    if (show) {
      addScrollLock()
      setAnimationClassName("slide-in-bottom")
      setShowClassName("")
    } else if (showClassName === "") {
      onClose()
    }

    return () => removeScrollLock()
  }, [show])

  return (
    <div className={`dash-mobile-overlay ${showClassName}`}>
      <div
        ref={modalElement}
        className={`dash-mobile-overlay__inner ${animationClassName}`}
      >
        <header className="mobile-overlay__header">
          <If
            condition={!!title}
            then={<h2 className="mobile-overlay__title txt-dark">{title}</h2>}
          />
          <CrossBtn
            className="mobile-overlay__close-btn txt-dark"
            onClick={onClose}
            label={`Close ${title}`}
            outline={false}
            size="md"
          />
        </header>
        <div className="mobile-overlay__content">{children}</div>
      </div>
    </div>
  )
}

const DashMobileOverlay = (props: Props) => {
  return (
    <Fragment>
      {createPortal(<MobileOverlayInner {...props} />, document.body)}
    </Fragment>
  )
}

export default DashMobileOverlay
