import { h } from "preact"
import { If, Form } from "@ui"
import CountUp from "react-countup"
import { FIELD_TYPE } from "@types"

interface Props {
  className: string
  isEditable: boolean
  name: FIELD_TYPE
  label: string
  value: number | string
  onEdit?: (value: number | string, fieldName: FIELD_TYPE) => void
}

const LegendItem = ({
  className,
  isEditable,
  name,
  label,
  value,
  onEdit
}: Props) => {
  const handleInputChange = (val: number | string) => {
    onEdit?.(val, name)
  }

  let countUpValue = typeof value === "number" ? value : Number(value)
  if (Number.isNaN(countUpValue)) {
    countUpValue = 0
  }
  return (
    <li className={`${className} linear-chart__label--${name} is-sm`}>
      {label}{" "}
      <If
        condition={isEditable}
        then={
          <Form.InputMasked
            className="linear-chart__input form__input"
            inputAutoWidth
            prefix="$"
            allowDecimal={false}
            required
            name={name}
            onChange={handleInputChange}
            min={0}
            value={value}
          />
        }
        else={
          <strong>
            $
            <CountUp duration={1} end={countUpValue} />
          </strong>
        }
      />
    </li>
  )
}

export default LegendItem
