import { h } from "preact"
import { css } from "emotion"
import styled from "preact-emotion"

interface Props {
  width?: number | string
  height?: number | string
  margin?: string
  className?: string
  borderRadius?: number | string
}

const TxtLoading = ({
  width,
  height,
  margin = "0",
  className = "",
  borderRadius = 8
}: Props) => {
  /*
   *  Classes
   */
  const element = css`
    background: transparent;
  `

  let txtRow: string | number = height ?? 15
  txtRow = css`
    height: ${txtRow}px;
  `
  let w = width ? (typeof width === "string" ? width : `${width}px`) : "100%"
  w = css`
    width: ${w};
  `
  const txtMargin = css`
    margin: ${margin};
  `

  /*
   *  Components
   */
  const Txt = styled("div")`
    ${element};
    ${txtRow};
  `

  const TxtWrapper = styled("div")`
    ${txtMargin};
    ${w};
    border-radius: ${typeof borderRadius === "number"
      ? borderRadius + "px"
      : borderRadius};
  `

  return (
    <TxtWrapper
      className={`loading-placeholder loading-placeholder--animated-background ${className}`}
    >
      <Txt />
    </TxtWrapper>
  )
}

export default TxtLoading
