import { h } from "preact"
import { useSelector } from "react-redux"
import { RootStates } from "@types"

interface OfficerDataProps {
  align?: "left" | "right"
}

const OfficerData = ({ align = "left" }: OfficerDataProps) => {
  const lo = useSelector(({ app: { lo } }: RootStates) => lo)

  return (
    <div className={`o-data d-flex o-data--${align}`}>
      <div className="o-data__info">
        <h3>{lo?.name}</h3>
        <p>
          {lo?.position} <br />
          NMLS #{lo?.nmls}
        </p>
      </div>
      <img
        className="o-data__thumb"
        src={lo?.picture}
        alt={`${lo?.name} thumb`}
      />
    </div>
  )
}

export default OfficerData
