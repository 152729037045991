import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { SimpleSlides, Loader } from "@ui"
import { PricingItem } from "@composite"
import { useAppContext } from "@contexts"
import {
  PricingItems,
  PricingItem as PricingItemInterface,
  PricingItemType
} from "@types"
import "@styles/components/_pricing-scroll.scss"

interface Props {
  type: PricingItemType
  card_details: {
    cards: PricingItems
  }
  className?: string
  isLoading?: boolean
  isResponsive?: boolean
  noAction?: boolean
  onClick?: (item: PricingItemInterface) => void
  hideArrows?: boolean
}

const PricingScroll = ({
  type,
  card_details,
  className = "",
  isLoading = false,
  isResponsive,
  noAction = false,
  onClick = item => {},
  hideArrows
}: Props) => {
  const [currentItems, setItems] = useState<PricingItems>([])

  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  useEffect(() => {
    setCurrentItems()
  }, [])

  useEffect(() => {
    setCurrentItems()
  }, [card_details, isLoading])

  const setCurrentItems = () => {
    const cards = card_details?.cards ?? []

    if (cards?.[0]) {
      setItems(cards)
    } else {
      setItems([])
    }
  }

  return !currentItems.length ? (
    <div className="no-cards" />
  ) : (
    <div
      className={`pricing-scroll is-${type} ${className} ${
        noAction ? "no-action" : ""
      }`}
    >
      {isLoading && <Loader className="pricing-scroll__loader" />}

      <div
        className={`pricing-scroll__${type} ${isLoading ? "is-loading" : ""}`}
      >
        <SimpleSlides
          gap={8}
          className={type === "wizard" && isMobile ? "" : "ml-4"}
          hasDots
          previewNextSlide
          hasArrows={!hideArrows}
          arrowsPosition={isMobile && isResponsive ? "default" : "middle"}
        >
          {currentItems.map((item, index) => (
            <PricingItem
              key={index}
              index={index}
              type={type}
              item={item}
              {...(!noAction
                ? {
                    onClick: () => onClick?.(item)
                  }
                : {})}
            />
          ))}
        </SimpleSlides>
      </div>
    </div>
  )
}

export default PricingScroll
