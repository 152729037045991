import { h, Fragment } from "preact"
import { toFixed } from "@helpers"
import PricingItemOfferDetailsTooltip from "./PricingItemOfferDetailsTooltip"
import { If } from "@ui"
import Fees from "./Fees"
import { PricingItem } from "@types"

interface Props {
  className?: string
  fees: number
  points: number
  hideFeesModal?: boolean
  hasTooltip?: boolean
  handleClickFees: ({
    e,
    openModal
  }: {
    e?: Event
    openModal?: boolean
  }) => void
  currentDetailsCard: PricingItem
}

const FeesAndPoints = ({
  className = "",
  fees,
  hideFeesModal,
  handleClickFees,
  points,
  hasTooltip = true,
  currentDetailsCard
}: Props) => {
  const pointsParsed = toFixed(points, 2, true)
  // if pointsParsed start with - the label is credit else it is points
  const pointsLabel = pointsParsed.startsWith("-") ? "Credit" : "Points"

  return (
    <div
      className={`pricing-item__details is-fees ${className}`}
      onClick={e => {
        if (!hideFeesModal) {
          handleClickFees({ e, openModal: true })
        }
      }}
      {...(!hideFeesModal ? { role: "button", tabIndex: 0 } : {})}
    >
      <Fees fees={fees} />
      <small className="accent-color">
        <Fragment>
          <If
            condition={!!hasTooltip}
            then={
              <PricingItemOfferDetailsTooltip
                handleClickFees={handleClickFees}
                currentDetailsCard={currentDetailsCard}
              />
            }
            else={<span className="pricing-item__label-notooltip">Fees</span>}
          />
          <div className="pricing-item__label">
            <div className="dot-separator">●</div>
            <div className="pricing-item__points">
              {pointsParsed} {pointsLabel}
            </div>
          </div>
        </Fragment>
      </small>
    </div>
  )
}

export default FeesAndPoints
