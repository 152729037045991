import { h, Fragment, ComponentChildren } from "preact"
import { useSelector } from "react-redux"
import { createPortal, useEffect } from "preact/compat"
import {
  LenderHeader,
  BlockingModal,
  InlineAffordabilityCalculator,
  InlineMortgageCalculator,
  RateCards
} from "@composite"
import { AppBaseState, RootStates } from "@types"
import { useState } from "preact/hooks"
import LeadWorkflow from "../../../entry/leadWorkflow"
import { InlineDynamicForm } from "../DynamicForm"
import InlineRateTableWrapper from "../InlineRateTableWrapper"
import { InlineReviews } from "../reviews"
import { getStorage, openModalFromBtn } from "@helpers"

interface Props {
  showBlockingModal: boolean
}

const PrimaryWidgetsComponents = ({ showBlockingModal }: Props) => {
  const {
    pricingGrid,
    mountInlineLeadWorkflow,
    mountInlineCalc,
    mountInlineAffordCalc,
    mountInlineRateTable,
    loReviews,
    brandReviews,
    mountInlineContactForm,
    config
  } = useSelector(({ app }: RootStates) => {
    return { ...(app as AppBaseState).widgets!, config: app }
  })

  const renderPortal = (
    condition: boolean,
    elementId: string,
    component: ComponentChildren
  ) => {
    const element = document.getElementById(elementId)
    return condition && element ? createPortal(component, element) : null
  }

  const [hideRateCards, setHideRateCards] = useState(false)

  const closeLeadForm = () => {
    setHideRateCards(false)
  }

  useEffect(() => {
    const initComponent = () => {
      const { email } = getStorage("leadEmail")

      const leadCapture = config.leadCapture
      const leadCaptureType = config.lead_capture_type

      // determine if leadCapture modal should be presented
      if (window.IntersectionObserver && pricingGrid && leadCapture) {
        const pricingGridEle = document.getElementById(
          "pricing-grid"
        ) as HTMLElement
        const observer = new IntersectionObserver(function (entries) {
          if (entries[0].intersectionRatio) {
            observer.unobserve(pricingGridEle)

            if (!email) {
              if (leadCaptureType == "external") {
                let time =
                  (typeof leadCapture === "number" ? leadCapture : 1) * 1000

                time = +time
                setTimeout(() => {
                  openModalFromBtn(
                    `${process.env.API_URL}/external/lead_capture_modal`
                  )
                }, time)
              } else {
                setHideRateCards(true)
              }
            }
          }
        })
        observer.observe(pricingGridEle)
      }
    }
    initComponent()
  }, [])

  return (
    <Fragment>
      <LenderHeader />

      {renderPortal(
        pricingGrid,
        "pricing-grid",
        <RateCards
          hideRateCards={hideRateCards}
          closeLeadForm={closeLeadForm}
        />
      )}

      {renderPortal(
        mountInlineLeadWorkflow,
        "inline-lead-workflow",
        <LeadWorkflow isInline />
      )}

      {renderPortal(
        mountInlineCalc,
        "inline-mortgage-calculator",
        <InlineMortgageCalculator />
      )}

      {renderPortal(
        mountInlineAffordCalc,
        "inline-affordability-calculator",
        <InlineAffordabilityCalculator />
      )}

      {renderPortal(
        mountInlineRateTable,
        "inline-rate-table",
        <InlineRateTableWrapper />
      )}

      {renderPortal(
        loReviews,
        "inline-lo-reviews",
        <InlineReviews reviewsViewMode="slide" type="lo" />
      )}

      {renderPortal(
        brandReviews,
        "inline-bank-reviews",
        <InlineReviews reviewsViewMode="slide" type="brand" />
      )}

      {renderPortal(
        mountInlineContactForm,
        "inline-contact-form",
        <InlineDynamicForm config={config} />
      )}

      {renderPortal(showBlockingModal, "blocking-modal", <BlockingModal />)}
    </Fragment>
  )
}

export default PrimaryWidgetsComponents
