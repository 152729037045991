import { h } from "preact"
import MoreFiltersBtn from "./MoreFiltersBtn"
import { onClickAnchor } from "@helpers"
import SubmitBtn from "../SubmitBtn"

interface Props {
  showMenu: () => void
  onClickSubmitBtn: () => void
}

const MobileButtons = ({ showMenu, onClickSubmitBtn }: Props) => {
  const onClickSubmitBtnLcl = () => {
    onClickAnchor("rates-toolbar")

    // set a time out to allow the scroll to the anchor to complete
    setTimeout(() => {
      onClickSubmitBtn()
    }, 500)
  }

  return (
    <div className="d-flex gap-base">
      <MoreFiltersBtn onClick={showMenu} />
      <SubmitBtn onClick={onClickSubmitBtnLcl} md={false} />
    </div>
  )
}

export default MobileButtons
