import { h } from "preact"
import { toFixed } from "@helpers"

interface Props {
  points: number
}

const Points = ({ points }: Props) => {
  return (
    <div className="pricing-item__details is-points">
      <strong>{toFixed(points, 2, true)}</strong>
      <small className="pricing-item__label">Points</small>
    </div>
  )
}

export default Points
