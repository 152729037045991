import { sendMessageToParentWithId } from "../.."
import {
  LOAN_DETAILS_MOBILE_FORM,
  MOBILE_FORM,
  SUBMIT_LOAN_DETAILS_FORM
} from "@constants"
import { LoanDetailsFormFields } from "@types"
import { closeGlobalModalFromWithin, openGlobalModalFromWithin } from "../base"

export const openLoanDetailsMobileFilters = (iframeID?: string) => {
  openGlobalModalFromWithin(MOBILE_FORM, LOAN_DETAILS_MOBILE_FORM, iframeID)
}

export const closeLoanDetailsMobileFilters = () => {
  closeGlobalModalFromWithin()
}

// Update the rate table with the new data from the global modal
export const sendLoanDetailsFormToRateTable = (
  formData: LoanDetailsFormFields,
  iframeID: string
) => {
  const msg = {
    action: SUBMIT_LOAN_DETAILS_FORM,
    params: { formData }
  }

  sendMessageToParentWithId(iframeID, msg)
}
