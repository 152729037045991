import { useRef, useState, useEffect } from "preact/hooks"
import { PricingCards } from "@types"
import { bbPricingAPI } from "../api"
import { numberToUSD } from "@helpers"
import { RESIDENCY_TYPE_OPTIONS } from "@constants"
import { useDispatch } from "react-redux"
import { saveAssumptions } from "@actions"

interface AssumptionsCache {
  [key: string]: string
}

export const useAssumptions = (items: PricingCards) => {
  const dispatch = useDispatch()

  const [assumptions, setAssumptions] = useState<string[]>([])
  const assumptionsCacheRef = useRef<AssumptionsCache>({})

  // function to map residency_type to RESIDENCY_TYPE_OPTIONS label
  const mapResidencyType = (type: string) =>
    RESIDENCY_TYPE_OPTIONS.find(option => option.value === type)?.label

  useEffect(() => {
    const fetchAssumptions = async () => {
      try {
        const promises = items.map(async item => {
          if (!item?.rateflow_log_id) return ""

          // Check if assumption is already in cache
          if (assumptionsCacheRef.current[item.rateflow_log_id]) {
            return assumptionsCacheRef.current[item.rateflow_log_id]
          }

          // If not in cache, fetch and store
          const response = await bbPricingAPI.getAssumptions({
            log_id: item.rateflow_log_id,
            fields: true
          })
          if (!response) return ""
          const { fields } = response

          const parts = item.tag.split("_")
          const loanType =
            parts[1] === "conv" ? "Conventional" : parts[1].toUpperCase()

          const formatterAssumptions = `${loanType}, ${
            fields.loan_purpose
          }, <span class="tw-lowercase">${mapResidencyType(
            fields.residency_type
          )}</span>,
            ${numberToUSD(fields.list_price, true)} sales price, ${numberToUSD(
            fields.loan_amount,
            true
          )} loan amount, ${fields.credit_score} credit score, ${
            fields.term * 12
          } months, ${fields.lock_period} day lock period in ${fields.state}.`

          const assumption = `
            <h3 class="tw-font-bold tw-text-sm tw-m-0">${item.label}</h3>
            <p class="tw-text-sm tw-mt-1 tw-mb-0">${formatterAssumptions}</p>
          `

          assumptionsCacheRef.current[item.rateflow_log_id] = assumption
          return assumption
        })

        let newAssumptions = await Promise.all(promises)

        newAssumptions = newAssumptions.filter(Boolean)

        setAssumptions(newAssumptions)
        //dispatch(saveAssumptions(newAssumptions))
        dispatch({
          type: "SAVE_BASE_STATE",
          payload: { assumptions: newAssumptions }
        })
      } catch (e) {
        console.error("Error fetching assumptions:", e)
      }
    }

    fetchAssumptions()
  }, [items])

  return assumptions
}
