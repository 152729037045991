import { h, ComponentChildren } from "preact"

type Props = {
  children: ComponentChildren
  style?: any
  twoCol?: boolean
  oneColSM?: boolean
  isMaterial?: boolean
  compact?: boolean
  className?: string
}

const Row = ({
  children,
  style = {},
  twoCol,
  oneColSM,
  compact,
  isMaterial = true,
  className = ""
}: Props) => (
  <div
    className={`form__row ${isMaterial ? "is-material-form" : ""} ${
      twoCol ? "two-col-row" : ""
    }  ${oneColSM ? "one-col-sm" : ""} ${className} ${
      compact ? "is-compact" : ""
    }`}
    style={style}
  >
    {children}
  </div>
)

export default Row
