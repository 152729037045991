import { h } from "preact"
import { useRatesFormContext } from "@contexts"
import { RatesFormContextProps } from "@types"
import { Button } from "@ui"

interface Props {
  label?: string
  alwaysEnabled?: boolean
  onClick?: () => void
  md?: boolean
}

const SubmitBtn = ({
  label = "Update rates",
  alwaysEnabled = false,
  md = true,
  onClick
}: Props) => {
  const ratesFormContext = useRatesFormContext()

  const { formIsDirty } = ratesFormContext as RatesFormContextProps

  return (
    <Button
      noShadow
      size={md ? "md" : "lg"}
      className="text-capitalize loan-details-form__submit-btn"
      onClick={onClick}
      label={label}
      disabled={!alwaysEnabled && !formIsDirty}
    />
  )
}

export default SubmitBtn
