import { h } from "preact"
import { useEffect, useMemo } from "preact/hooks"
import ReviewsList from "../ReviewsList"
import { useAppContext } from "@contexts"

interface FooterReviewsProps {
  className?: string
  isDarkMode?: boolean
  center?: boolean
}

const FooterReviews = ({
  className = "",
  isDarkMode,
  center
}: FooterReviewsProps) => {
  const {
    reviews: { getReviews, brand },
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  useEffect(() => {
    getReviews("brand")
  }, [])

  const hasReviewClassName = useMemo(() => {
    return brand.noReviews && !brand.isLoading ? "d-none" : ""
  }, [brand.noReviews, brand.isLoading])

  return (
    <div
      className={`footer-reviews tw-relative tw-px-6 tw-py-4 ${
        isDarkMode ? "is-dark" : ""
      } ${hasReviewClassName} ${className}`}
    >
      <ReviewsList
        limit={isMobile ? 2 : 0}
        center={center}
        type="brand"
        isDarkMode={isDarkMode}
      />
    </div>
  )
}

export default FooterReviews
