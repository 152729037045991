import { ComponentChildren, Fragment, h } from "preact"
import { If } from "@ui"
import Dots from "./Dots"
import Arrows from "./Arrows"
import useSimpleSlides from "./useSimpleSlides"
import "@styles/components/_simple-slides.scss"

interface Props {
  children: ComponentChildren
  className?: string
  hasArrows?: boolean
  arrowsPosition?: "default" | "middle"
  hasDots?: boolean
  autoplay?: boolean
  addScrollPadding?: boolean
  previewNextSlide?: boolean
  autoplayInfinite?: boolean
  autoplayInterval?: number
  autoplayDirection?: "left" | "right"
  gap?: number
}

const SimpleSlides = ({
  children,
  className = "",
  hasArrows = false,
  arrowsPosition = "default",
  hasDots = false,
  addScrollPadding = false,
  previewNextSlide = false,
  autoplay,
  autoplayInfinite,
  autoplayInterval,
  autoplayDirection,
  gap = 24
}: Props) => {
  const {
    containerRef,
    atStart,
    atEnd,
    currentIndex,
    numScrollPositions,
    scrollToNext,
    scrollToPrev,
    scrollToIndex,
    containerStyles
  } = useSimpleSlides(
    autoplay,
    autoplayInfinite,
    autoplayInterval,
    autoplayDirection,
    addScrollPadding,
    previewNextSlide,
    gap,
    children
  )

  return (
    <div
      className={`simple-slides ${className} ${
        hasArrows && arrowsPosition === "middle" ? "tw-relative" : ""
      }`}
    >
      <div
        className="simple-slides__inner"
        ref={containerRef}
        role="list"
        style={{ gap: `${gap}px`, ...containerStyles }}
      >
        {children}
      </div>

      <If
        condition={numScrollPositions > 1}
        then={
          <Fragment>
            <div className="simple-slides__buttons">
              <If
                condition={hasDots}
                then={
                  <Dots
                    numScrollPositions={numScrollPositions}
                    currentIndex={currentIndex}
                    scrollToIndex={scrollToIndex}
                  />
                }
              />
              <If
                condition={hasArrows && arrowsPosition === "default"}
                then={
                  <Arrows
                    scrollToPrev={scrollToPrev}
                    scrollToNext={scrollToNext}
                    atStart={atStart}
                    atEnd={atEnd}
                  />
                }
              />
            </div>

            <If
              condition={hasArrows && arrowsPosition === "middle"}
              then={
                <Arrows
                  scrollToPrev={scrollToPrev}
                  scrollToNext={scrollToNext}
                  atStart={atStart}
                  atEnd={atEnd}
                  inMiddle
                />
              }
            />
          </Fragment>
        }
      />
    </div>
  )
}

export default SimpleSlides
