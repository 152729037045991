import clsx from "clsx"
import { ComponentChildren, h } from "preact"
import { ChangeEvent } from "preact/compat"

interface Props {
  required?: boolean
  children: ComponentChildren
  label?: string
  name: string
  id: string
  checked: boolean
  solid?: boolean
  onChange: (event: ChangeEvent) => void
  className?: string
}

const Checkbox = ({
  required = false,
  children,
  label,
  name,
  id,
  checked,
  onChange,
  solid = false,
  className = ""
}: Props) => {
  const componentClassName = clsx("form__field checkbox", className, {
    "is-checked": checked,
    "is-solid": solid
  })

  return (
    <div className={componentClassName}>
      <input
        required={required}
        type="checkbox"
        name={name}
        id={id}
        className="form__checkbox"
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={name}>{children ? children : label}</label>
    </div>
  )
}

export default Checkbox
