import { Fragment, h, JSX } from "preact"
import { leadAPI } from "@api"
import { OfficerSidebar } from "@composite"
import { Loader, If, PreactHCaptcha, Form, CaptchaInvalidMsg } from "@ui"
import FieldMapper from "./FieldMapper"
import FormSuccessMsg from "./FormSuccesMsg"
import SubmitErrorMsg from "./SubmitErrorMsg"
import FormInvalidMsg from "./FormInvalidMsg"
import { gtagFormSubmitted } from "./DynamicFormGtagFunctions"
import { useDynamicForm } from "./DynamicFormProvider"
import { useRef, useState } from "preact/hooks"
import { DynamicFormData, DynamicFormPayload, LO } from "@types"
import DynamicFormHeader from "./DynamicFormHeader"
import DynamicFormSubmitBtn from "./DynamicFormSubmitBtn"

interface DynamicFormProps {
  isInline?: boolean
  lo?: LO
  config: any
}

const DynamicForm = ({ isInline, lo, config }: DynamicFormProps) => {
  const { fields, isLoading, showLeftColumn, bgColor, captchaEnabled } =
    useDynamicForm()

  const [form, setForm] = useState<DynamicFormData>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    full_location: "",
    location: "",
    message: "",
    subscription: false
  })
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [isValidated, setIsValidated] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [captchaSuccess, setCaptchaSuccess] = useState(true)
  const [locationFieldFilled, setLocationFieldFilled] = useState(false)
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)
  const [disclaimerChecked, setDisclaimerChecked] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const formEle = useRef<HTMLFormElement>(null)

  const setLocationFieldStatus = (val: boolean) => {
    setLocationFieldFilled(val)
  }

  const handleSubmit = (e: Event) => {
    e.preventDefault()

    if (!locationFieldFilled || !formEle.current?.checkValidity()) {
      setIsValidated(true)
      setIsValid(false)
      return
    }

    // If captcha has been failed, set isValid to false
    if (captchaEnabled && !captchaToken) {
      setCaptchaSuccess(false)
      return
    }

    const formData: DynamicFormPayload = {
      owner: lo?.uid ?? "",
      app_key: config.app_key,
      source: config.source,
      captcha_token: captchaToken,
      first_name: form.first_name ?? "",
      last_name: form.last_name ?? "",
      name: `${form.first_name ?? ""} ${form.last_name ?? ""}`.trim(),
      email: form.email ?? "",
      phone: form.phone ?? "",
      details: { message: form.message ?? "" }
    }

    fields.forEach((item: any) => {
      const id = item.id
      if (
        id !== "email" &&
        id !== "first_name" &&
        id !== "last_name" &&
        id !== "phone"
      ) {
        const fieldId: string = id === "address" ? "location" : id
        const fieldIdState = fieldId === "location" ? "full_location" : fieldId
        formData.details[fieldId] = form[
          fieldIdState as keyof DynamicFormData
        ] as string
      }
    })

    setIsSendingForm(true)

    leadAPI
      .leadManage({
        formData,
        srcComponent: "dynamic_contact_form"
      })
      .then(data => {
        const newState = {
          isSent: data.status !== "error",
          submitError: data.status === "error"
        }
        gtagFormSubmitted()
        setIsSendingForm(false)
        setIsSent(newState.isSent)
        setSubmitError(newState.submitError)
      })
      .catch(error => {
        console.error(error)
        setIsSendingForm(false)
        setIsSent(false)
      })
  }

  const handleSubscribeChange = (name: string) => {
    setForm({ ...form, [name]: form[name as keyof DynamicFormData] })
  }

  const updateFormState = (val: any, name: string) => {
    setForm({ ...form, [name]: val })
  }

  const updateLocation = (val: any, full_location: string, name: string) => {
    setForm({ ...form, [name]: val, full_location })
  }

  const handleCaptchaVerificationSuccess = (token: string) => {
    setCaptchaToken(token)
  }

  const handleCaptchaExpired = () => {
    setCaptchaToken(null)
  }

  const style: JSX.CSSProperties = {}
  if (bgColor) {
    style.background = bgColor
  }

  const onDisclaimerChange = (e: Event) => {
    const target = e.target as HTMLInputElement
    setDisclaimerChecked(target.checked)
  }

  return (
    <div
      className={`${
        isInline ? "inline-contact-form" : "modal-contact-form w-100"
      } d-flex flex-column flex-md-row`}
    >
      <If
        condition={showLeftColumn}
        then={
          <OfficerSidebar
            hideLogo={isInline}
            title={isInline ? null : "Contact Us"}
            user={lo}
          />
        }
      />

      <div className="dForm" style={style}>
        <If
          condition={isLoading}
          then={<Loader className="box__loader" />}
          else={
            <Fragment>
              <DynamicFormHeader />

              <If condition={submitError} then={<SubmitErrorMsg />} />

              <form
                className={`dForm__form ${isValidated ? "is-validated" : ""}`}
                ref={formEle}
              >
                <FieldMapper
                  form={form}
                  updateFormState={updateFormState}
                  handleSubscribeChange={handleSubscribeChange}
                  updateLocation={updateLocation}
                  setLocationFieldStatus={setLocationFieldStatus}
                  locationFieldFilled={locationFieldFilled}
                />

                <Form.Disclaimer
                  required
                  checked={disclaimerChecked}
                  onChange={onDisclaimerChange}
                />

                <If condition={!isValid} then={<FormInvalidMsg />} />
                <If condition={!captchaSuccess} then={<CaptchaInvalidMsg />} />

                <DynamicFormSubmitBtn
                  onClick={handleSubmit}
                  isSendingForm={isSendingForm}
                />

                <If
                  condition={captchaEnabled}
                  then={
                    <div className="text-center pb-3">
                      <PreactHCaptcha
                        onVerify={handleCaptchaVerificationSuccess}
                        onExpire={handleCaptchaExpired}
                      />
                    </div>
                  }
                />
              </form>

              <If condition={isSent} then={<FormSuccessMsg />} />
            </Fragment>
          }
        />
      </div>
    </div>
  )
}

export default DynamicForm
