import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useDispatch, useSelector } from "react-redux"
import { updateFormValues } from "@actions"
import {
  JornayaId,
  ResendBtn,
  ErrorMsg,
  PadlockIcon,
  CodeInput,
  VerifyBtn
} from "./FormChunks"
import { AuthCodeType, RootStates } from "@types"
import { useEvaluateCondition, useUpdateFormUI } from "@hooks"
import { Button, Chevron } from "@ui"

const CODE_LENGTH = 4

interface Props {
  setIsResend: (isResend: boolean) => void
  validateAuthCode: (code: string) => void
  getAuthCode: (forcedType?: AuthCodeType) => void
  disclaimer?: string
  isVerifying?: boolean
  makeFocus?: boolean
  authCodeType: AuthCodeType
}

const CodeForm = ({
  setIsResend,
  makeFocus = false,
  isVerifying = false,
  validateAuthCode,
  getAuthCode,
  authCodeType,
  disclaimer = ""
}: Props) => {
  const dispatch = useDispatch()

  const [showResendTxt, setShowResendTxt] = useState(false)
  const [autoSubmit, setAutoSubmit] = useState(false)

  const { codeValue } = useSelector(({ wizard }: RootStates) => {
    const code = wizard?.values.code
    return {
      codeValue: code?.trim() ?? ""
    }
  })

  useEffect(() => {
    resetCode()
  }, [])

  const resetCode = () => {
    dispatch(
      updateFormValues({
        value: "",
        key: "code"
      })
    )
  }

  const onInput = (newValue: string, submit: boolean) => {
    dispatch(
      updateFormValues({
        value: newValue,
        key: "code"
      })
    )
    if (submit) {
      setAutoSubmit(true)
    }
  }

  const resendCode = (forcedType?: AuthCodeType) => {
    setIsResend(true)
    getAuthCode(forcedType)
    setShowResendTxt(true)
    setTimeout(() => {
      setShowResendTxt(false)
    }, 2500)
  }

  const sendCodeInput = () => {
    const codeLength = codeValue.length
    // If the user enter the CODE_LENGTH characters check the code
    if (codeLength === CODE_LENGTH) {
      validateAuthCode(codeValue)
    }

    setAutoSubmit(false)
  }

  useEffect(() => {
    if (autoSubmit) {
      sendCodeInput()
    }
  }, [autoSubmit])

  const evaluateCondition = useEvaluateCondition()
  const updateFormUI = useUpdateFormUI()

  const onClickBack = () => {
    const remove = evaluateCondition(true)
    updateFormUI(remove)
    setTimeout(resetCode, 500)
  }

  return (
    <div className="wizard__step-form-wrapper">
      <form className="wizard__step-form">
        <Button className="mb-3" adapt link onClick={onClickBack}>
          <Chevron side="left" /> Back
        </Button>
        <p className="wizard__step-form-title">
          Enter 4-digit verification code sent to your
          {authCodeType === "sms" ? " phone" : " email"}
        </p>

        <CodeInput
          className="wizard__step-form-code"
          makeFocus={makeFocus}
          codeLength={CODE_LENGTH}
          onInput={onInput}
          value={codeValue}
        />

        <ErrorMsg />

        <JornayaId disclaimer={disclaimer} />

        <VerifyBtn
          isLoading={isVerifying}
          onClick={sendCodeInput}
          disabled={codeValue.length < CODE_LENGTH}
        />
        <ResendBtn
          authCodeType={authCodeType}
          onClick={resendCode}
          showResendTxt={showResendTxt}
          onClickBack={onClickBack}
        />
        <PadlockIcon />
      </form>
    </div>
  )
}

export default CodeForm
