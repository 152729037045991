/**
 * Actions called by iframe using postMessage
 */
import Cookies from "js-cookie"
import {
  getUrlVars,
  getStorage,
  setStorage,
  clearStorage,
  checkVersion,
  setVersion
} from "@helpers"

const saveConfigFromChildLcl = (params, that) => {
  const config = that.state.config
  const app_key = params.app_key

  config[app_key] = config[app_key] || {}
  const currentConfig = config[app_key]

  for (const key in params.data) {
    currentConfig[key] = currentConfig[key] || {}
    config[app_key][key] = {
      ...currentConfig[key],
      ...params.data[key]
    }
  }
  that.setState({
    config
  })
  return config
}

const actions = {
  "bar-open": function (data) {
    let targetLocal = data.target

    // Check if there is variables on to the iframe URL
    const options = getUrlVars(targetLocal)
    // And attach the app_key
    const concatenator = Object.entries(options).length > 0 ? "&" : "?"
    targetLocal = `${targetLocal}${concatenator}app_key=${this.props.app_key}`

    this.setState({
      barVisible: true,
      barHREF: targetLocal
    })
  },
  "modal-open": function (data) {
    const modals = [...this.state.modals]
    let targetLocal = data.target
    // if an exposed app key is passed, use it
    const app_key = data.exposedAppKey ?? this.props.app_key

    // Check if there is variables on to the iframe URL
    const options = getUrlVars(targetLocal)
    // And attach the app_key
    const concatenator = Object.entries(options).length > 0 ? "&" : "?"

    targetLocal = `${targetLocal}${concatenator}app_key=${app_key}`

    // and add it to the modals array
    modals.push(targetLocal)

    this.setState({
      modalVisible: true,
      activeModal: modals.length - 1,
      modals
    })
  },
  "modal-close": function (e) {
    // Reset Modals
    this.setState({
      modals: [],
      activeModal: 0,
      modalVisible: false
    })
  },
  "modal-back": function (e) {
    const modals = [...this.state.modals]
    // remove the last modal added, which the active one
    modals.pop()
    const newState = { modals, activeModal: modals.length - 1 }

    this.setState(newState)
  },
  showBlockingModal: function () {
    this.setBlockingModalVisible()
  },
  /**
   * Save the config from an iframe to the BB code inside the client website
   */
  saveConfigFromChild(params) {
    saveConfigFromChildLcl(params, this)
    const { config } = params.data

    // BB ready event
    if (!this.state.apiReady) {
      this.setState(
        {
          apiReady: true
        },
        () => {
          // This will only run after the state has been updated
          if (config?.exposeAPI) {
            // Create BB ready event and attach the API
            window.BB?.addAPI?.(config)
            if (typeof CustomEvent === "function") {
              const customEvent = new CustomEvent("BB-ready")
              console.info("BB API is ready.")
              window.dispatchEvent(customEvent)
            }
          } else {
            console.info("BB API is disabled.")
          }
        }
      )
    }
  },
  /**
   *  This function is almost the samex as saveConfigFromChild
   *  but it is used just from the rate table embed
   */
  saveRTConfigFromChild(params, event) {
    const config = saveConfigFromChildLcl(params, this)
    const app_key = params.app_key
    const { rateTable } = params.data

    const updateState = {
      rt_subscribed: rateTable?.subscribed ?? false,
      hasRateTable: !!rateTable
    }

    if (rateTable?.rateTableConfig?.usage_limit > 0) {
      updateState.rt_usage_data = {
        ...rateTable.usage_data,
        limit: rateTable.rateTableConfig.usage_limit
      }
    }

    this.setState(updateState)

    if (window.frames.length > 1 && config[app_key].config) {
      for (let i = 0; i < window.frames.length; i++) {
        /**
         *  Avoid the iframe that is calling this function
         */
        if (event.source.location !== window.frames[i].location) {
          window.frames[i].postMessage(
            {
              action: "rateTableSync",
              ...config[app_key]
            },
            "*"
          )
        }
      }
    }
  },
  getConfigFromChild({ app_key }, event) {
    const config = {
      ...this.state.config[app_key]
    }

    if (!config.config) {
      console.log("No config found", app_key, config)
      return
    }

    config.config.modals = this.state.modals

    // The postMessage below works like a kind of callback
    // sending the config to the frame from where it was requested
    event.source.postMessage(
      {
        action: "sendConfigFromParent",
        ...config
      },
      event.origin
    )
  },
  getCookiesFromChild({ param }, event) {
    const cookie = Cookies.get(param)
    event.source.postMessage(
      {
        action: "sendCookiesFromParent",
        cookie
      },
      event.origin
    )
  },
  emitEvent: function (data) {
    const event = new CustomEvent("bbEvent", {
      detail: {
        category: data.eventInfo.category,
        action: data.eventInfo.name
      }
    })
    try {
      document.dispatchEvent(event)
    } catch (ex) {
      console.log("Cannot emit custom event")
    }
  },
  sendEvent: function (data) {
    let payload = {
      event_category: "BB " + data.eventInfo.category,
      event_label: data.eventInfo.label
    }
    if (data.eventInfo.send_to) payload.send_to = data.eventInfo.send_to
    if (typeof gtag != "undefined" && gtag) {
      if (data.eventInfo) {
        gtag("event", data.eventInfo.name, payload)
        return true
      }
    }
    if (window.dataLayer) {
      console.log(
        "BB Embed: gtag() is not available in the parent, pushing to dataLayer manually"
      )

      dataLayer.push({
        event: "BBEvent",
        event_category: "BB " + data.eventInfo.category,
        event_action: data.eventInfo.name,
        event_label: data.eventInfo.label
      })
      return true
    }

    console.log("BB Embed: google analytics is not configured in parent")
    return false
  },
  // Set local storage from the iframe
  setParentStorage({ data, app_key }) {
    setStorage(data, app_key)
  },
  // Clear local storage from the iframe
  clearParentStorage({ app_key }) {
    clearStorage(app_key)
  },
  // Check embed version
  checkParentVersion({ app_key }) {
    checkVersion(app_key)
  },
  setParentVersion({ version }) {
    setVersion(version)
  },
  // This function is used for returning promises from the iframe
  msgPromise: function ({ func, params, __seq }, event) {
    if (__seq) {
      const value = funcs[func](params)
      event.source.postMessage({ value, __seq }, "*")
    }
  }
}

const getParentURL = () => {
  const parent_url =
    window.location != window.parent.location
      ? document.referrer
      : document.location.href

  return parent_url
}

const getCookies = key => {
  const cookies = Cookies.get(key)
  return cookies
}

const funcs = { getParentURL, getStorage, getCookies }

export default actions
