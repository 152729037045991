import { h } from "preact"
import { LineChart } from "../../.."
import { useAppContext } from "@contexts"

interface Props {
  showChartLoading?: boolean
  data: any
  onEdit: (value: number | string, fieldName: string) => void
}

const CalcLineChart = ({ showChartLoading, data, onEdit }: Props) => {
  const {
    media: {
      flags: { isDesktop }
    }
  } = useAppContext()

  return (
    <div className="afford-calc__chart">
      <LineChart
        editableFields={["homeInsurance", "propertyTaxAmount"]}
        onEdit={onEdit}
        isLoading={showChartLoading}
        data={data}
        includePMI={true}
        includeHOA={false}
        hasMonthlyPayment
        isVertical
        oneColumnLabels={!isDesktop}
        isChartOnTop
        title="Monthly payment & Debts"
      />
    </div>
  )
}

export default CalcLineChart
