import { Fragment, h } from "preact"
import {
  GoogleOAuthProvider,
  TokenResponse,
  useGoogleLogin
} from "@react-oauth/google"
import axios from "axios"
import { GoogleLoginResponse } from "@types"
import { OAUTH_PROVIDERS } from "@constants"
import { Icon, If } from "@ui"

interface Props {
  iconButton?: boolean
  onSuccess: (res: GoogleLoginResponse) => void
  onError?: (
    error: Pick<TokenResponse, "error" | "error_description" | "error_uri">
  ) => void
  onLoginAttempt: () => void
  theme?: "light" | "default"
}

const GoogleLoginBtnInner = ({
  onLoginAttempt,
  onSuccess,
  onError,
  theme,
  iconButton = false
}: Props) => {
  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
        }
      )
      onSuccess(userInfo.data)
    },
    onError: error => {
      onError?.(error)
    }
  })

  const handleLogin = () => {
    onLoginAttempt()
    login()
  }

  return (
    <div
      className={`social-login-btn google-login-btn google-login-btn__${
        iconButton ? "icon" : "full"
      } is-${theme}`}
      onClick={handleLogin}
    >
      <If
        condition={iconButton}
        then={
          <Icon
            src={OAUTH_PROVIDERS.google.icon}
            size={OAUTH_PROVIDERS.google.iconSize}
            originalSize={OAUTH_PROVIDERS.google.iconOriginalSize}
          />
        }
        else={
          <Fragment>
            <div className="google-login-btn__icon">
              <Icon
                src={OAUTH_PROVIDERS.google.icon}
                size={OAUTH_PROVIDERS.google.iconSize}
                originalSize={OAUTH_PROVIDERS.google.iconOriginalSize}
              />
            </div>
            <div className="google-login-btn__label">Continue with Google</div>
          </Fragment>
        }
      />
    </div>
  )
}

const GoogleLoginBtn = (props: Props) => {
  const { iconButton } = props
  return (
    <GoogleOAuthProvider
      clientId={process.env.GOOGLE_LOGIN_API_CLIENT_ID as string}
    >
      <GoogleLoginBtnInner {...props} iconButton={iconButton} />
    </GoogleOAuthProvider>
  )
}

export default GoogleLoginBtn
