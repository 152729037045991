const CREDIT_SCORE_OPTIONS = [
  { value: 800, label: "Outstanding 800+" },
  { value: 780, label: "Excellent 780 - 799" },
  { value: 740, label: "Very good 740 - 779" },
  { value: 720, label: "Fairly good 720 - 739" },
  { value: 700, label: "Good 700 - 719" },
  { value: 680, label: "Decent 680 - 699" },
  { value: 660, label: "Average 660 - 679" }
]

const PROPERTY_TYPE_OPTIONS = [
  {
    label: "Single Family Home",
    value: "single_family_home"
  },
  {
    label: "Townhome",
    value: "townhome"
  },
  {
    label: "Condominium",
    value: "condominium"
  },
  {
    label: "Multi Unit Home",
    value: "multi_tenant_home"
  },
  {
    label: "Manufactured Home",
    value: "manufactured_home"
  }
]

const RESIDENCY_TYPE_OPTIONS = [
  {
    label: "Primary Home",
    value: "primary_home"
  },
  {
    label: "Second Home",
    value: "second_home"
  },
  {
    label: "Rental Home",
    value: "rental_home"
  }
] as const

const LOAN_TERM_OPTIONS = [
  {
    label: "30 year",
    value: 30
  },
  {
    label: "20 year",
    value: 20
  },
  {
    label: "15 year",
    value: 15
  }
]

const AMORTIZATION_OPTIONS = [
  {
    label: "all adjustable",
    value: "all"
  },
  {
    label: "10/1",
    value: "10_1"
  },
  {
    label: "7/1",
    value: "7_1"
  },
  {
    label: "5/1",
    value: "5_1"
  }
]

const LOAN_TYPE_OPTIONS_BASE = [
  {
    label: "Conventional",
    value: "conventional"
  }
] as const

const LOAN_TYPE_OPTIONS_WITHOUT_ARM = [
  ...LOAN_TYPE_OPTIONS_BASE,
  {
    label: "VA",
    value: "va"
  },
  {
    label: "FHA",
    value: "fha"
  },
  {
    label: "USDA",
    value: "usda"
  }
] as const

const LOAN_TYPE_OPTIONS_BASE_WITH_ARM = [
  ...LOAN_TYPE_OPTIONS_BASE,
  {
    label: "ARM",
    value: "arm"
  }
] as const

const LOAN_TYPE_OPTIONS_WITH_ARM = [
  ...LOAN_TYPE_OPTIONS_WITHOUT_ARM,
  { label: "ARM", value: "arm" }
] as const

const POINTS_OPTIONS = [
  {
    label: "+",
    value: "+"
  },
  {
    label: "1",
    value: "1"
  },
  {
    label: "-",
    value: "-"
  }
]

const LOCK_PERIOD_OPTIONS = [
  {
    label: "30",
    value: 30
  },
  {
    label: "45",
    value: 45
  },
  {
    label: "60",
    value: 60
  }
]

const YES_NO_OPTIONS = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  }
]

const PURCHASE_VA_FUNDING_FEE_OPTIONS = [
  {
    label: "1st",
    value: "first_time"
  },
  {
    label: "2nd",
    value: "second_time"
  },
  {
    label: "Exempt",
    value: "exempt"
  }
]

const REFINANCE_VA_FUNDING_FEE_OPTIONS = [
  {
    label: "Exempt",
    value: "exempt"
  }
]

export {
  LOAN_TYPE_OPTIONS_BASE,
  LOAN_TYPE_OPTIONS_BASE_WITH_ARM,
  REFINANCE_VA_FUNDING_FEE_OPTIONS,
  PURCHASE_VA_FUNDING_FEE_OPTIONS,
  YES_NO_OPTIONS,
  CREDIT_SCORE_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  RESIDENCY_TYPE_OPTIONS,
  LOAN_TERM_OPTIONS,
  AMORTIZATION_OPTIONS,
  LOAN_TYPE_OPTIONS_WITHOUT_ARM,
  LOAN_TYPE_OPTIONS_WITH_ARM,
  POINTS_OPTIONS,
  LOCK_PERIOD_OPTIONS
}
