import { h } from "preact"
import { Form, Icon, CrossBtn } from "@ui"
import pinIcon from "@assets/icons/map-marker-alt-solid.svg"

const DynamicFormLocation = ({
  name,
  value,
  onChange,
  label,
  required,
  setValidation
}) => {
  const handlePlaceSelected = async place => {
    try {
      const addressObj = place.address_components

      const postal_code = addressObj.find(
        item => item.types[0] === "postal_code"
      )?.long_name

      setValidation(true)
      onChange(
        place.formatted_address,
        {
          state: addressObj.find(
            item => item.types[0] === "administrative_area_level_1"
          ).short_name,
          street: addressObj.find(item => item.types[0] === "route"),
          city: addressObj.find(item => item.types[0] === "locality"),
          zipcode: postal_code
        },
        name
      )
    } catch (error) {
      console.error("Error handling place selected:", error)
      setValidation(false)
    }
  }

  return (
    <Form.Row>
      <Form.Col>
        <div className={value ? "" : "tw-hidden"}>
          {label}
          <div className="tw-flex wizard__location-address">
            <Icon
              className="tw-mr-1"
              src={pinIcon}
              originalSize={[384, 512]}
              size={[16, 26]}
            />
            <span>{value?.replace(/, USA$/, "") ?? ""}</span>
            <CrossBtn
              className="tw-ml-2 tw-mt-1"
              onClick={() => {
                onChange("", {}, name)
                setValidation(false)
              }}
            />
          </div>
        </div>

        <Form.PlacesAutocompleteInput
          initialVal={value}
          name={name}
          className={value ? "tw-hidden" : ""}
          label={label}
          isMaterial
          onPlaceSelected={handlePlaceSelected}
          required={required}
        />
      </Form.Col>
    </Form.Row>
  )
}

export default DynamicFormLocation
