import { h, JSX } from "preact"
import { PricingItem as PricingItemInterface, PricingItemType } from "@types"
import Item from "./Item"
import "@styles/components/_pricing-item.scss"

type Props = {
  role?: JSX.HTMLAttributes["role"]
  item: PricingItemInterface
  index?: number
  onClick?: () => void
  hideFeesModal?: boolean
  isNotMobile?: boolean
  type: PricingItemType
  className?: string
}

const PricingItem = (props: Props) => {
  // set default type to "default" if it is not provided
  if (!props.type) {
    props.type = "default"
  }

  return <Item {...props} />
}

export default PricingItem
