import { h } from "preact"
import VAOptions from "./VAOptions"
import { useSelector } from "react-redux"
import {
  LoanDetailsFormFields,
  FundingFeeOption,
  LoanPurpose,
  RootStates
} from "@types"
import If from "../../../If"
import { SelectCustom } from "../../inputs"
import {
  LOAN_TYPE_OPTIONS_BASE,
  LOAN_TYPE_OPTIONS_BASE_WITH_ARM,
  LOAN_TYPE_OPTIONS_WITH_ARM,
  LOAN_TYPE_OPTIONS_WITHOUT_ARM
} from "@constants"

type LoanTypeOption =
  | typeof LOAN_TYPE_OPTIONS_WITH_ARM[number]
  | typeof LOAN_TYPE_OPTIONS_WITHOUT_ARM[number]

interface Props {
  label?: string
  name?: keyof LoanDetailsFormFields
  value?: LoanTypeOption["value"]
  onChange: (value: string, name: keyof LoanDetailsFormFields) => void
  optionsClassName?: string
  hasARMOption?: boolean
  va_funding_fee_type?: FundingFeeOption
  loan_purpose?: LoanPurpose
}

const LoanTypeDropDown = ({
  label = "Loan Type",
  name = "loan_type",
  value,
  onChange,
  optionsClassName = "",
  hasARMOption,
  va_funding_fee_type,
  loan_purpose
}: Props) => {
  const exclude_govt_programs = useSelector(
    ({ rateTable, subscriptionDash }: RootStates) => {
      return (
        subscriptionDash?.config?.exclude_govt_programs ??
        rateTable?.rateTableConfig?.exclude_govt_programs ??
        false
      )
    }
  )

  const options = hasARMOption
    ? exclude_govt_programs
      ? LOAN_TYPE_OPTIONS_BASE_WITH_ARM
      : LOAN_TYPE_OPTIONS_WITH_ARM
    : exclude_govt_programs
    ? LOAN_TYPE_OPTIONS_BASE
    : LOAN_TYPE_OPTIONS_WITHOUT_ARM

  return (
    <div className="form__field-combo">
      <SelectCustom
        optionsClassName={optionsClassName}
        isMaterial
        name={name}
        options={options}
        value={value}
        onChange={(option: LoanTypeOption) => onChange(option.value, name)}
        label={label}
        showErrorWhenEmpty
      />

      <If
        condition={!exclude_govt_programs}
        then={
          <VAOptions
            loan_purpose={loan_purpose}
            show={value === "va"}
            onChange={onChange}
            value={va_funding_fee_type}
          />
        }
      />
    </div>
  )
}

export default LoanTypeDropDown
