import { useContext } from "preact/hooks"
import AppContext from "./context"

const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider")
  }
  return context
}

export default useAppContext

export const useIsMobile = () => useAppContext().media.flags.isMobile
export const useIsTablet = () => useAppContext().media.flags.isTablet
export const useIsDesktop = () => useAppContext().media.flags.isDesktop
export const useMediaFlags = () => useAppContext().media.flags
export const useCurrentMedia = () => useAppContext().media.currentMedia
