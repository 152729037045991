import { h } from "preact"
import { toFixed } from "@helpers"

interface Props {
  apr: number
  rate: number
}

const PricingItemHeaderCompact = ({ apr, rate }: Props) => (
  <div className="pricing-item__header tw-relative tw-flex tw-justify-between">
    <div className="pricing-item__details">
      <strong>
        {toFixed(rate, 3)}% / {toFixed(apr)}%
      </strong>
      <small className="pricing-item__label">Rate / APR</small>
    </div>
  </div>
)

export default PricingItemHeaderCompact
