import { eventDispatch } from "@helpers"

const applyNowClick = () => {
  eventDispatch({
    name: "apply_now_clicked",
    category: "lead_workflow"
  })
}

export { applyNowClick }
