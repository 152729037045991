import { h } from "preact"
import { connect } from "react-redux"
import { Form } from "@ui"
import StepInner from "./StepInner"

const StepButtonsWelcome = ({
  isList,
  onInput,
  stepKey,
  options,
  values,
  title
}) => (
  <StepInner
    stepKey={stepKey}
    title={title}
    cols={isList || stepKey === "military_eligible" ? 2 : 1}
  >
    <Form.Buttons
      isList={isList || stepKey === "military_eligible"}
      isListIcon={stepKey === "military_eligible"}
      onInput={onInput}
      stepKey={stepKey}
      name={stepKey}
      options={options}
      value={values.steps[stepKey]}
    />
  </StepInner>
)

const mapStateToProps = ({ wizard }) => {
  return {
    values: wizard.values
  }
}

const StepButtons = connect(mapStateToProps)(StepButtonsWelcome)

export default StepButtons
