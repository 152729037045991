import { h } from "preact"
import { If } from "@ui"
import { Button } from "@ui"
import { useAppLink } from "@hooks"

interface Props {
  className?: string
  type: string
}
const ApplyBtn = ({ className = "", type }: Props) => {
  const parsedAppLink = useAppLink()

  return (
    <If
      condition={parsedAppLink?.length > 0 && type === "wizard"}
      then={
        <Button
          className={className}
          adapt
          size="sm"
          noShadow
          href={parsedAppLink}
          target="_blank"
          label="Apply now"
        />
      }
    />
  )
}

export default ApplyBtn
