import { h } from "preact"
import CountUp from "react-countup"
import { Icon } from "@ui"
import USA_FLAG from "@assets/icons/usa-flag.svg"

const NationalAverage = ({
  average,
  term
}: {
  average: number
  term: string
}) => (
  <div className="tw-flex tw-items-center tw-justify-center txt-dark tw-gap-1 tw-pb-6">
    <Icon src={USA_FLAG} size={[19, 13]} originalSize={[19, 13]} />
    National average ({term}):
    <strong>
      <CountUp duration={1} end={average} decimals={2} />%
    </strong>
  </div>
)

export default NationalAverage
