import { h } from "preact"
import { useMemo, useState } from "preact/hooks"
import { flattenReviewData } from "@helpers"
import ReviewTypeSwitch from "../../ReviewTypeSwitch"
import { useReviews } from "../../context"
import { Button, If, Review } from "@ui"
import { useMediaFlags } from "@contexts"

const InnerGridReviews = () => {
  const [expanded, setExpanded] = useState(false)

  const { brand, lo, reviewType } = useReviews()

  const reviewsData = reviewType === "brand" ? brand : lo

  const reviews = useMemo(
    () => flattenReviewData(reviewsData),
    [reviewsData.reviews]
  )

  const { isDesktop, isMobile, isTablet } = useMediaFlags()

  const showButton =
    (isDesktop && reviews.length > 6) ||
    (isTablet && reviews.length > 4) ||
    (isMobile && reviews.length > 2)

  return (
    <div>
      <div className="tw-text-center tw-pt-2">
        <ReviewTypeSwitch />
      </div>

      <div
        className={`grid-reviews__wrapper tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 gap-base tw-pt-10 ${
          expanded
            ? "tw-max-h-full"
            : `is-${isDesktop ? "lg" : isTablet ? "md" : "sm"}`
        } tw-overflow-hidden`}
      >
        {reviews.map((review, index) => (
          <Review
            row={review}
            index={index}
            key={review.author_name + review.time}
            excerpt={75}
          />
        ))}
      </div>
      <If
        condition={showButton}
        then={
          <div
            className={`tw-text-center grid-reviews__btn-wrapper is-${
              expanded ? "expanded" : "collapsed"
            }`}
          >
            <Button
              noShadow
              isLite
              adapt
              className="tw-mx-auto tw-mt-8 tw-w-44"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Less" : "Load More"}
            </Button>
          </div>
        }
      />
    </div>
  )
}

export default InnerGridReviews
