import { h } from "preact"
import { FundingFeeOption, LoanPurpose, LoanDetailsFormFields } from "@types"
import If from "../../../If"
import VATooltip from "../../../VATooltip"
import { RadioGroup } from "../../inputs"
import {
  PURCHASE_VA_FUNDING_FEE_OPTIONS,
  REFINANCE_VA_FUNDING_FEE_OPTIONS
} from "@constants"

interface Props {
  show: boolean
  value?: FundingFeeOption | ""
  loan_purpose?: LoanPurpose
  onChange: (value: string, name: keyof LoanDetailsFormFields) => void
}

const VAOptions = ({
  show,
  loan_purpose,
  onChange,
  value = "first_time"
}: Props) => {
  const options =
    loan_purpose === "refinance"
      ? REFINANCE_VA_FUNDING_FEE_OPTIONS
      : PURCHASE_VA_FUNDING_FEE_OPTIONS

  return (
    <If
      condition={show}
      then={
        <div className="pb-md-2 pb-lg-0 mb-3 mb-lg-0 mt-n3 mt-lg-2 tw-items-center tw-flex">
          <RadioGroup
            type={loan_purpose === "refinance" ? "checkbox" : "radio"}
            value={value === "" || !value ? "first_time" : value}
            options={options}
            name="va_funding_fee_type"
            onChange={(value: string, name: string) =>
              onChange(value, name as keyof LoanDetailsFormFields)
            }
          />

          <VATooltip loan_purpose={loan_purpose} />
        </div>
      }
    />
  )
}

export default VAOptions
