import { h } from "preact"
import { Icon } from "@ui"
import { Tooltip } from "@composite"
import InfoOutlineIcon from "@assets/icons/info-outline.svg"
import "@styles/components/_lender-fees-tooltip.scss"

const LenderFeesTooltip = () => (
  <Tooltip
    ctaClassName="lender-fees-tooltip"
    ttPlacement="right"
    eventTrigger="hover"
    reference={
      <Icon
        className="lender-fees-tooltip__icon"
        src={InfoOutlineIcon}
        size={16}
        originalSize={20}
      />
    }
  >
    Includes estimated lenders fees + discount points or credits.
  </Tooltip>
)

export default LenderFeesTooltip
