import { h, Component } from "preact"
import { connect } from "react-redux"
import { updateFormValues } from "@actions"
import { Form } from "@ui"
import { abbreviateN, checkIfNumeric } from "@helpers"
import StepInner from "./StepInner"

class ConnectedStepSlider extends Component {
  constructor(props) {
    super(props)

    const val = props.values.steps[props.stepKey]
    const initial = val
      ? val
      : checkIfNumeric(props.initial)
      ? props.initial
      : (props.min + props.max) / 2

    this.state = {
      value: initial,
      dirty: false
    }
  }

  handleSliderChange = value => {
    this.setState({
      value: value,
      dirty: true
    })
  }

  handleSliderEnd = value => {
    this.props.updateFormValues(value, this.props.stepKey)
  }

  handleInputChange = value => {
    this.setState({
      value: value,
      dirty: true
    })
    this.props.updateFormValues(value, this.props.stepKey)
  }

  onClick = () => {
    const { updateFormValues, min, max, stepKey, onClickContinue } = this.props

    if (this.state.value !== 0 && !this.state.value) {
      updateFormValues((min + max) / 2, stepKey)
    } else if (!this.state.dirty) {
      updateFormValues(this.state.value, stepKey)
    }
    onClickContinue()
  }

  componentWillReceiveProps(nextProps) {
    // when the wizard start over we need to reset the component value
    if (
      typeof nextProps.values.steps[nextProps.stepKey] === "undefined" &&
      nextProps.values.steps[nextProps.stepKey] !==
        this.props.values.steps[nextProps.stepKey]
    ) {
      this.setState({
        value: (this.props.min + this.props.max) / 2,
        dirty: false
      })
    }
  }

  getLtV = () => {
    const { values } = this.props
    const { value } = this.state
    let {
      down_payment = 0,
      cash_out = 0,
      list_price = 0,
      loan_purpose,
      loan_balance = 0
    } = values?.steps

    if (this.props.stepKey === "cash_out") {
      cash_out = value
    }

    let loan_amount = 0
    if (loan_purpose === "purchase") {
      loan_amount = list_price - down_payment
      cash_out = 0
    } else {
      loan_amount = this.props.stepKey === "loan_balance" ? value : loan_balance
    }

    const ltv = Math.round(((loan_amount + cash_out) / list_price) * 100)
    return ltv
  }

  getDownPercentage = () => {
    const { values } = this.props
    const { value } = this.state
    const { list_price = 0 } = values?.steps

    const downPercentage = Math.round((value / list_price) * 100)
    return downPercentage
  }

  render(
    {
      title,
      stepKey,
      className = "",
      step = 1,
      min = 0,
      max,
      disabled = false,
      buffer = 25000,
      prefix = ""
    },
    { value }
  ) {
    return (
      <StepInner
        stepKey={stepKey}
        title={title}
        btnDisabled={!checkIfNumeric(value)}
        onClickContinue={this.onClick}
        className={className}
      >
        <div className="wizard__slider-value-wrapper">
          <div className="wizard__slider-value accent-color">
            <Form.InputMasked
              width="100%"
              className="wizard__slider-value-input"
              prefix={prefix}
              allowDecimal={false}
              required
              name="min-range"
              onChange={this.handleInputChange}
              min={min + buffer}
              max={max - buffer}
              value={value}
              integerLimit={this.props.stepKey === "credit_score" ? 3 : 8}
            />
          </div>
        </div>

        {this.props.stepKey === "down_payment" ? (
          <strong className="wizard__slider-highlight">
            Down Payment: {this.getDownPercentage()}%
          </strong>
        ) : null}

        {this.props.stepKey === "loan_balance" ||
        this.props.stepKey === "cash_out" ? (
          <strong className="wizard__slider-highlight">
            Loan to Value: {this.getLtV()}%
          </strong>
        ) : null}

        <Form.InputRange
          className="wizard__slider-range"
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={this.handleSliderChange}
          onChangeComplete={this.handleSliderEnd}
        />
        <div className="wizard__slider-labels accent-color d-flex justify-content-between align-items-center">
          <span>
            {prefix}
            {abbreviateN(min)}
          </span>
          <span>
            {prefix}
            {abbreviateN(max)}+
          </span>
        </div>
      </StepInner>
    )
  }
}

const mapStateToProps = ({ wizard }) => {
  return {
    values: wizard.values
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateFormValues: (value, key) => dispatch(updateFormValues({ value, key }))
  }
}

const StepSlider = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedStepSlider)

export default StepSlider
