import { h } from "preact"
import FooterBtns from "./FooterBtns"

const StepInner = ({
  children,
  onClickContinue,
  onClickBack,
  btnDisabled,
  stepKey,
  title,
  cols = 1,
  className = ""
}) => (
  <div
    className={`wizard__${stepKey} wizard__step-inner wizard__animated-items wizard__animated ${className} ${
      title.icon ? "has-icon" : ""
    }`}
  >
    <div className={cols === 2 ? "wizard__step-cols d-md-flex" : ""}>
      {title && (
        <h2
          className={`wizard__step-title wizard__animated wizard__animated-titles ${
            title.icon ? "d-flex flex-md-column align-items-start" : ""
          }`}
        >
          {title.icon && (
            <img className="wizard__step-title-icon" src={title.icon} alt="" />
          )}
          {title.txt}
        </h2>
      )}
      {children}
    </div>
    <FooterBtns
      disabledNext={btnDisabled}
      onClickContinue={onClickContinue}
      onClickBack={onClickBack}
    />
  </div>
)

export default StepInner
