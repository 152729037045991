import { ComponentChildren, h, Ref } from "preact"
import { useEffect, useState } from "preact/hooks"
import If from "../../../If"

// Props interface
interface Props {
  inputRef?: Ref<HTMLInputElement>
  isSpecial?: boolean
  onFocus?: (event: FocusEvent) => void
  onInput?: (event: InputEvent) => void
  onBlur?: (event: FocusEvent) => void
  onKeypress?: (event: Event) => void
  submitOnEnter?: () => void
  name?: string
  placeholder?: string
  value?: string | number
  validate?: "text" | "number"
  children?: ComponentChildren
  label?: ComponentChildren
  compact?: boolean
  showErrorWhenEmpty?: boolean
  suffix?: string
  affix?: string
  hiddenLabel?: boolean
  showRequired?: boolean
  className?: string
  classNameInput?: string
  isMaterial?: boolean
  ariaDescribedby?: string
  type?: string
  size?: "sm" | "md" | "lg"
  [key: string]: any
}

const Input = ({
  inputRef,
  isSpecial,
  onFocus,
  onInput,
  onBlur,
  onKeypress,
  submitOnEnter,
  name = "",
  placeholder = "",
  value = "",
  validate = "text",
  children,
  label = "",
  compact,
  suffix = "",
  affix = "",
  hiddenLabel,
  showRequired,
  className = "",
  classNameInput = "",
  isMaterial,
  type = "text",
  size = "md",
  ariaDescribedby,
  showErrorWhenEmpty = false,
  ...rest
}: Props) => {
  const checkVal = (val: string | number) => {
    return typeof val === "number" || val?.length > 0
  }

  const [materialClassName, setMaterialClassName] = useState([
    checkVal(value) ? "is-typed" : ""
  ])

  const [inputClass, setInputClass] = useState("")
  const [hasFocus, setHasFocus] = useState(false)

  const handleChange = (event: InputEvent) => {
    let newValue: string | number = (event.target as HTMLInputElement)?.value

    if (newValue && validate === "number") {
      if (typeof newValue === "string") {
        newValue = parseFloat(newValue.split(",").join(""))
      }

      if (!Input.Validation.number(newValue)) {
        let oldVal: string | number =
          typeof value === "string"
            ? parseFloat(value.split(",").join(""))
            : value
        oldVal = typeof oldVal === "number" && isNaN(oldVal) ? "" : oldVal

        onInput?.(event)
        return
      }
    }

    newValue = typeof newValue === "number" && isNaN(newValue) ? "" : newValue

    toggleMaterialClassName("is-typed", checkVal(newValue))

    onInput?.(event)
  }

  const handleFocus = (event: FocusEvent) => {
    toggleMaterialClassName("has-focus", true)
    setHasFocus(true)
    onFocus?.(event)
  }

  const handleBlur = (event: FocusEvent) => {
    toggleMaterialClassName("has-focus")
    setHasFocus(false)
    onBlur?.(event)
  }

  const toggleMaterialClassName = (className: string, add?: boolean) => {
    const newClassName = [...materialClassName]
    const index = newClassName.indexOf(className)
    if (add && index === -1) {
      newClassName.push(className)
    } else if (!add && index > -1) {
      newClassName.splice(index, 1)
    }
    setMaterialClassName([...newClassName])
  }

  const handleKeypress = (e: KeyboardEvent) => {
    if (e.code === "Enter" && submitOnEnter) {
      submitOnEnter()
      ;(e.target as HTMLInputElement)?.blur()
    }
    onKeypress?.(e)
  }

  useEffect(() => {
    setInputClass(
      `form__input ${classNameInput} ${affix ? "form__input--affix" : ""} ${
        suffix ? "form__input--suffix" : ""
      } ${showErrorWhenEmpty && !value ? "is-invalid" : ""}`
    )
  }, [classNameInput])

  // Required when the value is set by the parent component
  useEffect(() => {
    !hasFocus && toggleMaterialClassName("is-typed", checkVal(value))
  }, [value])

  const { onInput: onInputTemp, onKeypress: onKeypressTemp, ...tempRest } = rest

  return (
    <div
      className={`form__field is-${size} ${isMaterial ? "is-material" : ""} ${
        isSpecial ? "is-special" : ""
      } ${compact ? "is-compact" : ""} ${className} ${materialClassName.join(
        " "
      )}`}
    >
      <If
        condition={!!label}
        then={
          <label
            className={`form__label ${hiddenLabel ? "tw-hidden" : ""}`}
            htmlFor={name}
          >
            <span className="form__label-txt">{label}</span>
            {showRequired && <span className="text-danger"> *</span>}
          </label>
        }
      />
      <div className="tw-relative">
        {affix && <span className="form__input-affix">{affix}</span>}
        <input
          {...(inputRef ? { ref: inputRef } : {})}
          {...(ariaDescribedby && { "aria-describedby": ariaDescribedby })}
          type={type}
          name={name}
          id={name}
          className={inputClass}
          placeholder={!isMaterial && placeholder ? placeholder : ""}
          value={value}
          onInput={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyPress={handleKeypress}
          {...tempRest}
        />
        {suffix && <span className="form__input-suffix">{suffix}</span>}
      </div>
      {children}
    </div>
  )
}

Input.Validation = {
  number: (value: string | number) => {
    return /^\d+$/.test(value + "")
  }
}

export default Input
