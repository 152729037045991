import { h } from "preact"
import Form from "../Form"
import Icon from "../Icon"
import { Tooltip } from "@composite"
import RateArrowIcon from "@assets/icons/rate-arrow.svg"
import "@styles/components/_subscribe.scss"

interface Props {
  className?: string
  title?: string
  subscribed: boolean
  updateSubscription: (payload: any) => void
  sm?: boolean
}

const Subscribe = ({
  className = "",
  title = "Subscribe",
  subscribed,
  updateSubscription,
  sm
}: Props) => (
  <div
    className={`subscribe d-inline-flex align-items-center ${className} ${
      sm ? "is-sm" : ""
    }`}
  >
    <Icon className="subscribe__icon accent-color" src={RateArrowIcon} />
    <span className="subscribe__label">
      <span className="d-inline-block">
        <Tooltip
          ttPlacement="top"
          icon="$"
          iconWeight="400"
          eventTrigger="hover"
          small
          // @ts-ignore
          reference={title}
        >
          Get real-time rate updates delivered directly to you inbox
        </Tooltip>
      </span>
    </span>
    <Form.Switch
      sm={sm}
      checked={subscribed}
      onChange={e => {
        const checked = (e.target as HTMLInputElement).checked
        setTimeout(() => {
          updateSubscription(checked)
        }, 100) // give some time to run the switch css animation
      }}
    />
  </div>
)

export default Subscribe
