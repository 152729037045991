import { useContext } from "preact/hooks"
import TabContext from "./context"

const useLoTabsContext = () => {
  const context = useContext(TabContext)
  if (!context) {
    throw new Error("useLoTabsContext must be used within a TabContextProvider")
  }
  return context
}

export default useLoTabsContext
