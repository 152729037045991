import { h } from "preact"
import arrowDown from "@assets/icons/arrow-down.svg"
import Icon from "../Icon"
import "@styles/components/_arrow-icon.scss"

const ArrowIcon = ({
  side = "bottom",
  size = [13, 23],
  originalSize = [14, 17],
  className = ""
}) => (
  <Icon
    className={`arrow-icon ${side} ${className}`}
    src={arrowDown}
    size={size}
    originalSize={originalSize}
  />
)

export default ArrowIcon
