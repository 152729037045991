import { Fragment, h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import { numberWithCommas, numberWithoutCommas, debounce } from "@helpers"
import If from "../../../If"

const ERROR_DEFAULT = { evalStatus: () => false, message: "" }

interface Props {
  forceMinMax?: boolean
  handleInputChange: (value: any, e: any, notForceValue: any) => void
  forceTwoDecimal?: boolean
  keyDownTimming?: number
  max?: number
  min?: number
  onClick?: (e: any) => void
  doOnFocus?: (e: any) => void
  doOnBlur?: (e: any) => void
  value: number
  name: string
  placeholder?: string
  readonly?: boolean
  className?: string
  hasPattern?: boolean
  pattern?: string
  makeFocus?: boolean
  submitOnEnter?: () => void
  error?: any
  styles?: any
  ariaDescribedby?: any
  allowEmptyWhileTyping?: boolean
}

const NumericInput = ({
  forceMinMax,
  handleInputChange,
  forceTwoDecimal = true,
  keyDownTimming = 1000,
  max,
  min,
  onClick,
  doOnFocus,
  doOnBlur,
  value,
  name,
  placeholder,
  readonly,
  className = "",
  hasPattern,
  pattern,
  makeFocus,
  submitOnEnter,
  error = ERROR_DEFAULT,
  styles = {},
  ariaDescribedby,
  allowEmptyWhileTyping = false,
  ...rest
}: Props) => {
  const [val, setVal] = useState<number | string>()
  const [actualFocus, setFocus] = useState(null)

  const inputEle = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (actualFocus === null && makeFocus) {
      inputEle?.current?.focus()
    }
  }, [actualFocus, makeFocus])

  useEffect(() => {
    // Don't update if we are editing the field
    if (actualFocus && name && actualFocus === name) {
      return
    }

    let valueTemp
    if (value) {
      valueTemp = Number(value)
      if (!Number.isInteger(valueTemp) && forceTwoDecimal) {
        valueTemp = valueTemp.toFixed(2)
      }
      valueTemp = numberWithCommas(valueTemp)
    } else {
      valueTemp = 0
    }

    setVal(valueTemp)
  }, [value])

  // Cancel non-numeric characters
  const handleKeyDown = (e: any) => {
    const charCode = e.which
    if (charCode === 13) {
      inputEle?.current?.blur()
      submitOnEnter?.()
      return
    }

    // Allow numbers, backspace, delete, arrows and dot keys
    if (
      (charCode >= 48 && charCode <= 57) ||
      [8, 9, 46, 37, 38, 39, 40, 190, 110].indexOf(charCode) >= 0
    ) {
      // Check if the number already has a dot
      const val = e.target.value + ""
      // Count the number of occurrences of a character in a string
      const count = (str: string, char: string) => str.split(char).length - 1
      const hasDot = count(val, ".")
      // Allow only one dot
      if (hasDot > 0 && charCode === 190) {
        e.preventDefault()
      }
      return
    } else {
      e.preventDefault()
    }
  }

  /**
   * if forceMinMax is true we force the min and max values
   */
  const checkValue = (v: number) => {
    let newValue = v | 0
    if (max !== undefined && newValue > max) {
      newValue = max
    } else if (min !== undefined && newValue < min) {
      newValue = min
    }
    return newValue
  }

  const handleChange = debounce((event: any) => {
    let newValue = event.target.value
    const notForceValue = event.target.value

    newValue =
      typeof newValue === "number" && isNaN(newValue)
        ? 0
        : isNaN(Number(newValue))
        ? numberWithoutCommas(newValue)
        : Number(newValue)

    if (allowEmptyWhileTyping) {
      newValue = newValue === "" || newValue === 0 ? "" : newValue
    } else if (forceMinMax) {
      newValue = checkValue(newValue)
    }
    handleInputChange?.(newValue, event, notForceValue)
    newValue = actualFocus ? newValue : numberWithCommas(newValue)

    setVal(newValue)
  }, keyDownTimming)

  const handleBlur = (event: any) => {
    let newValue = event.target.value

    if (forceMinMax) {
      newValue = checkValue(newValue) + ""
    }
    newValue = newValue.replace(/^0+/, "")
    newValue = newValue == "" ? 0 : newValue
    newValue = numberWithCommas(newValue)
    setVal(newValue)
    setFocus(null)
    doOnBlur?.(event)
  }

  const handleFocus = (event: any) => {
    let newValue = event.target.value
    newValue = numberWithoutCommas(newValue)
    setVal(newValue)
    setFocus(event.target.name)
    doOnFocus?.(event)
  }

  const handleClick = (event: any) => {
    onClick?.(event)
  }

  return (
    <If
      condition={!!readonly}
      then={
        <span
          className={`numeric-input--ro ${className}`}
          onClick={handleClick}
        >
          {val}
        </span>
      }
      else={
        <Fragment>
          <input
            {...(ariaDescribedby && { "aria-describedby": ariaDescribedby })}
            ref={inputEle}
            className={`numeric-input ${className} ${
              error.evalStatus(val ?? 0) ? "is-invalid" : ""
            }`}
            pattern={hasPattern && pattern}
            type="text"
            name={name}
            placeholder={placeholder}
            value={val}
            onInput={handleChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            style={styles}
            {...rest}
          />
          <If
            condition={error.evalStatus(val ?? 0)}
            then={
              <span className="txt-danger d-inline-block mt-1">
                {error.message}
              </span>
            }
          />
        </Fragment>
      }
    />
  )
}

export default NumericInput
