import { LoanDetailsFormFields, LocationObj } from "@types"

export const RATE_TABLE_CARDS_MAX_RESULTS = 6

const list_price = 450000
const down_payment = 90000
const loan_amount = list_price - down_payment
const zipcode = ""
const location: LocationObj = {
  state: "",
  county: "",
  city: "",
  zipcode
}

export const DEFAULT_FORM_DATA: Partial<LoanDetailsFormFields> = {
  first_time_homebuyer: false,
  loan_type: "conventional",
  down_percentage: 20,
  loan_purpose: "purchase",
  credit_score: 780,
  residency_type: "primary_home",
  property_type: "single_family_home",
  zipcode,
  cash_out: 0,
  phone: "",
  va_eligible: false,
  list_price,
  down_payment,
  loan_amount,
  location
}
