import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { connect, useDispatch } from "react-redux"
import { createPortal } from "preact/compat"
import { MEDIA_QUERIES, UPDATE_EMBED_STORE_FROM_GLOBAL_MODAL } from "@constants"
import { useIframeCommunication } from "@iframeCommunication"
import { handleMediaChange, updateConfig, updateMortgageCalc } from "@actions"
import { getUrlVars, matchMediaSize } from "@helpers"
import PrimaryBT from "../PrimaryBT"
import PrimaryWidgetsComponents from "../PrimaryWidgetsComponents"

function PrimaryContainer({ media, config, handleMediaChange, updateConfig }) {
  const [showBlockingModal, setShowBlockingModal] = useState(false)
  const dispatch = useDispatch()

  // Listen for messages from the global modal to update the store in main app
  useIframeCommunication(message => {
    if (message.action === UPDATE_EMBED_STORE_FROM_GLOBAL_MODAL) {
      const { store, data } = message.params
      switch (store) {
        case "mortgageCalc":
          dispatch(updateMortgageCalc(data))
          break

        default:
          break
      }
    }
  })

  useEffect(() => {
    // Update state with the widget config
    updateConfig(config)

    // Check if we need to show the blocking modal
    const options = getUrlVars()
    if (options.blockingModalVisible) {
      setShowBlockingModal(true)
    }
  }, [config, updateConfig])

  useEffect(() => {
    // Update media list
    const mql = window.matchMedia(MEDIA_QUERIES[media])
    mql.addListener(handleMediaChangeLcl)

    return () => {
      // Remove listener from media query list
      mql.removeListener(handleMediaChangeLcl)
    }
  }, [media])

  const handleMediaChangeLcl = e => {
    handleMediaChange(matchMediaSize())
  }

  if (config.widgetType === "boomtown") {
    return createPortal(
      <PrimaryBT showBlockingModal={showBlockingModal} />,
      document.getElementById("lo-page")
    )
  } else {
    return <PrimaryWidgetsComponents showBlockingModal={showBlockingModal} />
  }
}

const mapStateToProps = ({ app: { media } }) => {
  return {
    media
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleMediaChange: media => dispatch(handleMediaChange(media)),
    updateConfig: config => dispatch(updateConfig(config))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryContainer)
