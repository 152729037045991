import { h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { connect } from "react-redux"
import { openModalFromBtn, eventDispatch } from "@helpers"
import { onRTSaveSelectedRow } from "@actions"
import InitalMsg from "./InitalMsg"
import { List, Button } from "@ui"
import "@styles/components/_rate-table-bar-inner.scss"

const items = [
  "Accurate rates, specific to you",
  "Unlimited access to our online tools",
  "Closing cost estimates",
  "Expert home loan advice",
  "Personal mortgage professional support"
]

const ConnectedRateTableBarInner = ({
  onRTSaveSelectedRow,
  usage_limit,
  pulls,
  isSimple,
  className = ""
}) => {
  const [limit, setLimit] = useState()
  const [showFull, toggleFull] = useState(false)

  useEffect(() => {
    const l = usage_limit - pulls
    setLimit(l)
    if (l === 0) {
      eventDispatch({
        name: "usage_limit_met",
        category: "rate_table"
      })
    }
  }, [usage_limit, pulls])

  useEffect(() => {
    toggleFull(limit === 0 || !isSimple)
  }, [isSimple, limit])

  const showSubscriptionModal = () => {
    eventDispatch({
      name: "usage_limit_subscribe_click",
      category: "rate_table"
    })

    onRTSaveSelectedRow(null)
    openModalFromBtn(
      `${location.origin}/external/rate_table_subscription_modal`,
      "rate-table-subscription"
    )
  }

  return (
    <div className={`rt-bar-inner ${showFull ? "is-full" : ""} ${className}`}>
      {showFull ? (
        <div className="d-md-flex justify-content-around rt-bar-inner__full">
          <h1 className="rt-bar-inner__title">
            You're just seconds away from receiving some great benefits,
            absolutely FREE!
          </h1>
          <div className="rt-bar-inner__list-block">
            <List className="rt-bar-inner__list" items={items} iconSize={12} />
            <Button
              size="xl"
              noShadow
              outline
              isLite
              className="rt-bar-inner__btn"
              onClick={showSubscriptionModal}
            >
              Activate My Account
            </Button>
          </div>
        </div>
      ) : (
        <InitalMsg limit={limit} />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    onRTSaveSelectedRow: payload => dispatch(onRTSaveSelectedRow(payload))
  }
}

const mapStateToProps = ({
  rateTable: {
    rateTableConfig: { usage_limit },
    usage_data: { pulls }
  }
}) => {
  return {
    usage_limit,
    pulls
  }
}

const RateTableBarInner = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedRateTableBarInner)

export default RateTableBarInner
