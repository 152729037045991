import { h } from "preact"
import { useSelector } from "react-redux"
import CardsLeadForm from "./CardsLeadForm"
import { RootStates } from "@types"
import { Button } from "@ui"

interface Props {
  showLeadForm: boolean
  showLeadCaptureForm: () => void
  closeLeadForm: () => void
}

const CardPricingGridHidden = ({
  showLeadForm,
  showLeadCaptureForm,
  closeLeadForm
}: Props) => {
  const config = useSelector(({ app }: RootStates) => app)

  return (
    <div className="txt-dark tw-absolute tw-w-full tw-top-0 tw-bottom-0 tw-bg-white tw-bg-opacity-75 tw-z-10">
      <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
        {!showLeadForm && (
          <div className="tw-text-center">
            <h3 className="tw-m-0 text-xl">
              Before we can show you today's rates, we need to know a little bit
              about you. Click the button below to get started!
            </h3>
            <Button
              className="tw-w-64 tw-mt-6"
              size="lg"
              adapt
              noShadow
              onClick={showLeadCaptureForm}
            >
              Get My Rates
            </Button>
          </div>
        )}

        {showLeadForm && (
          <CardsLeadForm config={config} hideLeadForm={closeLeadForm} />
        )}
      </div>
    </div>
  )
}

export default CardPricingGridHidden
