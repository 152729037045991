import { Fragment, h } from "preact"
import { GoogleLoginBtn, If } from "@ui"
import { GoogleLoginResponse } from "@types"
import EmailWithButtonField from "./EmailWithButtonField"
import { gtagGoogleLoginClicked, gtagGoogleLoginSuccess } from "@helpers"

interface Props {
  field: {
    data: {
      value: string
      placeholder: string
      id: string
      emailIsNotFilled: boolean
    }
    onInput: (e: Event) => void
    onFocus: (e: Event) => void
    onValidationError?: () => void
    onValidationSuccess?: () => void
  }
  onClickContinue: () => void
  onSocialLogin: (data: {
    validated: boolean
    email: string
    first_name: string
    last_name: string
  }) => void
  noDisclaimer?: boolean
  embed: string
  className?: string
  googleLoginEnabled: boolean
  topBannerVisible?: boolean
  showGoogleOnly?: boolean // Temporary field - it will hide Facebook and Apple logins till they are fully ready
}

const SocialLogins = ({
  field,
  onClickContinue,
  onSocialLogin,
  noDisclaimer,
  embed,
  className = "",
  googleLoginEnabled,
  topBannerVisible = true,
  showGoogleOnly = true
}: Props) => {
  const onGoogleLoginSuccess = (response: GoogleLoginResponse) => {
    gtagGoogleLoginSuccess(embed)
    onSocialLogin({
      validated: true,
      email: response.email,
      first_name: response.given_name?.trim() ?? "",
      last_name: response.family_name?.trim() ?? ""
    })
  }

  return (
    <div className={`wizard__sl ${className}`}>
      {topBannerVisible && (
        <div className="wizard__sl-text text-center">
          Free and complete access in seconds
        </div>
      )}
      <EmailWithButtonField field={field} onClickButton={onClickContinue} />
      <If
        condition={googleLoginEnabled}
        then={
          <Fragment>
            <span className="wizard__sl-separator">or</span>
            <div
              className={`social-logins-container ${
                showGoogleOnly ? "no-padding" : ""
              }`}
            >
              <GoogleLoginBtn
                iconButton={!showGoogleOnly}
                onSuccess={onGoogleLoginSuccess}
                onLoginAttempt={() => gtagGoogleLoginClicked(embed)}
              />
            </div>
          </Fragment>
        }
      />

      <If
        condition={!noDisclaimer}
        then={<p className="wizard__sl-disclaimer">• No SSN required •</p>}
      />
    </div>
  )
}

export default SocialLogins
