import { h } from "preact"
import InputRange from "react-input-range"
import Marks from "../Marks"
import { toFixed } from "@helpers"

const Range = ({
  className = "",
  draggableTrack = true,
  onChangeComplete,
  onChange,
  value = 1,
  min = 0,
  max = 1,
  step = 1,
  disabled = false,
  hideTooltip,
  marks
}) => {
  // To avoid plugin warnings while data is being fetched
  if (min > max && value) {
    max = value + 1
  }

  return (
    <div className={`input-range__wrapper ${marks ? "has-marks" : ""}`}>
      <InputRange
        classNames={{
          activeTrack: "ir__track",
          disabledInputRange: "input-range--disabled",
          inputRange: "input-range " + className,
          labelContainer: "ir__label-val",
          maxLabel: "ir__label ir__label--max",
          minLabel: "ir__label ir__label--min",
          slider: "ir__handle",
          sliderContainer: "ir__handle-wrapper",
          track: "ir__rail",
          valueLabel: hideTooltip ? "d-none" : "ir__tip"
        }}
        formatLabel={(value, type) => {
          if (typeof value === "undefined") {
            return <span aria-hidden="true">0</span>
          } else if (type !== "value" || typeof value[0] === "undefined") {
            return (
              <span aria-hidden="true">
                {Number.isInteger(value) ? value : toFixed(value, 2)}
              </span>
            )
          }

          return (
            <span aria-hidden="true">
              {Number.isInteger(value?.[0])
                ? value?.[0]
                : toFixed(value?.[0] ?? 0, 2)}
            </span>
          )
        }}
        minValue={min}
        maxValue={max}
        step={step}
        value={value}
        onChange={onChange}
        onChangeComplete={onChangeComplete}
        draggableTrack={draggableTrack}
        disabled={disabled}
      />
      {marks && (
        <Marks
          onChange={onChange}
          onChangeComplete={onChangeComplete}
          min={min}
          max={max}
          marks={marks}
        />
      )}
    </div>
  )
}

export default Range
