import { FormattedReviews, ProviderTypeWithAllOption, ReviewData } from "@types"

export const filterReviewByProvider = (
  reviewsData: ReviewData,
  provider: ProviderTypeWithAllOption
) => {
  if (!reviewsData?.reviews) {
    return []
  }

  let tempReviews: FormattedReviews = []
  if (!provider || provider === "all") {
    tempReviews = Object.values(reviewsData.reviews).flatMap(a => a)
    // Flatten the array and order by date
    tempReviews = tempReviews.sort((a, b) => {
      const datetime = a.time
      const datetimePrev = b.time

      return datetimePrev - datetime
    })
  } else {
    tempReviews = reviewsData.reviews[provider]
  }

  return tempReviews
}

export const flattenReviewData = (reviewsData: ReviewData) =>
  filterReviewByProvider(reviewsData, "all")
