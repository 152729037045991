import { h } from "preact"

interface Props {
  term: string
  className?: string
}

const MortgageTerm = ({ term, className = "" }: Props) => {
  return (
    <div className={`pricing-item__details is-term ${className}`}>
      <div className="is-term-inner">
        <strong>{term}</strong>
        <small className="pricing-item__label">Term</small>
      </div>
    </div>
  )
}

export default MortgageTerm
