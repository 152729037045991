import { Fragment, h } from "preact"

interface Props {
  options?: Intl.DateTimeFormatOptions
  jsx?: boolean
}

const Now = ({
  options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  },
  jsx = true
}: Props) => {
  const dateNow = new Date()
  const timeFormatted = new Intl.DateTimeFormat("en-US", options).format(
    dateNow
  )

  return jsx ? (
    <time dateTime={dateNow.toISOString()}>{timeFormatted}</time>
  ) : (
    <Fragment>timeFormatted</Fragment>
  )
}

export default Now
