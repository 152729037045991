import { h } from "preact"
import { InputMaskedFull } from "../../inputs"
import { Group } from "../../layout"
import { UISize } from "@types"
import If from "../../../If"
import { useState } from "preact/hooks"
import { useIsTablet } from "@contexts"

interface Props {
  handleInputChange: (val: any, e: any) => void
  handlePercentageChange: (val: any, e: any) => void
  value?: string | number
  value_percentage?: string | number
  label?: string
  name?: string
  labelPercentage?: string
  namePercentage?: string
  size?: UISize
  maxValue: number
}

const DownPayment = ({
  handleInputChange,
  handlePercentageChange,
  value,
  value_percentage,
  label = "Down Payment",
  name = "down_payment",
  labelPercentage = "Down Payment Percentage",
  namePercentage = "down_payment_percentage",
  size = "md",
  maxValue
}: Props) => {
  const [maxValueExceed, setMaxOrMinValueExceed] = useState(false)
  const isTablet = useIsTablet()

  const warningTXT = isTablet
    ? "Must be < price"
    : "Must be less than purchase price"

  return (
    <div className="tw-relative">
      <Group rounded>
        <InputMaskedFull
          isMaterial
          label={label}
          inputPrefix="$"
          required
          name={name}
          onChange={handleInputChange}
          value={value}
          size={size}
          integerLimit={8}
          maxValue={maxValue}
          defaultOnExceed={(maxValue * 20) / 100}
          onExceed={setMaxOrMinValueExceed}
          forceIsInvalid={maxValueExceed}
        />

        <InputMaskedFull
          inputSuffix="%"
          required
          hiddenLabel
          label={labelPercentage}
          name={namePercentage}
          onChange={handlePercentageChange}
          className="attached-percent-field"
          value={value_percentage}
          size={size}
          includeThousandsSeparator={false}
          integerLimit={3}
          minValue={0}
          maxValue={100}
          onExceed={setMaxOrMinValueExceed}
          forceIsInvalid={maxValueExceed}
        />
      </Group>

      <If
        condition={maxValueExceed}
        then={
          <span className="tw-text-xs txt-danger tw-relative -tw-top-6 md:tw-top-0">
            {warningTXT}
          </span>
        }
      />
    </div>
  )
}

export default DownPayment
