import { h } from "preact"
import { useSelector } from "react-redux"
import { selectGlobalModalIframeID } from "@selectors"
import { openMobileAssumptionsTooltipFromWithin } from "@iframeCommunication/utils"
import { TooltipFullMobileProps } from "@types"

const TooltipMobile = ({
  className = "",
  ctaClassName = "",
  iconSize = 14,
  iconBG = "#B4C4D3",
  iconColor = "#fff",
  icon = "i",
  iconWeight = "600",
  children,
  onClick
}: TooltipFullMobileProps) => {
  const iframeID = useSelector(selectGlobalModalIframeID)

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Enter") {
      toggleTooltipClick()
    }
  }

  const toggleTooltipClick = () => {
    openMobileAssumptionsTooltipFromWithin(iframeID)
    onClick?.()
  }

  return (
    <div className={`tooltip-full__wrapper ${className}`}>
      <span
        tabIndex={0}
        onClick={toggleTooltipClick}
        onKeyDown={handleKeyDown}
        className={`tooltip-trigger ${ctaClassName}`}
        role="button"
        aria-haspopup="true"
        aria-describedby="tooltip-content"
      >
        {children || (
          <i
            style={{
              color: iconColor,
              background: iconBG,
              fontWeight: iconWeight,
              height: `${iconSize}px`,
              width: `${iconSize}px`,
              fontSize: `${iconSize - 4}px`
            }}
            className={`${icon === "i" ? "is-info" : "not-info"}${
              icon === "$" ? " is-dollar" : ""
            }`}
          >
            {icon}
          </i>
        )}
      </span>
    </div>
  )
}

export default TooltipMobile
