import { Fragment, h } from "preact"
import { useDispatch, useSelector } from "react-redux"
import {
  ExitCTA,
  HeaderComponent,
  OfficerData,
  StepFooter,
  StepLoop
} from "@composite"
import {
  If,
  LeadWorkflowEndStepSkeleton,
  LeadWorkflowSkeleton,
  ProgressBar
} from "@ui"
import { RootStates } from "@types"
import { saveWizardState, setWizardDetails } from "@actions"
import { LeadWorkflowSteps } from "@types"
import { useEvaluateCondition, useLeadWorkflow, useUpdateFormUI } from "@hooks"
import clsx from "clsx"
import "@styles/components/_lead-workflow.scss"

interface LeadWorkflowProps {
  className?: string
  isInline?: boolean
  sm?: boolean
  hasBorder?: boolean
}

const LeadWorkflow = ({
  className = "",
  isInline,
  sm,
  hasBorder
}: LeadWorkflowProps) => {
  const dispatch = useDispatch()
  const {
    forceExitCTA,
    values,
    branded,
    isLoading,
    isLoadingLastStep,
    steps,
    currentStep,
    validationRequired,
    disable_pricing,
    noCards
  } = useSelector(({ app, wizard }: RootStates) => {
    return {
      isLoading: wizard?.isLoading ?? false,
      isLoadingLastStep: wizard?.isLoadingLastStep ?? false,
      currentStep: wizard?.currentStep ?? 0,
      values: wizard?.values,
      steps: wizard?.steps ?? [],
      branded: wizard?.branded,
      validationRequired: wizard?.validationRequired,
      forceExitCTA: app.forceExitCTA,
      card_details: wizard?.card_details,
      disable_pricing: wizard?.disable_pricing,
      noCards:
        !wizard?.pricingGridIsLoading &&
        wizard?.card_details !== null &&
        (!wizard?.card_details?.cards ||
          wizard?.card_details?.cards.length === 0)
    }
  })

  const { classNames } = useLeadWorkflow()

  const evaluateCondition = useEvaluateCondition()
  const updateFormUI = useUpdateFormUI()

  const onInput = (e: InputEvent) => {
    const newSteps: Partial<LeadWorkflowSteps> = { ...(values?.steps ?? {}) }
    const target = e.target as HTMLInputElement
    if (target?.name !== undefined) {
      const key = target.name as keyof LeadWorkflowSteps
      ;(newSteps[key] as string) = target?.value

      const newValues = { ...values, steps: newSteps }

      dispatch(
        saveWizardState({
          values: { ...newValues }
        })
      )
      const add = evaluateCondition(false, newSteps)
      updateFormUI(add)
    }
  }

  const onClickContinue = () => {
    const add = evaluateCondition(false)
    updateFormUI(add)
  }

  const onClickBack = () => {
    const remove = evaluateCondition(true)
    updateFormUI(remove)
  }

  const hideWizardExitCTA = () => {
    dispatch(
      setWizardDetails({ forceExitCTA: false, forceExitCTAShowed: true })
    )
  }

  // Once the exit CTA form was sent we need to go to the last step
  const forceFinish = () => {
    const remainingSteps = steps.length - currentStep
    updateFormUI(remainingSteps - 1)
    hideWizardExitCTA()
  }

  const stepCount = steps.length - 1
  const percentage = `${Math.round((100 / stepCount) * currentStep)}%`
  const isLastStep = currentStep === stepCount
  const lastStepClassName = isLastStep ? " wizard--step-last" : ""
  const hideReviews = currentStep >= stepCount - (validationRequired ? 2 : 1)

  const wrapperClassName = clsx(
    "wizard",
    className,
    classNames?.animateClass ?? "",
    classNames?.isInClass ?? "",
    `wizard--step-${currentStep}${lastStepClassName}`,
    isInline ? "wizard--inline" : "",
    hasBorder ? "has--border" : "",
    isLoading ? "is-loading" : "",
    sm ? "is-sm-wrapper" : "is-normal-wrapper"
  )

  return (
    <Fragment>
      <h1 className="tw-sr-only">Banking Bridge Mortgage Calculator</h1>
      <div className={wrapperClassName}>
        <If condition={isLoading} then={<LeadWorkflowSkeleton />} />
        <If
          condition={isLoadingLastStep}
          then={
            <LeadWorkflowEndStepSkeleton
              hasResults={!noCards && !disable_pricing}
            />
          }
        />

        <div className={isLoading || isLoadingLastStep ? "tw-hidden" : ""}>
          {!isInline && branded && <HeaderComponent logoXL />}

          <div className="wizard__step-wrapper tw-relative">
            {!forceExitCTA && (
              <ProgressBar
                accentColor={false}
                className="wizard__step-percentage"
                percentage={percentage}
                label="Loan Assessment Progress"
              />
            )}
            <StepLoop
              forceExitCTA={forceExitCTA}
              steps={steps}
              onInput={onInput}
              onClickContinue={onClickContinue}
              values={values}
              currentStep={currentStep}
              onClickBack={onClickBack}
              validationRequired={validationRequired}
            />

            <StepFooter
              sm={sm}
              currentStep={currentStep}
              visible={!forceExitCTA && !isLoading && !hideReviews}
            />

            <ExitCTA
              forceFinish={forceFinish}
              show={forceExitCTA}
              hideWizardExitCTA={hideWizardExitCTA}
            />
          </div>

          {!isInline && !isLoading && branded && (
            <div className="wizard__footer pr-2">
              <OfficerData align="right" />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default LeadWorkflow
