import { h } from "preact"
import { useSelector } from "react-redux"
import { useCallback } from "preact/hooks"
import { Button, Icon, If } from "@ui"
import { useCurrentMedia, useIsDesktop } from "@contexts"
import { LO, RootStates } from "@types"
import { selectGlobalModalIframeID } from "@selectors"
import { openVideoModalFromWithin } from "@iframeCommunication/utils"
import PLAY_ICON from "@assets/icons/play.svg"

const PlayButton = () => {
  const isDesktop = useIsDesktop()
  const currentMedia = useCurrentMedia()

  const {
    lo: { youtube_link }
  } = useSelector(({ app }: RootStates) => {
    return {
      lo: app?.lo as LO
    }
  })

  const iframeID = useSelector(selectGlobalModalIframeID)
  const handleOnClick = useCallback(() => {
    openVideoModalFromWithin(iframeID)
  }, [iframeID])

  return (
    <If
      condition={!!youtube_link}
      then={
        <Button
          onClick={handleOnClick}
          adapt
          size={isDesktop ? "2xl" : "lg"}
          isBlack
          pill
          className="tw-absolute header-lo__play-btn"
        >
          <Icon src={PLAY_ICON} size={[16, 20]} originalSize={[16, 20]} />
          <span
            className={
              currentMedia !== "XL" ? "tw-sr-only" : "tw-inline-block tw-pl-2"
            }
          >
            Watch video
          </span>
        </Button>
      }
    />
  )
}

export default PlayButton
