import { h } from "preact"
import MaskedInput from "react-text-mask"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import { numberWithoutCommas, debounce } from "@helpers"
import { UISize } from "@types"

interface Props {
  helper?: string
  isMaterial?: boolean
  forceIsInvalid?: boolean
  compact?: boolean
  required?: boolean
  value?: string | number
  name: string
  onFocus?: (e: any) => void
  onBlur?: (val: any, e: any) => void
  onChange: (val: any, e: any) => void
  onKeypress?: (e: any) => void
  submitOnEnter?: () => void
  hiddenLabel?: boolean
  label: string
  showRequired?: boolean
  placeholder?: string
  isNumber?: boolean
  allowDecimal?: boolean
  includeThousandsSeparator?: boolean
  integerLimit?: number
  size?: UISize
  prefix?: string
  suffix?: string
  inputPrefix?: string
  inputSuffix?: string
  className?: string
  classNameInput?: string
  showErrorWhenEmpty?: boolean
  maxValue?: number
  minValue?: number
  defaultOnExceed?: number
  onExceed?: (val: boolean) => void
}

const InputMaskedFull = ({
  helper,
  isMaterial,
  forceIsInvalid,
  compact,
  required,
  value,
  name,
  onFocus,
  onBlur,
  onChange,
  onKeypress,
  submitOnEnter,
  hiddenLabel,
  label,
  showRequired,
  placeholder,
  showErrorWhenEmpty = false,
  isNumber = true,
  allowDecimal = true,
  includeThousandsSeparator = true,
  integerLimit,
  size = "md",
  prefix = "",
  suffix = "",
  inputPrefix = "",
  inputSuffix = "",
  className = "",
  classNameInput = "",
  maxValue,
  minValue,
  defaultOnExceed = 20,
  onExceed
}: Props) => {
  const fireMaxValueExceed = () => {
    onExceed?.(true)
    setTimeout(() => {
      onExceed?.(false)
    }, 5000)
  }

  //create the `numberMask` with configurations
  const mask = createNumberMask({
    prefix,
    suffix,
    allowDecimal,
    includeThousandsSeparator,
    integerLimit
  })

  const cleanValue = (val: string) => {
    if (val === null || val === undefined) {
      return ""
    }

    const newVal = allowDecimal
      ? val.replace(/[^0-9.]/g, "")
      : val.replace(/\D/g, "")

    const newValNumber = Number(numberWithoutCommas(newVal))

    if (maxValue && newValNumber > maxValue) {
      fireMaxValueExceed()
      return isNumber ? defaultOnExceed : defaultOnExceed + ""
    } else if (minValue && newValNumber < minValue) {
      return isNumber ? minValue : minValue + ""
    }

    return isNumber ? Number(numberWithoutCommas(newVal)) : newVal
  }

  const handleChange = debounce(
    (e: any) => {
      const rawVal = cleanValue(e.target.value)
      onChange(rawVal, e)
    },
    allowDecimal ? 500 : 0
  )

  const handleFocus = (event: any) => {
    onFocus && onFocus(event)
  }

  const handleBlur = (e: any) => {
    let rawVal = cleanValue(e.target.value)
    onBlur && onBlur(rawVal, e)
  }

  const handleKeypress = (e: any) => {
    if (e.code === "Enter" && submitOnEnter) {
      submitOnEnter()
      e.target.blur()
    }
    onKeypress && onKeypress(e)
  }

  const isInvalidClassName =
    (showErrorWhenEmpty &&
      (value === null || value === undefined || value === "")) ||
    forceIsInvalid
      ? "is-invalid"
      : ""

  return (
    <div
      className={`form__field is-${size} tw-relative ${
        isMaterial ? "is-material is-typed" : ""
      } ${compact ? "is-compact" : ""} ${className}`}
    >
      {label && (
        <label
          className={`form__label ${hiddenLabel ? "tw-hidden" : ""}`}
          htmlFor={name}
        >
          <span className="form__label-txt">{label}</span>
          {showRequired && <span className="text-danger"> *</span>}
        </label>
      )}
      <div className="tw-relative">
        {inputPrefix && (
          <span className="form__input-affix">{inputPrefix}</span>
        )}

        <MaskedInput
          className={`form__input ${classNameInput}${
            inputPrefix ? " form__input--affix" : ""
          }${inputSuffix ? "form__input--suffix" : ""} ${isInvalidClassName}`}
          name={name}
          value={value}
          required={required}
          placeholder={!isMaterial && placeholder ? placeholder : undefined}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyUp={handleKeypress}
          mask={mask}
          size={1}
        />

        {inputSuffix && (
          <span className="form__input-suffix">{inputSuffix}</span>
        )}
        {helper && <span className="form__helper">{helper}</span>}
      </div>
    </div>
  )
}

export default InputMaskedFull
