import { h } from "preact"
import TooltipFull from "../../TooltipFull"
import { PricingCards } from "@types"
import AssumptionsInner from "./Inner"
import { useAssumptions } from "@hooks"

interface Props {
  items: PricingCards
  className?: string
}

const Assumptions = ({ items, className = "" }: Props) => {
  const CurrentDate = new Date()

  const assumptions = useAssumptions(items)

  return (
    <div className={`md:tw-flex tw-justify-end txt-dark ${className}`}>
      <TooltipFull
        ttPlacement="top-start"
        ttContentClassName="tw-h-auto"
        hideArrow
        title="Assumptions"
        content={<AssumptionsInner assumptions={assumptions} />}
        className="tw-ml-auto"
        ctaClassName="tw-underline txt-dark"
      >
        See Assumptions
      </TooltipFull>
      <span className="tw-hidden md:tw-inline-block tw-px-1">|</span>
      <div className="tw-pt-1 md:tw-pt-0">
        Rates as of{" "}
        <time dateTime={CurrentDate.toISOString()}>
          {CurrentDate.toLocaleString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true
          })}
        </time>
      </div>
    </div>
  )
}

export default Assumptions
