import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useSelector } from "react-redux"
import CountUp from "react-countup"
import { If, LogoLinkBtn, TxtLoading } from "@ui"
import { ReviewsLine } from "@composite"
import { useAuthCode } from "@hooks"
import { RootStates } from "@types"
import { useAppContext } from "@contexts"
import UserForm from "./UserForm"
import CodeForm from "./CodeForm"

interface Props {
  isVisible?: boolean
  isCode?: boolean
  disclaimer: string
  stepKey: string
  fields: any[]
  goBack: () => void
  onSentCallback: () => void
  submitLabel: string
}

const WizardStepForm = ({
  isVisible = false,
  isCode = false,
  disclaimer,
  stepKey,
  fields,
  goBack,
  onSentCallback,
  submitLabel
}: Props) => {
  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  const {
    isLoginForm,
    cardsLength,
    pricingGridIsLoading,
    disable_pricing,
    validationRequired,
    auth_request_id
  } = useSelector(({ wizard }: RootStates) => {
    const isLoginForm = wizard?.steps[wizard?.currentStep]?.id === "login_form"

    return {
      pricingGridIsLoading: wizard?.pricingGridIsLoading,
      disable_pricing: wizard?.disable_pricing,
      cardsLength: wizard?.card_details?.cards.length ?? 0,
      validationRequired: wizard?.validationRequired,
      auth_request_id: wizard?.auth_request_id,
      isLoginForm
    }
  })

  const [isResend, setIsResend] = useState(false)

  const {
    getCode,
    validateCode,
    authCodeResponse,
    isLoadingAuthCode,
    isVerifyingAuthCode,
    authCodeType
  } = useAuthCode()

  useEffect(() => {
    if (
      validationRequired &&
      authCodeResponse?.status === "success" &&
      !isResend
    ) {
      onSentCallback()
    }
  }, [authCodeResponse, validationRequired])

  useEffect(() => {
    if (!auth_request_id && isCode && isVisible) {
      goBack()
    }
  }, [])

  return (
    <div className="wizard__form wizard__animated-items wizard__animated">
      <If
        condition={!pricingGridIsLoading && cardsLength > 0 && !disable_pricing}
        then={
          <div className="wizard__form-live-rates">
            <span>live rates</span>
          </div>
        }
      />
      <h2 className="wizard__step-title wizard__animated wizard__animated-titles">
        <If
          condition={!!pricingGridIsLoading}
          then={
            <div className="wizard__step-title-loader">
              <TxtLoading height={38} margin="0 0 8px" />
              <TxtLoading height={38} width={150} />
            </div>
          }
          elseCondition={cardsLength > 0 && !disable_pricing}
          else={
            <strong>
              You have{" "}
              <If
                condition={isLoginForm}
                then={
                  <span className="accent-color">
                    {" "}
                    <CountUp
                      delay={0.5}
                      duration={0.5}
                      end={cardsLength}
                    />{" "}
                  </span>
                }
              />{" "}
              mortgage options that are ready for your review!
            </strong>
          }
          default={
            <strong>
              We'll help you find the home loan options that fit your needs.
            </strong>
          }
        />
      </h2>
      <If
        condition={isCode}
        then={
          <CodeForm
            makeFocus={isCode && isVisible}
            disclaimer={disclaimer}
            getAuthCode={getCode}
            validateAuthCode={validateCode}
            isVerifying={isVerifyingAuthCode}
            authCodeType={authCodeType}
            setIsResend={setIsResend}
          />
        }
        else={
          <UserForm
            disclaimer={disclaimer}
            getAuthCode={getCode}
            stepKey={stepKey}
            fields={fields}
            onSentCallback={onSentCallback}
            submitLabel={submitLabel}
            isLoadingAuthCode={isLoadingAuthCode}
          />
        }
      />

      <ReviewsLine limit={isMobile ? 2 : 0} />

      <div className="bb-link d-flex d-flex">
        <LogoLinkBtn className="ml-auto" />
      </div>
    </div>
  )
}

export default WizardStepForm
