import { h, Component, Fragment } from "preact"
import { createPortal } from "preact/compat"
import Modal from "./Modal"
import GlobalModalFrame from "./GlobalModalFrame"
import Bar from "./Bar"
import Content from "./Content"
import actions from "./actions"
import { listenAndForwardMessage } from "@iframeCommunication"
import { openExitPopUp, shouldShowExitPopUp } from "@iframeCommunication/utils"
import { EXIT_POP_UP_MOBILE_TIMER } from "@constants"

class Widget extends Component {
  constructor(props) {
    super(props)

    const config = props.config
    const nmls = config.nmls ? `&nmls=${config.nmls}` : ""

    this.state = {
      timer: null,
      exitPopUpModalTimerFinished: false,
      bbConfig: config,
      apiReady: false,
      modalVisible: false,
      barVisible: false,
      hasRateTable: false,
      rt_subscribed: false,
      barHREF: null,
      activeModal: 0,
      iframesToSync: {},
      modals: [],
      config: {},
      rt_usage_data: {},
      url: `${process.env.API_URL}/embed/${config.type}?app_key=${props.app_key}${nmls}`
    }
  }

  componentDidMount() {
    listenAndForwardMessage()

    // check if the user is on mobile
    if (window.innerWidth < 768) {
      // add an interval to check if the config from the iframe is ready
      const timer = setInterval(() => {
        const config = this.state.config?.[this.props.app_key]?.config

        if (config) {
          clearInterval(timer)
          if (config?.globalExitPopUpEnable) {
            const showExitPopUp = shouldShowExitPopUp()
            if (showExitPopUp) {
              const iframeID = `bb-global-modal-${this.state.bbConfig.wrapperID}-${this.state.bbConfig.type}-${this.props.app_key}`
              openExitPopUp(iframeID)
            }
          }
        }
      }, EXIT_POP_UP_MOBILE_TIMER)

      setTimeout(() => {
        clearInterval(timer)
      }, 60000)

      this.setState({ timer })
    } else {
      // if the user is not on mobile, set the exitPopUpModalTimerFinished to true
      this.setState({ exitPopUpModalTimerFinished: true })
    }

    // This is used to get when the user pointer leaves the page
    document.addEventListener("mouseleave", this.listenMouseLeaveFromParentHost)
  }

  componentWillUnmount() {
    document.removeEventListener(
      "mouseleave",
      this.listenMouseLeaveFromParentHost
    )

    this.state.timer && clearTimeout(this.state.timer)
  }

  listenMouseLeaveFromParentHost = () => {
    const app_key = this.props.app_key
    const config = this.state.config?.[app_key]?.config
    if (
      window.frames.length > 0 &&
      config?.exitCTAStepPassed &&
      !config?.forceExitCTAShowed &&
      !config?.forceExitCTA &&
      !config?.isLastStep
    ) {
      for (let i = 0; i < window.frames.length; i++) {
        window.frames[i].postMessage(
          {
            action: "mouseleavePage"
          },
          "*"
        )
      }
    } else if (config?.globalExitPopUpEnable && window.innerWidth > 768) {
      const showExitPopUp = shouldShowExitPopUp()

      if (this.state.exitPopUpModalTimerFinished && showExitPopUp) {
        const iframeID = `bb-global-modal-${this.state.bbConfig.wrapperID}-${this.state.bbConfig.type}-${this.props.app_key}`
        openExitPopUp(iframeID)
      }
    }
  }

  handleFrameTasks = (data, e) => actions[data.action]?.call(this, data, e)

  // Used externally to get the state
  getState = () => this.state

  // Used externally to set the config
  setWidgetConfig = payload => this.setState({ bbConfig: payload })

  setBlockingModalVisible = () => {
    const blockingModalParam = "&blockingModalVisible=true"
    // Widget iframe URL
    const url = `${this.state.url}${blockingModalParam}`

    let targetLocal = this.state.modals[this.state.activeModal]
    if (this.state.modalVisible) {
      targetLocal += blockingModalParam
    }

    this.setState({ url, target: targetLocal })
  }

  render(
    { app_key },
    {
      bbConfig,
      modalVisible,
      url,
      modals,
      activeModal,
      barHREF,
      barVisible,
      rt_usage_data,
      rt_subscribed,
      hasRateTable
    }
  ) {
    return (
      <Fragment>
        {modalVisible &&
          createPortal(
            <Modal
              activeModal={activeModal}
              modals={modals}
              onMessage={this.handleFrameTasks}
              config={bbConfig}
            />,
            document.body
          )}
        {barVisible &&
          !rt_subscribed &&
          createPortal(
            <Bar
              modalVisible={modalVisible}
              isBlocker={rt_usage_data?.limit === rt_usage_data?.pulls}
              barHREF={barHREF}
              onMessage={this.handleFrameTasks}
              config={bbConfig}
              handleCloseButtonClick={() => {
                actions["modal-close"].call(this, actions["modal-close"])
              }}
            />,
            document.body
          )}

        <Content
          url={url}
          onMessage={this.handleFrameTasks}
          config={bbConfig}
        />

        <GlobalModalFrame
          onMessage={this.handleFrameTasks}
          config={bbConfig}
          appState={this.state.config?.[app_key]?.config}
          hasRateTable={hasRateTable}
        />
      </Fragment>
    )
  }
}

export default Widget
