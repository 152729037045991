import { h } from "preact"
import Chevron from "../../../Chevron"
import { OptionButton } from "@types"
import clsx from "clsx"
import { useMemo } from "preact/hooks"
import If from "../../../If"
import AccentColor from "../../../AccentColor"
import "@styles/components/_buttons.scss"

interface ButtonsProps {
  onInput: (e: InputEvent) => void
  label?: string
  options: OptionButton[]
  value: string | number | boolean | undefined
  isList?: boolean
  isListIcon?: boolean
  row?: boolean
  name: string
  className?: string
}

const Buttons = ({
  onInput,
  label,
  options,
  value,
  isList,
  isListIcon,
  row,
  name,
  className = ""
}: ButtonsProps) => {
  const onClick = (value: string) => {
    const event = new InputEvent("input", { bubbles: true })
    Object.defineProperty(event, "target", {
      writable: false,
      value: { name, value }
    })
    onInput(event)
  }

  const wrapperClassName = useMemo(
    () =>
      clsx("buttons d-flex", className, {
        "buttons--list": isList,
        "buttons--icons": !isList,
        "flex-column flex-md-row justify-content-center": isList && row,
        "flex-column justify-content-between": isList && !row
      }),
    [isList, row, className]
  )

  const buttonClassName = useMemo(
    () =>
      clsx("buttons__item d-flex align-items-center justify-content-start", {
        "d-md-block": !isList
      }),
    [isList]
  )

  const handleKeyDown = (code: string, value: string) => {
    if (code === "Enter") {
      onClick(value)
    }
  }

  return (
    <div className={wrapperClassName}>
      {label}
      {options.map((option, i) => {
        return (
          <button
            onKeyDown={(e: KeyboardEvent) =>
              handleKeyDown(e.code, option.value)
            }
            onClick={() => onClick(option.value)}
            tabIndex={0}
            className={`${buttonClassName} ${
              options.length === i + 1 ? "is-last" : ""
            } ${value === option.value ? "is-checked" : ""} `}
            key={option.value}
            type="button"
          >
            <If
              condition={!isList}
              then={
                <span
                  className="buttons__icon"
                  style={{ "background-image": `url(${option.icon})` }}
                />
              }
            />

            <If
              condition={!!isListIcon}
              then={
                <span
                  className="buttons__icon"
                  style={{ "background-image": `url(${option.icon})` }}
                />
              }
            />

            <AccentColor className="buttons__label">{option.label}</AccentColor>
            <Chevron className="ml-auto" side="right" size={14} />
          </button>
        )
      })}
    </div>
  )
}

export default Buttons
