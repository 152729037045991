export const capitalizeTheFirstLetterOfEachWord = (words: string): string => {
  const separateWord = words.toLowerCase().split("_")
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
  }
  return separateWord.join(" ")
}

export const toSentenceCase = (text: string): string => {
  if (!text) return text // Handle empty strings gracefully
  const lowercased = text.toLowerCase()
  return lowercased.charAt(0).toUpperCase() + lowercased.slice(1)
}

export const formDataToUrlParams = (
  URLWithTokens: string,
  formData: any = {},
  forceParams = false
): string => {
  let modifiedURL = URLWithTokens

  // Check if URLWithTokens contains any tokens
  if (!/{{.*?}}/.test(modifiedURL)) {
    // If no tokens are present, append the form data to the URL
    if (forceParams) {
      const prefix = modifiedURL.indexOf("?") > 0 ? "&" : "?"
      modifiedURL +=
        prefix +
        Object.entries(formData)
          .map(([key, val]) => `${key}=${encodeURIComponent(val as string)}`)
          .join("&")
    }

    return modifiedURL
  }

  // Loop through each form field and replace the corresponding token in the URL
  for (const field in formData) {
    if (formData.hasOwnProperty(field)) {
      const token = `{{${field}}}`
      const value = encodeURIComponent(formData[field])
      modifiedURL = modifiedURL.replace(new RegExp(token, "g"), value)
    }
  }

  // Replace any remaining tokens in the URL with empty strings
  modifiedURL = modifiedURL.replace(/{{.*?}}/g, "")
  return modifiedURL
}

export const getYouTubeVideoIdFromUrl = (url: string) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? match[2] : null
}
