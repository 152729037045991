export const onClickAnchor = (anchor: string) => {
  const element = document.getElementById(anchor)
  if (element) {
    element.scrollIntoView({
      behavior: "smooth"
    })
  }
}

export const addScrollLock = () => {
  document
    .getElementsByTagName("html")[0]
    .classList.add("bankingbridge-page-scroll-locked")
}

export const removeScrollLock = () => {
  document
    .getElementsByTagName("html")[0]
    .classList.remove("bankingbridge-page-scroll-locked")
}
