import { h } from "preact"
import { useDispatch } from "react-redux"
import { getOfferDetails, setCurrentOfferDetailsCard } from "@actions"
import { gtagWorkflowFeesClick, openModalFromBtn } from "@helpers"
import { useGetTermLabel } from "@hooks"
import FeesBlock from "./FeesBlock"
import Points from "./Points"
import PI from "./PI"
import MortgageTerm from "./MortgageTerm"
import ApplyBtn from "./ApplyBtn"
import { PricingItem, PricingItemType } from "@types"
import { If } from "@ui"

interface Props {
  quote_id: number
  item: PricingItem
  isRow?: boolean
  type: PricingItemType
  hideFeesModal?: boolean
  fees: number
}

const PricingItemBodyCompact = ({
  quote_id,
  item,
  isRow,
  type,
  hideFeesModal,
  fees
}: Props) => {
  const dispatch = useDispatch()

  const term = useGetTermLabel(item)

  const handleClickFees = ({ e }: { e?: Event }) => {
    dispatch(setCurrentOfferDetailsCard(item))

    dispatch(
      getOfferDetails({
        quote_id,
        callback: () => {
          openModalFromBtn(`${location.origin}/external/offer_details_modal`)
        }
      })
    )

    if (type === "wizard") {
      gtagWorkflowFeesClick()
    }

    e?.stopPropagation()
  }

  return (
    <div className="pricing-item__body is-compact">
      <div
        className={`pricing-item__body-inner tw-flex tw-items-center ${
          isRow ? "" : "tw-justify-between"
        }`}
      >
        <FeesBlock
          type={type}
          fees={fees}
          hideFeesModal={hideFeesModal}
          handleClickFees={handleClickFees}
        />
        <Points points={item.pts} />

        <If
          condition={type === "wizard"}
          then={<PI principalAndInterest={item.principalAndInterest} />}
        />

        <MortgageTerm term={term} />

        {isRow && <ApplyBtn className="hover:accent-bg-color" type={type} />}
      </div>
    </div>
  )
}

export default PricingItemBodyCompact
