/**
 * Check if the loan type is FHA
 * if residency_type === primary_home, credit_score is between 600 and 700 and loan_amount is between 50000 - 600000
 */
export const isFHA = (
  residency_type: string,
  credit_score: number,
  loan_amount: number
): boolean => {
  return (
    residency_type === "primary_home" &&
    credit_score >= 570 &&
    credit_score <= 700 &&
    loan_amount >= 50000 &&
    loan_amount <= 600000
  )
}
