import { h } from "preact"
import ReviewsList from "../../../ReviewsList"
import { useIsDesktop, useLoTabsContext } from "@contexts"
import { onClickAnchor } from "@helpers"
import { LO_TABS_PANEL_ID } from "@constants"

const Reviews = () => {
  const { setTab } = useLoTabsContext()

  const isDesktop = useIsDesktop()

  const handleClick = () => {
    onClickAnchor(LO_TABS_PANEL_ID)
    setTab("reviews")
  }

  return (
    <ReviewsList
      onClick={handleClick}
      size="md"
      type="lo"
      currentColor={false}
      withSeparator
      className={!isDesktop ? "tw-mt-8" : ""}
    />
  )
}

export default Reviews
