import axios from "axios"
import { bbProcessEnv } from "@helpers"

export default {
  getDefaultValues: async nmls => {
    const tmpnmls = nmls ? `?nmls=${nmls}` : ""

    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/external/loan_calc.json${tmpnmls}`
      )
      return response.data && response.data.default_values
    } catch (error) {
      console.log(error)
    }
  },
  getLoanCalcConfig: async () => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/external/loan_calc.json`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  getLoanCalc: async (nmls, params) => {
    const tmpnmls = nmls ? `?nmls=${nmls}` : ""
    try {
      const response = await axios.post(
        `${bbProcessEnv.API_URL}/external/loan_calc.json${tmpnmls}`,
        {
          ...params
        }
      )

      //When it returns an "error"
      if (response.data.status === "error") {
        console.error(response.data.message)
        return
      }

      const data = {
        card_details: response.data?.card_details,
        pi: 0,
        mortgageInsurance: 0,
        taxes: 0,
        mortgageInsurance: 0,
        homeInsurance: 0,
        rate: 0,
        apr: 0,
        price: 0
      }
      if (response.data.values) {
        data.pi = response.data.values?.pi ?? 0
        data.mortgageInsurance = response.data.values?.mi ?? 0
        data.taxes = response.data.values?.taxes ?? 0
        data.homeInsurance = response.data.values?.home_insurance ?? 0
        data.rate = response.data.values?.rate ?? 0
        data.apr = response.data.values?.apr ?? 0
        data.price = response.data.values?.price ?? 100
      }
      return data
    } catch (error) {
      console.log(error)
    }
  }
}
