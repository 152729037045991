import { h } from "preact"
import DescriptionListItem from "./Item"
import { DescriptionListItems } from "@types"

interface Props {
  className?: string
  items: DescriptionListItems
  dark?: boolean
}

const DescriptionList = ({ className = "", items, dark = false }: Props) => {
  return (
    <dl className={`${className} tw-text-sm ${dark ? "tw-dark" : ""}`}>
      {items.map(item => (
        <DescriptionListItem key={item.term} {...item} />
      ))}
    </dl>
  )
}

export default DescriptionList
