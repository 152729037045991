import { h, Fragment } from "preact"
import { If, Form, Button, Alert } from "@ui"
import { AuthCodeType } from "@types"

type Props = {
  authCodeType: AuthCodeType
  showResendTxt: boolean
  onClick: (forcedType?: AuthCodeType) => void
  onClickBack: () => void
}

const ResendBtn = ({
  authCodeType,
  showResendTxt,
  onClick,
  onClickBack
}: Props) => (
  <Fragment>
    <Form.Row>
      <p className="wizard_resend-block">
        Didn't receive the code?{" "}
        <Button link inline label="Resend code" onClick={() => onClick()} />
        {/* <If
          condition={authCodeType === "sms"}
          then={
            <Fragment>
              {" "}
              or{" "}
              <Button
                className="ml-1"
                link
                inline
                label="send it to your Email"
                onClick={() => onClick("email")}
              />
            </Fragment>
          }
        /> */}
        <If
          condition={authCodeType === "sms"}
          then={
            <Fragment>
              {" "}
              or{" "}
              <Button
                link
                inline
                label="edit phone number"
                onClick={onClickBack}
              />
            </Fragment>
          }
        />
        .
      </p>
    </Form.Row>

    <If
      condition={showResendTxt}
      then={
        <Alert.Info className="wizard__form-alert">
          The code was sent again
        </Alert.Info>
      }
    />
  </Fragment>
)

export default ResendBtn
