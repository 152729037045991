import { h } from "preact"
import clsx from "clsx"
import MaskedInput from "react-text-mask"
import { cleanUSPhone } from "@helpers"
import { useEffect } from "preact/hooks"
import { usePhoneNumberInput } from "@hooks"
import Label from "./Label"
import If from "../../../If"
import ValidatingAnimation from "./ValidatingAnimation"

const MASK = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

interface Props {
  helper?: string
  isMaterial?: boolean
  compact?: boolean
  required?: boolean
  value: string
  name: string
  onFocus?: (e: FocusEvent) => void
  onBlur?: (e: FocusEvent, cleanValue: string) => void
  onChange: (e: InputEvent, cleanValue: string) => void
  onKeypress?: (e: KeyboardEvent) => void
  onValidationError?: (error: string) => void
  onValidationSuccess?: () => void
  onValidationStart?: () => void
  submitOnEnter?: () => void
  valiateOnBlur?: boolean
  triggerValidation?: boolean
  APIValidationEnabled?: boolean
  hiddenLabel?: boolean
  label?: string
  showRequired?: boolean
  placeholder?: string
  size?: "sm" | "md" | "lg"
  className?: string
  classNameInput?: string
}

const PhoneNumber = ({
  helper,
  isMaterial,
  compact,
  required,
  value,
  name,
  onValidationError,
  onValidationSuccess,
  onValidationStart,
  onFocus,
  onBlur,
  onChange,
  onKeypress,
  submitOnEnter,
  valiateOnBlur = true,
  triggerValidation = false,
  APIValidationEnabled = false,
  hiddenLabel,
  label,
  showRequired,
  placeholder,
  size = "md",
  className = "",
  classNameInput = ""
}: Props) => {
  const { handleValidation, isValidating } = usePhoneNumberInput({
    onValidationError,
    onValidationSuccess,
    onValidationStart,
    APIValidationEnabled
  })

  useEffect(() => {
    if (triggerValidation) {
      handleValidation(value)
    }
  }, [triggerValidation])

  const handleChange = (e: InputEvent) => {
    onChange(e, cleanUSPhone((e.target as HTMLInputElement).value))
  }

  const handleFocus = (e: FocusEvent) => {
    onFocus?.(e)
  }

  const handleBlur = async (e: FocusEvent) => {
    const cleanValue = cleanUSPhone((e.target as HTMLInputElement).value)
    if (valiateOnBlur) {
      await handleValidation(value)
    }
    onBlur?.(e, cleanValue)
  }

  const handleKeypress = (e: KeyboardEvent) => {
    if (e.code === "Enter" && submitOnEnter) {
      submitOnEnter()
      ;(e.target as HTMLInputElement).blur()
    }
    onKeypress?.(e)
  }

  const wrapperClassName = clsx(
    "form__field form__field--validation",
    `is-${size}`,
    isMaterial ? "is-material" : "",
    compact ? "is-compact" : "",
    className,
    value?.length ? "is-typed" : "",
    className
  )

  return (
    <div className={wrapperClassName}>
      <Label
        label={label}
        hiddenLabel={hiddenLabel}
        showRequired={showRequired}
      />

      <div className="tw-relative">
        <MaskedInput
          /* @ts-ignore */
          className={`form__input ${classNameInput}`}
          name={name}
          value={value}
          type="tel"
          required={required}
          placeholder={!isMaterial && placeholder ? placeholder : undefined}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyUp={handleKeypress}
          mask={MASK}
        />

        <ValidatingAnimation isValidating={isValidating} />

        <If
          condition={!!helper}
          then={<span className="form__helper">{helper}</span>}
        />
      </div>
    </div>
  )
}

export default PhoneNumber
