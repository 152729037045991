import { h } from "preact"
import PlacesAutocompleteInput from "../../inputs/PlacesAutocompleteInput"
import { LocationObj } from "@types"
import { decoupleAddress } from "@helpers"

interface Props {
  isMaterial?: boolean
  showErrorWhenEmpty?: boolean
  className?: string
  size?: "sm" | "md" | "lg"
  initialValue?: string
  onChange: (value: LocationObj, place: google.maps.places.PlaceResult) => void
}

const AutoZipcode = ({
  isMaterial = true,
  showErrorWhenEmpty,
  className = "",
  size = "md",
  initialValue,
  onChange
}: Props) => {
  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    const newLocation = decoupleAddress(place.address_components)

    const { state = "", city = "" } = newLocation // Add default values for state and city

    // get the lat and lng from the place object
    const lat = place.geometry?.location?.lat() ?? 0
    const lng = place.geometry?.location?.lng() ?? 0

    onChange({ ...newLocation, state, city, coords: { lat, lng } }, place) // Pass the updated newLocation object to onChange
  }

  return (
    <PlacesAutocompleteInput
      showErrorWhenEmpty={showErrorWhenEmpty}
      className={className}
      hasMetaDataUI
      initialValue={initialValue}
      size={size}
      isMaterial={isMaterial}
      apiOptions={{
        componentRestrictions: {
          country: "us"
        },
        types: ["(regions)"]
      }}
      onPlaceSelected={handlePlaceSelected}
    />
  )
}

export default AutoZipcode
