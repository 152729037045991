import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import "@styles/components/_dot-animation.scss"

const DotAnimation = ({ className = "" }) => {
  const [classNames, setClassNames] = useState<string[]>(["", "", ""])
  useEffect(() => {
    const dots = Array(3).fill(null)
    const duration = 350

    const animateDots = () => {
      dots.forEach((_, index) => {
        setTimeout(() => {
          setClassNames(prevClassNames => {
            const newClassNames = [...prevClassNames]
            newClassNames[index] = "is-visible"
            return newClassNames
          })
          setTimeout(() => {
            setClassNames(prevClassNames => {
              const newClassNames = [...prevClassNames]
              newClassNames[index] = ""
              return newClassNames
            })
          }, duration * (dots.length - index))
        }, index * duration)
      })
    }

    const interval = setInterval(animateDots, dots.length * duration * 2)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <span className={`dot-animation ${className}`}>
      <span className={`dot-animation__dot ${classNames[0]}`}>.</span>
      <span className={`dot-animation__dot ${classNames[1]}`}>.</span>
      <span className={`dot-animation__dot ${classNames[2]}`}>.</span>
    </span>
  )
}

export default DotAnimation
