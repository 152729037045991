import { GLOBAL_MODAL, CONTACT_MODAL } from "@constants"
import { openGlobalModal, openGlobalModalFromWithin } from "../base"

export const openContactModalFromWithin = (iframeID?: string) => {
  openGlobalModalFromWithin(GLOBAL_MODAL, CONTACT_MODAL, iframeID)
}

export const openContact = (iframeID?: string) => {
  openGlobalModal(GLOBAL_MODAL, CONTACT_MODAL, iframeID)
}
