import { h } from "preact"
import { Button } from "@ui"

interface Props {
  handleClickFees: ({ e, openModal }: { e: Event; openModal?: boolean }) => void
  outline?: boolean
  isLite?: boolean
  className?: string
  md?: boolean
}

const DetailsButton = ({
  handleClickFees,
  outline = true,
  isLite,
  md = true,
  className = ""
}: Props) => (
  <Button
    className={`details-btn hover:accent-bg-color ${className}`}
    {...(md ? { size: "md" } : {})}
    adapt
    outline={outline}
    isLite={isLite}
    noShadow
    onClick={(e: Event) => handleClickFees({ e })}
    label="Details"
  />
)

export default DetailsButton
