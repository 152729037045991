// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
function numberWithCommas(x: number | string) {
  return new Intl.NumberFormat("en-EN").format(Number(x))
}

function numberToUSD(
  x = 0,
  noDecimals?: boolean,
  maximumSignificantDigits?: number
) {
  const options: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "USD"
  }
  const num = Number(noDecimals ? toFixed(x, 0) : x)
  if (noDecimals) {
    const n = toFixed(x, 0)
    const l = (n + "").length
    options.maximumSignificantDigits = l
  } else if (maximumSignificantDigits) {
    options.maximumSignificantDigits = maximumSignificantDigits
  }
  return new Intl.NumberFormat("en-EN", options).format(num)
}

// Abbreviate number to compact notation
// 1000000 -> 1M
function abbreviateN(x: number) {
  return new Intl.NumberFormat("en-EN", {
    notation: "compact",
    compactDisplay: "short"
  }).format(x)
}

// Unformat price from $ 50,000 -> 50000
function numberWithoutCommas(x: string) {
  return x.toString().replace(/,/g, "")
}

/**
 * Formats a number or string to have a specific number of decimal places.
 * Similar to Number.toFixed() but with more control over decimal handling.
 *
 * @param x - The input value (number or string)
 * @param digits - Number of decimal places to display (default: 3)
 * @param keepExtra - Whether to keep extra decimal places (default: false)
 * @returns Formatted string with specified decimal places
 */
function toFixed(x: string | number, digits = 3, keepExtra = false) {
  let strngX = x + ""
  const decimalsLength =
    (strngX.split(".")[1] && strngX.split(".")[1].length) || 0

  if (decimalsLength < digits) {
    const count = digits - decimalsLength
    strngX = count === digits ? `${strngX}.` : strngX
    for (let i = 0; i < count; i++) {
      strngX += "0"
    }
  } else if (decimalsLength > digits && !keepExtra) {
    const pointPos = strngX.indexOf(".")
    const fullDigits = pointPos + digits + 1
    return digits === 0
      ? strngX.substring(0, pointPos)
      : strngX.substring(0, fullDigits)
  }
  return strngX
}

/**
 * Check if a number is between a range
 * @param {number} num Number to check
 * @param {number} a   Range start
 * @param {number} b   Range end
 */
const inRange = (num: number, a: number, b: number = 0) =>
  Math.min(a, b) <= num && num < Math.max(a, b)

/**
 * Convert a string to a number
 * @param {any} value
 * @returns {number}
 * @example
 * convertToNumber('1.23') // 1.23
 * or if the value is not a number
 * convertToNumber('abc') // 0
 */
function convertToNumber(value: string | number | null | undefined) {
  const number = parseFloat(String(value))
  return isNaN(number) ? 0 : number
}

function formatFeeValue(fee: number) {
  return `${fee < 0 ? "(" : ""}${numberToUSD(Math.abs(fee), true)}${
    fee < 0 ? ")" : ""
  }`
}

function formatNumber(
  number: number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits,
    maximumFractionDigits
  }).format(number)
}

export {
  inRange,
  toFixed,
  numberWithoutCommas,
  abbreviateN,
  numberToUSD,
  numberWithCommas,
  convertToNumber,
  formatFeeValue,
  formatNumber
}
