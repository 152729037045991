import { h } from "preact"
import { connect } from "react-redux"
import { updateFormValues } from "@actions"
import StepInner from "./StepInner"
import { Form, Icon, CrossBtn } from "@ui"
import { parseAddress } from "@helpers"
import pinIcon from "@assets/icons/map-marker-alt-solid.svg"

const ConnectedStepLocation = ({
  onClickContinue,
  currentVal,
  stepKey,
  title,
  placeholder,
  updateFormValues
}) => {
  const handlePlaceSelected = async place => {
    const addressObj = place.address_components

    const postal_code = addressObj.find(
      item => item.types[0] === "postal_code"
    )?.long_name

    updateFormValues(
      {
        value: place.formatted_address,
        ...parseAddress(addressObj),
        postal_code
      },
      stepKey,
      "location_autocomplete"
    )
  }

  const resetInput = () => {
    updateFormValues({}, stepKey, "location_autocomplete")
  }

  // match zip on the placeholder and replace it with ZIP
  const zipPlaceholder = placeholder.replace(/[zZ][iI][pP]/g, "ZIP")

  return (
    <StepInner
      cols={2}
      stepKey={stepKey}
      title={title}
      btnDisabled={!currentVal}
      onClickContinue={onClickContinue}
    >
      {currentVal ? (
        <div className="d-flex wizard__location-address">
          <Icon
            className="mr-1"
            src={pinIcon}
            originalSize={[384, 512]}
            size={[16, 26]}
          />
          <span>{currentVal?.replace(/, USA$/, "") ?? ""}</span>
          <CrossBtn className="ml-2 mt-1" onClick={resetInput} />
        </div>
      ) : (
        <Form.PlacesAutocompleteInput
          label={zipPlaceholder}
          isMaterial
          apiOptions={{
            componentRestrictions: {
              country: "us"
            },
            types: ["(regions)"]
          }}
          onPlaceSelected={handlePlaceSelected}
        />
      )}
    </StepInner>
  )
}

const mapStateToProps = ({ wizard }, { stepKey }) => {
  return {
    currentVal: wizard.values.steps[stepKey]
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateFormValues: (value, key, type) =>
      dispatch(updateFormValues({ value, key, type }))
  }
}

const StepLocation = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedStepLocation)

export default StepLocation
