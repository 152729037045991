import { h } from "preact"
import { Button } from "@ui"

interface Props {
  disabledNext?: boolean
  disabledBack?: boolean
  labelNext?: string
  labelBack?: string
  className?: string
  onClickBack?: () => void
  onClickContinue?: () => void
}

const FooterBtns = ({
  disabledNext,
  disabledBack,
  labelNext = "Next",
  labelBack = "Back",
  className = "",
  onClickBack,
  onClickContinue
}: Props) =>
  onClickBack || onClickContinue ? (
    <div
      className={`wizard__buttons d-flex justify-content-center align-items-center ${className}`}
    >
      {onClickBack && (
        <Button
          className="wizard__nxt-btn"
          disabled={disabledBack}
          onClick={onClickBack}
          label={labelBack}
          noShadow
          pill
          size="md"
        />
      )}
      {onClickContinue && (
        <Button
          className="wizard__nxt-btn"
          disabled={disabledNext}
          onClick={onClickContinue}
          label={labelNext}
          noShadow
          pill
          size="md"
        />
      )}
    </div>
  ) : null

export default FooterBtns
