import { h } from "preact"
import If from "../../../If"
import ScreenReaderOnly from "../../../ScreenReaderOnly"

interface Props {
  max?: Date
  min?: Date
  value: Date
  onChange: (date: Date) => void
  name: string
  className?: string
  ariaDescribedby?: string
  label?: string
  hiddenLabel?: boolean
  size?: "sm" | "md" | "lg"
  notStacked?: boolean
}

const DatePicker = ({
  value,
  onChange,
  max,
  min,
  name,
  className = "",
  ariaDescribedby = "date format: mm/dd/yyyy",
  label,
  hiddenLabel,
  size = "md",
  notStacked
}: Props) => {
  return (
    <div
      className={`form__field is-datepicker is-${size} ${className} ${
        notStacked ? "" : "is-stacked"
      }`}
    >
      <If
        condition={!!label}
        then={
          <label
            className={`form__label ${hiddenLabel ? "d-none" : ""}`}
            htmlFor={name}
          >
            <span className="form__label-txt">{label}</span>
          </label>
        }
      />
      <input
        aria-describedBy={`${name}-desc`}
        className="form__input"
        type="date"
        value={value?.toISOString().split("T")[0]}
        onChange={e => {
          onChange(new Date((e.target as HTMLInputElement).value))
        }}
        max={max?.toISOString().split("T")[0]}
        min={min?.toISOString().split("T")[0]}
        name={name}
      />
      <ScreenReaderOnly id={`${name}-desc`}>
        ({ariaDescribedby})
      </ScreenReaderOnly>
    </div>
  )
}

export default DatePicker
