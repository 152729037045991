import { ASSUMPTIONS_MOBILE_TOOLTIP, MOBILE_TOOLTIP } from "@constants"
import { closeGlobalModalFromWithin, openGlobalModalFromWithin } from "../base"

export const openMobileAssumptionsTooltipFromWithin = (iframeID?: string) => {
  openGlobalModalFromWithin(
    MOBILE_TOOLTIP,
    ASSUMPTIONS_MOBILE_TOOLTIP,
    iframeID
  )
}

export const closeMobileTooltipFromWithin = () => {
  closeGlobalModalFromWithin()
}
