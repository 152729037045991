import { Fragment, h } from "preact"
import { useMediaFlags } from "@contexts"
import { LoanDetailsFormFields, OnChangeValue } from "@types"
import { TooltipPurchaseFields, ResponsivePurchaseFields } from "./ExtraFields"
import { Form, If } from "@ui"

interface Props {
  hasARMTerms: boolean
  showFullForm?: boolean
  formData: LoanDetailsFormFields
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
  numericInputChange: (value: any, e: Event) => void
  disabledFields?: Array<keyof LoanDetailsFormFields>
}

const PurchaseForm = ({
  hasARMTerms,
  showFullForm = false,
  formData,
  formData: {
    credit_score = 0,
    down_payment,
    down_percentage,
    property_type,
    residency_type
  },
  handleOnChange,
  numericInputChange,
  disabledFields = []
}: Props) => {
  const { isDesktop, isTablet, isMobile } = useMediaFlags()

  const showMoreFilters = isTablet && !showFullForm

  return (
    <Fragment>
      <Form.ListPriceField
        label="Purchase Price"
        onChange={numericInputChange}
        value={formData.list_price}
      />

      <Form.DownPaymentField
        maxValue={formData.list_price}
        handleInputChange={(value: any) => {
          handleOnChange(value, "down_payment")
        }}
        handlePercentageChange={(value: any) => {
          handleOnChange(value, "down_percentage")
        }}
        value={down_payment}
        value_percentage={down_percentage}
      />

      <Form.CreditScoreFull
        value={credit_score}
        name="credit_score"
        onChangeComplete={(value: number) =>
          handleOnChange(value, "credit_score")
        }
      />

      <If
        condition={!isMobile}
        then={
          <Fragment>
            <Form.PropertyTypeField
              handleOnChange={(value: string) =>
                handleOnChange(value, "property_type")
              }
              value={property_type}
            />
            <Form.ResidencyTypeField
              value={residency_type}
              onChange={(value: string) =>
                handleOnChange(value, "residency_type")
              }
            />
          </Fragment>
        }
      />

      <ResponsivePurchaseFields
        showFields={isDesktop || showFullForm}
        formData={formData}
        handleOnChange={handleOnChange}
        hasLoanTerm={showFullForm}
        disabledFields={disabledFields}
        hasARMTerms={hasARMTerms}
      />

      <TooltipPurchaseFields
        hasARMTerms={hasARMTerms}
        formData={formData}
        handleOnChange={handleOnChange}
        showMoreFiltersBtn={showMoreFilters}
        hasLoanTerm={showFullForm}
      />
    </Fragment>
  )
}

export default PurchaseForm
