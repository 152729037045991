import { Fragment, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useSelector } from "react-redux"
import { If, MortgageCalcSkeleton, Form } from "@ui"
import Chart from "./Chart"
import {
  numberWithCommas,
  sprintf,
  calcDownPayment,
  calcDownPercentage,
  calcPropertyTax,
  calcPropertyTaxPercentage,
  calcMortgageInsurance,
  calcHomeInsurance
} from "@helpers"
import { useMortgageForm } from "@hooks"
import { apiFactory } from "./utils"
import { MORTGAGE_TXT } from "@content"

const tooltipTxt = MORTGAGE_TXT.tooltip
const helpers = MORTGAGE_TXT.form.helpers

let api

const MortgageForm = ({ app_key, defaultValues, isInline }) => {
  const {
    selectedProfile,
    conformingLimit,
    creditScoreOptions,
    credit_score,
    downPaymentIssue,
    downPaymentMax,
    downPercentage,
    down_payment,
    fullState,
    hoaDues,
    homeInsurance,
    homeInsurancePercentage,
    homePrice,
    interestRate,
    isLoading,
    list_price_max,
    list_price_min = 0,
    loanTerm,
    loanTermOptions,
    loan_type,
    loan_typeOptions,
    mortgageInsurancePercentage,
    propertyTaxAmount,
    propertyTaxPercentage
  } = useSelector(({ mortgageCalc, app }) => {
    return {
      ...mortgageCalc,
      zipcode: app?.zipcode ?? "",
      fullState: mortgageCalc,
      defaultsFromURL: app?.defaults ?? {}
    }
  })

  const { defaultStateLcl, handleUpdateMortgageCalc, setForm } =
    useMortgageForm({ defaultValues })

  const [localMax, setLocalMax] = useState(
    list_price_max || conformingLimit * 1.2
  )

  useEffect(() => {
    api = apiFactory(app_key)
    setForm()
  }, [])

  useEffect(() => {
    setLocalMax(list_price_max || conformingLimit * 1.2)
  }, [list_price_max, conformingLimit])

  useEffect(() => {
    if (!selectedProfile) {
      return
    }
    const { loanTerm, principalAndInterest, rate } = selectedProfile

    let tempTerm = loanTerm

    tempTerm = (tempTerm | 0) * 12

    handleUpdateMortgageCalc({
      interestRate: rate,
      app_key,
      loanTerm: tempTerm,
      pi: principalAndInterest
    })
    defaultStateLcl && updateForm()
  }, [selectedProfile])

  const updateForm = (value, name) => {
    handleUpdateMortgageCalc({
      app_key,
      showChartLoading: true,
      downPaymentIssue: false
    })
    let newState = {}
    if (value) {
      newState = { [name]: value }

      switch (name) {
        case "homePrice":
          newState.down_payment = calcDownPayment({
            homePrice: value,
            downPercentage
          })
          newState.propertyTaxAmount = calcPropertyTax({
            homePrice: value,
            propertyTaxPercentage
          })
          newState.homeInsurance = calcHomeInsurance({
            homePrice: value,
            homeInsurancePercentage
          })
          newState.mortgageInsurance = calcMortgageInsurance({
            homePrice: value,
            downPercentage: newState.down_payment / value,
            mortgageInsurancePercentage
          })

          newState.downPaymentMax = 0.75 * value

          // set the state for the slider
          handleUpdateMortgageCalc({
            app_key,
            homePrice: value
          })
          break
        case "down_payment":
          newState.downPercentage = calcDownPercentage({
            homePrice,
            down_payment: value
          })
          newState.mortgageInsurance = calcMortgageInsurance({
            homePrice: homePrice,
            downPercentage: value / homePrice,
            mortgageInsurancePercentage
          })

          // set the state for the slider
          handleUpdateMortgageCalc({
            app_key,
            down_payment: value
          })
          break
        case "downPercentage":
          newState.down_payment = calcDownPayment({
            app_key,
            homePrice,
            downPercentage: value
          })
          break
        case "propertyTaxAmount":
          newState.propertyTaxPercentage = calcPropertyTaxPercentage({
            homePrice,
            propertyTaxAmount: value
          })
          break
        case "propertyTaxPercentage":
          newState.propertyTaxAmount = calcPropertyTax({
            homePrice,
            propertyTaxPercentage: value
          })
          break
        case "loan_type":
          if (value === "FHA") {
            newState.mortgageInsurance = calcMortgageInsurance({
              homePrice,
              downPercentage,
              mortgageInsurancePercentage
            })
          } /**
            * We need to keep this disabled for now.
            * https://app.asana.com/0/511585595086826/1201924516304875/f
            * 
            else if (value === "VA") {
            newState.down_payment = 0
            newState.downPercentage = 0
          }*/

          break
      }
    }

    api.getLoanCalc({ ...fullState, ...newState }).then((response = {}) => {
      const {
        card_details = {},
        pi = 0,
        mortgageInsurance = 0,
        rate = 0
      } = response
      const { interestRate, apr } = defaultStateLcl
      const aprTemp =
        selectedProfile?.apr || card_details?.cards?.[0].apr || apr
      const interestRateTemp =
        rate ||
        selectedProfile?.rate ||
        card_details?.cards?.[0].rate ||
        interestRate

      handleUpdateMortgageCalc({
        force_rate: false,
        ...newState,
        app_key,
        interestRate: interestRateTemp,
        apr: aprTemp,
        isLoading: false,
        showChartLoading: false,
        card_details,
        pi,
        mortgageInsurance
      })
    })
  }

  const handleSelectChange = (value, name) => {
    handleUpdateMortgageCalc({
      app_key,
      [name]: value
    })
    updateForm(value, name)
  }

  const handleSliderChange = (value, name) => {
    handleUpdateMortgageCalc({
      app_key,
      [name]: value
    })
  }

  const handleSliderEnd = (value, name) => {
    updateForm(value, name)
  }

  return (
    <If
      condition={isLoading}
      then={<MortgageCalcSkeleton className="is-mortgage-form" />}
      else={
        <div className="mortgage-calculator">
          <Chart />

          <div
            className={`mortgage-calculator__form ${
              isInline ? "is-inline" : ""
            }`}
          >
            <Form.InputRangeGroupMasked
              inputAutoWidth
              className="mortgage-calculator__field"
              classNameLabel="mortgage-calculator__label"
              classNameInput="mortgage-calculator__input accent-color"
              label="Home Price"
              name="homePrice"
              value={homePrice}
              min={list_price_min}
              max={localMax}
              step={500}
              onChange={handleSliderChange}
              onChangeComplete={handleSliderEnd}
              errorTxt={sprintf(
                helpers.down_payment_max,
                numberWithCommas(localMax)
              )}
              showErrorTxt={downPercentage < 20 && localMax < homePrice}
            />

            <Form.InputRangeGroupMasked
              className="mortgage-calculator__field"
              classNameLabel="mortgage-calculator__label"
              classNameInput="mortgage-calculator__input accent-color"
              label="Down Payment"
              name="down_payment"
              value={down_payment}
              min={0}
              max={downPaymentMax}
              step={500}
              onChange={(value, name) => {
                handleSliderChange(value, name)
                let dpp = calcDownPercentage({
                  homePrice,
                  down_payment: value
                })
                handleSliderChange(dpp, "downPercentage")
              }}
              onChangeComplete={handleSliderEnd}
              helpTxt={helpers.down_payment}
              showHelpTxt={down_payment < 20}
              errorTxt={helpers.down_payment_issue}
              showErrorTxt={downPaymentIssue}
              hasAmountPercent
              amountPercent={downPercentage}
              amountPercentName="downPercentage"
              inputAutoWidth
            />

            <If
              condition={!isInline}
              then={
                <Fragment>
                  <Form.InputRangeGroupMasked
                    inputAutoWidth
                    className="mortgage-calculator__field"
                    classNameLabel="mortgage-calculator__label"
                    classNameInput="mortgage-calculator__input accent-color"
                    label="Property Tax"
                    name="propertyTaxAmount"
                    tt={tooltipTxt.prop_taxes}
                    value={propertyTaxAmount}
                    suffix="/mo"
                    min={0}
                    max={calcPropertyTax({
                      homePrice,
                      propertyTaxPercentage: 4
                    })}
                    step={0.01}
                    onChange={(amountPercent, name) => {
                      handleSliderChange(amountPercent, name)
                    }}
                    isPercentSlider={true}
                    onChangeComplete={(amountPercent, name) => {
                      handleSliderEnd(amountPercent, name)
                    }}
                    hasAmountPercent
                    amountPercent={propertyTaxPercentage}
                    amountPercentName="propertyTaxPercentage"
                    amountPercentMax={4}
                  />

                  <Form.InputRangeGroupMasked
                    inputAutoWidth
                    className="mortgage-calculator__field"
                    classNameLabel="mortgage-calculator__label"
                    classNameInput="mortgage-calculator__input accent-color"
                    label="HOA Dues"
                    name="hoaDues"
                    tt={tooltipTxt.HOA_dues}
                    value={hoaDues}
                    suffix="/mo"
                    min={0}
                    max={1000}
                    step={0.01}
                    onChange={value => {
                      handleSliderChange(value, "hoaDues")
                    }}
                    onChangeComplete={value => {
                      handleSliderEnd(value, "hoaDues")
                    }}
                  />
                  <Form.InputRangeGroupMasked
                    inputAutoWidth
                    className="mortgage-calculator__field"
                    classNameLabel="mortgage-calculator__label"
                    classNameInput="mortgage-calculator__input accent-color"
                    label="Home Insurance"
                    name="homeInsurance"
                    tt={tooltipTxt.hi}
                    value={homeInsurance}
                    min={0}
                    max={1000}
                    onChange={handleSliderChange}
                    onChangeComplete={handleSliderEnd}
                    suffix="/mo"
                  />

                  <div className="mortgage-calculator__field">
                    <Form.NumericInputMaterial
                      className="mortgage-calculator__inner"
                      label={
                        <span className="mortgage-calculator__label">
                          Interest rate
                        </span>
                      }
                      suffix="%"
                      name="interestRate"
                      value={interestRate}
                      forceTwoDecimal={false}
                      readonly
                    />
                  </div>

                  <div className="mortgage-calculator__field">
                    <Form.SelectCustom
                      className="mortgage-calculator__inner"
                      isMaterial
                      name="loan_type"
                      options={loan_typeOptions}
                      value={loan_type}
                      onChange={option => {
                        handleSelectChange(option.value, "loan_type")
                      }}
                      label="Loan Type"
                    />
                  </div>
                  <div className="mortgage-calculator__field">
                    <Form.SelectCustom
                      className="mortgage-calculator__inner"
                      isMaterial
                      name="loanTerm"
                      options={loanTermOptions}
                      value={loanTerm}
                      onChange={option => {
                        handleSelectChange(option.value, "loanTerm")
                      }}
                      label="Loan Term"
                      tooltip={tooltipTxt.loan_term}
                    />
                  </div>

                  <div className="mortgage-calculator__field">
                    <Form.SelectCustom
                      className="mortgage-calculator__inner"
                      isMaterial
                      name="credit_score"
                      options={creditScoreOptions}
                      value={credit_score}
                      onChange={option => {
                        handleSelectChange(option.value, "credit_score")
                      }}
                      label="Credit Score"
                    />
                  </div>
                </Fragment>
              }
            />
          </div>
        </div>
      }
    />
  )
}

export default MortgageForm
