import { Fragment, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useSelector } from "react-redux"
import { RootStates, SortByOption } from "@types"
import RateTableNoResults from "../RateTableNoResults"
import FHAVAEligibleBanner from "../FHAVAEligibleBanner"
import SubscriptionToggle from "../SubscriptionToggle"
import { useRateTableToggleSubscription } from "@hooks"
import Toolbar from "./Toolbar"
import Table from "./Table"
import { useMediaFlags } from "@contexts"
import { RateTableItemsSkeleton, If } from "@ui"
import { ONE_DAY_IN_MILLISECONDS, SORT_BY_OPTIONS } from "@constants"
import { useSortedCards } from "@hooks"
import { getStorage, setStorage } from "@helpers"
import "@styles/components/_rate-table.scss"

interface Props {
  hideAlertButton?: boolean
}

const RateTable = ({ hideAlertButton = false }: Props) => {
  const {
    isPricingLoading,
    noResults,
    subscription_cta_enabled,
    storageKey,
    sort_by_default
  } = useSelector(({ rateTable, app }: RootStates) => {
    const storageKey = `rt-${app.app_key}`
    const storageData = getStorage(storageKey)

    let sort_by_default =
      rateTable?.rateTableConfig?.sort_by_default ?? SORT_BY_OPTIONS[1].value

    if (storageData?.sort_by_default) {
      const sixtyDaysInMilliseconds = 60 * ONE_DAY_IN_MILLISECONDS
      const isDataRecent =
        Date.now() - storageData.sort_by_default.date < sixtyDaysInMilliseconds

      if (isDataRecent) {
        sort_by_default = storageData.sort_by_default.value
      }
    }

    return {
      storageKey,
      sort_by_default,
      isPricingLoading: rateTable?.pricingLoading ?? false,
      noResults: rateTable?.rateData.length === 0,
      subscription_cta_enabled:
        rateTable?.rateTableConfig?.subscription_cta_enabled ?? true
    }
  })

  const { isMobile, isDesktop } = useMediaFlags()

  const { subscribed, updateSubscription } = useRateTableToggleSubscription()

  const [sortValue, setSort] = useState<SortByOption>(sort_by_default)

  const { sortLoading } = useSortedCards(sortValue)

  useEffect(() => {
    setStorage(
      {
        sort_by_default: {
          value: sortValue,
          date: Date.now()
        }
      },
      storageKey
    )
  }, [sortValue])

  const isLoading = isPricingLoading || sortLoading

  return (
    <Fragment>
      <Toolbar
        sortValue={sortValue}
        onSortChange={setSort}
        className={isLoading || !noResults ? "" : "d-none"}
        updateSubscription={() => updateSubscription(true)}
        showGetRateAlertsButton={subscription_cta_enabled && !hideAlertButton}
      />

      <div className="rates-block">
        <FHAVAEligibleBanner />

        {/* this is the initial loader */}
        <RateTableItemsSkeleton
          pricingIsLoading={isLoading}
          isNotMobile={isDesktop}
          cols={isMobile ? 1 : 3}
        />

        <RateTableNoResults
          pricingIsLoading={isLoading}
          noResults={noResults}
        />

        <Table sortValue={sortValue} />
      </div>
      <If
        condition={subscription_cta_enabled && !hideAlertButton}
        then={
          <SubscriptionToggle
            hasLoader={false}
            active={subscribed}
            updateSubscription={updateSubscription}
          />
        }
      />
    </Fragment>
  )
}

export default RateTable
