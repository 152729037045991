import { h, Fragment } from "preact"
import Icon from "../Icon"
import ReviewAuthor from "../ReviewAuthor"
import { REVIEWS_ICONS } from "@constants"
import { FormattedReview } from "@types"
import { If } from "@ui"
import ExpandableContent from "../../composite/ExpandableContent"
import defaultThumb from "@assets/avatar.svg"
import "@styles/components/_review.scss"

const Providers = {
  zillow: {
    icon: (
      <Icon
        src={REVIEWS_ICONS.zillow.icon}
        size={REVIEWS_ICONS.zillow.iconSize}
      />
    ),
    label: "Zillow"
  },
  google: {
    icon: (
      <Icon
        src={REVIEWS_ICONS.google.icon}
        size={REVIEWS_ICONS.google.iconSize}
      />
    ),
    label: "Google"
  },
  experience: {
    icon: (
      <Icon
        src={REVIEWS_ICONS.experience.icon}
        originalSize={REVIEWS_ICONS.experience.iconOriginalSize}
        size={REVIEWS_ICONS.experience.iconSize}
      />
    ),
    label: "Experience"
  },
  yelp: {
    icon: (
      <Icon
        src={REVIEWS_ICONS.yelp.icon}
        originalSize={REVIEWS_ICONS.yelp.iconOriginalSize}
        size={REVIEWS_ICONS.yelp.iconSize}
      />
    ),
    label: "Yelp"
  }
}

interface Props {
  row: FormattedReview
  ratingColor?: string
  index: number
  excerpt?: number
  reviewsViewMode?: "list" | "grid"
  className?: string
  hideProvider?: boolean
  hideReviewBorder?: boolean
}

const Review = ({
  row,
  ratingColor,
  index,
  excerpt = 250,
  reviewsViewMode,
  className = "",
  hideProvider,
  hideReviewBorder
}: Props) => {
  const icon = Providers[row.provider].icon

  const footerLabel = (
    <Fragment>
      <div className="review__footer-label">Posted on</div>
      <div className="review__footer-provider tw-sr-only">
        {Providers[row.provider].label}
      </div>
      <div className="ml-2">{icon}</div>
    </Fragment>
  )

  return (
    <div
      className={`review is-row-${index} ${className} review-${reviewsViewMode} ${
        hideReviewBorder ? "review--no-border" : ""
      }`}
    >
      <div className="d-flex pb-3">
        <div className="review-author__img">
          {row.profile_photo_url ? (
            <img
              className="review-author__thumb-big"
              src={row.profile_photo_url}
              alt={`${row.author_name} thumb`}
            />
          ) : (
            <Icon
              className="review-author__thumb-big"
              src={defaultThumb}
              size={36}
              originalSize={36}
            />
          )}
        </div>
        <ReviewAuthor
          author={{ img: row?.profile_photo_url, name: row.author_name }}
          rating={row.rating}
          ratingColor={ratingColor}
          time={{ datetime: row.time, relative_time: row.relative_time }}
          reviewsViewMode={reviewsViewMode}
        />
      </div>

      <div className="review__main">
        <div className="review__body">
          <If
            condition={(row?.text?.length ?? 0) < excerpt}
            then={<p className="review__body-text">{row.text}</p>}
            else={
              <ExpandableContent
                lengthLimit={excerpt}
                text={row.text}
                labelLess="Less"
                showBtnIcon={false}
                btnClassName="mt-4 review__more-btn"
              />
            }
          />
        </div>

        <If
          condition={!hideProvider}
          then={
            <div className="review__footer">
              {row?.reviewURL ? (
                <a
                  className="d-flex align-items-center justify-content-end"
                  href={row.reviewURL ?? "#"}
                  target="_blank"
                >
                  {footerLabel}
                </a>
              ) : (
                <div className="d-flex align-items-center justify-content-end">
                  {footerLabel}
                </div>
              )}
            </div>
          }
        />
      </div>
    </div>
  )
}

export default Review
