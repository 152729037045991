import { h } from "preact"
import { useState } from "preact/hooks"
import check from "@assets/icons/check-thin.svg"
import bell from "@assets/icons/bell-simple.svg"
import { Icon, Button, Loader, If } from "@ui"
import "@styles/components/_subscription-toggle.scss"

interface Props {
  updateSubscription: (checked: boolean, callback: () => void) => void
  active: boolean
  hasLoader?: boolean
}

const SubscriptionToggle = ({
  updateSubscription,
  active,
  hasLoader = false
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const updateSubscriptionLocal = (checked: boolean) => {
    hasLoader && setIsLoading(true)
    updateSubscription(checked, () => setIsLoading(false))
  }

  return (
    <div className="subscription-toggle">
      <div className="subscription-toggle__wrapper-icon">
        <Icon
          src={bell}
          size={24}
          originalSize={22}
          className="subscription-toggle__icon"
        />
      </div>
      <div className="subscription-toggle_inner">
        <div className="subscription-toggle__text">
          <h3>Keep track of rates</h3>
          <p>Get personalized rate updates sent directly to your inbox.</p>
        </div>
        <div className="subscription-toggle__action">
          {isLoading && <Loader className="card__loader" />}
          <If
            condition={active}
            then={
              <div>
                <p>
                  <Icon src={check} size={24} /> Subscribed to rate alerts
                </p>
                <Button link onClick={() => updateSubscriptionLocal(false)}>
                  <u>Unsubscribe</u>
                </Button>
              </div>
            }
            else={
              <Button
                pill
                noShadow
                onClick={() => updateSubscriptionLocal(true)}
              >
                Subscribe to rate alerts
              </Button>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default SubscriptionToggle
