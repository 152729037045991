import { ComponentChildren, h } from "preact"
import { useState, useRef, useEffect } from "preact/hooks"
import { Manager, Reference, Popper } from "react-popper"
import * as PopperJS from "popper.js"
import { createPortal } from "preact/compat"
import TooltipInner from "./TooltipInner"
import { Button, If } from "@ui"

interface Props {
  forceClose?: { state: boolean; set: (b: boolean) => void }
  triggerIsButton?: boolean
  trigger: ComponentChildren
  ttPlacement?: PopperJS.Placement
  ttClassName?: string
  className?: string
  ctaClassName?: string
  children: ComponentChildren
  keepParentWidth?: boolean
  onClick?: (e: Event) => void
}

const Tooltip = ({
  forceClose,
  keepParentWidth,
  triggerIsButton = true,
  trigger,
  ttPlacement = "bottom",
  ttClassName = "",
  className = "",
  ctaClassName = "",
  children,
  onClick
}: Props) => {
  const [show, setShow] = useState(false)
  const ele = useRef<HTMLDivElement>(null)

  const hideTooltipClick = (target: HTMLElement) => {
    let node: HTMLElement | null = target
    let isInsideTooltip = false

    while (node && node !== document.documentElement) {
      if (
        node.classList.contains("is-on-tooltip") ||
        node.classList.contains("tooltip") ||
        node.classList.contains("tooltip-form__trigger") ||
        node.classList.contains("gp-input__wrapper")
      ) {
        // The click occurred inside the tooltip or its nested tooltips, so don't hide it
        return
      }
      node = node.parentElement
    }

    setShow(isInsideTooltip)
  }

  const toggleTooltipClick = (e: Event) => {
    setShow(prevShow => !prevShow)
    onClick?.(e)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Enter") {
      setShow(prevShow => !prevShow)
    }
  }

  const tooltipClass = `tooltip ${show ? "tooltip--show" : ""} ${ttClassName}`

  useEffect(() => {
    if (forceClose?.state) {
      setShow(false)
      forceClose.set(false)
    }
  }, [forceClose?.state])

  const setWidth = (data: PopperJS.Data) => {
    if (!keepParentWidth) return data
    const { width } = data.offsets.reference
    data.styles.width = width.toString() + "px"
    return data
  }

  return (
    <Manager>
      <div
        ref={ele}
        className={`tooltip-form__wrapper tw-relative ${className}`}
      >
        <Reference>
          {({ ref }) => (
            <div ref={ref} className="tooltip-form__trigger">
              <If
                condition={triggerIsButton}
                then={
                  <Button
                    className={ctaClassName}
                    isLite
                    noShadow
                    onClick={toggleTooltipClick}
                    onKeyDown={handleKeyDown}
                  >
                    {trigger}
                  </Button>
                }
                else={
                  <span onKeyDown={handleKeyDown} onClick={toggleTooltipClick}>
                    {trigger}
                  </span>
                }
              />
            </div>
          )}
        </Reference>

        {show &&
          createPortal(
            <Popper
              placement={ttPlacement}
              modifiers={{
                setPopperWidth: {
                  enabled: true,
                  order: 849,
                  fn: setWidth
                }
              }}
            >
              {({ ref, style, placement }) => (
                <div
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  className={`${tooltipClass} is-full bs-tooltip-${placement}`}
                  role="tooltip"
                >
                  <TooltipInner
                    addEvent
                    hideTooltipClick={hideTooltipClick}
                    className="tw-bg-white tw-max-w-[430px] tw-p-4 txt-dark tw-shadow-lg tw-rounded-xl"
                  >
                    <div className="tooltip-form__content tw-h-auto tw-pt-2 tw-overflow-y-scroll">
                      {children}
                    </div>
                  </TooltipInner>
                </div>
              )}
            </Popper>,
            document.body
          )}
      </div>
    </Manager>
  )
}

export default Tooltip
