import { h } from "preact"
import { useState } from "preact/hooks"
import { FormattedReview } from "@types"
import { REVIEWS_ICONS } from "@constants"
import { Icon, Rating, If } from "@ui"
import IconLeftQuote from "@assets/icons/bxs-quote-left.svg"

interface Props {
  review: FormattedReview
  bank: string
  excerpt: number
}

const ReviewSlide = ({ review, bank, excerpt }: Props) => {
  const [textOpen, setTextOpen] = useState<boolean>(false)

  const { text = "", provider, relative_time, author_name, rating } = review
  const { icon, iconSize, iconOriginalSize } = REVIEWS_ICONS[provider]

  const toggleTextOpen = () => setTextOpen(!textOpen)

  const showMoreVisible = text.length > excerpt

  return (
    <div className="review-block">
      <div className="review-block__text">
        <div className="review-block__text--quote">
          <Icon src={IconLeftQuote} size={40} originalSize={40} />
        </div>
        <If
          condition={textOpen || !showMoreVisible}
          then={text.slice(0, 2 * excerpt)}
          else={`${text.slice(0, excerpt)}…`}
        />

        {showMoreVisible && (
          <span className="review-block__text--more" onClick={toggleTextOpen}>
            {textOpen ? "Less" : "More"}
          </span>
        )}
      </div>
      <div className="review-block__info">
        <div className="review-block__info--left">
          <div className="review-block__rating">
            <Rating rating={rating} color="#F3B20A" size={12} />
          </div>
          <div className="review-block__author">{author_name}</div>
          <div className="review-block__extra">{bank} customer</div>
        </div>
        <div className="review-block__info--right">
          <div className="review-block__provider">
            <div className="review-block__provider--icon">
              <Icon
                src={icon}
                size={iconSize}
                originalSize={iconOriginalSize}
              />
            </div>
            <div className="review-block__provider--posted">
              Posted on&nbsp;
              <span className="review-block__provider--label">{provider}</span>
            </div>
          </div>
          <div className="review-block__timeago">{relative_time}</div>
        </div>
      </div>
    </div>
  )
}

export default ReviewSlide
