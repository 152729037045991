import { h } from "preact"
import { store } from "../../../../../store"
import { SelectCustom } from "../../inputs"
import { PROPERTY_TYPE_OPTIONS } from "@constants"

interface Props {
  value?: typeof PROPERTY_TYPE_OPTIONS[number]["value"]
  optionsClassName?: string
  handleOnChange: (
    value: typeof PROPERTY_TYPE_OPTIONS[number]["value"],
    name: string
  ) => void
  isMaterial?: boolean
  showErrorWhenEmpty?: boolean
  name?: string
  label?: string
  size?: "sm" | "md" | "lg"
}

const PropertyType = ({
  showErrorWhenEmpty = true,
  value,
  handleOnChange,
  isMaterial = true,
  name = "property_type",
  label = "Property Type",
  size = "md",
  optionsClassName = ""
}: Props) => {
  let options = PROPERTY_TYPE_OPTIONS
  let fieldLabel = label

  // if we are on the subscription dash, we get the config from the redux store
  if (store) {
    const state = (store && store.getState()) || {} // we get the config from the redux store

    options =
      state?.subscriptionDash?.config?.field_overrides?.property_type.options ??
      PROPERTY_TYPE_OPTIONS
    fieldLabel =
      state?.subscriptionDash?.config?.field_overrides?.property_type.label ??
      label
  }

  return (
    <SelectCustom
      optionsClassName={optionsClassName}
      isMaterial={isMaterial}
      name={name}
      options={options}
      value={value}
      onChange={option => {
        handleOnChange(option.value, name)
      }}
      label={fieldLabel}
      size={size}
      showErrorWhenEmpty={showErrorWhenEmpty}
    />
  )
}

export default PropertyType
