import { Fragment, h } from "preact"
import { useLayoutEffect, useState } from "preact/hooks"
import { useSelector } from "react-redux"
import { useAppContext } from "@contexts"
import LeadWorkflowPricingTableToolbar from "../LeadWorkflowPricingTableToolbar"
import PricingScroll from "../PricingScroll"
import LeadWorkflowPricingRows from "../LeadWorkflowPricingRows"
import { ColLoader } from "@ui"
import "@styles/components/_pricing-table.scss"

const classNameDefault = ""

const LeadWorkflowPricingTable = ({
  showNoResults,
  orderBy,
  className = classNameDefault
}) => {
  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  const { card_details, isLoading } = useSelector(
    ({ wizard: { card_details, pricingGridIsLoading } }) => {
      return {
        card_details,
        isLoading: pricingGridIsLoading
      }
    }
  )

  const [viewType, setView] = useState("table")
  const [cardDetailsLcl, setCardDetails] = useState()

  const setViewType = type => {
    setView(type)
  }

  useLayoutEffect(() => {
    if (orderBy && card_details?.cards) {
      function compare(a, b) {
        if (a.rate < b.rate) {
          return -1
        }
        if (a.rate > b.rate) {
          return 1
        }
        return 0
      }

      card_details.cards.sort(compare)
    }
    setCardDetails(card_details)
  }, [card_details, isLoading])

  if (showNoResults && !isLoading) {
    return null
  }

  return (
    <div className={`pricing-table ${className}`}>
      <LeadWorkflowPricingTableToolbar
        setViewType={setViewType}
        viewType={viewType}
      />
      {viewType === "card" || isMobile ? (
        <Fragment>
          {isLoading ? (
            <ColLoader cols={1} />
          ) : (
            <PricingScroll
              className="pb-4 pb-md-5"
              type="wizard"
              card_details={cardDetailsLcl}
              isResponsive
              noAction
              hideArrows
            />
          )}
        </Fragment>
      ) : (
        <LeadWorkflowPricingRows
          rowsLimit={2}
          card_details={cardDetailsLcl}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default LeadWorkflowPricingTable
