import { Fragment, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useSelector } from "react-redux"
import { LogoLinkBtn, If } from "@ui"
import { LeadWorkflowPricingTable } from "@composite"
import LoanProfileView from "./LoanProfileView"
import StepEndHeader from "./StepEndHeader"
import { InfoBlocks, Disclaimer } from "./ResultStep"
import StepEndExitConfirm from "./StepEndExitConfirm"
import { useAppLink } from "@hooks"

const StepEnd = () => {
  const {
    widgetType,
    card_details,
    disable_pricing,
    loanTermChanged,
    pricingGridIsLoading,
    exit_cta
  } = useSelector(
    ({
      app: { widgetType },
      wizard: {
        card_details,
        disable_pricing,
        loanTermChanged,
        pricingGridIsLoading,
        exit_cta,
        formDataSent
      }
    }) => {
      return {
        widgetType,
        card_details,
        disable_pricing,
        loanTermChanged,
        pricingGridIsLoading,
        exit_cta: exit_cta || formDataSent?.details?.exit_cta || false
      }
    }
  )

  const parsedAppLink = useAppLink()
  const [showCards, toggleCards] = useState(false)
  const [showNoResults, setshowNoResults] = useState(false)

  useEffect(() => {
    const show = card_details?.cards.length && card_details?.cards[0].apr > 0
    setshowNoResults(!show && loanTermChanged && !disable_pricing)

    toggleCards(!disable_pricing)
  }, [card_details, disable_pricing])

  return (
    <div
      className={`wizard-end__step-container ${showCards ? "has-cards" : ""}`}
    >
      <If
        condition={exit_cta}
        then={<StepEndExitConfirm />}
        else={
          <Fragment>
            <StepEndHeader
              showApplyBtn={
                !pricingGridIsLoading && !showCards && !showNoResults
              }
              app_link={parsedAppLink}
            />
            <LoanProfileView />
            <If
              condition={showCards}
              then={<LeadWorkflowPricingTable showNoResults={showNoResults} />}
            />
            <InfoBlocks />
            <Disclaimer
              className={widgetType !== "boomtown" ? "" : "tw-pb-6"}
            />
            <If
              condition={widgetType !== "boomtown"}
              then={
                <div className="bb-link tw-flex">
                  <LogoLinkBtn className="tw-ml-auto" />
                </div>
              }
            />
          </Fragment>
        }
      />
    </div>
  )
}

export default StepEnd
