import { h } from "preact"
import { connect } from "react-redux"
import { updateFormValues } from "@actions"
import { Form } from "@ui"
import StepInner from "./StepInner"

const ConnectedStepText = ({
  value,
  placeholder,
  updateFormValues,
  onClickContinue,
  stepKey,
  title
}) => {
  const onInput = e => {
    updateFormValues(e.target.value, stepKey, "text")
  }

  return (
    <StepInner
      cols={2}
      stepKey={stepKey}
      title={title}
      btnDisabled={!value}
      onClickContinue={onClickContinue}
    >
      <Form.Input
        isMaterial={!!placeholder}
        onInput={onInput}
        value={value || ""}
        placeholder={placeholder}
        submitOnEnter={onClickContinue}
      />
    </StepInner>
  )
}

const mapStateToProps = ({ wizard }, { stepKey }) => {
  const currentVal = wizard.values.steps[stepKey]
  return {
    value: currentVal
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateFormValues: (value, key, type) =>
      dispatch(updateFormValues({ value, key, type }))
  }
}

const StepText = connect(mapStateToProps, mapDispatchToProps)(ConnectedStepText)

export default StepText
