import { useEffect } from "preact/hooks"
import { GLOBAL_MODAL_LOADED } from "@constants"

/*
 * This utility is used to communicate between iframes and also with its parent.
 * Eventually it will replace the message bridge, looking to have cleaner code, easy to understand and
 * easy to maintain.
 */

type Message = {
  action: string
  params?: any
  __seq?: string
}

type MessageHandler = (
  message: Message,
  origin: string,
  source: MessageEventSource | null
) => void

const iframeEventListeners = new Map<
  Window | null,
  (event: MessageEvent) => void
>()
const iframeReadyStates = new Map<Window | null, boolean>()

export const sendMessageToIframe = (
  iframeID: string,
  message: Message,
  targetOrigin: string = "*",
  eventName: string = GLOBAL_MODAL_LOADED // Default event name to wait for
) => {
  const iframe = document.getElementById(iframeID)

  try {
    if (iframe instanceof HTMLIFrameElement) {
      const iframeWindow = iframe.contentWindow
      const send = (iw: Window | null) => iw?.postMessage(message, targetOrigin)

      if (iframeReadyStates.get(iframeWindow)) {
        // If iframe is already loaded, send the message right away
        send(iframeWindow)
      } else {
        // Otherwise, wait for the iframe to load before sending the message
        const checkMsgAndSend = (event: MessageEvent) => {
          if (event.source === iframeWindow && event.data === eventName) {
            iframeReadyStates.set(iframeWindow, true)
            send(iframeWindow)
            window.removeEventListener("message", checkMsgAndSend)
            iframeEventListeners.delete(iframeWindow)
          }
        }

        if (!iframeEventListeners.has(iframeWindow)) {
          window.addEventListener("message", checkMsgAndSend)
          iframeEventListeners.set(iframeWindow, checkMsgAndSend)
        }
      }
    } else {
      throw new Error(
        `Iframe with ID ${iframeID} not found or not an iframe element`
      )
    }
  } catch (error) {
    console.error("Error in sendMessageToIframe: ", error)
  }
}

export const sendMessageToParent = (
  message: any,
  targetOrigin: string = "*"
) => {
  window.parent.postMessage(message, targetOrigin)
}

export const receiveMessage = (handler: MessageHandler) => {
  const listener = (event: MessageEvent) => {
    handler(event.data, event.origin, event?.source)
  }

  window.addEventListener("message", listener)

  // Return a cleanup function
  return () => window.removeEventListener("message", listener)
}

// Custom hook for Preact
export const useIframeCommunication = (handler: MessageHandler) => {
  useEffect(() => {
    const cleanup = receiveMessage(handler)
    return cleanup
  }, [handler])
}

// Function to send a message from an iframe to the parent
export const sendMessageToParentWithId = (
  iframeID: string,
  message: Message,
  targetOrigin: string = "*"
) => {
  const msg = { iframeID, message }

  window.parent.postMessage(msg, targetOrigin)
}

// Function in the parent window to listen and forward messages to the target iframe
export const listenAndForwardMessage = () => {
  window.addEventListener("message", event => {
    // Add origin checks if necessary for security
    const { iframeID, message } = event.data

    const targetIframe = document.getElementById(iframeID) as HTMLIFrameElement
    if (targetIframe && targetIframe.contentWindow) {
      targetIframe.contentWindow.postMessage(message, event.origin)
    }
  })
}

// TODO: review and replace the existing one on the project
// Function to generate a unique identifier for each message
const generateUniqueSequence = (): string => {
  const randomPart = Math.random().toString(36).substring(2, 15)
  const timePart = Date.now().toString(36)
  return timePart + randomPart
}

/**
 * Post a message to an iframe with a promise
 * @param params Params to be sent to the iframe
 * @param win Window object
 * @param target Target window object
 * @param targetOrigin Target origin
 * @returns Promise
 *
 * Example:
 * postMessagePromise({
 *  params: { message: "Hello World" },
 *  win: window,
 *  target: iframe.contentWindow,
 *  targetOrigin: "*"
 * }).then((response) => {
 *  console.log(response)
 * })
 */

export type PostMsgPromiseArgs = {
  params: any
  win: Window
  target: Window
  targetOrigin: string
  action: string
}

/**
 * Sends a message to an iframe and returns a promise that resolves with the response.
 *
 * @param params - The parameters to send along with the message.
 * @param win - The window object.
 * @param target - The target iframe.
 * @param targetOrigin - The target origin. Defaults to "*".
 * @param action - The action to send. Defaults to "iframeMsgPromise".
 * @returns A promise that resolves with the response from the iframe.
 */
export const postMessagePromise = ({
  params,
  win,
  target,
  targetOrigin = "*",
  action = "iframeMsgPromise"
}: PostMsgPromiseArgs): Promise<any> => {
  return new Promise(resolve => {
    const seq = generateUniqueSequence() // ID to track messages responses

    const handleListener = (event: MessageEvent) => {
      if (event.data?.___seq === seq) {
        win.removeEventListener("message", handleListener)
        resolve(event.data.value)
      }
    }

    win.addEventListener("message", handleListener)

    target.postMessage({ action, params, ___seq: seq }, targetOrigin)
  })
}

/**
 * This will listen for messages on the iframe and call the callback function
 * @param win Window object
 * @action Action to listen for
 * @param callback Function to be called when a message is received
 * @returns void
 *
 * Example:
 *  startListening(window, (params) => {
 *   return new Promise((resolve, reject) => {
 *    // Do something with params
 *   resolve("Success")
 *  })
 * })
 */
export const startListeningForPromise = (
  win: Window,
  action: string = "iframeMsgPromise",
  callback: (params: any) => Promise<any>
) => {
  win.addEventListener("message", event => {
    if (event.data?.action === action && event.data.__seq) {
      const { params, __seq } = event.data
      callback(params).then(value => {
        event.source?.postMessage({ __seq, value })
      })
    }
  })
}
