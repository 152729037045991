import { h } from "preact"
import TxtLoading from "./Txt"
import SmallReviewItem from "./SmallReviewItem"
import "@styles/components/_review-block-skeleton.scss"

interface Props {
  cols?: 1 | 2 | 3 | 4 | 5 | 6
  className?: string
}

const ReviewBlockSkeleton = ({ cols = 3, className = "" }: Props) => (
  <div className={`review-block-loader ${className}`} data-items={cols}>
    {[...Array(cols)].map(() => (
      <div className="review-block-loader__item">
        <SmallReviewItem />

        <TxtLoading height={16} width="75%" margin="24px 0 8px" />
        <TxtLoading height={16} width="55%" />

        <TxtLoading
          height={16}
          width="25%"
          className="review-block-loader__footer"
        />
      </div>
    ))}
  </div>
)

export default ReviewBlockSkeleton
