import { h } from "preact"
import { AlertProps } from "./type"

const DEFAULT_SIZE = "md"

const Wrapper = ({
  children,
  className,
  id,
  size = DEFAULT_SIZE
}: AlertProps) => {
  const sizeVariants = {
    sm: "tw-py-1 tw-px-2 tw-text-sm tw-text-xs",
    md: "tw-py-3 tw-px-5 tw-text-sm",
    lg: "tw-py-3 tw-px-5 tw-text-xl"
  }

  return (
    <div
      {...(id ? { id } : {})}
      className={`tw-relative tw-mb-4 tw-border tw-rounded ${className} ${sizeVariants[size]}`}
      role="alert"
    >
      {children}
    </div>
  )
}

export default Wrapper
