import { Fragment, h } from "preact"
import { useMemo, useRef, useState } from "preact/hooks"
import { useDispatch, useSelector } from "react-redux"
import { PricingItem, RootStates, SortByOption } from "@types"
import { If } from "@ui"
import PricingScroll from "../PricingScroll"
import { dispatchRateTableNextEvent, openModalFromBtn } from "@helpers"
import RateTableRow from "../RateTableRow"
import { onRTSaveSelectedRow } from "@actions"
import { useAppContext } from "@contexts"
import { RateTableItemsSkeleton, RateTableTime } from "@ui"
import { useSortedCards } from "@hooks"
import { RateTableBanner } from "@composite"
import { RATE_TABLE_CARDS_MAX_RESULTS } from "@constants"
import * as Sentry from "@sentry/react"

const BANNER_POSITION = 2

interface Props {
  sortValue: SortByOption
}

const Table = ({ sortValue }: Props) => {
  const [bannerPosition, setBannerPosition] = useState(BANNER_POSITION)

  const dispatch = useDispatch()

  const eleRef = useRef(null)

  const {
    rateData,
    noResults,
    apiPriceCounter,
    isPricingLoading,
    max_results
  } = useSelector(({ rateTable }: RootStates) => {
    const apiPriceCounter = rateTable?.apiPriceCounter ?? 0

    return {
      apiPriceCounter,
      isPricingLoading: rateTable?.pricingLoading ?? false,
      noResults: rateTable?.rateData.length === 0 && apiPriceCounter === 0,
      rateData: rateTable?.rateData,
      max_results:
        rateTable?.rateTableConfig?.max_results ?? RATE_TABLE_CARDS_MAX_RESULTS
    }
  })

  const { sortCards, sortLoading } = useSortedCards(sortValue)

  const isLoading = isPricingLoading || sortLoading

  const {
    media: {
      flags: { isMobile, isDesktop }
    }
  } = useAppContext()

  const rates = useMemo(() => {
    if (noResults || !!isLoading) {
      return []
    }

    const newRates = rateData ?? []
    const newRatesLength = newRates?.length - 1

    const sortedCards = sortCards(newRates, sortValue)

    setBannerPosition(
      BANNER_POSITION > newRatesLength ? newRatesLength : BANNER_POSITION
    )

    const result = sortedCards.slice(0, max_results)

    if (!Array.isArray(result)) {
      Sentry.captureException(new Error("Rates is not an array"), {
        extra: {
          ratesValue: result,
          rateData,
          sortValue,
          max_results
        }
      })
    }

    return result
  }, [isLoading, rateData, sortValue, max_results])

  const showSubscriptionModal = (row: PricingItem) => {
    dispatchRateTableNextEvent()
    dispatch(onRTSaveSelectedRow(row))
    openModalFromBtn(
      `${location.origin}/external/rate_table_subscription_modal`,
      "rate-table-subscription"
    )
  }

  return (
    <If
      condition={!noResults && isDesktop && Array.isArray(rates)}
      then={
        <Fragment>
          <div className="pricing-rows" ref={eleRef}>
            {rates?.map((item, index) => (
              <Fragment>
                <RateTableRow
                  parentRef={eleRef.current}
                  key={`${item.hash}-${!!isDesktop}`}
                  item={item}
                  onClick={() => {
                    showSubscriptionModal(item)
                  }}
                />
                <If
                  condition={index === bannerPosition}
                  then={<RateTableBanner />}
                />
              </Fragment>
            ))}
          </div>
          {/* this loader is to show the remaining responses when "all" is selected */}
          <If
            condition={apiPriceCounter > 0}
            then={
              <RateTableItemsSkeleton rows={apiPriceCounter} pricingIsLoading />
            }
          />
          <RateTableTime />
        </Fragment>
      }
      elseCondition={!noResults && !isDesktop && Array.isArray(rates)}
      else={
        <Fragment>
          <PricingScroll
            className="tw-pb-4"
            type="rt"
            card_details={{ cards: rates }}
            isResponsive
            hideArrows={isMobile}
            onClick={(item: PricingItem) => {
              showSubscriptionModal(item)
            }}
          />
          <RateTableTime />
        </Fragment>
      }
    />
  )
}

export default Table
