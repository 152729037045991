import { h } from "preact"
import TxtLoading from "./Txt"

const Dropdown = ({ className = "" }) => (
  <div className={`p-2 ${className}`}>
    <TxtLoading height={14} width="80%" />
    <TxtLoading height={14} width="70%" className="mt-3" />
    <TxtLoading height={14} width="85%" className="mt-3" />
    <TxtLoading height={14} width="75%" className="mt-3" />
  </div>
)

export default Dropdown
