import { Fragment, h } from "preact"
import { css } from "emotion"
import styled from "preact-emotion"
import { If } from "@ui"

const loaderCSS = css`
  position: relative;
  display: inline-block;
  margin: 0 auto;
  transform: translateZ(0);
  width: 4em;
  height: 4em;
`
const LoaderWrapper = styled("div")`
  ${loaderCSS};
`

const loaderCSSCircle = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: center;
`

const loaderCSSCircleInner = css`
  animation: rotate-circle 0.5s linear infinite;
`

const LoaderCircleInner = styled("div")`
  ${loaderCSSCircle};
  ${loaderCSSCircleInner};
`

const loaderCSSCircleOuter = css`
  opacity: 0.3;
  animation: rotate-circle 1s linear infinite reverse;
`

const LoaderCircleOuter = styled("div")`
  ${loaderCSSCircle};
  ${loaderCSSCircleOuter};
`

const Loader = ({
  label = "",
  ...props
}: { label?: React.ReactNode } & { [key: string]: any }) => (
  <div {...props}>
    <LoaderWrapper
      className={`${
        props.classNamePrefix ? props.classNamePrefix : ""
      }loader is-circle`}
    >
      <LoaderCircleOuter
        className={`${
          props.classNamePrefix ? props.classNamePrefix : ""
        }loader__circle is-outer`}
      >
        <svg
          className="accent-fill-color"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="100%"
          height="100%"
        >
          <path d="M0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15a1 1 0 0 0 0 2c7.18 0 13 5.82 13 13s-5.82 13-13 13S2 22.18 2 15a1 1 0 0 0-2 0z"></path>
        </svg>
      </LoaderCircleOuter>
      <LoaderCircleInner
        className={`${
          props.classNamePrefix ? props.classNamePrefix : ""
        }loader__circle is-inner`}
      >
        <svg
          className="accent-fill-color"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="100%"
          height="100%"
        >
          <path d="M4 15c0 6.075 4.925 11 11 11s11-4.925 11-11S21.075 4 15 4a1 1 0 0 0 0 2 9 9 0 1 1-9 9 1 1 0 0 0-2 0z"></path>
        </svg>
      </LoaderCircleInner>
    </LoaderWrapper>
    <If
      condition={typeof label !== "string" || label.length > 0}
      then={<Fragment>{label}</Fragment>}
    />
  </div>
)

export default Loader
