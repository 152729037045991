import { h } from "preact"
import { Tooltip } from "@composite"
import { Button, If } from "@ui"
import clsx from "clsx"
import "@styles/components/_button-group.scss"

interface Option<T = string> {
  label: string
  value: T
}

interface Props<T = string> {
  label?: string
  value: T
  options: Option<T>[]
  handleClick: (value: T) => void
  tooltip?: string
  size?: "sm" | "md" | "lg" | "xl"
  className?: string
  hideLabel?: boolean
}

const ButtonGroup = <T extends string | number>({
  label,
  value,
  options,
  handleClick,
  tooltip,
  size = "sm",
  className = "",
  hideLabel = true
}: Props<T>) => {
  const onClick = (newValue: T) => {
    newValue !== value && handleClick(newValue)
  }

  const handleInteraction = (event: Event, newValue: T) => {
    if (
      (event as KeyboardEvent).type === "keydown" &&
      (event as KeyboardEvent).code !== "Enter"
    )
      return
    if (newValue !== value) handleClick(newValue)
  }

  return (
    <div className={`btn-group ${className}`}>
      <If
        condition={!!label}
        then={
          <span className={`btn-group__label ${hideLabel ? "tw-sr-only" : ""}`}>
            {label} {tooltip && <Tooltip>{tooltip}</Tooltip>}
          </span>
        }
      />

      <div className="btn-group__btns d-flex" aria-label={label}>
        {options.map(option => {
          const btnClassName = clsx("btn-group__btn", {
            "is-active hover:accent-bg-color": value === option.value
          })

          return (
            <Button
              key={option.value.toString()}
              adapt
              onKeyDown={e => handleInteraction(e, option.value)}
              onClick={e => handleInteraction(e, option.value)}
              {...(size ? { size: size } : {})}
              noShadow
              outline
              className={btnClassName}
              type="button"
              aria-pressed={value === option.value}
            >
              {option.label}
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default ButtonGroup
