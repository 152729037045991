import { h } from "preact"
import TxtLoading from "./Txt"
import StarLoader from "./Star"

interface Props {
  className?: string
}

const ReviewLineSkeleton = ({ className = "" }: Props) => (
  <div className={`tw-flex tw-items-center gap-sm ${className}`}>
    <TxtLoading height={16} width={24} />
    <StarLoader size={12} width="auto" />
    <TxtLoading height={16} width={64} />
  </div>
)

export default ReviewLineSkeleton
