import { h } from "preact"
import { InputMaskedFull } from "../../inputs"

interface Props {
  onChange: (value: string, name: Event) => void
  value?: number
  label?: string
  name?: string
  integerLimit?: number
}

const LoanAmount = ({
  onChange,
  value,
  label = "Mortgage Balance",
  name = "loan_amount",
  integerLimit = 8
}: Props) => (
  <InputMaskedFull
    integerLimit={integerLimit}
    isMaterial
    label={label}
    inputPrefix="$"
    required
    name={name}
    onChange={onChange}
    value={value}
    allowDecimal={false}
    showErrorWhenEmpty
  />
)

export default LoanAmount
