import { h } from "preact"
import starIcon from "@assets/icons/star.svg"
import Icon from "../Icon"

type Props = {
  rating: number
  hasShadow?: boolean
  className?: string
  color?: string
  bgColor?: string
  size?: number
  isAccentColor?: boolean
}

const DEFAULT_COLOR = "#F3B20A"
const DEFAULT_BG_COLOR = "#c5c5c5"
const DEDAULT_SIZE = 20
const DEFAULT_CLASSNAME = ""

const Rating = ({
  rating,
  hasShadow,
  className = DEFAULT_CLASSNAME,
  color = DEFAULT_COLOR,
  bgColor = DEFAULT_BG_COLOR,
  size = DEDAULT_SIZE,
  isAccentColor
}: Props) => (
  <div
    class={`rating ${className} ${hasShadow ? "has-shadow" : ""}`}
    style={{
      height: `${size}px`,
      color: bgColor
    }}
    aria-hidden="true"
  >
    <div
      class={`rating__top ${isAccentColor ? "accent-color" : ""}`}
      style={{
        width: `${(rating * 100) / 5}%`,
        color: `${isAccentColor ? "" : color}`
      }}
    >
      <div
        className="d-flex"
        style={{
          width: `${size * 5 + 16}px`
        }}
      >
        {[...Array(5)].map((_, i) => (
          <Icon
            key={i}
            src={starIcon}
            size={size}
            originalSize={16}
            className={i !== 0 ? "ml-1" : ""}
          />
        ))}
      </div>
    </div>
    <div class="ratings__bottom">
      <div className="d-flex">
        {[...Array(5)].map((_, i) => (
          <Icon
            key={i}
            src={starIcon}
            size={size}
            originalSize={16}
            className={i !== 0 ? "ml-1" : ""}
          />
        ))}
      </div>
    </div>
  </div>
)

export default Rating
