import { h } from "preact"
import TxtLoading from "./Txt"

const Skeleton = ({ className = "" }) => (
  <div
    className={`mortgage-calculator__loader d-md-flex justify-content-between ${className}`}
  >
    <div className="w-100">
      <TxtLoading height={50} width="55%" margin="0 0 24px !important" />
      <TxtLoading height={24} width="55%" />
      <TxtLoading height={24} width="90%" />
    </div>
    <div className="w-100">
      <TxtLoading height={25} width="100%" margin="0 0 24px !important" />
      <div className="d-md-flex justify-content-between">
        <TxtLoading height={24} width="55%" />
        <TxtLoading height={24} width="20%" />
      </div>
      <div className="d-md-flex justify-content-between">
        <TxtLoading height={24} width="45%" />
        <TxtLoading height={24} width="20%" />
      </div>
      <div className="d-md-flex justify-content-between">
        <TxtLoading height={24} width="35%" />
        <TxtLoading height={24} width="20%" />
      </div>
    </div>
  </div>
)

export default Skeleton
