import {
  CLOSE_GLOBAL_MODAL,
  CLOSE_LAST_ITEM_GLOBAL_MODAL,
  INIT_GLOBAL_MODAL,
  TOGGLE_GLOBAL_MODAL,
  UPDATE_EMBED_STORE_FROM_GLOBAL_MODAL,
  UPDATE_GLOBAL_MODAL_CONFIG
} from "@constants"
import {
  sendMessageToIframe,
  sendMessageToParent,
  sendMessageToParentWithId
} from "@iframeCommunication"
import {
  GlobalModalConfig,
  GlobalModalSubType,
  GlobalModalType,
  StoreKeys
} from "@types"

export const initGlobalModal = (
  config: GlobalModalConfig,
  iframeID: string
) => {
  sendMessageToIframe(iframeID, {
    action: INIT_GLOBAL_MODAL,
    params: { config }
  })
}
export const updateGlobalModalConfig = (
  config: GlobalModalConfig,
  iframeID: string
) => {
  sendMessageToIframe(iframeID, {
    action: UPDATE_GLOBAL_MODAL_CONFIG,
    params: { config }
  })
}

export const initGlobalModalFromIframe = (
  config: GlobalModalConfig,
  iframeID: string
) => {
  sendMessageToParentWithId(iframeID, {
    action: INIT_GLOBAL_MODAL,
    params: { config }
  })
}

export const openGlobalModalFromWithin = (
  type: GlobalModalType,
  subType: GlobalModalSubType,
  iframeID?: string
) => {
  // Tell the parent to open the modal
  sendMessageToParent({
    action: TOGGLE_GLOBAL_MODAL,
    params: { show: true, type, subType, iframeID }
  })
}

export const goBackGlobalModalFromWithin = (
  type: GlobalModalType,
  iframeID?: string
) => {
  // Tell the parent to close the modal
  sendMessageToParent({
    action: CLOSE_LAST_ITEM_GLOBAL_MODAL,
    params: { type, iframeID }
  })
}

export const closeGlobalModalFromWithin = () => {
  // Tell the parent to close the modal
  sendMessageToParent({
    action: CLOSE_GLOBAL_MODAL
  })
}

export const openGlobalModal = (
  type: GlobalModalType,
  subType: GlobalModalSubType,
  iframeID?: string
) => {
  const msg = {
    action: TOGGLE_GLOBAL_MODAL,
    params: { show: true, type, subType, iframeID }
  }

  window.postMessage(msg, "*")
}

export const closeGlobalModal = (type: GlobalModalType, iframeID?: string) => {
  const msg = {
    action: TOGGLE_GLOBAL_MODAL,
    params: { show: false, type, iframeID }
  }
  window.postMessage(msg, "*")
}

// Update parent store
export const updateEmbedStoreFromGlobalModal = (
  data: {
    store: StoreKeys
    data: any
  },
  iframeID: string
) => {
  const msg = {
    action: UPDATE_EMBED_STORE_FROM_GLOBAL_MODAL,
    params: data
  }

  sendMessageToParentWithId(iframeID, msg)
}
