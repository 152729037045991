import { h } from "preact"
import DatePicker from "../DatePicker"
import "@styles/components/_date-picker-range.scss"

interface Props {
  startValue: Date
  onStartChange: (date: Date) => void
  startMin?: Date
  startName?: string
  startLabel?: string
  endValue: Date
  onEndChange: (date: Date) => void
  endMax?: Date
  endName?: string
  endLabel?: string
  notStacked?: boolean
}

const DatePickerRange = ({
  startValue,
  onStartChange,
  startMin,
  startName = "startDate",
  startLabel = "Start date",
  endValue,
  onEndChange,
  endMax,
  endName = "endDate",
  endLabel = "End date",
  notStacked
}: Props) => {
  return (
    <div className="date-picker-range">
      <DatePicker
        min={startMin}
        label={startLabel}
        name={startName}
        value={startValue}
        onChange={onStartChange}
        max={endValue}
        notStacked={notStacked}
      />
      <DatePicker
        name={endName}
        label={endLabel}
        value={endValue}
        onChange={onEndChange}
        max={endMax ?? new Date()}
        min={startValue}
        notStacked={notStacked}
      />
    </div>
  )
}

export default DatePickerRange
