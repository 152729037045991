import { useDispatch, useSelector } from "react-redux"
import { PricingItem, RootStates } from "@types"
import {
  calcHomeInsurance,
  calcPropertyTax,
  getStorage,
  mapLoanProperties
} from "@helpers"
import { useState } from "preact/hooks"
import { onMortgageProfileSelect, updateMortgageCalc } from "@actions"
import { apiFactory } from "../components/composite/Mortgage/utils"
import { ONE_DAY_IN_MILLISECONDS } from "@constants"
import { updateEmbedStoreFromGlobalModal } from "@iframeCommunication/utils"

interface Params {
  defaultValues?: any
}

const useMortgageForm = ({ defaultValues }: Params) => {
  const dispatch = useDispatch()

  const [defaultStateLcl, setDefaultStateLcl] = useState()

  const {
    fullState,
    zipcode,
    app_key,
    defaultsFromURL,
    nmls,
    iframeID,
    isGlobalModal,
    isAPI
  } = useSelector(({ mortgageCalc, app }: RootStates) => {
    return {
      app_key: app?.app_key,
      isGlobalModal: app?.isGlobalModal,
      isAPI: app?.type === "api",
      iframeID: `${app?.wrapperID}-${app?.type}-${app?.app_key}`,
      zipcode: app?.zipcode ?? "",
      fullState: mortgageCalc,
      nmls: app?.nmls,
      defaultsFromURL: app?.defaults ?? {}
    }
  })

  const handleUpdateMortgageCalc = (payload: any) => {
    if (isGlobalModal) {
      updateEmbedStoreFromGlobalModal(
        { store: "mortgageCalc", data: payload },
        iframeID
      )
    }

    dispatch(updateMortgageCalc(payload))
  }

  const api = apiFactory(app_key, nmls)

  const handleDefaults = async () => {
    const storedState = getStorage(`mortgage-${app_key}`) ?? {}

    // if we have defaultValues, we use them
    const response =
      defaultValues && !isAPI
        ? await Promise.resolve(defaultValues)
        : await api.getDefaultValues()

    let defaultAndStoredValues = response

    if (storedState?.state && !isAPI) {
      const isUpdatedWithin15Days =
        Date.now() - storedState.state.updated < 15 * ONE_DAY_IN_MILLISECONDS

      if (isUpdatedWithin15Days) {
        defaultAndStoredValues = {
          ...response,
          ...storedState.state,
          isStateStored: !!storedState.state
        }
      }
    }
    return defaultAndStoredValues
  }

  const setForm = async () => {
    try {
      let defaults = await handleDefaults()

      if (!defaults?.isStateStored) {
        // defaultsFromURL is the defaults from the URL on main embed page, we use them in case is a modal
        defaults = { ...defaults, ...defaultsFromURL }

        defaults = mapLoanProperties(defaults)

        let newState: {
          propertyTaxAmount?: number
          homeInsurance?: number
          downPaymentMax?: number
        } = {}

        newState.propertyTaxAmount = calcPropertyTax({
          homePrice: defaults.homePrice,
          propertyTaxPercentage: defaults.propertyTaxPercentage
        })
        newState.homeInsurance = calcHomeInsurance({
          homePrice: defaults.homePrice,
          homeInsurancePercentage: defaults.homeInsurancePercentage
        })

        newState.downPaymentMax = 0.75 * defaults.homePrice

        if (!defaults.loan_type) {
          defaults.loan_type = "conventional"
        }

        defaults = { ...defaults, ...newState }

        defaults.zipcode = zipcode ? zipcode : defaults.zipcode ?? ""

        if (defaultsFromURL?.rate) {
          defaults.interestRate = defaultsFromURL?.rate
        } else if (fullState?.card_details?.cards?.[0].rate) {
          defaults.interestRate = fullState.card_details.cards[0].rate
        }

        setDefaultStateLcl(defaults)

        const data = await api.getLoanCalc(defaults)

        const { card_details, pi, mortgageInsurance, taxes } = data as any

        defaults.pi = pi
        defaults.mortgageInsurance = mortgageInsurance
        defaults.propertyTaxAmount = taxes
        defaults.propertyTaxPercentage =
          ((taxes * 12) / defaults.homePrice) * 100

        defaults.apr = defaults.interestRate

        // If there is a rate set by the URL, we set the rate to the card's rate
        if (defaultsFromURL?.rate) {
          defaults.interestRate = defaultsFromURL.rate

          // filter the cards to find the one with the rate
          const card = card_details.cards.find(
            (card: PricingItem) => card.rate === defaultsFromURL.rate
          )
          // and if we find the card, we set the apr to the card's apr
          if (card) {
            defaults.apr = card.apr
            dispatch(onMortgageProfileSelect(card))
          } else {
            defaults.apr = defaultsFromURL?.rate
          }
        } else if (card_details.cards?.[0].rate) {
          dispatch(onMortgageProfileSelect(card_details.cards?.[0]))
          defaults.interestRate = card_details.cards[0].rate
          defaults.apr = card_details.cards[0].apr
        }

        defaults.card_details = card_details.cards ? card_details : {}
      } else {
        setDefaultStateLcl(defaults)
      }
      handleUpdateMortgageCalc({
        ...defaults,
        app_key,
        isLoading: false,
        showChartLoading: false
      })
    } catch (error) {
      console.error("Error handling mortgage form:", error)
    }
  }

  return { defaultStateLcl, handleUpdateMortgageCalc, setForm }
}

export default useMortgageForm
