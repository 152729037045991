import { h } from "preact"
import { Button } from "~/components"

const FooterBtns = ({
  disabledNext,
  disabledBack,
  labelNext = "Next",
  labelBack = "Back",
  className = "",
  onClickBack,
  onClickContinue
}) =>
  onClickBack || onClickContinue ? (
    <div
      className={`wizard__buttons d-flex justify-content-center align-items-center ${className}`}
    >
      {onClickBack && (
        <Button
          className="wizard__nxt-btn"
          disabled={disabledBack}
          onClick={onClickBack}
          label={labelBack}
          noShadow
          pill
          size="md"
        />
      )}
      {onClickContinue && (
        <Button
          className="wizard__nxt-btn"
          disabled={disabledNext}
          onClick={onClickContinue}
          label={labelNext}
          noShadow
          pill
          size="md"
        />
      )}
    </div>
  ) : null

export default FooterBtns
