import { h } from "preact"
import { numberToUSD } from "@helpers"
import { If } from "@ui"
import LenderFeesTooltip from "../../LenderFeesTooltip"
import { useIsMobile } from "@contexts"

interface Props {
  fees: number
}

const Fees = ({ fees }: Props) => {
  const isMobile = useIsMobile()
  return (
    <strong>
      {numberToUSD(fees, true)}
      <If condition={!isMobile} then={<LenderFeesTooltip />} />
    </strong>
  )
}

export default Fees
