import { h } from "preact"
import Form from "../Form"
import Alert from "../Alert"

const CaptchaInvalidMsg = () => (
  <Form.Row>
    <Form.Col>
      <Alert.Danger className="tw-w-full" id="captcha-msg">
        Captcha failed, please verify that you are a human.
      </Alert.Danger>
    </Form.Col>
  </Form.Row>
)

export default CaptchaInvalidMsg
