import { useState } from "preact/hooks"
import { cleanUSPhone, validatePhoneFormat } from "@helpers"
import { contactValidationAPI } from "../api"

interface Params {
  onValidationError?: (error: string) => void
  onValidationStart?: () => void
  onValidationSuccess?: () => void
  APIValidationEnabled?: boolean
}

const usePhoneNumberInput = ({
  onValidationError,
  onValidationStart,
  onValidationSuccess,
  APIValidationEnabled
}: Params) => {
  const [notValid, setNotValid] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [validatedPhone, setValidatedPhone] = useState("")

  const successHandler = () => {
    setNotValid(false)
    onValidationSuccess?.()
  }

  const handleValidation = async (value: string) => {
    const cleanValue = cleanUSPhone(value)

    setValidatedPhone(cleanValue)
    const invalidPhoneNumberTmp = !validatePhoneFormat(cleanValue)

    if (invalidPhoneNumberTmp) {
      onValidationError?.("Please enter a valid phone number")
      setNotValid(true)
    } else if (!APIValidationEnabled) {
      successHandler()
    } else if (cleanValue !== validatedPhone) {
      onValidationStart?.()
      setIsValidating(true)
      try {
        const response = await contactValidationAPI.phone(cleanValue)
        if (response.status === "error") {
          onValidationError?.(response.message)
          setNotValid(true)
        } else {
          successHandler()
        }
        setIsValidating(false)
      } catch (error) {
        console.log(error)
        setIsValidating(false)
        onValidationError?.("An error occurred with your phone validation")
      }
    } else if (notValid) {
      onValidationError?.("Please enter a valid phone number")
    }
  }

  return { handleValidation, isValidating }
}

export default usePhoneNumberInput
