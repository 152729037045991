import { h } from "preact"
import { RootStates } from "@types"
import { useSelector } from "react-redux"
import { If, Icon, ScreenReaderOnly } from "@ui"
import { useIsMobile } from "@contexts"
import FB_ICON from "@assets/icons/social/facebook.svg"
import IN_ICON from "@assets/icons/social/linkedin.svg"
import INSTA_ICON from "@assets/icons/social/instagram.svg"

interface Props {
  className?: string
}

const LOSocialLinks = ({ className = "" }: Props) => {
  const { fbLink, instaLink, inLink } = useSelector(({ app }: RootStates) => {
    return {
      fbLink: app?.lo?.facebook_link,
      instaLink: app?.lo?.instagram_link,
      inLink: app?.lo?.linkedin_link
    }
  })
  const isMobile = useIsMobile()

  const iconSize = isMobile ? 48 : 65
  const iconOriginalSize = 65

  return (
    <div className={`tw-flex tw-items-center gap-base tw-h-16 ${className}`}>
      <If
        condition={!!fbLink}
        then={
          <a
            href={fbLink}
            target="_top"
            className="hover:tw-shadow-md tw-rounded-3xl tw-transition-shadow tw-shadow-none"
          >
            <ScreenReaderOnly>Facebook</ScreenReaderOnly>
            <Icon
              src={FB_ICON}
              size={iconSize}
              originalSize={iconOriginalSize}
            />
          </a>
        }
      />
      <If
        condition={!!instaLink}
        then={
          <a
            href={instaLink}
            target="_top"
            className="hover:tw-shadow-md tw-rounded-3xl tw-transition-shadow tw-shadow-none"
          >
            <ScreenReaderOnly>Instagram</ScreenReaderOnly>
            <Icon
              src={INSTA_ICON}
              size={iconSize}
              originalSize={iconOriginalSize}
            />
          </a>
        }
      />
      <If
        condition={!!inLink}
        then={
          <a
            href={inLink}
            target="_top"
            className="hover:tw-shadow-md tw-rounded-3xl tw-transition-shadow tw-shadow-none"
          >
            <ScreenReaderOnly>LinkedIn</ScreenReaderOnly>
            <Icon
              src={IN_ICON}
              size={iconSize}
              originalSize={iconOriginalSize}
            />
          </a>
        }
      />
    </div>
  )
}

export default LOSocialLinks
