import { h } from "preact"
import { Form } from "@ui"

interface Props {
  name: string
  value: string
  onInput: (value: string, name: string) => void
  label: string
  required: boolean
}

const DynamicFormInput = ({ name, value, onInput, label, required }: Props) => {
  const handleOnInput = (e: Event) => {
    onInput((e.target as HTMLInputElement).value, name)
  }

  return (
    <Form.Row>
      <Form.Col>
        <Form.Email
          name={name}
          value={value}
          onInput={handleOnInput}
          label={label}
          required={required}
          isMaterial
        />
      </Form.Col>
    </Form.Row>
  )
}

export default DynamicFormInput
