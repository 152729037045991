import { useMemo } from "preact/hooks"
import { FeeItem, PricingItem } from "@types"
import { calcTotalFeeAmount } from "@helpers"

const useLenderFees = (item: PricingItem) => {
  const lenderFees = useMemo(() => {
    const itemFeeList = Array.isArray(item?.feeList) ? item.feeList : []
    const feeList: FeeItem[] = [
      {
        label: `Points ${item.pts}`,
        amount: Math.sign(item.pts) * (item.fee?.amount ?? 0),
        type: ""
      },
      ...itemFeeList.filter(
        fee => fee?.type !== "ThirdParty" && fee?.type !== "State"
      )
    ]

    if (item.pts === 0 && item.fee?.amount) {
      feeList.push({
        label: item.fee.title,
        amount: item.fee.amount,
        type: ""
      })
    }

    return calcTotalFeeAmount(feeList)
  }, [item])

  return lenderFees
}

export default useLenderFees
