import { useDispatch, useSelector } from "react-redux"
import { RootStates } from "@types"
import { onRTSaveSelectedRow } from "@actions"
import { dispatchRateTableSwitchClickEvent } from "@helpers"
import { openSubscriptionModalFromWithin } from "@iframeCommunication/utils"

const useRateTableToggleSubscription = () => {
  const dispatch = useDispatch()

  const { subscribed, app } = useSelector(({ rateTable, app }: RootStates) => ({
    subscribed: rateTable?.subscribed ?? false,
    app
  }))

  const showSubscriptionModal = () => {
    dispatch(onRTSaveSelectedRow(null))
    const iframeID = `bb-global-modal-${app.wrapperID}-${app.type}-${app.app_key}`
    openSubscriptionModalFromWithin(iframeID)
  }

  const updateSubscription = (checked: boolean) => {
    if (checked) {
      dispatchRateTableSwitchClickEvent() // send event
      showSubscriptionModal()
    }
  }

  return { subscribed, updateSubscription }
}

export default useRateTableToggleSubscription
