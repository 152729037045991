import { h } from "preact"
import { TxtLoading } from "../../.."

const Skeleton = ({ className = "" }) => (
  <div
    className={`mortgage-calculator__loader d-md-flex justify-content-between ${className}`}
  >
    <div className="w-100">
      <TxtLoading height={50} width="55%" margin="0 0 24px !important" />
      <TxtLoading height={24} width="55%" />
      <TxtLoading height={24} width="90%" />
    </div>
    <div className="w-100">
      <div className="d-md-flex gap-base">
        <div className="w-50">
          <TxtLoading height={24} width="50%" />
        </div>
        <div className="w-50">
          <TxtLoading height={24} width="50%" />
        </div>
      </div>
      <div className="d-md-flex mb-3 gap-base">
        <div className="w-50">
          <TxtLoading height={24} />
        </div>
        <div className="w-50">
          <TxtLoading height={24} />
        </div>
      </div>
      <div className="d-md-flex gap-base">
        <div className="w-50">
          <TxtLoading height={24} width="50%" />
        </div>
        <div className="w-50">
          <TxtLoading height={24} width="50%" />
        </div>
      </div>
      <div className="d-md-flex mb-3 gap-base">
        <div className="w-50">
          <TxtLoading height={24} />
        </div>
        <div className="w-50">
          <TxtLoading height={24} />
        </div>
      </div>
    </div>
  </div>
)

export default Skeleton
