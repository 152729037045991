import { h } from "preact"
import { numberWithCommas, numberToUSD, toFixed } from "@helpers"
import { useGetTermLabel } from "@hooks"
import { PricingItem } from "@types"

interface Props {
  item: PricingItem
  fees: number
}

const PricingItemBodyRTM = ({ item, fees }: Props) => {
  const term = useGetTermLabel(item)

  return (
    <div className="pricing-item__body">
      <div className="pricing-item__body--title tw-uppercase">
        Selected rate
      </div>
      <div className="pricing-item__body--main">
        <span>Rate/APR: </span>
        {toFixed(item.rate, 3)}% / {toFixed(item.apr)}%
      </div>
      <div className="pricing-item__body-inner">
        <div className="pricing-item__details is-term">{term}</div>
        <div className="pricing-item__dot">·</div>
        <div className="pricing-item__details is-points">
          {toFixed(item.pts, 2, true)} Points
        </div>
        <div className="pricing-item__dot">·</div>
        <div className="pricing-item__details is-fees">
          Fees {fees < 0 ? "-" : ""}${numberWithCommas(Math.abs(fees))}
        </div>
        <div className="pricing-item__dot">·</div>
        <div className="pricing-item__details is-tp">
          P&I {numberToUSD(item.principalAndInterest, true)}
          /mo
        </div>
      </div>
    </div>
  )
}

export default PricingItemBodyRTM
