import { h } from "preact"
import { useSelector } from "react-redux"
import { RootStates } from "@types"
import { useAppContext } from "@contexts"
import { If, LogoLinkBtn, RowList } from "@ui"
import FooterReviews from "../FooterReviews"

const items = [
  "No social or date of birth",
  "Access to live rates",
  "Compare multiple loan options"
]
const itemsMobile = ["No credit check", "Access to live rates"]

const itemsPriceDisabled = [
  "No social or date of birth",
  "Submit in 45 seconds",
  "Personalized service"
]

const itemsPriceDisabledMobile = ["No credit check", "Submit in 45 seconds"]

interface StepFooterProps {
  currentStep: number
  className?: string
  visible: boolean
  sm?: boolean
}

const StepFooter = ({
  currentStep,
  className = "",
  visible,
  sm
}: StepFooterProps) => {
  const disable_pricing = useSelector(
    ({ wizard }: RootStates) => !!wizard?.disable_pricing
  )

  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  const itemsToUse = disable_pricing
    ? isMobile
      ? itemsPriceDisabledMobile
      : itemsPriceDisabled
    : isMobile
    ? itemsMobile
    : items

  return (
    <If
      condition={visible}
      then={
        <div
          className={`wizard__step-footer tw-relative ${className} ${
            currentStep === 0 ? "is-dark" : ""
          }`}
        >
          <If
            condition={currentStep === 0}
            then={
              <div className="d-md-flex wizard__benefits">
                <RowList className="wizard__benefits-list" items={itemsToUse} />
              </div>
            }
          />

          <div
            className={`wizard__step-footer-reviews ${
              currentStep === 0 ? "is-dark" : ""
            }`}
          >
            <FooterReviews
              center={!!sm}
              className={!!sm ? "tw-w-full" : ""}
              isDarkMode={currentStep === 0}
            />

            <If
              condition={!sm}
              then={
                <LogoLinkBtn
                  onlyLogo={isMobile}
                  className="wizard__step-footer-logo"
                  size="sm"
                />
              }
            />
          </div>
        </div>
      }
    />
  )
}
export default StepFooter
