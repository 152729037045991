import { h } from "preact"
import Chevron from "../Chevron"
import { ScreenReaderOnly } from "@ui"

interface ArrowsProps {
  scrollToPrev: () => void
  scrollToNext: () => void
  atStart: boolean
  atEnd: boolean
  inMiddle?: boolean
}

const Arrows = ({
  scrollToPrev,
  scrollToNext,
  atStart,
  atEnd,
  inMiddle
}: ArrowsProps) => (
  <div className={`simple-slides__arrows ${inMiddle ? "in-middle" : ""}`}>
    <button
      type="button"
      className="simple-slides__arrow is-prev"
      onClick={scrollToPrev}
      disabled={atStart}
      aria-disabled={atStart}
      aria-label="Previous"
      tabIndex={atStart ? -1 : 0}
    >
      <ScreenReaderOnly>Prev</ScreenReaderOnly>
      <Chevron size={18} side="left" />
    </button>

    <button
      type="button"
      className="simple-slides__arrow is-next"
      onClick={scrollToNext}
      disabled={atEnd}
      aria-disabled={atEnd}
      aria-label="Next"
      tabIndex={atEnd ? -1 : 0}
    >
      <ScreenReaderOnly>Next</ScreenReaderOnly>
      <Chevron size={18} side="right" />
    </button>
  </div>
)

export default Arrows
