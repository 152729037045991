import { h } from "preact"
import { SortByOption } from "@types"
import { SORT_BY_OPTIONS } from "@constants"
import { Tooltip } from "@composite"
import { If, Form } from "@ui"
import sortIcon from "@assets/icons/sort.svg"
import "@styles/components/_sort-by.scss"

const NAME = "sortBy"
const LABEL = "Sort By:"
const PLACEHOLDER = "SORT BY"

interface Props {
  className?: string
  isIcon?: boolean
  value?: SortByOption
  onChange: (value: SortByOption) => void
}

const SortBy = ({
  value = SORT_BY_OPTIONS[0].value,
  className = "",
  onChange,
  isIcon = false
}: Props) => {
  const handleSortChange = (value: SortByOption) => {
    onChange(value)
  }

  return (
    <div className={`sort-by tw-relative tw-mt-4 md:tw-mt-6 ${className}`}>
      <If
        condition={isIcon}
        then={
          <Tooltip
            ttPlacement="top"
            small
            eventTrigger="hover"
            reference={
              <Form.IconDropDown<SortByOption>
                name={NAME}
                label={LABEL}
                placeholder={PLACEHOLDER}
                options={SORT_BY_OPTIONS}
                value={value}
                onChange={handleSortChange}
                icon={sortIcon}
              />
            }
          >
            Sort by
          </Tooltip>
        }
        else={
          <Form.SelectCustom
            optionsClassName="tw-max-w-fit"
            popperPlacement="bottom-end"
            inputAutoWidth
            transparent
            notStacked
            name={NAME}
            label={LABEL}
            options={SORT_BY_OPTIONS}
            value={value}
            onChange={option => {
              handleSortChange(option.value)
            }}
          />
        }
      />
    </div>
  )
}

export default SortBy
