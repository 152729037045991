import { Fragment, h } from "preact"
import { useState, useRef } from "preact/hooks"
import { useSelector, useDispatch } from "react-redux"
import { GoogleLoginResponse, RootStates } from "@types"
import { sendForm } from "@actions"
import {
  eventDispatch,
  gtagGoogleLoginClicked,
  gtagGoogleLoginSuccess
} from "@helpers"
import { Alert, Button, Form, GoogleLoginBtn, If } from "@ui"
import { ChangeEvent } from "preact/compat"

const EMBED_TYPE = "lead_workflow"

interface Props {
  invalidEmail: boolean
  forceFinish: () => void
}

const ExitCtaForm = ({ invalidEmail, forceFinish }: Props) => {
  const [disclaimerChecked, setDisclaimerChecked] = useState(false)
  const [emailError, setInvalidEmail] = useState(false)
  const [invalidFields, setInvalidFields] = useState(false)
  const [invalidDisclaimer, setInvalidDisclaimer] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [doValidation, setDoValidation] = useState(false)
  const [socialLoginDone, setSocialLoginDone] = useState(false)
  const [formData, setFormData] = useState({
    validated: false,
    email: "",
    first_name: "",
    last_name: ""
  })

  const dispatch = useDispatch()

  const {
    disclaimer,
    background_validation_enabled,
    google_login_enabled,
    jornayaId
  } = useSelector(({ wizard, app }: RootStates) => ({
    background_validation_enabled: wizard?.background_validation_enabled,
    disclaimer: wizard?.disclaimers?.lead_verification || "",
    google_login_enabled: wizard?.google_login_enabled ?? false,
    jornayaId: app?.jornayaId
  }))

  const inputEmail = useRef<HTMLInputElement>(null)

  const handleSubmit = (e: Event) => {
    e.preventDefault()
    if (!disclaimerChecked) {
      setInvalidDisclaimer(true)
      return
    }
    setDoValidation(true)
  }

  const onInput = (e: Event) => {
    const target = e.target as HTMLInputElement

    setFormData({
      ...formData,
      [target.name]: target.value.trim(),
      validated: target.name === "email" ? false : formData.validated
    })
  }

  const doSubmit = () => {
    if (isValidating) {
      return
    }

    if (invalidEmail) {
      inputEmail?.current?.focus()
      return
    }

    const params = { ...formData }

    if (!params.email || !params.first_name || !params.last_name) {
      setInvalidFields(true)
      return
    }

    dispatch(sendForm({ values: params, onSentCallback: forceFinish }))

    eventDispatch({
      name: "lead_workflow_exit_cta_submit",
      category: "lead_workflow"
    })
  }

  const onGoogleLoginSuccess = (response: GoogleLoginResponse) => {
    setSocialLoginDone(true)
    gtagGoogleLoginSuccess(EMBED_TYPE)

    setFormData({
      validated: true,
      email: response.email,
      first_name: response.given_name?.trim() ?? "",
      last_name: response.family_name?.trim() ?? ""
    })
  }

  const onDisclaimerChange = (e: ChangeEvent) => {
    const checked = (e.target as HTMLInputElement).checked
    setDisclaimerChecked(checked)
    if (checked) {
      setInvalidDisclaimer(false)
    }
  }

  const onGoogleLoginAttempt = () => {
    gtagGoogleLoginClicked(EMBED_TYPE)
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit} className="mt-5">
        <Form.Row twoCol>
          <Form.Col>
            <Form.Input
              isSpecial
              isMaterial
              size="lg"
              name="first_name"
              type="text"
              value={formData.first_name}
              onInput={onInput}
              onBlur={onInput}
              onFocus={() => {
                setInvalidFields(false)
              }}
              label="First name"
              placeholder="First name"
              aria-describedby="first_name"
              required
            />
          </Form.Col>
          <Form.Col>
            <Form.Input
              isSpecial
              isMaterial
              size="lg"
              name="last_name"
              type="text"
              value={formData.last_name}
              onInput={onInput}
              onBlur={onInput}
              onFocus={() => {
                setInvalidFields(false)
              }}
              label="Last name"
              placeholder="Last name"
              aria-describedby="last_name"
              required
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Form.Email
              APIValidationEnabled={background_validation_enabled}
              triggerValidation={doValidation}
              valiateOnBlur={false}
              isSpecial
              isMaterial
              size="lg"
              className={`${emailError ? "is-invalid" : ""}`}
              value={formData.email}
              onInput={onInput}
              onValidationError={() => {
                setIsValidating(false)
                setInvalidEmail(true)
              }}
              onValidationSuccess={() => {
                doSubmit()
                setIsValidating(false)
                setInvalidEmail(false)
              }}
              onValidationStart={() => {
                setDoValidation(false)
                setIsValidating(true)
              }}
              name="email"
              label="Email address"
              placeholder="Email address"
              required
            />
          </Form.Col>
        </Form.Row>

        <If
          condition={disclaimer.length > 0}
          then={
            <Form.Disclaimer
              checked={disclaimerChecked}
              onChange={onDisclaimerChange}
              label={disclaimer}
            />
          }
        />

        <If
          condition={invalidFields}
          then={
            <Alert.Danger id="email" className="wizard__form-alert">
              Please review the form fields.
            </Alert.Danger>
          }
        />

        <If
          condition={invalidDisclaimer}
          then={
            <Alert.Danger size="sm">
              Please check the box and accept to submit.
            </Alert.Danger>
          }
        />

        <Form.Row>
          <Form.Col>
            <Button type="submit" noShadow pill label="Create my dashboard" />
          </Form.Col>
        </Form.Row>
      </form>

      <If
        condition={google_login_enabled && !socialLoginDone}
        then={
          <Fragment>
            <span className="wizard__sl-separator is-light my-4">or</span>
            <GoogleLoginBtn
              theme="light"
              onSuccess={onGoogleLoginSuccess}
              onLoginAttempt={onGoogleLoginAttempt}
            />
          </Fragment>
        }
      />
    </Fragment>
  )
}

export default ExitCtaForm
