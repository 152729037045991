import { h } from "preact"
import { AFFORD_CALC_TXT } from "../../../../content"
import { useAppContext } from "@contexts"
import { Form } from "@ui"

const TOOLTIP_TXT = AFFORD_CALC_TXT.tooltip

interface Props {
  handleInputChange: (
    value: string | number,
    {
      target: { name }
    }: { target: { name: string } },
    calculate: boolean
  ) => void
  handleChange: (value: string, name: string, isComplete?: boolean) => void
  location: string
  annual_income: number
  monthly_debt: number
  down_payment: number
  loan_type: number
  interest_rate: number
  zipcodePlcHolder: string
}

const CalcFields = ({
  handleInputChange,
  handleChange,
  location,
  annual_income,
  monthly_debt,
  down_payment,
  loan_type,
  interest_rate,
  zipcodePlcHolder
}: Props) => {
  const {
    media: {
      flags: { isDesktop }
    }
  } = useAppContext()

  const handlePlaceSelected = (placeObj: any) => {
    handleInputChange(
      placeObj,
      {
        target: { name: "locationObj" }
      },
      true
    )
  }

  return (
    <div className="afford-calc__fields">
      <Form.Row twoCol={isDesktop}>
        <Form.Col>
          <Form.AutoZipcode
            className="afford-calc__places"
            size="lg"
            onChange={handlePlaceSelected}
            initialValue={location || zipcodePlcHolder}
          />
        </Form.Col>
        <Form.Col>
          <Form.InterestRate
            handleChange={handleChange}
            interest_rate={interest_rate}
            loan_type={loan_type}
          />
        </Form.Col>
      </Form.Row>

      <div className="afford-calc__field is-slider">
        <div class="form__field">
          <Form.InputRangeGroupMasked
            inputAutoWidth
            classNameInput="mortgage-calculator__input"
            classNameLabel="form__label"
            tt={TOOLTIP_TXT.annualIncome}
            label="Annual income"
            name="annual_income"
            value={annual_income}
            min={30000}
            max={400000}
            step={10000}
            onChange={(value: string) => {
              handleChange(value, "annual_income")
            }}
            onChangeComplete={(value: string) => {
              handleChange(value, "annual_income", true)
            }}
          />
        </div>
      </div>
      <div className="afford-calc__field is-slider">
        <div class="form__field">
          <Form.InputRangeGroupMasked
            inputAutoWidth
            classNameInput="mortgage-calculator__input"
            classNameLabel="form__label"
            tt={TOOLTIP_TXT.monthlyDebt}
            label="Monthly debt"
            name="monthly_debt"
            value={monthly_debt}
            min={0}
            max={7500}
            step={500}
            onChange={(value: string) => {
              handleChange(value, "monthly_debt")
            }}
            onChangeComplete={(value: string) => {
              handleChange(value, "monthly_debt", true)
            }}
          />
        </div>
      </div>
      <div className="afford-calc__field is-slider">
        <div class="form__field">
          <Form.InputRangeGroupMasked
            inputAutoWidth
            classNameInput="mortgage-calculator__input"
            classNameLabel="form__label"
            tt={TOOLTIP_TXT.downPayment}
            label="Down payment"
            name="down_payment"
            value={down_payment}
            min={10000}
            max={250000}
            step={5000}
            onChange={(value: string) => {
              handleChange(value, "down_payment")
            }}
            onChangeComplete={(value: string) => {
              handleChange(value, "down_payment", true)
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default CalcFields
