import { createContext } from "preact"
import { MobileLoanDetailsFieldsFormContextProps } from "@types"
import { useContext } from "preact/hooks"

export const MobileLoanDetailsFieldsFormContext = createContext<
  MobileLoanDetailsFieldsFormContextProps | undefined
>(undefined)

export const useMobileLoanDetailsFieldsFormContext =
  (): MobileLoanDetailsFieldsFormContextProps => {
    const context = useContext(MobileLoanDetailsFieldsFormContext)
    if (!context) {
      throw new Error("useMyContext must be used within a MyContextProvider")
    }
    return context
  }
