import { h } from "preact"
import { FIELD_TYPE, FIELD_TYPES } from "@types"
import LegendItem from "./LegendItem"
import { LINE_CHART_FIELDS, LINE_CHART_FIELDS_KEYS } from "@constants"

interface Props {
  pi: number
  homeInsurance: number
  propertyTaxAmount: number
  monthly_debt: number
  mortgageInsurance: number | string
  hoaDues: number
  includePMI?: boolean
  includeHOA?: boolean
  isVertical?: boolean
  editableFields?: FIELD_TYPES
  onEdit?: (value: number | string, fieldName: FIELD_TYPE) => void
}

const ChartLegend = (props: Props) => {
  const {
    includePMI = false,
    includeHOA = false,
    isVertical,
    editableFields = [],
    onEdit
  } = props

  const liClassName = `linear-chart__label align-items-center ${
    isVertical ? "is-vertical" : ""
  }`

  const shouldSkipField = (field: string) => {
    if (!includePMI && field === "mortgageInsurance") return true
    if (!includeHOA && field === "hoaDues") return true
    if (field === "monthly_debt" && Number(props[field]) < 1) return true

    return false
  }

  return (
    <ul
      className={`linear-chart__labels ${isVertical ? "mb-4 is-vertical" : ""}`}
    >
      {LINE_CHART_FIELDS_KEYS.map(field => {
        if (shouldSkipField(field)) {
          return null
        }
        return (
          <LegendItem
            key={field}
            className={liClassName}
            label={LINE_CHART_FIELDS[field]}
            isEditable={editableFields.includes(field)}
            value={props[field]}
            onEdit={onEdit}
            name={field}
          />
        )
      })}
    </ul>
  )
}

export default ChartLegend
