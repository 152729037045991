// This file tries to contain all the texts of the mortgage calculator

export default {
  form: {
    helpers: {
      down_payment:
        "Your payment includes mortgage insurance because your payment is less than 20%. Remove in more options.",
      down_payment_issue: "High list price requires at least 20% down payment.",
      down_payment_max:
        "If loan amount is above $%s then 20% down payment may be required."
    }
  },
  tooltip: {
    chart:
      "This calculator is intended for planning and education purposes only. The above figures are based on the numbers shown on the payment calculator. The interest rate, APR, and payment amount may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors. Chart data is for illustrative purposes only. Costs displayed here are not necessarily all inclusive and can vary based on numerous factors.  Rates are subject to change without notice. The output of the tool is not a loan offer or solicitation, nor is it financial or legal advice and should not be used as your sole source of information.",
    prop_taxes:
      "This tax is a percentage of a home's assessed value and varies by area. Homeowners pay this tax to annually, semi-annually, or as part of a monthly mortgage payment.",
    HOA_dues:
      "Fees paid by homeowners within a shared complex to pay for building repairs and operation costs.",
    hi: "The average homeowners' insurance rate in the USA is 0.55%. Your actual rate may vary. Homeowners should purchase insurance when they apply for a mortgage loan to guard against potential damages to their future home.",
    ir: "The cost of borrowing money expressed as a yearly percentage rate of a loan amount.",
    loan_term:
      "A fixed-rate loan such as a 30-year fixed has a set interest rate for the entire duration of the loan. An adjustable-rate mortgage (ARM), such as a 5/1 ARM, typically has a lower set interest rate for the first 5 years and then switches to an adjustable interest rate for the following 25 years.",
    credit_score:
      " Also known as FICO scores, this score measures how likely you are to default on a loan. It allows lenders to determine credit risk and is based on bill payment history, length, and type of credit, along with many other factors."
  },
  footer: {
    disclaimer:
      "This calculator is intended for planning and education purposes only. The above figures are based on the numbers shown on the payment calculator. The interest rate, APR, and payment amount may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors. Chart data is for illustrative purposes only. Costs displayed here are not necessarily all inclusive and can vary based on numerous factors.  Rates are subject to change without notice. The output of the tool is not a loan offer or solicitation, nor is it financial or legal advice and should not be used as your sole source of information. Rates assume a credit score of 740 or higher, single family home for a purchase mortgage. APR = Annual Percentage Rate.",
    tagline: "Don't miss this chance, get your personalized offer!",
    cta: "Get My Custom Offer"
  },
  inline: {
    footer: {
      calc_CTA: "Advanced",
      CTA: "Get a custom quote"
    }
  }
}
