import { h, Component, Fragment } from "preact"
import ModalLoading from "./ModalLoading"
import { Icon, Avatar } from "@ui"
import eohIcon from "@assets/icons/ehl.svg"

class ModalSidebar extends Component {
  renderTitle = ({ position }) => (position ? <p>{position}</p> : <span />)
  renderNMLS = ({ nmls, nmls2, nmls2Title }) => {
    let content = `NMLS #${nmls}`
    if (nmls2 && nmls2Title) {
      content += `, ${nmls2Title} NMLS #${nmls2}`
    }
    return <p>{content}</p>
  }

  renderAddress({
    office_street_addr = "",
    office_city = "",
    office_state = ""
  }) {
    return (
      <Fragment>
        <p>{office_street_addr}</p>
        <p>{[office_city, office_state].join(", ")}</p>
      </Fragment>
    )
  }

  render({ user, isLoading }) {
    return (
      <div className="modal__side">
        {isLoading && <ModalLoading />}
        {!isLoading && (
          <div className="modal__profile">
            <Avatar
              className="modal__profile-avatar"
              rounded
              src={user.picture}
              alt={`${user.name} Avatar`}
            />
            <div className="modal__profile-data">
              <h3 className="modal__profile-name">{user.name}</h3>
              {this.renderTitle(user)}
              <p> {user.email} </p>
              <p>Phone: {user.phone} </p>
              {this.renderNMLS(user)}
            </div>

            <hr className="modal__separator" />

            <div className="modal__company-data">
              <div className="modal__company-image">
                <img src={user.logo} alt="Logo" />
              </div>
              {user.extra_details && this.renderAddress(user.extra_details)}
              <p>NMLS #{user.brand_nmls}</p>
            </div>

            <Icon
              className="modal__side__eoh-icon txt-color"
              src={eohIcon}
              size={38}
              originalSize={38}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ModalSidebar
