import { ComponentChildren, h } from "preact"
import { useState, useEffect } from "preact/hooks"

interface FadeInOutProps {
  show?: boolean
  duration?: number
  className?: string
  defaultComponent: ComponentChildren
  alternateComponent: ComponentChildren
}

const FadeInOut = ({
  show = false,
  duration = 300,
  className = "",
  defaultComponent,
  alternateComponent
}: FadeInOutProps) => {
  const [defaultVisible, setDefaultVisible] = useState<boolean>(true)
  const [alternateVisible, setAlternateVisible] = useState<boolean>(false)

  useEffect(() => {
    if (show) {
      setDefaultVisible(false)
      setTimeout(() => {
        setAlternateVisible(true)
      }, duration)
    } else {
      setAlternateVisible(false)
      setTimeout(() => {
        setDefaultVisible(true)
      }, duration)
    }
  }, [show, duration])

  return (
    <div className={className}>
      <div
        style={{
          position: "absolute",
          right: 0,
          opacity: defaultVisible ? 1 : 0,
          transition: `opacity ${duration}ms ease-in-out`,
          pointerEvents: defaultVisible ? "all" : "none"
        }}
      >
        {defaultComponent}
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          opacity: alternateVisible ? 1 : 0,
          transition: `opacity ${duration}ms ease-in-out`,
          pointerEvents: alternateVisible ? "all" : "none"
        }}
      >
        {alternateComponent}
      </div>
    </div>
  )
}

export default FadeInOut
