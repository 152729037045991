import { Fragment, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useSelector } from "react-redux"
import MortgageInlineFooter from "./MortgageInlineFooter"
import MortgageForm from "./MortgageForm"
import { apiFactory } from "./utils"
import { getConfig } from "../../../config"
import { setStorage } from "@helpers"
import { Box, If, MortgageCalcSkeleton } from "@ui"
import { HeaderComponent } from "@composite"
import { RootStates } from "@types"

interface Props {
  isCalculators?: boolean
}

const InlineMortgageCalculator = ({ isCalculators }: Props) => {
  const [state, setState] = useState({
    branded: false,
    defaultValues: null,
    isLoadingConfig: true
  })

  const { isLoading, app_key } = useSelector(
    ({ mortgageCalc, app }: RootStates) => ({
      isLoading: mortgageCalc?.isLoading ?? false,
      app_key: app.app_key
    })
  )

  const config = getConfig()

  const api = apiFactory(app_key, config.nmls)

  useEffect(() => {
    // Remove all data
    //clearStorage(`mortgage-${app_key}`)

    // Get loanCalc branding options
    api.getLoanCalcConfig().then((data: any) => {
      setState({
        branded: data?.config?.branded,
        isLoadingConfig: false,
        defaultValues: data?.default_values
      })

      setStorage(
        {
          branded: data?.config?.branded,
          defaultValues: data?.default_values
        },
        `mortgage-${app_key}`
      )
    })
  }, [])

  return (
    <Box
      smallGutter
      noClassName={isCalculators}
      className="inline-mortgage-calculator"
    >
      {!isCalculators && (
        <HeaderComponent title="Mortgage Calculator" hasVCard={state.branded} />
      )}
      <If
        condition={state.isLoadingConfig}
        then={<MortgageCalcSkeleton />}
        else={
          <Fragment>
            <div className="box__content">
              <MortgageForm
                isInline
                defaultValues={state.defaultValues}
                app_key={app_key}
              />
              <If
                condition={!isCalculators && !isLoading}
                then={<MortgageInlineFooter />}
              />
            </div>
          </Fragment>
        }
      />
    </Box>
  )
}

export default InlineMortgageCalculator
