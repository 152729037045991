import { h, Component } from "preact"
import PropTypes from "prop-types"
import ModalBox from "./ModalBox"
import { closeModal } from "@message-bridge"
import { BlockingModal } from "@composite"
import { getUrlVars } from "@helpers"
import "@styles/components/_modal.scss"

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      isLoading: true,
      showBlockingModal: false,
      showClassName: props.animated ? "is-animated" : ""
    }
  }

  handleCloseButtonClick = e => {
    if (e.target === e.currentTarget && !this.props.hideClose) {
      e.preventDefault()

      if (this.props.animated) {
        this.setState({ showClassName: "is-animated" })

        setTimeout(() => {
          closeModal()
        }, 500)
      } else {
        closeModal()
      }
    }
  }

  componentDidMount() {
    const options = getUrlVars(window.location.href)
    if (options.blockingModalVisible) {
      this.setState({ showBlockingModal: true, isLoading: false })
      return
    }

    if (this.props.animated) {
      setTimeout(() => {
        this.setState({ showClassName: "is-animated show" })
      }, 500)
    }
  }

  render(
    {
      children,
      className,
      hideClose,
      hideBackBtn,
      closeIsCross,
      closeOutside = true,
      withSpace,
      size = ""
    },
    { isLoading, showBlockingModal, showClassName }
  ) {
    return (
      <div
        className={`modal-cover ${className} ${showClassName} ${
          closeOutside ? "modal-cover__close-outside" : ""
        } ${withSpace ? "has-space" : ""}`}
        onClick={this.handleCloseButtonClick}
      >
        {showBlockingModal ? (
          <BlockingModal />
        ) : (
          <ModalBox
            hideBackBtn={hideBackBtn}
            size={size}
            hideClose={hideClose}
            closeIsCross={closeIsCross}
            handleCloseButtonClick={this.handleCloseButtonClick}
            isLoading={isLoading}
          >
            {children}
          </ModalBox>
        )}
      </div>
    )
  }
}

Modal.propTypes = {
  withSpace: PropTypes.bool,
  closeIsCross: PropTypes.bool,
  animated: PropTypes.bool,
  className: PropTypes.string
}

Modal.defaultProps = {
  withSpace: true,
  closeIsCross: true,
  animated: true,
  className: ""
}

export default Modal
