import { store } from "./store"
import { SAVE_BASE_STATE } from "./constants"

const clearConfig = () => {
  store && store.dispatch({ type: SAVE_BASE_STATE, payload: {} })
}

const setConfig = config => {
  if (config.exposeAPI && window.BB?.addAPI) {
    BB.addAPI(config)
  }
  store && store.dispatch({ type: SAVE_BASE_STATE, payload: config })
}

const getConfig = (getAll = false) => {
  const state = (store && store.getState()) || {} // we get the config from the redux store
  if (!state.app) {
    // No cofig
    //console.log("There is no config")
    return state
  }
  return getAll ? state : state.app
}

const testConfig = () => {
  let test = "test"
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export { clearConfig, setConfig, getConfig, testConfig }
