import { h } from "preact"
import TxtLoading from "./Txt"

interface Props {
  className?: string
}

const TextAndListSkeleton = ({ className = "" }: Props) => (
  <div className={className}>
    <TxtLoading height={24} width="45%" margin="0 0 16px" />
    <TxtLoading height={24} margin="0 0 4px" />
    <TxtLoading height={24} width="65%" margin="0 0 4px" />
    <div className="tw-ml-4 tw-mt-6 tw-mr-12">
      <div className="tw-flex">
        <TxtLoading height={16} width="16px" margin="0 4px 8px 0" />
        <TxtLoading height={16} width="75%" margin="0 0 8px" />
      </div>
      <div className="tw-flex">
        <TxtLoading height={16} width="16px" margin="0 4px 8px 0" />
        <TxtLoading height={16} width="65%" margin="0 0 8px" />
      </div>
      <div className="tw-flex">
        <TxtLoading height={16} width="16px" margin="0 4px 8px 0" />
        <TxtLoading height={16} width="50%" margin="0 0 8px" />
      </div>
      <div className="tw-flex">
        <TxtLoading height={16} width="16px" margin="0 4px 8px 0" />
        <TxtLoading height={16} width="75%" margin="0 0 8px" />
      </div>
      <div className="tw-flex">
        <TxtLoading height={16} width="16px" margin="0 4px 8px 0" />
        <TxtLoading height={16} width="60%" margin="0 0 8px" />
      </div>
      <div className="tw-flex">
        <TxtLoading height={16} width="16px" margin="0 4px 8px 0" />
        <TxtLoading height={16} width="55%" margin="0 0 8px" />
      </div>
    </div>
    <div className=" tw-mt-12">
      <TxtLoading height={48} width="33%" margin="0 auto" />
    </div>
  </div>
)

export default TextAndListSkeleton
