import { useState } from "preact/hooks"
import { authCodeAPI } from "../api"
import { AuthCodeType, GetCodeResponse, RootStates } from "@types"
import { useDispatch, useSelector } from "react-redux"
import { cleanUSPhone, eventDispatch, setStorage } from "@helpers"
import { apiErrored, getPricing, saveWizardState } from "@actions"

const useAuthCode = () => {
  const dispatch = useDispatch()
  const {
    pricingGridLoaded,
    card_details,
    storageKey,
    type,
    contact,
    auth_request_id
  } = useSelector(({ wizard }: RootStates) => {
    const contact =
      wizard?.values?.login_form?.form?.phone ||
      wizard?.values?.login_form?.form?.email
    const type: AuthCodeType = wizard?.values?.login_form?.form?.phone
      ? "sms"
      : "email"

    return {
      pricingGridLoaded: wizard?.pricingGridLoaded,
      auth_request_id: wizard?.auth_request_id,
      card_details: wizard?.card_details,
      storageKey: wizard?.storageKey,
      contact: contact ?? "",
      type
    }
  })

  const [authCodeResponse, setAuthCodeResponse] =
    useState<GetCodeResponse | null>(null)

  const [isLoadingAuthCode, setIsLoadingAuthCode] = useState(false)
  const [isVerifyingAuthCode, setIsVerifyingAuthCode] = useState(false)

  const getCode = async (forcedType?: AuthCodeType) => {
    setIsLoadingAuthCode(true)

    dispatch(
      saveWizardState({
        validateError: false,
        validationReady: false
      })
    )

    try {
      eventDispatch({
        name: "validation_initiated",
        category: "lead_workflow"
      })
      const authCodeType = forcedType ? forcedType : type
      const authContact =
        authCodeType === "sms" ? cleanUSPhone(contact) : contact
      const response = await authCodeAPI.getCode({
        type: authCodeType,
        contact: authContact
      })
      setAuthCodeResponse(response)
      setIsLoadingAuthCode(false)

      dispatch(
        saveWizardState({
          auth_request_id: response.validation.id
        })
      )
    } catch (error: any) {
      console.log(error)
      setIsLoadingAuthCode(false)
    }
  }

  const validateCode = async (code: string) => {
    if (auth_request_id === undefined) {
      return
    }
    setIsVerifyingAuthCode(true)
    try {
      eventDispatch({
        name: "validation_submitted",
        category: "lead_workflow"
      })
      const response = await authCodeAPI.validateCode({
        code,
        auth_request_id: auth_request_id as number
      })

      const status = response.status

      if (status === "success") {
        dispatch(
          saveWizardState({
            validateError: false,
            isLoading: !pricingGridLoaded,
            validationReady: true
          })
        )

        eventDispatch({
          name: "validation_success",
          category: "lead_workflow"
        })

        // Save local storage
        setStorage({ isValidated: true }, storageKey)
        // Get pricing grid
        if (!card_details) {
          // Get pricing grid if it was not called before
          dispatch(getPricing())
        }
      } else {
        eventDispatch({
          name: "validation_error",
          category: "lead_workflow"
        })

        dispatch(
          saveWizardState({
            validateError: true
          })
        )

        dispatch(apiErrored())
      }

      setIsVerifyingAuthCode(false)
    } catch (error) {
      setIsVerifyingAuthCode(false)
      console.log(error)
    }
  }

  return {
    isLoadingAuthCode,
    isVerifyingAuthCode,
    authCodeResponse,
    getCode,
    validateCode,
    authCodeType: type
  }
}

export default useAuthCode
