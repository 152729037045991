import { h } from "preact"
import { useMemo } from "preact/hooks"
import { useSelector } from "react-redux"
import { If } from "@ui"
import { numberToUSD } from "@helpers"
import { RootStates } from "@types"

const Table = () => {
  const wizard = useSelector(({ wizard }: RootStates) => wizard)

  const {
    login_form,
    steps: {
      down_payment = 0,
      loan_purpose,
      list_price = 0,
      household_income = 0,
      credit_score,
      military_eligible,
      cash_out
    } = {}
  } = wizard?.values ?? {}

  const percentDown = useMemo(
    () => Math.round((down_payment / list_price) * 100),
    [down_payment, list_price]
  )

  return (
    <ul className="wizard__loan-profile-table">
      <li>
        <strong>Estimated Home Value</strong>
        <span>{numberToUSD(list_price, true)}</span>
      </li>

      <If
        condition={
          loan_purpose !== "refinance" &&
          (percentDown === 0 || (!!percentDown && !isNaN(percentDown)))
        }
        then={
          <li>
            <strong>Estimated Down Payment</strong>
            <span>{percentDown}%</span>
          </li>
        }
      />

      <If
        condition={loan_purpose === "refinance"}
        then={
          <li>
            <strong>Cash Out</strong>
            <span>{numberToUSD(cash_out ?? 0, true)}</span>
          </li>
        }
      />
      <li>
        <strong>Loan Amount</strong>
        <span>{numberToUSD(list_price - down_payment, true)}</span>
      </li>
      <li>
        <strong>Loan Type</strong>
        <span className="tw-capitalize">{loan_purpose}</span>
      </li>

      <If
        condition={!!credit_score}
        then={
          <li>
            <strong>Credit Score</strong>
            <span>{credit_score}</span>
          </li>
        }
      />
      <li>
        <strong>VA Eligible</strong>
        <span>{military_eligible === "yes" ? "Yes" : "No"}</span>
      </li>

      <If
        condition={!!household_income && !isNaN(household_income)}
        then={
          <li>
            <strong>Annual Income</strong>
            <span>{numberToUSD(household_income, true)}</span>
          </li>
        }
      />
      <li>
        <strong>Name</strong>
        <span>
          {login_form?.form.first_name} {login_form?.form.last_name}
        </span>
      </li>
      <li>
        <strong>Email</strong>
        <span>{login_form?.form.email}</span>
      </li>
      <If
        condition={!!login_form?.form.phone}
        then={
          <li>
            <strong>Phone</strong>
            <span>{login_form?.form.phone}</span>
          </li>
        }
      />
    </ul>
  )
}

export default Table
