import { ComponentChildren, h } from "preact"
import { If } from "@ui"

interface Props {
  children: ComponentChildren
  isVisible: boolean
  mount: boolean
}

const Section = ({ children, isVisible, mount }: Props) => (
  <If
    condition={mount}
    then={<div className={isVisible ? "" : "tw-hidden"}>{children}</div>}
  />
)

export default Section
