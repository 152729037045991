import { h } from "preact"
import { useSelector } from "react-redux"
import { RootStates } from "@types"
import OfficerData from "../OfficerData"
import { If } from "@ui"
import "@styles/components/_header-component.scss"

interface HeaderComponentProps {
  hasVCard?: boolean
  title?: string
  logoXL?: boolean
}

const HeaderComponent = ({
  hasVCard = false,
  title = "",
  logoXL
}: HeaderComponentProps) => {
  const lo = useSelector(({ app: { lo } }: RootStates) => lo)

  return (
    <div className="header-component d-flex align-items-center">
      <If
        condition={title.length === 0 && !!lo?.logo}
        then={
          <img
            src={lo?.logo}
            alt={`${lo?.bank} logo`}
            className={`header-component__logo ${logoXL ? "is-xl" : ""}`}
          />
        }
      />

      <If
        condition={title.length > 0}
        then={
          <h3 className="tw-text-xl txt-dark tw-mr-auto tw-my-0 font-bold">
            {title}
          </h3>
        }
      />

      <If condition={hasVCard} then={<OfficerData />} />
    </div>
  )
}

export default HeaderComponent
