import { useState, useMemo } from "preact/hooks"

const useMaterialClassName = () => {
  const [materialClassNameMap, setMaterialClassName] = useState<
    Map<string, boolean>
  >(new Map())

  const toggleMaterialClassName = (classNames: Record<string, boolean>) => {
    const newClassNames = new Map(materialClassNameMap)

    Object.entries(classNames).forEach(([key, value]) => {
      if (value) {
        newClassNames.set(key, true)
      } else {
        newClassNames.delete(key)
      }
    })

    setMaterialClassName(newClassNames)
  }

  const materialClassName = useMemo(() => {
    return Array.from(materialClassNameMap.entries())
      .filter(([key, value]) => value)
      .map(([key]) => key)
      .join(" ")
  }, [materialClassNameMap])

  return {
    materialClassName,
    toggleMaterialClassName
  }
}

export default useMaterialClassName
