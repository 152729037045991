import { h } from "preact"
import { connect } from "react-redux"
import StepSlider from "./StepSlider"
import StepLocation from "./StepLocation"
import StepWelcome from "./StepWelcome"
import StepForm from "./StepForm"
import StepEnd from "./StepEnd"
import StepButtons from "./StepButtons"
import StepText from "./StepText"
import StepLOSelect from "./StepLOSelect"

const ConnectedStepInput = ({
  index,
  steps,
  currentStep,
  disclaimers,
  step,
  onInput,
  title,
  onClickContinue,
  goBack
}) => {
  const { lead_verification } = disclaimers
  const stepCount = steps.length - 1
  const isLastStep = currentStep == stepCount

  if (!step) {
    return null
  }

  const {
    id,
    type,
    title_icon,
    items,
    placeholder,
    step: stepValue,
    initial,
    prefix,
    point_size,
    range_start,
    range_end,
    header,
    subheader,
    fields,
    submitLabel
  } = step

  switch (type) {
    case "buttons":
    case "list":
      return (
        <StepButtons
          title={{ txt: title, icon: title_icon }}
          isList={type === "list"}
          onInput={onInput}
          stepKey={id}
          options={items}
        />
      )
    case "location_autocomplete":
      return (
        <StepLocation
          title={{ txt: title, icon: title_icon }}
          stepKey={id}
          placeholder={placeholder}
          onClickContinue={onClickContinue}
        />
      )
    case "text":
      return (
        <StepText
          title={{ txt: title, icon: title_icon }}
          stepKey={id}
          placeholder={placeholder}
          onClickContinue={onClickContinue}
        />
      )
    case "lo_select":
      return (
        <StepLOSelect
          title={{ txt: title, icon: title_icon }}
          stepKey={id}
          placeholder={placeholder}
          onClickContinue={onClickContinue}
        />
      )
    case "slider":
      return (
        <StepSlider
          title={{ txt: title, icon: title_icon }}
          step={stepValue}
          initial={initial}
          prefix={prefix}
          buffer={point_size}
          stepKey={id}
          min={range_start}
          max={range_end}
          onClickContinue={onClickContinue}
        />
      )
    case "welcome":
      return (
        <StepWelcome
          title={{ txt: title, icon: title_icon }}
          onInput={onInput}
          stepKey={id}
          options={items}
          header={header}
          subheader={subheader}
        />
      )
    case "form":
    case "form_verification":
      return (
        <StepForm
          isVisible={currentStep === index}
          title={{ txt: title, icon: title_icon }}
          isCode={type === "form_verification"}
          stepKey={id}
          header={header}
          fields={fields}
          submitLabel={submitLabel}
          disclaimer={lead_verification || ""}
          onSentCallback={onClickContinue}
          goBack={goBack}
        />
      )
    case "end":
      return isLastStep ? <StepEnd /> : null
    default:
      return null
  }
}

const mapStateToProps = ({ wizard }) => {
  return {
    currentStep: wizard.currentStep,
    steps: wizard.steps,
    values: wizard.values,
    card_details: wizard.card_details,
    disclaimers: wizard.disclaimers
  }
}

const StepInput = connect(mapStateToProps)(ConnectedStepInput)

export default StepInput
