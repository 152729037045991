import { ComponentChildren, h } from "preact"
import { useMediaFlags } from "@contexts"
import { SVG } from "@ui"
import headerBanner from "@assets/lo-page/header.svg"
import headerTableBanner from "@assets/lo-page/header-tablet.svg"
import headerMobileBanner from "@assets/lo-page/header-mobile.svg"

interface Props {
  children: ComponentChildren
}

const HeaderWrapper = ({ children }: Props) => {
  const { isMobile, isTablet } = useMediaFlags()

  let image = headerBanner
  let imageSizes = [1728, 240]

  if (isTablet) {
    image = headerTableBanner
    imageSizes = [1024, 240]
  } else if (isMobile) {
    image = headerMobileBanner
    imageSizes = [656, 155]
  }

  return (
    <header className="header-lo tw-relative">
      <div className="header-lo__banner-wrapper accent-color__lightened accent-bg__lightened tw-absolute tw-top-0 tw-w-full tw-left-1/2 -tw-translate-x-1/2 tw-z-0 tw-overflow-hidden">
        <SVG
          className="tw-block lg:tw-mx-auto tw-bg-white"
          width={imageSizes[0]}
          height={imageSizes[1]}
          src={image}
        />
      </div>

      <div className="container tw-flex-col tw-z-10 tw-relative !tw-overflow-visible tw-px-4 lg:tw-px-6 xl:tw-px-0">
        {children}
      </div>
    </header>
  )
}

export default HeaderWrapper
