import { h } from "preact"
import { Button } from "@ui"

interface Props {
  className?: string
}

const GetStartedButton = ({ className = "" }: Props) => (
  <Button className={className} adapt size="sm" label="Get started" noShadow />
)

export default GetStartedButton
