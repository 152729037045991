import { h } from "preact"
import { css } from "emotion"
import styled from "preact-emotion"

interface Props {
  width?: number
  height?: number
  margin?: string
  fullRounded?: boolean
}

export default ({ width, height, margin, fullRounded }: Props) => {
  /*
   *  Classes
   */

  const element = css`
    background: transparent;
  `
  const hMargin = css`
    flex: 1 0 10%;
  `
  let imgRow = height ? height : 100
  const imgRowStyle = css`
    height: ${imgRow}px;
  `
  let w = width ? width : 150
  const wrapper_w = { width: `${w}px`, borderRadius: "0", margin: "" }

  if (fullRounded) {
    wrapper_w.borderRadius = "100%"
  }
  if (margin) {
    wrapper_w.margin = margin
  }

  const wStyle = css`
    width: ${w}px;
  `

  /*
   *  Components
   */
  const Img = styled("div")`
    ${element};
    ${imgRowStyle};
    ${wStyle};
  `

  return (
    <div
      className="loading-placeholder loading-placeholder--animated-background"
      style={{ ...wrapper_w }}
    >
      <Img />
    </div>
  )
}
