import { h } from "preact"
import { useSelector } from "react-redux"
import { Button } from "@ui"
import { RootStates } from "@types"
import { bbProcessEnv } from "@helpers"

const WizardNextSteps = ({ className = "" }) => {
  const lead_id = useSelector(({ wizard }: RootStates) => wizard?.lead_id)
  const dashboardLink = `${bbProcessEnv.DASHBOARD_URL}/new-subscription?lead_id=${lead_id}&active=0&source=embed`
  return (
    <div className={`wizard__info-block ${className}`}>
      <div className="wizard__info-block-title">Next steps</div>
      <ol className="wizard__info-block-list">
        <li>
          Visit your{" "}
          <a target="_blank" href={dashboardLink}>
            personalized dashboard
          </a>
        </li>
        <li>
          Like what you see or have questions? Contact our home loan
          professional.
        </li>
      </ol>
      <div className="mt-auto">
        <Button
          label="Go to my dashboard"
          href={dashboardLink}
          target="_blank"
          adapt
          outline
        />
      </div>
    </div>
  )
}

export default WizardNextSteps
