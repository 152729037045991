import { h, cloneElement } from "preact"

interface Props {
  children: any
  label?: any
  helper?: string
  compact?: boolean
  suffix?: string
  affix?: string
  className?: string
  size?: "sm" | "md" | "lg"
  style?: any
}

const DecoratedInput = ({
  children,
  label,
  helper,
  compact,
  suffix,
  affix,
  className,
  size = "md",
  style
}: Props) => {
  let inputClass = "form__input"

  if (affix) {
    inputClass += " form__input--affix"
  }

  if (suffix) {
    inputClass += " form__input--suffix"
  }

  const props = {
    className: `${children.props.className || ""} ${inputClass}`
  }

  const clonedInput = cloneElement(children, props)

  return (
    <div
      className={`form__field is-${size} ${className} ${
        compact ? "is-compact" : ""
      }`}
      style={style}
    >
      {label && <label className="form__label">{label}</label>}
      <div className="tw-relative">
        {affix && <span className="form__input-affix">{affix}</span>}
        {clonedInput}
        {suffix && <span className="form__input-suffix">{suffix}</span>}
        {helper && <span className="form__helper">{helper}</span>}
      </div>
    </div>
  )
}
export default DecoratedInput
