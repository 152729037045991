import { h } from "preact"
import { AverageRating } from "@composite"
import "@styles/components/_rt-form-rating.scss"

interface Props {
  anchor?: string
}

const RateTableFormRating = ({ anchor }: Props) => (
  <div className="rt-form-rating">
    <AverageRating anchor={anchor} />
  </div>
)

export default RateTableFormRating
