import { h } from "preact"
import Box from "../Box"
import TxtLoading from "./Txt"
import { useIsMobile } from "@contexts"

const TabsWithLeadWorkflowSkeleton = ({ className = "" }) => {
  const isMobile = useIsMobile()
  return (
    <div className={`tabs-skeleton ${className}`}>
      <div className="tabs-skeleton__tabs tw-flex gap-base">
        {[...Array(isMobile ? 3 : 5)].map((_, i) => (
          <Box
            hasGutter
            hasShadow={false}
            key={i}
            className="tw-grow-[1] !tw-mt-0 tw-border-0 !tw-p-0"
          >
            <TxtLoading
              height={isMobile ? 36 : 56}
              width={isMobile ? 36 : 56}
              margin="24px auto 0"
              borderRadius={4}
            />
            <TxtLoading
              height={isMobile ? 24 : 36}
              width="100%"
              margin="24px auto"
              borderRadius={3}
            />
          </Box>
        ))}
      </div>

      <div className="tw-max-w-4xl tw-mx-auto tw-mt-16">
        <TxtLoading
          height={isMobile ? 265 : 540}
          width="90%"
          margin="0 auto"
          borderRadius={3}
        />
      </div>
    </div>
  )
}

export default TabsWithLeadWorkflowSkeleton
