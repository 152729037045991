import { LEAD_WORKFLOW_MODAL, GLOBAL_MODAL } from "@constants"
import { openGlobalModal, openGlobalModalFromWithin } from "../base"

export const openLeadWorkflow = (iframeID?: string) => {
  openGlobalModal(GLOBAL_MODAL, LEAD_WORKFLOW_MODAL, iframeID)
}

export const openLeadWorkflowFromWithin = (iframeID?: string) => {
  openGlobalModalFromWithin(GLOBAL_MODAL, LEAD_WORKFLOW_MODAL, iframeID)
}
