import {
  GET_WIZARDS_STEPS_API,
  UPDATE_SUBSCRIPTION,
  UPDATE_FORM_VALUES,
  UPDATE_CURRENT_STEP,
  SET_STORAGE_KEY,
  RESET_STATE,
  SEND_FORM,
  LOAN_TERM_CHANGE,
  HANDLE_MEDIA_CHANGE,
  GET_RATE_TABLE_PRICING,
  INIT_RATE_TABLE_PRICING,
  SAVE_BASE_STATE,
  ON_LOAN_TYPE_CHANGE,
  UPDATE_RATE_FORM,
  UPDATE_RATE_SUBSCRIPTION,
  TOGGLE_RATE_TABLE_FORM,
  RATE_FORM_SUBMITTED,
  RATE_FORM_SUBMITTED_ALL,
  RATE_FORM_IS_DIRTY,
  INIT_DASH,
  UPDATE_DASH_FORM,
  UPDATE_MORTGAGE_CALC,
  UPDATE_SUBSCRIBE_SUBMITTED,
  SET_FORM_UPDATED,
  TOGGLE_CONTACT_MODAL,
  TOGGLE_OFFER_DETAILS_MODAL,
  TOGGLE_SUBSCRIBE_MODAL,
  SET_CURRENT_DETAILS_CARD,
  ON_MORTGAGE_PROFILE_SELECT,
  ON_RT_SAVE_SELECTED_ROW,
  GET_DASH_CARDS,
  PUSH_TOAST_ITEM,
  SPLICE_TOAST_ITEM,
  SAVE_WIZARD_STATE,
  GET_OFFER_DETAILS,
  GET_PRICING_BEFORE_LEAD,
  GET_LOS_BY_BRAND_ID,
  TOGGLE_WELCOME_MODAL,
  SAVE_WIZARD_DETAILS,
  TAXES_AND_INSURANCE_LOADED,
  API_ERRORED,
  GET_PRICING,
  TOGGLE_RATE_TABLE_MODAL,
  SET_CLASS_NAMES,
  SAVE_ASSUMPTIONS,
  SET_FEATURED_RATE
} from "../constants/action-types"
import { LoanDetailsFormFields, PricingItem, Toast } from "@types"
import { LeadWorkflowClassNames, UserForm } from "../types/LeadWorkflow"

export function getWizardStepsAPI(payload: any) {
  return { type: GET_WIZARDS_STEPS_API, payload }
}
export function saveAssumptions(payload: any) {
  return { type: SAVE_ASSUMPTIONS, payload }
}

export function getRateTablePricing(payload: any) {
  return { type: GET_RATE_TABLE_PRICING, payload }
}

export function initRateTablePricing() {
  return { type: INIT_RATE_TABLE_PRICING }
}

export function updateFormValues(payload: {
  value: any
  key: any
  type?: string
}) {
  return { type: UPDATE_FORM_VALUES, payload }
}

export function resetState(payload: any) {
  return { type: RESET_STATE, payload }
}

export function updateCurrentStep(payload: any) {
  return { type: UPDATE_CURRENT_STEP, payload }
}

export function setStorageKey(payload: any) {
  return { type: SET_STORAGE_KEY, payload }
}

export function sendForm(payload: {
  values: Partial<UserForm | string>
  from?: string
  triggerBy?: string
  onSentCallback: any
}) {
  return { type: SEND_FORM, payload }
}

export function loanTermChange(payload: any) {
  return { type: LOAN_TERM_CHANGE, payload }
}

export function handleMediaChange(payload: any) {
  return { type: HANDLE_MEDIA_CHANGE, payload }
}

export function updateConfig(payload: any) {
  return { type: SAVE_BASE_STATE, payload }
}

export function onLoanTypeChange(payload: any) {
  return { type: ON_LOAN_TYPE_CHANGE, payload }
}

export function updateRateForm(payload: Partial<LoanDetailsFormFields>) {
  return { type: UPDATE_RATE_FORM, payload }
}

export function rateFormIsDirty(payload: any) {
  return { type: RATE_FORM_IS_DIRTY, payload }
}

export function updateSubscription(payload: any) {
  return { type: UPDATE_SUBSCRIPTION, payload }
}

export function updateRateSubscription(payload: boolean) {
  return { type: UPDATE_RATE_SUBSCRIPTION, payload }
}

export function toggleRateTableForm(payload: any) {
  return { type: TOGGLE_RATE_TABLE_FORM, payload }
}

export function rateFormSubmitted(payload?: boolean | undefined) {
  return { type: RATE_FORM_SUBMITTED, payload }
}

export function rateFormSubmittedAll() {
  return { type: RATE_FORM_SUBMITTED_ALL }
}

export function initDash(payload: any) {
  return { type: INIT_DASH, payload }
}

export function updateDashForm(payload: any) {
  return { type: UPDATE_DASH_FORM, payload }
}

export function updateMortgageCalc(payload: any) {
  return { type: UPDATE_MORTGAGE_CALC, payload }
}

export function updateSubscribeSubmitted(payload: any) {
  return { type: UPDATE_SUBSCRIBE_SUBMITTED, payload }
}

export function setFormUpdated(payload: any) {
  return { type: SET_FORM_UPDATED, payload }
}

export function toggleOfferDetailsModal(payload: {
  visible: boolean
  initialTab?: string
}) {
  return { type: TOGGLE_OFFER_DETAILS_MODAL, payload }
}

export function toggleRateTableModal(payload: boolean) {
  return { type: TOGGLE_RATE_TABLE_MODAL, payload }
}

export function toggleSubscribeModal(payload: boolean) {
  return { type: TOGGLE_SUBSCRIBE_MODAL, payload }
}

export function toggleContactModal(payload: boolean) {
  return { type: TOGGLE_CONTACT_MODAL, payload }
}

export function onMortgageProfileSelect(payload: any) {
  return { type: ON_MORTGAGE_PROFILE_SELECT, payload }
}

export function onRTSaveSelectedRow(payload: PricingItem | null) {
  return { type: ON_RT_SAVE_SELECTED_ROW, payload }
}

export function getDashCards() {
  return { type: GET_DASH_CARDS }
}

export function apiErrored() {
  return { type: API_ERRORED }
}

export function pushToastItem(payload: Toast) {
  return { type: PUSH_TOAST_ITEM, payload }
}

export function spliceToastItem(payload: any) {
  return { type: SPLICE_TOAST_ITEM, payload }
}

export function saveWizardState(payload: any) {
  return { type: SAVE_WIZARD_STATE, payload }
}

export function getOfferDetails(payload: {
  quote_id: number
  callback?: () => void
}) {
  return { type: GET_OFFER_DETAILS, payload }
}

export function setCurrentOfferDetailsCard(payload: PricingItem) {
  return { type: SET_CURRENT_DETAILS_CARD, payload }
}

export function getPricing() {
  return { type: GET_PRICING }
}

export function getPricingBeforeLead() {
  return { type: GET_PRICING_BEFORE_LEAD }
}

export function getLOsByBrandID(payload: any) {
  return { type: GET_LOS_BY_BRAND_ID, payload }
}

export function toggleWelcomeModal(payload?: boolean) {
  return { type: TOGGLE_WELCOME_MODAL, payload }
}

export function setWizardDetails(payload: any) {
  return { type: SAVE_WIZARD_DETAILS, payload }
}
export function setLeadWorkflowClassNames(payload: LeadWorkflowClassNames) {
  return { type: SET_CLASS_NAMES, payload }
}

export function updateTaxesAndInsurance(payload: any) {
  return {
    type: TAXES_AND_INSURANCE_LOADED,
    payload
  }
}

export function setFeaturedRate(payload: PricingItem) {
  return { type: SET_FEATURED_RATE, payload }
}
