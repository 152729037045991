import { h } from "preact"

type Props = {
  src: string
  size?: number | number[]
  originalSize?: number | number[]
  className?: string
  fill?: string
}

const Icon = ({ src, size = 24, originalSize = 24, fill, ...rest }: Props) => {
  let originalSizeH = originalSize
  let originalSizeW = originalSize

  let sizeH = size
  let sizeW = size

  if (typeof originalSize != "number") {
    originalSizeW = originalSize[0]
    originalSizeH = originalSize[1]
  }

  if (typeof size != "number") {
    sizeW = size[0]
    sizeH = size[1]
  }

  try {
    const svgContainer = document.createElement("div")
    svgContainer.innerHTML = src
    const svg = svgContainer.firstElementChild?.innerHTML ?? ""

    return (
      <svg
        {...(fill ? { fill } : {})}
        viewBox={`0 0 ${originalSizeW} ${originalSizeH}`}
        width={sizeW as number}
        height={sizeH as number}
        dangerouslySetInnerHTML={{ __html: svg }}
        aria-hidden="true"
        {...rest}
      />
    )
  } catch (error) {
    console.log(error)
    return null
  }
}

export default Icon
