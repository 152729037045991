import { h } from "preact"
import { Icon, Button } from "@ui"
import SlidersIcon from "@assets/icons/sliders-h.svg"

interface Props {
  onClick: () => void
}

const MoreFiltersBtn = ({ onClick }: Props) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Enter") {
      onClick()
    }
  }

  return (
    <Button isLite noShadow onClick={onClick} onKeyDown={handleKeyDown}>
      <Icon className="mr-2" src={SlidersIcon} size={16} />
      More Filters
    </Button>
  )
}

export default MoreFiltersBtn
