import { useState, useRef, useCallback, useEffect } from "preact/hooks"
import { locationAPI } from "@api"

// Type guard to check if response is an error
function isErrorResponse(
  response: any
): response is { status: "error"; message: string } {
  return (
    response &&
    typeof response === "object" &&
    "status" in response &&
    response.status === "error" &&
    "message" in response
  )
}

/**
 * Extracts a zipcode from a place description string
 * @param description The place description text
 * @returns The zipcode if found, null otherwise
 */
function getZipcodeFromDescription(description: string): string | null {
  if (!description) return null

  // Match standard US 5-digit zipcode
  const zipCodeMatch = description.match(/\b\d{5}\b/)

  // If found, return the first match
  return zipCodeMatch ? zipCodeMatch[0] : null
}

const usePlacesAutocomplete = (
  autocompleteService: google.maps.places.AutocompleteService | null,
  apiOptions: Partial<google.maps.places.AutocompletionRequest>,
  debounce: number,
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void,
  initialValue?: string,
  forceZipcode = true
) => {
  const [showError, setShowError] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(initialValue ?? "")
  const [currentPlace, setCurrentPlace] =
    useState<google.maps.places.PlaceResult>()
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState<
    number | null
  >(null)
  const debounceTimeout = useRef<number | null>(null)

  const fetchPlaceByValue = async ({
    input,
    sendOnPlaceSelected = true,
    prediction,
    zipCodeMatch
  }: {
    input: string
    sendOnPlaceSelected?: boolean
    prediction?: google.maps.places.AutocompletePrediction
    zipCodeMatch?: string | null
  }) => {
    try {
      // Create an optimistic update based on the input
      const optimisticZipcode =
        zipCodeMatch ||
        getZipcodeFromDescription(input) ||
        input.replace(/\D/g, "").substring(0, 5)

      // Create an optimistic place result
      const optimisticPlace: google.maps.places.PlaceResult = {
        name: input.split(",")[0] || input,
        formatted_address: input,
        address_components: [
          {
            long_name: optimisticZipcode,
            short_name: optimisticZipcode,
            types: ["postal_code"]
          }
        ]
      }

      // Update UI optimistically
      setShowError(false)
      setCurrentPlace(optimisticPlace)

      if (forceZipcode) {
        setInputValue(optimisticZipcode)
      } else {
        setInputValue(optimisticPlace.formatted_address ?? "")
      }

      if (sendOnPlaceSelected) {
        onPlaceSelected(optimisticPlace)
      }

      // Now make the actual API call
      const response = await locationAPI.zipcodeSearch(input)

      if (!response) {
        console.warn("No response received from API")
        setShowError(true)
        return
      }

      // Check for error response
      if (isErrorResponse(response)) {
        console.warn("API Error:", response.message)

        // Only try to create a place if we have prediction with postal code
        if (
          prediction &&
          prediction.types?.includes("postal_code") &&
          zipCodeMatch
        ) {
          const place: google.maps.places.PlaceResult = {
            name: prediction.terms?.[0]?.value ?? "",
            formatted_address: prediction.description,
            address_components: [
              {
                long_name: zipCodeMatch,
                short_name: zipCodeMatch,
                types: ["postal_code"]
              }
            ]
          }

          setShowError(false)
          setCurrentPlace(place)

          if (forceZipcode) {
            setInputValue(zipCodeMatch)
          } else {
            setInputValue(place.formatted_address ?? "")
          }

          if (sendOnPlaceSelected) {
            onPlaceSelected(place)
          }
        } else {
          // No fallback data available, show error
          setShowError(true)
        }

        return
      }

      // If we get here, we have a successful response
      const place: google.maps.places.PlaceResult = {
        name: response.city,
        formatted_address: `${response.city}, ${response.state} ${response.zipcode}, USA`,
        address_components: [
          {
            long_name: response.city,
            short_name: response.city,
            types: ["locality", "political"]
          },
          {
            long_name: response.state,
            short_name: response.state,
            types: ["administrative_area_level_1", "political"]
          },
          {
            long_name: "United States",
            short_name: "US",
            types: ["country", "political"]
          },
          {
            long_name: response.zipcode,
            short_name: response.zipcode,
            types: ["postal_code"]
          }
        ]
      }

      setShowError(false)
      setCurrentPlace(place)

      if (forceZipcode) {
        setInputValue(response.zipcode ?? "")
      } else {
        setInputValue(place.formatted_address ?? "")
      }

      if (sendOnPlaceSelected) {
        onPlaceSelected(place)
      }
    } catch (error) {
      console.error("Error fetching place by value:", error)
      setShowError(true)
    }
  }

  //Initial lookup when component mounts and initialValue is provided
  useEffect(() => {
    if (initialValue && !initialized) {
      setInitialized(true)
      fetchPlaceByValue({ input: initialValue })
    }
  }, [initialValue, inputValue, initialized])

  const forceInputChange = (value: string) => {
    initialized && fetchPlaceByValue({ input: value })
  }

  const fetchSuggestions = useCallback(
    (input: string) => {
      if (input.length > 0 && autocompleteService) {
        setIsLoading(true)
        const request: google.maps.places.AutocompletionRequest = {
          input,
          ...apiOptions
        }
        autocompleteService?.getPlacePredictions(
          request,
          (predictions, status) => {
            setIsLoading(false)

            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              setSuggestions(predictions)
            } else {
              console.error("Error fetching suggestions:", status)
              setSuggestions([])
            }
          }
        )
      } else {
        setIsLoading(false)
        setSuggestions([])
      }
    },
    [autocompleteService, apiOptions]
  )

  const handleInputChange = (e: InputEvent) => {
    const target = e.target as HTMLInputElement
    const value = target.value
    setInputValue(value)
    setFocusedSuggestionIndex(null)

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }

    debounceTimeout.current = window.setTimeout(() => {
      fetchSuggestions(value)
    }, debounce)
  }

  const handleSuggestionClick = useCallback(
    (prediction: google.maps.places.AutocompletePrediction) => {
      let description = prediction.description
      // Try to extract potential zipcode from the description
      const zipCodeMatch = getZipcodeFromDescription(description)

      fetchPlaceByValue({
        input: description,
        sendOnPlaceSelected: true,
        zipCodeMatch,
        prediction
      })

      setSuggestions([])
      setFocusedSuggestionIndex(null)
      setShowError(false)

      description = description?.replace(/, USA$/, "")

      if (forceZipcode) {
        const extractedZipcode = zipCodeMatch ?? inputValue
        setInputValue(extractedZipcode)
      } else {
        setInputValue(description)
      }
    },
    [onPlaceSelected]
  )

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!suggestions || suggestions.length === 0) return

    if (e.key === "ArrowDown") {
      e.preventDefault()
      setFocusedSuggestionIndex(prevIndex => {
        if (prevIndex === null || prevIndex === suggestions.length - 1) {
          return 0
        }
        return prevIndex + 1
      })
    } else if (e.key === "ArrowUp") {
      e.preventDefault()
      setFocusedSuggestionIndex(prevIndex => {
        if (prevIndex === null || prevIndex === 0) {
          return suggestions.length - 1
        }
        return prevIndex - 1
      })
    } else if (e.key === "Enter") {
      if (
        focusedSuggestionIndex !== null &&
        suggestions[focusedSuggestionIndex]
      ) {
        e.preventDefault()
        handleSuggestionClick(suggestions[focusedSuggestionIndex])
      }
    }
  }

  return {
    inputValue,
    setInputValue: forceInputChange,
    suggestions,
    focusedSuggestionIndex,
    handleInputChange,
    handleSuggestionClick,
    handleKeyDown,
    isLoading,
    currentPlace,
    showError,
    setShowError
  }
}

export default usePlacesAutocomplete
