import { ComponentChildren, h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { ReviewType } from "@types"
import ReviewTypeContext from "./context"
import { useAppContext } from "@contexts"

interface Props {
  children: ComponentChildren
}

// Provider component
const ReviewsProvider = ({ children }: Props) => {
  const [reviewType, setReviewType] = useState<ReviewType>("lo")

  const {
    reviews: { getReviews, lo, brand }
  } = useAppContext()

  useEffect(() => {
    getReviews("lo")
    getReviews("brand")
  }, [])

  const value = {
    isLoLoading: lo.isLoading,
    isBrandLoading: brand.isLoading,
    hasLoReviews: !lo.noReviews,
    hasBrandReviews: !brand.noReviews,
    reviewType,
    setReviewType,
    lo,
    brand
  }

  useEffect(() => {
    if (lo.noReviews && !brand.noReviews) {
      setReviewType("brand")
    }
  }, [lo, brand])

  return (
    <ReviewTypeContext.Provider value={value}>
      {children}
    </ReviewTypeContext.Provider>
  )
}

export default ReviewsProvider
