import { h } from "preact"
import { AccentColor, DotAnimation, FadeInOut, Form } from "@ui"
import { useState } from "preact/hooks"
import { useSelector } from "react-redux"
import { RootStates } from "@types"

interface Props {
  field: {
    data: {
      placeholder: string
      id: string
      value: string
      emailIsNotFilled: boolean
    }
    onInput: (e: Event) => void
    onFocus: (e: Event) => void
  }
  onClickButton: () => void
}

const EmailWithButtonField = ({ field, onClickButton }: Props) => {
  const [className, setClassName] = useState("")
  const [isValidating, setIsValidating] = useState(false)
  const [doValidation, setDoValidation] = useState(false)

  const background_validation_enabled = useSelector(
    ({ wizard }: RootStates) => wizard?.background_validation_enabled
  )

  const handleOnClickButton = () => {
    !field.data.emailIsNotFilled && onClickButton()
  }

  const handleClick = () => {
    !isValidating && setDoValidation(true)
  }

  const handleOnFocus = (e: Event) => {
    field.onFocus(e)
    if (className !== "") setClassName("")
  }

  const onValidationError = () => {
    setIsValidating(false)
    setClassName("is-invalid")
  }

  const onValidationSuccess = () => {
    setIsValidating(false)
    setClassName("")
    handleOnClickButton()
  }

  const onValidationStart = () => {
    setDoValidation(false)
    setIsValidating(true)
  }

  return (
    <div className={`wizard__sl-inline-input ${className}`}>
      <Form.Email
        APIValidationEnabled={background_validation_enabled}
        triggerValidation={doValidation}
        valiateOnBlur={false}
        showValidationIndicator={false}
        value={field.data.value}
        onInput={field.onInput}
        onFocus={handleOnFocus}
        onValidationError={onValidationError}
        onValidationSuccess={onValidationSuccess}
        onValidationStart={onValidationStart}
        placeholder={field.data.placeholder}
        name={field.data.id}
        required
      />
      <button
        tabIndex={0}
        type="button"
        onClick={handleClick}
        className={`wizard__sl-inline-btn ${
          isValidating ? "is-validating" : ""
        }`}
      >
        <FadeInOut
          show={isValidating}
          defaultComponent={<strong>Continue</strong>}
          alternateComponent={
            <div className="d-flex align-items-center justify-content-center">
              <AccentColor>Validating</AccentColor>
              <DotAnimation className="accent-color" />
            </div>
          }
        />
      </button>
    </div>
  )
}

export default EmailWithButtonField
