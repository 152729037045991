import { h } from "preact"
import ModalLoading from "../Modal/ModalLoading"
import eohIcon from "@assets/icons/ehl.svg"
import { Icon } from "@ui"

const OfficerSidebar = ({
  user,
  isLoading = false,
  hideLogo = false,
  title
}) => {
  const renderNMLS = ({ nmls, nmls2 = null, nmls2Title = null }) => {
    let content = `NMLS #${nmls}`
    if (nmls2 && nmls2Title) {
      content += `, ${nmls2Title} NMLS #${nmls2}`
    }
    return content
  }

  const renderAddress = ({
    office_street_addr = "",
    office_city = "",
    office_state = ""
  }) => (
    <div>
      <p>{office_street_addr}</p>
      <p>{[office_city, office_state].join(", ")}</p>
    </div>
  )
  return (
    <div className="officer-sidebar">
      {isLoading && <ModalLoading />}
      {!isLoading && (
        <div>
          {title && (
            <h1 className="officer-sidebar__title text-center">{title}</h1>
          )}

          {!hideLogo && (
            <img className="officer-sidebar__logo" src={user.logo} alt="Logo" />
          )}
          <div className="officer-sidebar__nmls text-center">
            {renderNMLS(user)}
          </div>
          <hr className="officer-sidebar__separator" />
          {user.number && (
            <div>
              <div className="d-flex">
                <strong>Direct:</strong>
                <span className="ml-auto">{user.number}</span>
              </div>
              <hr className="officer-sidebar__separator" />
            </div>
          )}

          {user.extra_details && (
            <div>
              <div className="officer-sidebar__address text-center">
                {renderAddress(user.extra_details)}
              </div>
              <hr className="officer-sidebar__separator" />
            </div>
          )}

          <Icon
            className="officer-sidebar__icon txt-color"
            src={eohIcon}
            size={28}
            originalSize={38}
          />
        </div>
      )}
    </div>
  )
}

export default OfficerSidebar
