import { h } from "preact"
import { PricingItem } from "@types"
import { useSelector } from "react-redux"
import { selectGlobalModalIframeID } from "@selectors"
import { openLeadWorkflowFromWithin } from "@iframeCommunication/utils"
import IntAprAndPoints from "./IntAprAndPoints"
import CtaButton from "./CtaButton"
import { If } from "@ui"

interface Props {
  item: PricingItem
  label: "purchase" | "refinance"
  term?: string
  disableCTA: boolean
}

const Card = ({ item, label, term, disableCTA }: Props) => {
  const iframeID = useSelector(selectGlobalModalIframeID)
  const handleOnClick = () => openLeadWorkflowFromWithin(iframeID)

  return (
    <div
      {...(!disableCTA && {
        tabIndex: 0,
        role: "button",
        onClick: handleOnClick
      })}
      className={`md:tw-flex tw-rounded-3xl md:tw-rounded-full tw-items-center tw-justify-between tw-bg-white tw-p-6 hover:tw-shadow-md tw-border tw-border-zinc-300 tw-transition txt-dark tw-space-y-4 md:tw-space-y-0 tw-group ${
        disableCTA ? "tw-cursor-default" : ""
      }`}
    >
      <div className="md:tw-pl-6">
        <span className="tw-block tw-font-medium tw-uppercase">{label}</span>
        <strong className="tw-block tw-text-xl tw-font-bold">{term}</strong>
      </div>

      <IntAprAndPoints item={item} className={disableCTA ? "md:tw-pr-6" : ""} />
      <If condition={!disableCTA} then={<CtaButton />} />
    </div>
  )
}

export default Card
