import { h } from "preact"
import { useEffect } from "preact/hooks"
import LOCTAButtons from "./LOCTAButtons"
import HeaderWrapper from "./HeaderWrapper"
import HeaderLoProfile from "./HeaderLoProfile"
import HeaderLOAvatar from "./LOAvatar"
import { useAppContext, useIsDesktop } from "@contexts"
import { If, HeaderLoSkeleton } from "@ui"
import LOSocialLinks from "../LOSocialLinks"
import PlayButton from "./PlayButton"
import "@styles/components/_header-lo.scss"

const HeaderLo = () => {
  const isDesktop = useIsDesktop()

  const {
    reviews: { getReviews, lo }
  } = useAppContext()

  useEffect(() => {
    getReviews("lo")
  }, [])

  const { isLoading } = lo

  return (
    <If
      condition={isLoading}
      then={<HeaderLoSkeleton />}
      else={
        <HeaderWrapper>
          <div className="tw-flex">
            <div className="tw-relative tw-mr-auto">
              <HeaderLOAvatar />
              <PlayButton />
            </div>
            <If
              condition={!isDesktop}
              then={<LOSocialLinks className="tw-mt-auto" />}
              else={<LOCTAButtons />}
            />
          </div>

          <HeaderLoProfile />
        </HeaderWrapper>
      }
    />
  )
}

export default HeaderLo
