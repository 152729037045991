import { h } from "preact"
import Button from "../Button"
import Icon from "../Icon"
import crossIcon from "@assets/icons/cross.svg"
import "@styles/components/_cross-btn.scss"
import { ScreenReaderOnly } from "@ui"

interface Props {
  onClick: () => void
  label?: string
  className?: string
  outline?: boolean
  primary?: boolean
  size?: "sm" | "md" | "lg"
}

const CrossBtn = ({
  onClick,
  label = "Clear",
  className = "",
  outline = true,
  primary = false,
  size = "sm"
}: Props) => (
  <Button
    link={!outline && !primary}
    pill
    noShadow
    adapt
    outline={outline}
    {...(size === "sm" ? { size: "sm" } : {})}
    className={`cross-btn ${className}`}
    onClick={onClick}
  >
    <Icon
      src={crossIcon}
      size={size === "sm" ? 12 : size === "md" ? 16 : 20}
      originalSize={16}
    />
    <ScreenReaderOnly>{label}</ScreenReaderOnly>
  </Button>
)

export default CrossBtn
