import { h, Fragment } from "preact"
import { useEffect, useState, useReducer, useRef, useMemo } from "preact/hooks"
import { useSelector, useDispatch } from "react-redux"
import { useAppContext } from "@contexts"
import { updateFormValues, sendForm } from "@actions"
import { Alert, If, Form, Chevron, Button } from "@ui"
import { PadlockIcon, SubmitBtn, SocialLogins } from "./FormChunks"

const initialState = {
  form: {},
  showResendTxt: false,
  invalidPhoneNumber: false,
  invalidEmail: false,
  invalidFields: false,
  inputNameWasFocused: false,
  isPhoneValidating: false
}

function reducer(state, action) {
  switch (action.type) {
    case "set_form":
      return { ...state, form: action.payload }
    case "toggle_invalid_phone":
      return { ...state, invalidPhoneNumber: action.payload }
    case "is_phone_validating":
      return { ...state, isPhoneValidating: action.payload }
    case "toggle_invalid_email":
      return { ...state, invalidEmail: action.payload }
    case "toggle_invalid_fields":
      return { ...state, invalidFields: action.payload }
    case "toggle_invalid_disclaimer":
      return { ...state, invalidDisclaimer: action.payload }
    case "toggle_input_name_focus":
      return { ...state, inputNameWasFocused: !state.inputNameWasFocused }
    default:
      return state
  }
}

const UserForm = ({
  isLoadingAuthCode,
  stepKey,
  fields,
  submitLabel,
  disclaimer,
  onSentCallback,
  getAuthCode
}) => {
  const dispatch = useDispatch()

  const formEle = useRef(null)

  const [disclaimerChecked, setDisclaimerChecked] = useState(false)
  const [doValidation, setDoValidation] = useState(false)
  const [state, dispatchLcl] = useReducer(reducer, initialState)

  const {
    values,
    validationRequired,
    background_validation_enabled,
    phoneRequired,
    formDataSent,
    google_login_enabled,
    hasResults,
    pricingGridIsLoading,
    validationReady,
    auth_request_id,
    isLastStep
  } = useSelector(({ wizard, app }) => {
    const cardsLength = wizard?.card_details?.cards.length ?? 0
    const hasResults = cardsLength > 0 && !wizard?.disable_pricing

    return {
      isLastStep: app.isLastStep,
      hasResults,
      ...wizard,
      formDataSent: wizard?.formDataSent || {},
      google_login_enabled: wizard?.google_login_enabled ?? false
    }
  })

  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  useEffect(() => {
    // if values.login_form is not empty, it means that the user filled the form
    // and we need to  set the state.form with the values from the store
    if (values.login_form) {
      dispatchLcl({ type: "set_form", payload: values.login_form.form })
    }
  }, [])

  const [showLoginOptions, setShowLoginOptions] = useState(true)

  const [emailIsNotFilled, setEmailIsNotFilled] = useState(true)

  useEffect(() => {
    const formStore = values[stepKey].form
    const emailField = (
      formStore?.email ??
      formDataSent?.email ??
      state?.form?.email ??
      ""
    ).trim()

    if (emailField.length === 0) {
      setEmailIsNotFilled(true)
      return
    }
    const newVal =
      fields[3] &&
      ((validationRequired && formStore?.[fields[3]?.id]) ||
        !validationRequired) &&
      state.invalidEmail

    setEmailIsNotFilled(newVal)
  }, [
    values[stepKey].form,
    state.invalidEmail,
    validationRequired,
    formDataSent?.email,
    state?.form?.email
  ])

  const onInput = e => {
    const target = e.target
    const name = target.name
    const value = target.type === "checkbox" ? target.checked : target.value
    const formValues = Object.assign({}, state.form, { [name]: value })

    dispatchLcl({
      type: "toggle_invalid_fields",
      payload: false
    })

    dispatch(updateFormValues({ value: { form: formValues }, key: stepKey }))
    dispatchLcl({ type: "set_form", payload: formValues })
  }

  const onBlur = e => {
    // trim the value before sending it to the store
    const target = e.target
    const name = target.name
    const value = target.value.trim()
    const formValues = Object.assign({}, state.form, { [name]: value })

    dispatch(updateFormValues({ value: { form: formValues }, key: stepKey }))
    dispatchLcl({ type: "set_form", payload: formValues })
  }

  const onPhoneValidationError = () => {
    dispatchLcl({
      type: "toggle_invalid_phone",
      payload: true
    })
  }

  const onPhoneValidationSuccess = () => {
    ;(background_validation_enabled || phoneRequired) && doSubmit()
    dispatchLcl({
      type: "toggle_invalid_phone",
      payload: false
    })
  }

  const onEmailValidationError = () => {
    dispatchLcl({
      type: "toggle_invalid_email",
      payload: true
    })
  }

  const onEmailValidationSuccess = () => {
    dispatchLcl({
      type: "toggle_invalid_email",
      payload: false
    })
  }

  const onSocialLogin = ({ email, first_name, last_name, validated }) => {
    const formValues = Object.assign({}, state.form, {
      email,
      first_name,
      last_name,
      validated
    })

    dispatch(updateFormValues({ value: { form: formValues }, key: stepKey }))

    dispatchLcl({ type: "set_form", payload: formValues })
    setShowLoginOptions(false)
  }

  const handleSubmit = e => {
    setDoValidation(true)
    e.preventDefault()
    if (!phoneRequired) {
      doSubmit()
    }
  }

  const doSubmit = () => {
    if (!validationRequired) {
      validateFieldsAndSubmitLead()
    } else {
      validateFieldsAndGenerateCode()
    }
  }

  useEffect(() => {
    if (validationReady && validationRequired) {
      validateFieldsAndCallback(submitLead)
    }
  }, [validationReady])

  const validateFieldsAndGenerateCode = () => {
    validateFieldsAndCallback(() => getAuthCode())
  }

  const validateFieldsAndSubmitLead = () => {
    validateFieldsAndCallback(submitLead)
  }

  const submitLead = params => {
    dispatch(sendForm({ values: params, onSentCallback }))
  }

  const validateFieldsAndCallback = callback => {
    if (!disclaimerChecked) {
      dispatchLcl({ type: "toggle_invalid_disclaimer", payload: true })
      setDoValidation(false)
      return
    }

    const params = { ...values[stepKey].form }

    const updateStore = !params.first_name

    params.first_name =
      params?.first_name ??
      formDataSent?.first_name ??
      state?.form?.first_name ??
      ""
    params.last_name =
      params?.last_name ??
      formDataSent?.last_name ??
      state?.form?.last_name ??
      ""
    params.email = (
      params?.email ??
      formDataSent?.email ??
      state?.form?.email ??
      ""
    ).toLowerCase()

    if (phoneRequired) {
      params.phone = params.phone || formDataSent.phone || state.form.phone
    }

    if (auth_request_id) {
      params.auth_request_id = auth_request_id
    }

    updateStore &&
      dispatch(updateFormValues({ value: { form: params }, key: stepKey }))

    // if for any reason params.email, params.first_name, params.last_name, params.phone(if  phoneRequired is true) are empty, undefined or null, we don't send the form
    if (
      !params.email ||
      !params.first_name ||
      !params.last_name ||
      (phoneRequired && !params.phone)
    ) {
      dispatchLcl({ type: "toggle_invalid_fields", payload: true })
      return
    } else if (state.invalidPhoneNumber) {
      return
    }

    callback(params)
    setDoValidation(false)
  }

  const onClickContinue = () => {
    if (formEle.current?.checkValidity()) {
      setShowLoginOptions(false)
    }
  }

  const onClickBack = () => {
    setDoValidation(false)
    setShowLoginOptions(true)
  }

  const onDisclaimerChange = e => {
    setDisclaimerChecked(e.target.checked)
    if (e.target.checked) {
      dispatchLcl({ type: "toggle_invalid_disclaimer", payload: false })
    }
  }

  const formStore = values[stepKey].form

  const hidePhoneField = useMemo(() => {
    return (
      phoneRequired ||
      (validationRequired && formStore?.[fields?.[4]?.id]) ||
      !validationRequired
    )
  }, [phoneRequired, validationRequired, formStore])

  useEffect(() => {
    if (hidePhoneField && !isLastStep) {
      const form = state?.values?.login_form?.form ?? {}
      const formValues = Object.assign({}, form, {
        phone: ""
      })

      dispatch(updateFormValues({ value: { form: formValues }, key: stepKey }))
      dispatchLcl({ type: "set_form", payload: formValues })
    }
  }, [hidePhoneField, isLastStep])

  return (
    <div className="wizard__step-form-wrapper">
      <form
        ref={formEle}
        onSubmit={handleSubmit}
        className={`wizard__step-form ${showLoginOptions ? "is-sl" : ""}`}
      >
        <If
          condition={!showLoginOptions}
          then={
            <Button adapt link onClick={onClickBack}>
              <Chevron side="left" /> Back
            </Button>
          }
        />
        <If
          condition={showLoginOptions}
          then={
            <div>
              <div className="wizard__step-form-login-options">
                <SocialLogins
                  width={isMobile ? 306 : 290}
                  embed="lead_workflow"
                  googleLoginEnabled={google_login_enabled}
                  field={{
                    data: {
                      emailIsNotFilled,
                      ...fields[3],
                      value:
                        formStore?.email ??
                        formDataSent?.email ??
                        state?.form?.email ??
                        ""
                    },
                    onValidationError: onEmailValidationError,
                    onValidationSuccess: onEmailValidationSuccess,
                    onInput: onInput,
                    onFocus: () => {
                      dispatchLcl({
                        type: "toggle_invalid_email",
                        payload: false
                      })
                    }
                  }}
                  onClickContinue={onClickContinue}
                  onSocialLogin={onSocialLogin}
                />
              </div>
            </div>
          }
          else={
            <Fragment>
              <Form.Row oneColSM twoCol compact>
                <Form.Col>
                  <Form.Input
                    isMaterial
                    size="lg"
                    name={fields[0].id}
                    type={fields[0].type}
                    value={
                      formStore?.first_name ||
                      formDataSent.first_name ||
                      state.form.first_name ||
                      ""
                    }
                    onInput={onInput}
                    onBlur={onBlur}
                    label={fields[0].placeholder}
                    placeholder={fields[0].placeholder}
                    required
                    id="firstName"
                  />
                </Form.Col>
                <Form.Col>
                  <Form.Input
                    isMaterial
                    size="lg"
                    name={fields[1]?.id}
                    type={fields[1]?.type}
                    value={
                      formStore?.last_name ||
                      formDataSent.last_name ||
                      state.form.last_name ||
                      ""
                    }
                    onInput={onInput}
                    onBlur={onBlur}
                    label={fields[1]?.placeholder}
                    placeholder={fields[1]?.placeholder}
                    required
                  />
                </Form.Col>
              </Form.Row>

              <If
                condition={hidePhoneField}
                then={
                  <Form.Row compact oneColSM>
                    <Form.Col>
                      <Form.PhoneNumber
                        APIValidationEnabled={background_validation_enabled}
                        triggerValidation={doValidation}
                        valiateOnBlur={false}
                        onValidationStart={() => {
                          setDoValidation(false)
                          dispatchLcl({
                            type: "is_phone_validating",
                            payload: true
                          })
                        }}
                        onValidationError={() => {
                          onPhoneValidationError()
                          dispatchLcl({
                            type: "is_phone_validating",
                            payload: false
                          })
                        }}
                        onValidationSuccess={() => {
                          onPhoneValidationSuccess()
                          dispatchLcl({
                            type: "is_phone_validating",
                            payload: false
                          })
                        }}
                        isMaterial
                        size="lg"
                        ref={inputPhone => {
                          inputPhone = inputPhone
                        }}
                        className={`${
                          state.invalidPhoneNumber ? "is-invalid" : ""
                        }`}
                        name={fields[2]?.id}
                        type={fields[2]?.type}
                        value={
                          formStore?.phone ||
                          formDataSent.phone ||
                          state.form.phone ||
                          ""
                        }
                        onChange={onInput}
                        onFocus={() => {
                          setDoValidation(false)
                          dispatchLcl({
                            type: "toggle_invalid_phone",
                            payload: false
                          })
                        }}
                        label={fields[2]?.placeholder}
                        placeholder={fields[2]?.placeholder}
                        required={phoneRequired || validationRequired}
                        aria-describedby="phone_number"
                      />
                    </Form.Col>
                  </Form.Row>
                }
              />

              <If
                condition={validationRequired && !phoneRequired}
                then={
                  <Form.Row compact className="form__checkbox-row">
                    <Form.Col>
                      <Form.Group>
                        <div className="form__field">
                          <input
                            type="checkbox"
                            name={fields[4]?.id}
                            className="form__checkbox"
                            onChange={onInput}
                            id={fields[4]?.id}
                            checked={state.form[fields[4]?.id]}
                          />
                          <label htmlFor={fields[4]?.id}>
                            {fields[4]?.options[0].label}
                          </label>
                        </div>
                      </Form.Group>
                    </Form.Col>
                  </Form.Row>
                }
              />

              <If
                condition={disclaimer.length > 0}
                then={
                  <Form.Disclaimer
                    checked={disclaimerChecked}
                    onChange={onDisclaimerChange}
                    label={disclaimer}
                  />
                }
              />
            </Fragment>
          }
        />

        <If
          condition={
            fields[4] &&
            (phoneRequired ||
              (validationRequired && formStore?.[fields[4]?.id]) ||
              !validationRequired) &&
            state.invalidPhoneNumber
          }
          then={
            <Alert.Danger id="phone_number" size="sm">
              Invalid Phone Number.
            </Alert.Danger>
          }
        />

        <If
          condition={state.invalidEmail}
          then={
            <Alert.Danger id="email" size="sm">
              Invalid Email.
            </Alert.Danger>
          }
        />

        <If
          condition={state.invalidFields}
          then={
            <Alert.Danger size="sm">
              Please review the form fields.
            </Alert.Danger>
          }
        />
        <If
          condition={state.invalidDisclaimer}
          then={
            <Alert.Danger size="sm">
              Please check the box and accept to submit.
            </Alert.Danger>
          }
        />

        <If
          condition={!showLoginOptions}
          then={
            <SubmitBtn
              isLoading={pricingGridIsLoading || isLoadingAuthCode}
              submitLabel={
                hasResults ? submitLabel : "Unlock & view my options"
              }
              disabled={state.isPhoneValidating}
            />
          }
        />

        <PadlockIcon />
      </form>
    </div>
  )
}

export default UserForm
