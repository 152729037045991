import { ComponentChildren, h } from "preact"
import "@styles/components/_reviews-mobile-cta-footer.scss"

interface Props {
  children: ComponentChildren
}

const MobileFooter = ({ children }: Props) => {
  return (
    <div className="reviews-mobile-cta-footer gap-base">
      <p className="reviews-mobile-cta-footer__txt">
        🎉 <span>Customized rates tailored to your need.</span>
      </p>
      {children}
    </div>
  )
}

export default MobileFooter
