import { h } from "preact"
import { ScreenReaderOnly } from "@ui"
import "@styles/components/_progress-bar.scss"

interface ProgressBarProps {
  percentage?: string
  label?: string
  className?: string
  accentColor?: boolean
}

const ProgressBar = ({
  percentage = "0%",
  label,
  className = "",
  accentColor = true
}: ProgressBarProps) => (
  <div className={`progress-bar ${className}`}>
    {label && <ScreenReaderOnly>{label}</ScreenReaderOnly>}
    <div
      role="progressbar"
      aria-label={label}
      aria-valuenow={parseInt(percentage)}
      aria-valuemin={0}
      aria-valuemax={100}
      className={`progress-bar__inner ${
        accentColor ? "accent-bg-color" : "is-default"
      }`}
      data-percentage={percentage}
      style={{ width: percentage }}
    />
  </div>
)

export default ProgressBar
