import { h } from "preact"
import { useGetTermLabel } from "@hooks"
import FeesBlock from "./FeesBlock"
import Points from "./Points"
import MortgageTerm from "./MortgageTerm"
import { PricingItem, PricingItemType } from "@types"
import PrincipalAndInterest from "./PrincipalAndInterest"
import { useRateTableRowActions } from "@hooks"

interface Props {
  item: PricingItem
  type: PricingItemType
  hideFeesModal?: boolean
  fees: number
}

const PricingItemBodyRTMobile = ({
  item,
  type,
  hideFeesModal,
  fees
}: Props) => {
  const term = useGetTermLabel(item)
  const { handleClickFees, handleClickPI } = useRateTableRowActions(item)

  return (
    <div className="pricing-item__body PricingItemBodyDashMobile">
      <div className="pricing-item__body-inner tw-flex tw-items-center tw-justify-between">
        <FeesBlock
          type={type}
          fees={fees}
          hideFeesModal={hideFeesModal}
          handleClickFees={handleClickFees}
        />
        <Points points={item.pts} />

        <PrincipalAndInterest
          value={item.principalAndInterest}
          hasTooltip={false}
          onClick={handleClickPI}
        />
        <MortgageTerm term={term} />
      </div>
    </div>
  )
}

export default PricingItemBodyRTMobile
