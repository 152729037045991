import { h } from "preact"
import Icon from "../Icon"
import svgIcon from "@assets/icons/check-list.svg"
import { If } from "@ui"
import "@styles/components/list/_list.scss"

type Props = {
  items: string[]
  className?: string
  iconSize?: number
  originalIconSize?: number
  dots?: boolean
  svg?: string
}

const RowList = ({
  items,
  svg = svgIcon,
  iconSize = 24,
  originalIconSize = 24,
  dots = false,
  className = ""
}: Props) => (
  <ul className={`row-list ${className} ${!dots ? "notDot" : ""}`}>
    <If
      condition={items.length > 0}
      then={items.map(item => (
        <li>
          <If
            condition={!!svg}
            then={
              <Icon
                className="customIcon"
                src={svg}
                size={iconSize}
                originalSize={originalIconSize}
              />
            }
          />
          {item}
        </li>
      ))}
    />
  </ul>
)

export default RowList
