import { MediaSize } from "@types"

export const ALPHABET = "abcdefghijklmnopqrstuvwxyz"
export const ALPHABET_UPPER_CASE = ALPHABET.toUpperCase()

export const MEDIA_QUERIES: Record<MediaSize, string> = {
  XS: "(max-width: 480px)",
  SM: "(min-width: 481px) and (max-width: 767px)",
  MD: "(min-width: 768px) and (max-width: 991px)",
  LG: "(min-width: 992px) and (max-width: 1199px)",
  XL: "(min-width: 1200px)"
}

export { default as US_STATES } from "./us-states"
export * from "./action-types"
export * from "./rate-table"
export * from "./rate-cards"
export * from "./rates-toolbar"
export * from "./embed-names"
export * from "./reviews-providers"
export * from "./subscription-dashboard"
export * from "./global-modal"
export * from "./line-chart"
export * from "./lo-page"
export * from "./elements-ids"
export * from "./fees"
export * from "./form-options"
export * from "./oauth-providers"
export * from "./comparison-tool"

export const MEDIA_QUERIES_HOVER_POINTER = {
  NO: "(hover: none) and (pointer: coarse)",
  YES: "(hover: hover) and (pointer: fine)"
}

export const ONE_DAY_IN_MILLISECONDS = 86400000
export const ONE_HOUR_IN_MILLISECONDS = 3600000
