import { Fragment, h } from "preact"
import { If } from "@ui"
import { useDynamicForm } from "./DynamicFormProvider"

const DynamicFormHeader = () => {
  const { title, subtitle } = useDynamicForm()

  return (
    <Fragment>
      <If
        condition={title.length > 0}
        then={<h2 className="dForm__title text-center">{title}</h2>}
      />
      <If
        condition={subtitle.length > 0}
        then={<h3 className="dForm__subtitle text-center">{subtitle}</h3>}
      />
    </Fragment>
  )
}

export default DynamicFormHeader
