import { useSelector } from "react-redux"
import { useMemo } from "preact/hooks"
import { RootStates } from "@types"
import { formDataToUrlParams } from "@helpers"
import { FormData } from "@types"

const useAppLink = () => {
  const { formData, applicationLink, apply_now_params_enabled } = useSelector(
    ({ wizard, app, subscriptionDash }: RootStates) => {
      let formData: Partial<FormData> = {}

      // first case is for rate table and workflow fees modal
      if (app.formData && Object.keys(app.formData).length) {
        formData = app.formData
      } else if (wizard?.values?.steps) {
        formData = {
          ...wizard?.values.steps,
          ...wizard?.values.login_form.form
        }
      } else {
        formData = {
          ...subscriptionDash?.quote_info,
          ...subscriptionDash
        }
      }

      return {
        apply_now_params_enabled: wizard?.apply_now_params_enabled ?? false,
        formData,
        applicationLink: app.applicationLink.length
          ? app.applicationLink
          : app.lo?.app_link ?? ""
      }
    }
  )

  const parsedAppLink = useMemo(() => {
    if (!applicationLink) return ""
    const url = formDataToUrlParams(
      applicationLink,
      formData,
      apply_now_params_enabled
    )
    return url
  }, [applicationLink, formData])

  return parsedAppLink
}

export default useAppLink
