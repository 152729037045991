import { Fragment, h } from "preact"
import { Form, If } from "@ui"
import {
  TooltipRefinanceFields,
  ResponsiveRefinanceFields
} from "./ExtraFields"
import { OnChangeValue, LoanDetailsFormFields } from "@types"
import { useMediaFlags } from "@contexts"

interface Props {
  formData: LoanDetailsFormFields
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
  numericInputChange: (value: string, name: Event) => void
  showFullForm?: boolean
  hasARMTerms: boolean
  disabledFields?: Array<keyof LoanDetailsFormFields>
}

const RefinanceForm = ({
  hasARMTerms,
  formData,
  formData: {
    list_price,
    cash_out,
    loan_amount,
    credit_score = 0,
    property_type,
    residency_type
  },
  handleOnChange,
  numericInputChange,
  showFullForm = false,
  disabledFields = []
}: Props) => {
  const { isDesktop, isTablet, isMobile } = useMediaFlags()

  const showMoreFilters = isTablet && !showFullForm

  return (
    <Fragment>
      <Form.ListPriceField onChange={numericInputChange} value={list_price} />

      <Form.LoanAmountField onChange={numericInputChange} value={loan_amount} />

      <If
        condition={isDesktop}
        then={
          <Form.CashOutField onChange={numericInputChange} value={cash_out} />
        }
      />

      <Form.CreditScoreFull
        value={credit_score}
        name="credit_score"
        onChangeComplete={(value: number) =>
          handleOnChange(value, "credit_score")
        }
        showErrorWhenEmpty
      />

      <If
        condition={!isMobile}
        then={
          <Fragment>
            <Form.PropertyTypeField
              handleOnChange={(value: string) =>
                handleOnChange(value, "property_type")
              }
              value={property_type}
            />

            <Form.ResidencyTypeField
              value={residency_type}
              onChange={(value: string) =>
                handleOnChange(value, "residency_type")
              }
            />
          </Fragment>
        }
      />

      <ResponsiveRefinanceFields
        hasARMTerms={hasARMTerms}
        showFields={isDesktop || showFullForm}
        formData={formData}
        handleOnChange={handleOnChange}
        numericInputChange={numericInputChange}
        hasLoanTerm={showFullForm}
        disabledFields={disabledFields}
      />
      <TooltipRefinanceFields
        hasARMTerms={hasARMTerms}
        formData={formData}
        handleOnChange={handleOnChange}
        numericInputChange={numericInputChange}
        showMoreFiltersBtn={showMoreFilters}
        hasLoanTerm={showFullForm}
      />
    </Fragment>
  )
}

export default RefinanceForm
