/**
 * GA Event functions used on the rate table
 */
import eventDispatch from "./eventDispatch"

const category = "rate_table"

const dispatchRateTableLoadedEvent = () => {
  const name = "rate_table_loaded"
  eventDispatch({
    name,
    category
  })
}

const dispatchRateTableNextEvent = () => {
  const name = "next_button_clicked"
  eventDispatch({
    name,
    category
  })
}

const dispatchRateTableSwitchClickEvent = () => {
  const name = "subscribe_switch_clicked"
  eventDispatch({
    name,
    category
  })
}

const dispatchRateTableCtaEvent = () => {
  const name = "workflow_button_clicked"
  eventDispatch({
    name,
    category
  })
}

const dispatchRateTableUpdateClickedEvent = () => {
  const name = "update_button_clicked"
  eventDispatch({
    name,
    category
  })
}

const dispatchRateTableLeadCapturedEvent = () => {
  const name = "lead_captured"
  eventDispatch({
    name,
    category
  })
}

const dispatchRateTablerateTableLeadErrorEvent = () => {
  eventDispatch({
    name: "lead_capture_error",
    category
  })
}

const dispatchRateTableraterateTableApplyNowClickedEvent = () => {
  const name = "apply_now_clicked"
  eventDispatch({
    name,
    category
  })
}

export {
  dispatchRateTableLoadedEvent,
  dispatchRateTableNextEvent,
  dispatchRateTableSwitchClickEvent,
  dispatchRateTableCtaEvent,
  dispatchRateTableUpdateClickedEvent,
  dispatchRateTableLeadCapturedEvent,
  dispatchRateTablerateTableLeadErrorEvent,
  dispatchRateTableraterateTableApplyNowClickedEvent
}
