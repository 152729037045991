import { h } from "preact"
import { Icon } from "@ui"
import successIcon from "@assets/icons/check-and-circle.svg"

const FormSuccessMsg = () => (
  <div className="contact-form__success scale-in-bottom">
    <Icon src={successIcon} size={[65, 64]} originalSize={[43, 42]} />
    <h3>Success!</h3>
    <p>We will be in touch with you soon to address your specific needs.</p>
  </div>
)

export default FormSuccessMsg
