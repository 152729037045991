import { h } from "preact"
import { BLOCKING_MODAL_TXT } from "@content"

const BlockingModal = () => (
  <div className="modal-cover is-blocking">
    <div className="blocking-modal">
      <div className="blocking-modal__content">
        <h1>{BLOCKING_MODAL_TXT.title}</h1>
        <p>{BLOCKING_MODAL_TXT.msg}</p>
      </div>
    </div>
    <div className="modal__overlay" />
  </div>
)

export default BlockingModal
