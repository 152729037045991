import { h } from "preact"
import { InputMaskedFull } from "../../inputs"

interface Props {
  value?: number | null
  onChange: (value: string, e: Event) => void
  isMaterial?: boolean
  showErrorWhenEmpty?: boolean
  name?: string
  label?: string
  size?: "sm" | "md" | "lg"
  notStacked?: boolean
}

const CashOut = ({
  value = 0,
  isMaterial = true,
  label = "Cash Out",
  name = "cash_out",
  onChange,
  size = "md",
  showErrorWhenEmpty = true,
  notStacked = false
}: Props) => {
  if (value === null) {
    value = 0
  }
  return (
    <InputMaskedFull
      size={size}
      isMaterial={isMaterial}
      label={label}
      inputPrefix="$"
      required
      name={name}
      onChange={onChange}
      value={value}
      allowDecimal={false}
      showErrorWhenEmpty={showErrorWhenEmpty}
      notStacked={notStacked}
    />
  )
}

export default CashOut
