import { h } from "preact"
import { useEffect, useReducer } from "preact/hooks"
import { useSelector } from "react-redux"
import { ApplyLoan, InlineAffordabilityCalculator } from "@composite"
import { RootStates } from "@types"
import { useAppContext, useLoTabsContext } from "@contexts"
import { LO_TABS_PANEL_ID } from "@constants"
import InlineRateTableWrapper from "../InlineRateTableWrapper"
import InlineMortgageCalculator from "../Mortgage/InlineMortgageCalculator"
import { GridReviews } from "../reviews"
import Section from "./Section"
import LeadWorkflowWrapper from "./LeadWorkflowWrapper"

type State = {
  leadWorkflowLoaded: boolean
  ratesLoaded: boolean
  calcsLoaded: boolean
  reviewsLoaded: boolean
}

type Action =
  | { type: "SET_LEAD_WORKFLOW_LOADED"; payload: boolean }
  | { type: "SET_RATES_LOADED"; payload: boolean }
  | { type: "SET_CALCS_LOADED"; payload: boolean }
  | { type: "SET_REVIEWS_LOADED"; payload: boolean }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_LEAD_WORKFLOW_LOADED":
      return { ...state, leadWorkflowLoaded: action.payload }
    case "SET_RATES_LOADED":
      return { ...state, ratesLoaded: action.payload }
    case "SET_CALCS_LOADED":
      return { ...state, calcsLoaded: action.payload }
    case "SET_REVIEWS_LOADED":
      return { ...state, reviewsLoaded: action.payload }
    default:
      return state
  }
}

const PrimaryBTBody = () => {
  const { activeTab } = useLoTabsContext()

  const {
    reviews: { getReviews, brand, lo }
  } = useAppContext()

  const {
    config,
    loReviews,
    brandReviews,
    mountInlineLeadWorkflow,
    mountInlineAffordCalc,
    mountInlineCalc,
    mountInlineRateTable
  } = useSelector(({ app }: RootStates) => {
    const { widgets } = app

    return {
      config: app,
      loReviews: widgets?.loReviews,
      brandReviews: widgets?.brandReviews,
      mountInlineLeadWorkflow: widgets?.mountInlineLeadWorkflow ?? false,
      mountInlineAffordCalc: widgets?.mountInlineAffordCalc,
      mountInlineCalc: widgets?.mountInlineCalc,
      mountInlineRateTable: widgets?.mountInlineRateTable
    }
  })

  const initialState: State = {
    leadWorkflowLoaded: mountInlineLeadWorkflow,
    ratesLoaded: false,
    calcsLoaded: false,
    reviewsLoaded: false
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const { leadWorkflowLoaded, ratesLoaded, calcsLoaded, reviewsLoaded } = state

  useEffect(() => {
    switch (activeTab) {
      case "leadWorkflow":
        if (!leadWorkflowLoaded) {
          dispatch({ type: "SET_LEAD_WORKFLOW_LOADED", payload: true })
        }
        break
      case "rates":
        if (!ratesLoaded) dispatch({ type: "SET_RATES_LOADED", payload: true })
        break
      case "calcs":
        if (!calcsLoaded) dispatch({ type: "SET_CALCS_LOADED", payload: true })
        break
      case "reviews":
        if (!reviewsLoaded) {
          getReviews("brand")
          getReviews("lo")
          dispatch({ type: "SET_REVIEWS_LOADED", payload: true })
        }
        break
      default:
        break
    }
  }, [activeTab])

  return (
    <div id={LO_TABS_PANEL_ID} className="bt-body tw-px-3 tw-py-8">
      <Section
        mount={!!mountInlineLeadWorkflow && leadWorkflowLoaded}
        isVisible={leadWorkflowLoaded && activeTab === "leadWorkflow"}
      >
        <LeadWorkflowWrapper />
      </Section>

      <Section
        mount={!!mountInlineRateTable && ratesLoaded}
        isVisible={ratesLoaded && activeTab === "rates"}
      >
        <InlineRateTableWrapper hideReviews noBrand hideAlertButton />
      </Section>

      <Section
        mount={!!(mountInlineAffordCalc || mountInlineCalc) && calcsLoaded}
        isVisible={calcsLoaded && activeTab === "calcs"}
      >
        {mountInlineCalc && <InlineMortgageCalculator />}
        {mountInlineAffordCalc && <InlineAffordabilityCalculator />}
      </Section>

      <Section
        mount={!!(loReviews || brandReviews) && reviewsLoaded}
        isVisible={reviewsLoaded && activeTab === "reviews"}
      >
        <GridReviews />
      </Section>

      <Section mount isVisible={activeTab === "loan"}>
        <ApplyLoan />
      </Section>
    </div>
  )
}

export default PrimaryBTBody
