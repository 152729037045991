import { h } from "preact"
import { useAppContext } from "@contexts"
import { If } from "@ui"
import MobileFooter from "./MobileFooter"
import CTAButton from "./CTAButton"
import RegularFooter from "./RegularFooter"

type Props = {
  inSidebar?: boolean
  className?: string
  btnAdapt?: boolean
}

const ReviewsCTAFooter = ({
  inSidebar,
  className = "",
  btnAdapt = true
}: Props) => {
  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  return (
    <If
      condition={isMobile}
      then={
        <MobileFooter>
          <CTAButton btnAdapt={btnAdapt} size="md" />
        </MobileFooter>
      }
      else={
        <RegularFooter inSidebar={inSidebar} className={className}>
          <CTAButton btnAdapt={btnAdapt} />
        </RegularFooter>
      }
    />
  )
}

export default ReviewsCTAFooter
