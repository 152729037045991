import { h } from "preact"
import { useEffect } from "preact/hooks"
import { useSelector } from "react-redux"
import { RootStates } from "@types"
import ReviewRatingBlock from "../ReviewRatingBlock"
import { If } from "@ui"
import { useAppContext } from "@contexts"
import "@styles/components/_reviews-line.scss"

type Props = {
  className?: string
  limit?: number
}

const ReviewsLine = ({ className = "", limit = 0 }: Props) => {
  const {
    reviews: { getReviews, brand }
  } = useAppContext()

  useEffect(() => {
    getReviews("brand")
  }, [])

  const { isLoading, providers, noReviews } = brand

  const bank = useSelector(({ app: { lo } }: RootStates) => lo?.bank)

  return (
    <If
      isLoading={isLoading}
      condition={!noReviews}
      then={
        <div className={`reviews-line ${className}`}>
          <p className="tw-text-center reviews-line__title">
            Reviews of {bank}
          </p>

          <div
            className={
              noReviews && isLoading === false
                ? "tw-hidden"
                : "tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-items-center tw-justify-center"
            }
          >
            {providers.map((provider, index) => {
              if (limit && index >= limit) return null

              return (
                <ReviewRatingBlock
                  className="reviews-line__item"
                  type="brand"
                  iconSize="sm"
                  ratingSize={10}
                  accentColor={false}
                  provider={provider}
                  isLogoOriginal
                  hideTT
                />
              )
            })}
          </div>
        </div>
      }
    />
  )
}

export default ReviewsLine
