import { h } from "preact"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { abbreviateN, numberToUSD } from "@helpers"
import { If } from "@ui"
import "@styles/components/_charts.scss"

Highcharts.Templating.helpers.numberToUSD = (value: number) =>
  numberToUSD(value, true)

Highcharts.Templating.helpers.abbreviateN = (value: number) =>
  abbreviateN(value)

type Legend = {
  name: string
  color: string
}

const GLOBAL_OPTIONS: Highcharts.Options = {
  credits: { enabled: false },
  title: {
    text: ""
  },
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false
  }
}

interface ChartProps {
  title?: string
  options: Highcharts.Options
  legend?: Legend[]
}

const Chart = ({ options, title, legend }: ChartProps) => {
  const mergedOptions = { ...GLOBAL_OPTIONS, ...options }

  return (
    <div className="chart">
      <If
        condition={!!title}
        then={<h3 className="chart__title">{title}</h3>}
      />

      <HighchartsReact highcharts={Highcharts} options={mergedOptions} />
      <If
        condition={!!legend}
        then={
          <div className="chart__legend">
            {legend?.map((item, index) => (
              <div key={index} className="chart__legend-item">
                <div
                  className="chart__legend-color"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span>{item.name}</span>
              </div>
            ))}
          </div>
        }
      />
    </div>
  )
}

export default Chart
