import { Fragment, h } from "preact"
import { createPortal } from "preact/compat"
import { useSelector } from "react-redux"
import HeaderLo from "../HeaderLo"
import PrimaryBTTabs from "../PrimaryBTTabs"
import {
  InitWorkflowBanner,
  SubscriptionBanner,
  BlockingModal
} from "@composite"
import { RootStates } from "@types"
import { LoTabsContextProvider } from "@contexts"
import { BannerSkeleton, LogoFooter, If } from "@ui"
import { FullReviews } from "../reviews"
import "@styles/components/_primary-bt.scss"

interface Props {
  showBlockingModal: boolean
}

const PrimaryBT = ({ showBlockingModal = false }: Props) => {
  const { mountInlineRateTable, leadWorkflowIsLoading, mountReviews, loid } =
    useSelector(({ app, wizard }: RootStates) => {
      const mountInlineLeadWorkflow =
        app.widgets?.mountInlineLeadWorkflow ?? false

      let leadWorkflowIsLoading = false

      if (mountInlineLeadWorkflow) {
        leadWorkflowIsLoading = wizard?.isLoading ?? false
      }

      return {
        loid: app?.lo?.loid,
        mountInlineRateTable: app?.widgets?.mountInlineRateTable,
        mountReviews:
          (app?.widgets?.loReviews || app?.widgets?.brandReviews) ?? false,
        leadWorkflowIsLoading
      }
    })

  return (
    <LoTabsContextProvider>
      <div className="tw-bg-white tw-pb-10">
        <HeaderLo />

        <div className="container tw-flex-col tw-pt-8 tw-px-4 lg:tw-px-6 xl:tw-px-0">
          <PrimaryBTTabs />

          <If
            condition={leadWorkflowIsLoading}
            then={<BannerSkeleton />}
            else={
              <Fragment>
                <InitWorkflowBanner className="tw-mt-16" />
                <If
                  condition={mountReviews}
                  then={<FullReviews className="tw-pt-24" />}
                />
                <If
                  condition={!!mountInlineRateTable}
                  then={<SubscriptionBanner className="tw-mt-12" />}
                />
              </Fragment>
            }
          />
          <LogoFooter />
        </div>

        {showBlockingModal &&
          createPortal(
            <BlockingModal />,
            document.getElementById("blocking-modal") as Element
          )}
      </div>
    </LoTabsContextProvider>
  )
}

export default PrimaryBT
