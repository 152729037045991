import { getUrlVars } from "@helpers"
import { matchMediaSize } from "../helpers/matchMedia"
import { AppBaseState } from "@types"

const { app_key } = getUrlVars()

const defaultState: AppBaseState = {
  media: matchMediaSize(),
  app_key,
  toasts: [],
  offerDetailsLoaded: false,
  offerDetailsIsLoading: true,
  offerDetails: [],
  forceExitCTA: false,
  forceExitCTAShowed: false,
  isLastStep: false,
  bbToken: "",
  widgetType: "",
  xloid: 0,
  source: "",
  applicationLink: "",
  serverPath: "",
  leadCapture: false,
  leadCaptureType: "",
  rateflow_id: 0,
  headerImg: "",
  autoSlide: false,
  disableCalcCards: false,
  jornayaId: "",
  wrapperID: "",
  type: "",
  nmls: "",
  referrerParam: false,
  modals: [],
  exposeAPI: false,
  exitCTAStepPassed: false,
  custom_params: []
}

export default defaultState
