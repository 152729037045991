import { h } from "preact"
import { RowLoader, If } from "@ui"
import { PricingItems } from "@types"
import LeadWorkflowPricingItem from "../LeadWorkflowPricingItem"

type Props = {
  currentItems: PricingItems
  isLoading: boolean
}

const Rows = ({ currentItems, isLoading }: Props) => (
  <div className="pricing-rows__inner" style={{ height: 250 }}>
    <If
      condition={isLoading}
      then={<RowLoader />}
      elseCondition={currentItems.length > 0}
      else={currentItems.map((item, index) => {
        return (
          <LeadWorkflowPricingItem
            className="d-flex align-items-center"
            key={item.hash}
            index={index}
            item={item}
            isRow
          />
        )
      })}
      default={<div className="no-cards" />}
    />
  </div>
)

export default Rows
