import { h } from "preact"
import Icon from "../Icon"
import { If } from "@ui"
import Button from "../Button"
import GlassesIcon from "@assets/icons/glasses.svg"

interface Props {
  title: string
  message?: string
  className?: string
  onReset?: () => void
}

const NoRatesMessage = ({
  title,
  message = "",
  className = "",
  onReset
}: Props) => (
  <div
    className={`tw-my-12 md:tw-mt-24 tw-mx-24 tw-text-center txt-dark ${className}`}
  >
    <Icon fill="transparent" src={GlassesIcon} size={40} originalSize={40} />
    <h3 className="tw-text-base tw-font-bold tw-mt-4 tw-mb-0">{title}</h3>
    <If
      condition={!!message.length}
      then={<p className="tw-mt-1 tw-mb-0">{message}</p>}
    />
    <If
      condition={!!onReset}
      then={
        <Button
          size="md"
          adapt
          noShadow
          onClick={onReset}
          className="tw-min-w-32 tw-mt-6"
        >
          Reset scenario
        </Button>
      }
    />
  </div>
)

export default NoRatesMessage
