import { h } from "preact"
import { Form } from "@ui"

const DynamicFormInputNumber = ({ name, value, onInput, label, required }) => {
  const handleOnInput = newValue => {
    onInput(newValue, name)
  }

  return (
    <Form.Row>
      <Form.Col>
        <Form.NumericInputMaterial
          name={name}
          value={value}
          handleInputChange={handleOnInput}
          label={label}
          required={required}
          forceEmpty
        />
      </Form.Col>
    </Form.Row>
  )
}

export default DynamicFormInputNumber
