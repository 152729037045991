import { createStore, applyMiddleware, combineReducers } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import {
  rootReducer,
  wizardReducer,
  rateTableReducer,
  subscriptionDashReducer,
  mortgageCalcReducer
} from "../reducers"
import { middlewares } from "../middleware"
import createSagaMiddleware from "redux-saga"
import rootSaga from "../sagas"
import { EmbedConfig, RootReducers } from "@types"

let store: any

type InitStore = {
  isSubscriptionDash?: boolean // Keep this as an optional parameter
  config?: EmbedConfig
}

const initStore = ({ isSubscriptionDash, config }: InitStore = {}) => {
  // Check if store already exists
  if (store) {
    return store // Return the existing store
  }

  const initialiseSagaMiddleware = createSagaMiddleware()
  const storeEnhancers = composeWithDevTools({ trace: true })

  // Always include necessary reducers
  const reducers: RootReducers = {
    app: rootReducer,
    wizard: wizardReducer,
    rateTable: rateTableReducer,
    mortgageCalc: mortgageCalcReducer
  }

  // Conditionally include the subscriptionDash reducer
  if (isSubscriptionDash) {
    reducers.subscriptionDash = subscriptionDashReducer
  }

  const combinedReducers = combineReducers(reducers)

  store = createStore(
    combinedReducers,
    { app: config as any },
    storeEnhancers(applyMiddleware(middlewares, initialiseSagaMiddleware))
  )

  initialiseSagaMiddleware.run(rootSaga)

  return store
}

export { store, initStore }
