import { h } from "preact"
import CountUp from "react-countup"
import { toFixed } from "@helpers"

interface Props {
  list_price: number
}

const CalcSummary = ({ list_price }: Props) => {
  const fixedListPrice = Number(toFixed(list_price, 0))

  return (
    <div className="afford-calc__summary text-center">
      <div className="afford-calc__list-price">
        $
        <CountUp duration={1} end={fixedListPrice} separator="," />
      </div>
      <h3>Home price you can afford</h3>
      <p>
        This calculation is based on your income, a house at this price should
        fit comfortably within your budget.
      </p>
    </div>
  )
}
export default CalcSummary
