import axios from "axios"
import { bbProcessEnv } from "@helpers"
import { SubscriptionData, SubscriptionDashConfig } from "@types"

export const getSubscriptionData = async (params: {
  id: string
}): Promise<SubscriptionData | undefined> => {
  try {
    const ENDPOINT = `${bbProcessEnv.API_URL}/api/v1/subscriptions`
    const response = await axios.get(ENDPOINT, { params })
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getConfig = async (
  brand_id: string
): Promise<SubscriptionDashConfig | undefined> => {
  try {
    const CONFIG_ENDPOINT = `${bbProcessEnv.API_URL}/api/v1/subscription-configs`
    const response = await axios.get(CONFIG_ENDPOINT, {
      params: { brand_id }
    })
    if (!response.data) {
      console.error(response)
    }
    return response.data[0]
  } catch (error) {
    console.error(error)
  }
}
