import { h } from "preact"
import { Alert, Form } from "@ui"

const FormInvalidMsg = () => (
  <div>
    <Form.Row>
      <Form.Col>
        <Alert.Danger id="invalid-form-msg">
          Please fill in all the required fields(*) in order for us to be able
          to contact you as soon as possible.
        </Alert.Danger>
      </Form.Col>
    </Form.Row>
  </div>
)

export default FormInvalidMsg
