import { h, Fragment } from "preact"
import { useDispatch, useSelector } from "react-redux"
import { Icon, Button, ScreenReaderOnly } from "@ui"
import { Toolbar } from "@composite"
import { RootStates } from "@types"
import { loanTermChange } from "@actions"
import tableViewIcon from "@assets/icons/table-view.svg"
import cardViewIcon from "@assets/icons/card-view.svg"
import linkIcon from "@assets/icons/external-link.svg"
import { useAppContext } from "@contexts"

interface Props {
  viewType: string
  setViewType: (val: string) => void
}

const LeadWorkflowPricingTableToolbar = ({ setViewType, viewType }: Props) => {
  const dispatch = useDispatch()

  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  const { loan_term, leadId } = useSelector(({ wizard }: RootStates) => ({
    loan_term: wizard?.values.steps.loan_term || 360,
    leadId: wizard?.lead_id
  }))

  const handleLoanTermChange = (term: number) => dispatch(loanTermChange(term))

  return (
    <Toolbar flexWrap={false}>
      {!isMobile && (
        <Fragment>
          <Button
            link
            size="sm"
            adapt
            className={viewType === "card" ? "is-active" : ""}
            onClick={() => {
              setViewType("card")
            }}
          >
            <Icon src={cardViewIcon} size={[15, 13]} originalSize={[15, 13]} />
            <ScreenReaderOnly>Switch to card view</ScreenReaderOnly>
          </Button>
          <Button
            link
            size="sm"
            adapt
            className={viewType === "table" ? "is-active" : ""}
            onClick={() => {
              setViewType("table")
            }}
          >
            <Icon src={tableViewIcon} size={[15, 13]} originalSize={[15, 13]} />
            <ScreenReaderOnly>Switch to table view</ScreenReaderOnly>
          </Button>
        </Fragment>
      )}
      <Button
        noShadow
        adapt
        size="sm"
        label="30-Year Fixed"
        isAlt={loan_term !== 360}
        onClick={() => {
          handleLoanTermChange(360)
        }}
      />
      <Button
        noShadow
        adapt
        size="sm"
        label="20-Year Fixed"
        isAlt={loan_term !== 240}
        onClick={() => {
          handleLoanTermChange(240)
        }}
      />
      <Button
        noShadow
        adapt
        size="sm"
        label="15-Year Fixed"
        isAlt={loan_term !== 180}
        onClick={() => {
          handleLoanTermChange(180)
        }}
      />
      <Button
        className="px-2"
        noShadow
        adapt
        size="sm"
        target="_blank"
        isAlt
        href={`${process.env.DASHBOARD_URL}/new-subscription?lead_id=${leadId}&active=0&source=embed`}
      >
        <Icon originalSize={16} size={16} src={linkIcon} className="mr-2" />
        More options
      </Button>
    </Toolbar>
  )
}

export default LeadWorkflowPricingTableToolbar
