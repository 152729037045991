import { useState } from "preact/hooks"
import { LoanDetailsFormFields, RootStates } from "@types"
import { LocationObj, OnChangeValue } from "../types/Loan"
import { useSelector } from "react-redux"

function useRatesFormChange(
  initialFormData: LoanDetailsFormFields,
  defaultFormData: Partial<LoanDetailsFormFields>,
  setFormCallback?: (formData: Partial<LoanDetailsFormFields>) => void,
  handleSubmit?:
    | ((formData: Partial<LoanDetailsFormFields>) => void)
    | undefined
) {
  const [formData, setFormDataLcl] = useState(initialFormData)
  const [formIsDirty, setFormDirty] = useState(false)
  const [lastUpdateField, setLastUpdateField] =
    useState<keyof LoanDetailsFormFields>()
  const [updateFields, setUpdateFields] = useState(false)
  const [handleSubmitFn, setHandleSubmitFn] = useState(() => handleSubmit)

  const lo = useSelector(({ app }: RootStates) => app.lo)

  const handleOnChange = (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => {
    const formDataTemp = { ...formData, [name]: value }

    if (name === "arm_term") {
      formDataTemp.loan_type = "arm"
    } else if (name === "loan_term") {
      if (formDataTemp.loan_type === "arm") {
        formDataTemp.loan_type = "conventional"
      }
    } else if (name === "loan_type") {
      if (value === "arm") {
        formDataTemp.arm_term = "10"
      } else if (formData.loan_type === "arm") {
        formDataTemp.loan_term = 30
      }
    } else if (name === "loan_purpose" && value === "refinance") {
      formDataTemp.first_time_homebuyer = false
    } else if (name === "location") {
      formDataTemp.zipcode = (value as LocationObj).zipcode as string
    }

    setFormData(formDataTemp)
    !formIsDirty && setFormDirty(true)

    //setLastUpdateField(name === "loan_purpose" ? "list_price" : name)
    setLastUpdateField(name)

    // Check if we need to update any field if it's purchase loan type
    const includeFields = [
      "down_payment",
      "list_price",
      "down_percentage",
      "loan_amount",
      "loan_purpose"
    ].includes(name)

    setUpdateFields(includeFields)
  }

  const setFormData = (newFormData: Partial<LoanDetailsFormFields>) => {
    setFormDataLcl({ ...formData, ...newFormData })
    setFormCallback?.(newFormData)
  }

  const cleanForm = () => {
    const location: LocationObj = {
      zipcode: lo!.office_zipcode,
      state: lo!.office_state,
      city: lo!.office_city
    }

    const cleanData: Partial<LoanDetailsFormFields> = {
      ...defaultFormData,
      zipcode: lo!.office_zipcode,
      location
    }
    setFormData(cleanData)
    setFormDirty(false)
    handleSubmitFn?.(cleanData)
  }

  const setHandleSubmit = (
    newHandleSubmit: (data: Partial<LoanDetailsFormFields>) => void
  ) => setHandleSubmitFn(newHandleSubmit)

  return {
    setFormData,
    setFormDirty,
    setUpdateFields,
    formData,
    formIsDirty,
    lastUpdateField,
    updateFields,
    handleOnChange,
    cleanForm,
    setHandleSubmit
  }
}

export default useRatesFormChange
