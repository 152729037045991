import { useEffect, useRef, useState } from "preact/hooks"
import { debounce } from "@helpers"
import { ComponentChildren } from "preact"

interface MainState {
  atStart: boolean
  atEnd: boolean
  numScrollPositions: number
  containerStyles: {}
}

const useSimpleSlides = (
  autoplay = false,
  autoplayInfinite = true,
  autoplayInterval = 4000,
  autoplayDirection = "next",
  addScrollPadding = false,
  previewNextSlide = false,
  gap = 24,
  children: ComponentChildren
) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [state, setState] = useState<MainState>({
    atStart: true,
    atEnd: false,
    numScrollPositions: 0,
    containerStyles: {}
  })

  const updateButtonStates = () => {
    const container = containerRef.current
    if (container && container.children?.length > 0) {
      const itemWidth = container.children[0].getBoundingClientRect().width
      const containerWidth = container.offsetWidth
      const scrollLeft = container.scrollLeft

      let visibleItems = Math.floor(containerWidth / itemWidth)
      visibleItems = isNaN(visibleItems) ? 0 : visibleItems
      const totalItems = container.children.length
      const possibleScrollPositions = totalItems - visibleItems + 1

      const newState: Partial<MainState> = {}

      if (possibleScrollPositions !== state.numScrollPositions) {
        newState.numScrollPositions = possibleScrollPositions
      }

      const newCurrentIndex = previewNextSlide
        ? Math.round(scrollLeft / itemWidth)
        : Math.floor(scrollLeft / itemWidth)

      newState.atStart = newCurrentIndex === 0
      newState.atEnd = newCurrentIndex === possibleScrollPositions - 1

      setCurrentIndex(newCurrentIndex)

      if (addScrollPadding) {
        const nextItemPadding = (containerWidth - itemWidth) / 2
        let percentage = (nextItemPadding * 100) / containerWidth

        newState.containerStyles = {
          scrollPaddingLeft: `${percentage}%`,
          paddingLeft: `${percentage}%`
        }
      }

      setState(
        (prevState: MainState): MainState => ({
          ...prevState,
          ...newState
        })
      )
    }
  }

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      const debouncedUpdateButtonStates = debounce(updateButtonStates, 100)
      container.addEventListener("scroll", updateButtonStates)
      window.addEventListener("resize", debouncedUpdateButtonStates)
      return () => {
        container.removeEventListener("scroll", updateButtonStates)
        window.removeEventListener("resize", debouncedUpdateButtonStates)
      }
    }
  }, [])

  useEffect(() => {
    if (!containerRef.current?.children?.length) return
    updateButtonStates()
  }, [containerRef.current, children])

  const scroll = (amount?: number) => {
    const container = containerRef.current
    if (container && amount) {
      container.scrollBy({ left: amount, behavior: "smooth" })
    }
  }
  const scrollToNext = () =>
    scroll(containerRef.current?.children[0].getBoundingClientRect().width)
  const scrollToPrev = () =>
    scroll(
      -(containerRef.current?.children[0].getBoundingClientRect().width ?? 0)
    )

  const scrollToIndex = (index: number) => {
    const container = containerRef.current
    if (container) {
      const childWidth = container.children[0].getBoundingClientRect().width
      const left = childWidth * index + gap * index
      container.scrollTo({ left, behavior: "smooth" })
      setCurrentIndex(index)
    }
  }

  useEffect(() => {
    if (!autoplay) return

    const interval = setInterval(() => {
      if (autoplayDirection === "next") {
        if (state.atEnd && autoplayInfinite) {
          scrollToIndex(0)
        } else {
          scrollToNext()
        }
      } else {
        if (state.atStart && autoplayInfinite) {
          scrollToIndex(state.numScrollPositions - 1)
        } else {
          scrollToPrev()
        }
      }
    }, autoplayInterval)

    return () => clearInterval(interval)
  }, [
    autoplay,
    autoplayInfinite,
    autoplayInterval,
    autoplayDirection,
    state.atEnd,
    state.atStart,
    state.numScrollPositions,
    scrollToNext,
    scrollToPrev,
    scrollToIndex
  ])

  return {
    containerRef,
    currentIndex,
    scrollToNext,
    scrollToPrev,
    scrollToIndex,
    ...state
  }
}

export default useSimpleSlides
