import { Fragment, h } from "preact"
import { If } from "@ui"
import { useDynamicForm } from "./DynamicFormProvider"

const DynamicFormHeader = () => {
  const { title, subtitle } = useDynamicForm()

  return (
    <Fragment>
      <If
        condition={title.length > 0}
        then={
          <h2 className="tw-text-2xl tw-font-normal tw-text-center title-color tw-mb-2 tw-mt-0">
            {title}
          </h2>
        }
      />
      <If
        condition={subtitle.length > 0}
        then={
          <h3 className="tw-text-base tw-font-normal tw-text-center title-color tw-mb-6 tw-mt-0">
            {subtitle}
          </h3>
        }
      />
    </Fragment>
  )
}

export default DynamicFormHeader
