import { h, Fragment } from "preact"
import { useMemo } from "preact/hooks"
import { ReviewTabItem } from "@composite"
import { Tabs, Rating } from "@ui"
import { capitalizeTheFirstLetterOfEachWord, numberWithCommas } from "@helpers"
import { REVIEWS_ICONS } from "@constants"
import { ProviderType, ReviewType } from "@types"
import { useAppContext } from "@contexts"

interface ReviewsTableHeaderProps {
  currentProvider: ProviderType | "all"
  setProvider: (provider: ProviderType) => void
  type: ReviewType
  reviewsViewMode: string
  hideTotalReviews: boolean
}
const ReviewsTableHeader = ({
  currentProvider,
  setProvider,
  type,
  reviewsViewMode,
  hideTotalReviews
}: ReviewsTableHeaderProps) => {
  const {
    media: {
      flags: { isMobile }
    },
    reviews: { lo, brand }
  } = useAppContext()

  const reviewsData = type === "brand" ? brand : lo

  const { providers, reviewsByProvider, ratings, numberOfReviews, reviewURLs } =
    reviewsData

  const sumRatings =
    reviewsByProvider?.reduce(
      (acc: number, current: { rating: number; totalReviews: number }) =>
        acc + current.rating * current.totalReviews,
      0
    ) ?? 0

  const sumTotalReviews = reviewsByProvider?.reduce(
    (acc: any, current: { totalReviews: any }) => acc + current.totalReviews,
    0
  )
  const hasReviews = sumTotalReviews > 0

  const ratingsWithAll = {
    ...ratings,
    all: hasReviews ? sumRatings / sumTotalReviews : 0
  }
  const numberOfReviewsWithAll = { ...numberOfReviews, all: sumTotalReviews }

  const formattedNumberOfReviews = numberWithCommas(
    numberOfReviewsWithAll[currentProvider]
  )

  const options = useMemo(
    () =>
      [
        {
          value: "all",
          label: (
            <ReviewTabItem
              currentProvider="all"
              rating={ratingsWithAll.all}
              totalReviews={numberOfReviewsWithAll.all}
            />
          ),
          labelStr: `All Reviews ${ratingsWithAll.all.toFixed(1)}`
        },
        ...providers.map(currentProvider => ({
          value: currentProvider,
          label: (
            <ReviewTabItem
              currentProvider={currentProvider}
              rating={ratingsWithAll[currentProvider]}
              totalReviews={numberOfReviewsWithAll[currentProvider]}
              reviewURL={reviewURLs?.[currentProvider]}
              icon={REVIEWS_ICONS[currentProvider]}
              hideTotalReviews={hideTotalReviews}
            />
          ),
          labelStr: `${capitalizeTheFirstLetterOfEachWord(currentProvider)} ${(
            ratingsWithAll?.[currentProvider] ?? 0
          ).toFixed(1)} ${
            hideTotalReviews ? "" : "(" + formattedNumberOfReviews + "reviews)"
          }`,
          icon: REVIEWS_ICONS[currentProvider]
        }))
      ].filter(
        ({ value }) =>
          value === "all" ||
          !!ratingsWithAll[value as keyof typeof ratingsWithAll]
      ),
    [ratingsWithAll, numberOfReviews, reviewsViewMode, formattedNumberOfReviews]
  )

  return (
    <Fragment>
      <header className="reviews__header tw-flex tw-items-baseline">
        <div className="reviews__header--tabs">
          <Tabs
            active={currentProvider}
            onClickHandle={currentProvider =>
              setProvider(currentProvider as ProviderType)
            }
            options={options}
          />
        </div>
      </header>
      {reviewsViewMode === "list" && (
        <div className="reviews__header-sub tw-flex tw-items-baseline">
          <div className="rating">
            {(ratingsWithAll[currentProvider] ?? 0).toFixed(1)}
          </div>
          <Rating
            color="#0077df"
            rating={ratingsWithAll[currentProvider] ?? 0}
            size={isMobile ? 12 : 14}
          />

          {currentProvider !== "all" &&
          !!reviewURLs?.[currentProvider as ProviderType] ? (
            <a
              href={reviewURLs[currentProvider]}
              className="rating-number"
              target="_blank"
            >
              {formattedNumberOfReviews}
              &nbsp;Reviews
            </a>
          ) : (
            <div className="rating-number">
              {formattedNumberOfReviews}
              &nbsp;Reviews
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}

export default ReviewsTableHeader
