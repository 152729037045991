import offerIcon from "@assets/icons/tabs/offer.svg"
import ratesIcon from "@assets/icons/tabs/rates.svg"
import calcIcon from "@assets/icons/tabs/calc.svg"
import reviewsIcon from "@assets/icons/tabs/reviews.svg"
import loanIcon from "@assets/icons/tabs/loan.svg"

export const ICONS = {
  leadWorkflow: { src: offerIcon, size: [32, 39] },
  rates: { src: ratesIcon, size: 36 },
  calcs: { src: calcIcon, size: [33, 40] },
  reviews: { src: reviewsIcon, size: [36, 35] },
  loan: { src: loanIcon, size: 36 }
}

export const ABOUT_LO = `<p>As a dedicated loan officer with years of experience in the financial sector, I am here to serve as your personal guide through the often complex process of securing a home loan. My goal is to not only meet your immediate financial needs but also to provide you with the knowledge and resources necessary to make informed decisions about your future home loan requirements. By offering personalized service tailored to each client's unique situation, I strive to ensure that you feel confident and educated about your mortgage options. </p><p>Whether you're a first-time homebuyer, looking to refinance, or exploring investment opportunities, my commitment is to support you every step of the way, ensuring a smooth and stress-free experience. Let me help you navigate the path to homeownership with expertise, empathy, and a deep commitment to your financial well-being.</p>
`
