import { h } from "preact"
import { Button, Form } from "@ui"
import { useDynamicForm } from "./DynamicFormProvider"

interface Props {
  isSendingForm: boolean
  onClick: (e: Event) => void
}

const DynamicFormSubmitBtn = ({ isSendingForm, onClick }: Props) => {
  const { submitLabel } = useDynamicForm()

  return (
    <Form.Row>
      <Form.Col className="dForm__btn-wrapper">
        <Button
          noShadow
          label={submitLabel}
          onClick={onClick}
          size="xl"
          isLoading={isSendingForm}
        />
      </Form.Col>
    </Form.Row>
  )
}

export default DynamicFormSubmitBtn
