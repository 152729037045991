import { h } from "preact"
import { Button, Form } from "@ui"
import { useSelector } from "react-redux"
import { RootStates } from "@types"

interface Props {
  submitLabel: string
  disabled: boolean
  isLoading?: boolean
}

const getButtonLabel = (wizard: RootStates["wizard"]) => {
  const cardDetails = wizard?.card_details
  const showUnlockRatesLabel =
    cardDetails?.cards?.length &&
    cardDetails.cards[0].apr > 0 &&
    !wizard?.disable_pricing
  return showUnlockRatesLabel ? "Unlock & view my rates" : "View my options"
}

const SubmitBtn = ({ submitLabel, disabled, isLoading }: Props) => {
  const { label } = useSelector(({ wizard }: RootStates) => ({
    label: getButtonLabel(wizard)
  }))

  return (
    <Form.Row>
      <Button
        className={disabled ? "no-events" : ""}
        type="submit"
        noShadow
        label={submitLabel || label}
        isLoading={!!isLoading}
      />
    </Form.Row>
  )
}

export default SubmitBtn
