import { Fragment, h } from "preact"
import { useState, useEffect, useMemo } from "preact/hooks"
import { useDispatch, useSelector } from "react-redux"
import { updateFormValues, getLOsByBrandID } from "@actions"
import { Loader, Form } from "@ui"
import StepInner from "./StepInner"

const secondTitle = {
  txt: "Select your loan officer"
}

const StepLOSelect = ({
  placeholder = "Search & Select",
  onClickContinue,
  stepKey,
  title
}) => {
  const dispatch = useDispatch()
  const { value, brand_id, los, isLoadingLOs } = useSelector(
    ({ wizard, app: { los, isLoadingLOs } }) => {
      const currentVal = wizard.values.steps[stepKey]
      return {
        value: currentVal,
        brand_id: wizard.brand_id,
        los,
        isLoadingLOs
      }
    }
  )

  const [showDropdown, toggleDropdown] = useState(false)
  const [losOptions, setLosOptions] = useState([])

  const titleToShow = useMemo(
    () => (showDropdown ? secondTitle : title),
    [showDropdown, title]
  )

  useEffect(() => {
    if (value) {
      dispatch(getLOsByBrandID(brand_id))
      toggleDropdown(true)
    }
  }, [])

  useEffect(() => {
    if (!los) {
      return
    }

    // Filter out brand admins and sort by name
    const options = los
      .filter(lo => !lo.brand_admin)
      .map(lo => ({ id: lo.uid, label: lo.name, picture: lo.picture }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))

    setLosOptions(options)
  }, [los])

  const onInput = val => {
    dispatch(updateFormValues({ value: val, key: stepKey, type: "lo_select" }))
  }

  const onButtonClick = ({ target }) => {
    const showDD = target.value === "yes"
    toggleDropdown(showDD)
    if (showDD) {
      if (!los) {
        dispatch(getLOsByBrandID(brand_id))
      }
    } else {
      onClickContinue()
    }
  }

  const onClickBack = () => {
    toggleDropdown(false)
  }

  const handleClickContinue = () => {
    toggleDropdown(false)
    onClickContinue()
  }

  return (
    <StepInner
      cols={2}
      stepKey={stepKey}
      title={titleToShow}
      btnDisabled={!value}
      onClickContinue={showDropdown && handleClickContinue}
      onClickBack={showDropdown && onClickBack}
    >
      {showDropdown ? (
        <Fragment>
          {isLoadingLOs ? (
            <Loader className="box__loader" />
          ) : (
            <Form.Typehead
              name="lo_select"
              value={value}
              placeholder={placeholder}
              onInput={option => {
                onInput(option.id)
              }}
              options={losOptions}
              hasPicture
              className="ml-md-4"
            />
          )}
        </Fragment>
      ) : (
        <Form.Buttons
          isList
          isListIcon
          onInput={onButtonClick}
          name="showLODropDown"
          options={[
            {
              icon: "https://cdn.bankingbridge.com/uploads/yes.svg",
              label: "Yes",
              value: "yes"
            },
            {
              icon: "https://cdn.bankingbridge.com/uploads/no.svg",
              label: "No",
              value: "no"
            }
          ]}
          value={showDropdown}
        />
      )}
    </StepInner>
  )
}

export default StepLOSelect
