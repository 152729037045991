import { h } from "preact"
import { Icon, If } from "@ui"
import { TooltipFormWrapper } from "@composite"
import { LoanDetailsFormFields, OnChangeValue } from "@types"
import WaiveEscrows from "./WaiveEscrows"
import FirstTimeHomebuyer from "./FirstTimeHomebuyer"
import LockPeriod from "./LockPeriod"
import ResponsivePurchaseFields from "./ResponsivePurchaseFields"
import SlidersIcon from "@assets/icons/sliders-h.svg"

interface Props {
  hasARMTerms: boolean
  showMoreFiltersBtn: boolean
  hasLoanTerm?: boolean
  formData: LoanDetailsFormFields
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
}

const TooltipFields = ({
  hasARMTerms,
  showMoreFiltersBtn,
  hasLoanTerm,
  handleOnChange,
  formData
}: Props) => (
  <If
    condition={showMoreFiltersBtn}
    then={
      <div className="extra-fields">
        <TooltipFormWrapper
          ttClassName="extra-fields__tooltip"
          ttPlacement="bottom-end"
          trigger={
            <span>
              <Icon className="mr-2" src={SlidersIcon} size={16} />
              More Filters
            </span>
          }
          ctaClassName="button--md"
        >
          <ResponsivePurchaseFields
            hasARMTerms={hasARMTerms}
            isOnTooltip={true}
            showFields={showMoreFiltersBtn}
            formData={formData}
            handleOnChange={handleOnChange}
            hasLoanTerm={hasLoanTerm}
          />

          {/*  <LockPeriod
        value={formData.lock_period}
        onChange={(option: { value: any }) =>
          handleOnChange(option.value, "lock_period")
        }
      /> 

      <WaiveEscrows
        value={formData.waive_escrows}
        onChange={(option: { value: string }) =>
          handleOnChange(option.value, "waive_escrows")
        }
      />
        <FirstTimeHomebuyer
        value={formData.first_time_homebuyer}
        onChange={(option: { value: string }) =>
          handleOnChange(option.value, "first_time_homebuyer")
        }
      />*/}
        </TooltipFormWrapper>
      </div>
    }
  />
)

export default TooltipFields
