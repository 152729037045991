import { useSelector } from "react-redux"
import { RootStates } from "@types"
import {
  MORTGAGE_TERMS_CONFIG,
  MORTGAGE_TERMS_CONFIG_WITH_FEATURED
} from "../constants/rates-toolbar"

interface UseGetTermLabel {
  ({ arm_term, loanTerm }: { arm_term?: string; loanTerm: number }): string
}

const getTermLabel = (
  terms: Partial<typeof MORTGAGE_TERMS_CONFIG_WITH_FEATURED>,
  term: string | number
) => {
  // loop through terms and return the label.
  //  We cant apply strict typing here because we never know how it could return from the API 🤷🏻
  return terms.find(item => item?.term == term)?.label ?? ""
}

const useGetTermLabel: UseGetTermLabel = item => {
  const terms = useSelector(({ rateTable, subscriptionDash }: RootStates) => {
    // first check if subscriptionDash terms are available, then the rate table, and if not, we use the defaults
    const terms = (subscriptionDash?.config?.mortgage_terms ??
      rateTable?.rateTableConfig?.mortgage_terms ??
      MORTGAGE_TERMS_CONFIG) as Partial<
      typeof MORTGAGE_TERMS_CONFIG_WITH_FEATURED
    > // fallback to hardcoded config

    return terms
  })

  return getTermLabel(terms, !!item.arm_term ? item.arm_term : item.loanTerm)
}

export default useGetTermLabel
