import { h, ComponentChildren } from "preact"

type Props = {
  children: ComponentChildren
  style?: any
  className?: string
}

const Col = ({ children, style = {}, className = "" }: Props) => (
  <div className={`form__col ${className}`} style={style}>
    {children}
  </div>
)

export default Col
