import { h, Fragment } from "preact"
import { useEffect } from "preact/hooks"
import Frame from "./Frame"
import { If } from "@ui"
import modalStyles from "./modal-styles"
import { addScrollLock, removeScrollLock } from "@helpers"

interface Props {
  activeModal: number
  config: any
  isBlockingModal: boolean
  onMessage?: (data: any, e: any) => void
  modals: string[]
}

const Modal = ({
  activeModal,
  config,
  isBlockingModal,
  onMessage,
  modals
}: Props) => {
  useEffect(() => {
    // use session storage to set modal is open
    sessionStorage.setItem("regularModalOpened", "true")

    addScrollLock()

    return () => {
      sessionStorage.removeItem("regularModalOpened")
      removeScrollLock()
    }
  }, [])

  const handleFrameTasks = (data: any, e: Event) => {
    onMessage?.(data, e)
  }

  return (
    <div className="bankingbridge-modal-wrapper">
      <div
        className={`bankingbridge-modal-wrapper-simple bankingbridge-modal-wrapper-simple--${activeModal}`}
      >
        <If
          condition={!isBlockingModal}
          then={
            <Fragment>
              {modals.map((modal, index) => (
                <Frame
                  key={`${modal}-${index}`}
                  url={modal}
                  onMessage={handleFrameTasks}
                  config={config}
                  className={`bankingbridge-modal-iframe ${
                    index + 1 === modals.length ? "active" : ""
                  }`}
                  isModal
                />
              ))}
            </Fragment>
          }
        />
      </div>
      <style>
        {modalStyles}
        {`.bankingbridge-modal-wrapper-simple.bankingbridge-modal-wrapper-simple--${activeModal} {
          -webkit-transform: translate(-${activeModal * 100}vw,0);
          -ms-transform: translate(-${activeModal * 100}vw,0); 
          transform: translate(-${activeModal * 100}vw,0);
        }`}
      </style>
    </div>
  )
}

export default Modal
