import { Fragment, h } from "preact"
import { useIsMobile } from "@contexts"
import TxtLoading from "./Txt"
import RateTableItemsSkeleton from "./RateTableItems"
import { If } from "@ui"
import LeadWorkflowEndStepHeader from "./LeadWorkflowEndStepHeader"
import "@styles/components/_lead-workflow-step-end-loader.scss"

interface Props {
  hasResults?: boolean
}

const LeadWorkflowEndStep = ({ hasResults = true }: Props) => {
  const isMobile = useIsMobile()

  return (
    <div
      className={`lead-workflow-end-step-loader ${
        hasResults ? "" : "no-results"
      }`}
    >
      <LeadWorkflowEndStepHeader hasResults={hasResults} />

      <If
        condition={hasResults}
        then={
          <Fragment>
            <div className="lead-workflow-end-step-loader__highlight tw-flex tw-justify-between tw-items-center">
              <div>
                <TxtLoading height={20} width={220} />
                <TxtLoading height={20} width={172} margin="8px 0 0" />
              </div>
              <TxtLoading
                height={20}
                width={135}
                className="d-none d-md-flex"
              />
            </div>

            <div className="rate-table-skeleton__rows">
              <RateTableItemsSkeleton
                pricingIsLoading
                isNotMobile={!isMobile}
              />
            </div>

            <div className="tw-flex tw-justify-between tw-items-center gap-md">
              <div className="lead-workflow-end-step-loader__highlight flex-fill lead-workflow-end-step-loader__footer-item">
                <TxtLoading height={20} width={220} />
                <TxtLoading height={20} width={172} margin="8px 0 0" />
                <TxtLoading
                  height={45}
                  className="lead-workflow-end-step-loader__cta"
                />
              </div>
              <div className="lead-workflow-end-step-loader__highlight flex-fill lead-workflow-end-step-loader__footer-item d-none d-md-flex">
                <TxtLoading height={20} width={220} />
                <TxtLoading height={20} width={172} margin="8px 0 0" />
                <TxtLoading
                  height={45}
                  className="lead-workflow-end-step-loader__cta"
                />
              </div>
            </div>
          </Fragment>
        }
        else={
          <Fragment>
            <div className="lead-workflow-end-step-loader__highlight">
              <TxtLoading height={20} width={110} />
              <div className="lead-workflow-end-step-loader__resume">
                <div>
                  <TxtLoading height={20} width={172} />
                  <TxtLoading height={20} width={132} margin="8px 0 0" />
                </div>
                <div className="lead-workflow-end-step-loader__resume-item d-none d-sm-flex">
                  <div className="lead-workflow-end-step-loader__resume-col-main">
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                  </div>
                  <div className="lead-workflow-end-step-loader__resume-col-secondary">
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                  </div>
                </div>
                <div className="lead-workflow-end-step-loader__resume-item d-none d-md-flex">
                  <div className="lead-workflow-end-step-loader__resume-col-main">
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                  </div>
                  <div className="lead-workflow-end-step-loader__resume-col-secondary">
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                    <TxtLoading height={20} />
                  </div>
                </div>
              </div>
            </div>

            <div className="wizard-end__no-results-footer">
              <TxtLoading
                height={20}
                className="lead-workflow-end-step-loader__title"
              />
              <TxtLoading
                height={20}
                className="lead-workflow-end-step-loader__subtitle"
              />
            </div>
          </Fragment>
        }
      />
    </div>
  )
}

export default LeadWorkflowEndStep
