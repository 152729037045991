import { useEffect, useMemo, useState } from "preact/hooks"
import { PricingItem, SortByOption } from "@types"
import useLenderFees from "./useLenderFees"
import useGetMonthlyPayment from "./useGetMonthlyPayment"

const useSortedCards = (sortValue: SortByOption) => {
  const getMonthlyPayment = useGetMonthlyPayment()

  const [sortLoading, setSortLoading] = useState(false)

  useEffect(() => {
    setSortLoading(true)
    setTimeout(() => {
      setSortLoading(false)
    }, 500)
  }, [sortValue])

  const sortCards = useMemo(
    () => (cards: PricingItem[], sortValue: SortByOption) => {
      if (!cards || !Array.isArray(cards)) return cards
      let sortedCards = [...cards]
      switch (sortValue) {
        case "low_rate":
          sortedCards.sort((a, b) => a.rate - b.rate)
          break
        case "low_apr":
          sortedCards.sort((a, b) => a.apr - b.apr)
          break
        case "low_monthly_payment":
          sortedCards.sort((a, b) => {
            const aMP = getMonthlyPayment(a)
            const bMP = getMonthlyPayment(b)
            return aMP - bMP
          })
          break
        case "low_upfront_costs":
          sortedCards.sort((a, b) => {
            const aLenderFees = useLenderFees(a)
            const bLenderFees = useLenderFees(b)
            return aLenderFees - bLenderFees
          })
          break
        default:
          sortedCards = cards // Relevance
      }
      return sortedCards
    },
    [getMonthlyPayment, useLenderFees]
  )

  return { sortCards, sortLoading }
}

export default useSortedCards
