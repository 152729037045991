import { useEffect, useState } from "preact/hooks"
import { affordabilityAPI } from "../api"
import {
  AffordabilityFormData,
  AffordabilityInitials,
  AffordabilityResponse,
  AffordData,
  IAffordabilityCalcState,
  LocationObj
} from "@types"

const DEFAULT_STATE: IAffordabilityCalcState = {
  zipcodePlcHolder: "",
  marks: {
    15: "Conservative"
  },
  isRate: false,
  form: {
    locationObj: {
      state: "",
      zipcode: ""
    },
    location: "",
    down_payment: 10000,
    interest_rate: 0,
    loan_type: 360,
    annual_income: 30000,
    debt_percentage: 0,
    monthly_debt: 0,
    mort_pp: 0,
    debt_to_income: 0,
    monthly_payment: 0,
    list_price: 0,
    home_ins: 0,
    prop_tax: 0,
    mort_ins: 0,
    military: false
  },
  data: {
    pi: 1,
    propertyTaxAmount: 0,
    homeInsurance: 0,
    mortgageInsurance: 0
  }
}

interface IAffordabilityCalc {
  nmls: string
}

const useAffordabilityCalc = ({ nmls }: IAffordabilityCalc) => {
  const [state, setState] = useState(DEFAULT_STATE)

  const [isLoadingInitials, setIsLoadingInitials] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [doCalculation, setDoCalculation] = useState(false)
  const [userHomeInsurance, setUserHomeInsurance] = useState(-1)
  const [userPropertyTax, setUserPropertyTax] = useState(-1)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { form } = state
        const data = await affordabilityAPI.getInitials(nmls)
        const default_values = (data as AffordabilityInitials).default_values
        const formValues = {
          ...form,
          interest_rate: default_values.rate,
          annual_income: default_values.income,
          debt_percentage: default_values.debt_p,
          mort_pp: default_values.mort_pp.moderate,
          prop_tax: default_values.prop_tax,
          home_ins: default_values.home_ins,
          mort_ins: default_values.mort_ins,
          debt_to_income: default_values.debt_p + 15,
          monthly_payment:
            (default_values.mort_pp.moderate / 100) *
            (default_values.income / 12)
        }

        setState({
          ...state,
          form: formValues,
          max: default_values.mort_pp.aggressive * 2,
          marks: {
            15: "Conservative",
            [default_values.mort_pp.moderate * 2]: "",
            [default_values.mort_pp.aggressive * 2]: "Aggressive"
          },
          zipcodePlcHolder: default_values.zipcode,
          homeSearchLink: data?.search_homes_link
        })
        setDoCalculation(true)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingInitials(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (doCalculation) {
      handleCalculate()
    }
  }, [doCalculation])

  const getMonthlyPayment = () => {
    const { form, data } = state

    return (
      Object.keys(data).reduce(function (previous, key) {
        return previous + data[key as keyof AffordData]
      }, 0) + form.monthly_debt
    )
  }

  useEffect(() => {
    const newMonthly = getMonthlyPayment()

    setState({ ...state, form: { ...state.form, monthly_payment: newMonthly } })
  }, [state.data, state.form.monthly_debt])

  useEffect(() => {
    // if user has entered a value for home insurance or property tax, set then on state.data
    if (userHomeInsurance > -1 || userPropertyTax > -1) {
      const data = {
        ...state.data,
        homeInsurance:
          userHomeInsurance > -1 ? userHomeInsurance : state.data.homeInsurance,
        propertyTaxAmount:
          userPropertyTax > -1 ? userPropertyTax : state.data.propertyTaxAmount
      }
      setState({ ...state, data })
    }
  }, [userPropertyTax, userHomeInsurance])

  const handleCalculate = (value?: number) => {
    const { form, isRate } = state

    setIsLoading(true)
    setDoCalculation(false)

    const param: AffordabilityFormData = {
      income: form.annual_income,
      mort_pp: value ? value / 2 : form.mort_pp,
      term: form.loan_type,
      debt: form.monthly_debt,
      dp: form.down_payment,
      zipcode: form.locationObj?.zipcode ?? "",
      state: form.locationObj?.state,
      rate: !isRate ? form.interest_rate : undefined
    }

    const { coords } = form.locationObj

    if (coords) {
      param.coords = coords
    }

    setState({ ...state, isRate: false })

    const calculate = async () => {
      try {
        const response = await affordabilityAPI.calculate(nmls, param)
        const { form } = state
        const res = response as AffordabilityResponse
        const values = {
          pi: res.pni,
          propertyTaxAmount: userPropertyTax > -1 ? userPropertyTax : res.taxes,
          homeInsurance:
            userHomeInsurance > -1 ? userHomeInsurance : res.insurance,
          mortgageInsurance: (res.list_price * res.mi_percent) / 1200
        }

        // const newMonthly =
        //   Object.keys(values).reduce(function (previous, key) {
        //     return previous + values[key as keyof AffordData]
        //   }, 0) + form.monthly_debt

        const newForm = {
          ...form,
          //monthly_payment: newMonthly,
          list_price: res.list_price
        }

        if (res.rate) {
          newForm.interest_rate = res.rate
        }

        setState({ ...state, form: newForm, data: values })
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }
    calculate()
  }

  const handleMainSliderChange = (value: number) => {
    const { form, max = 0 } = state

    let newValue = value

    if (value > max) {
      newValue = max
    }

    const mort_pp = newValue / 2
    const formValues = {
      ...form,
      mort_pp,
      debt_to_income: form.debt_percentage + mort_pp
    }

    setState({ ...state, form: formValues })
  }

  const handleChange = (
    value: string | number | LocationObj,
    name: string,
    calculate = false
  ) => {
    const { form } = state

    const update = { [name]: value }

    if (name === "locationObj") {
      update["location"] = (value as LocationObj)?.["zipcode"] ?? form.location
    }

    const newState = {
      form: {
        ...form,
        ...update,
        isRate: name === "loan_type" ? true : form.isRate
      }
    }

    setState({ ...state, ...newState })
    setDoCalculation(calculate)
  }

  const handleEditChartField = (value: number | string, fieldName: string) => {
    if (fieldName === "homeInsurance") {
      setUserHomeInsurance(value as number)
    } else if (fieldName === "propertyTaxAmount") {
      setUserPropertyTax(value as number)
    }
  }

  return {
    state,
    isLoading,
    isLoadingInitials,
    handleChange,
    handleMainSliderChange,
    handleCalculate,
    handleEditChartField
  }
}

export default useAffordabilityCalc
