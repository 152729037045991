import { h } from "preact"
import { useRef } from "preact/hooks"

type Props = {
  label?: string
  checked?: boolean
  sm?: boolean
  className?: string
  onChange?: (e: Event) => void
}

const Switch = ({ label, checked, sm, className = "", ...rest }: Props) => {
  const input = useRef<HTMLInputElement>(null)

  const handleKeyDown = (code: string) => {
    if (code === "Enter") {
      input.current?.click()
    }
  }

  return (
    <label
      tabIndex={0}
      className={`switch ${className} ${sm ? "is-sm" : ""}`}
      onKeyDown={e => {
        handleKeyDown(e.code)
      }}
    >
      <input
        ref={input}
        type="checkbox"
        className="tw-sr-only"
        checked={checked}
        {...rest}
      />
      <span className="switch__slider">
        <span class="switch__dot"></span>
        <span class="switch__on">
          <span class="switch__on-inner"></span>
        </span>
        <span class="switch__off"></span>
      </span>
    </label>
  )
}

export default Switch
