// api/v1/og-signature

import { ONE_DAY_IN_MILLISECONDS } from "@constants"
import { APICallWithCache, bbProcessEnv } from "@helpers"

interface OgSignatureResponse {
  file: string
  elapsed: number
  label: string
  read: boolean
}

interface OgSignatureParams {
  loid?: number
  brand_id?: string
  format?: "jpeg" | "pdf" | "html"
  width?: number
  use_cache?: boolean
  cacheEnabled?: boolean
  expiresIn?: number
}

interface OgSignatureRequestParams {
  loid?: number
  brand_id?: string
  format: "jpeg" | "pdf" | "html"
  width?: string
  use_cache?: boolean
}

export default {
  signature: async ({
    loid,
    brand_id,
    format = "jpeg",
    width,
    use_cache = true,
    cacheEnabled,
    expiresIn = ONE_DAY_IN_MILLISECONDS * 5
  }: OgSignatureParams): Promise<string> => {
    let params: OgSignatureRequestParams = {
      format,
      use_cache
    }

    if (loid) {
      params.loid = loid
    }
    if (brand_id) {
      params.brand_id = brand_id
    }
    if (width) {
      params.width = `${width}px`
    }

    const url = `${bbProcessEnv.API_URL}/api/v1/og-signature`
    const response: OgSignatureResponse = await APICallWithCache({
      url,
      params,
      method: "get",
      cacheEnabled,
      expiresIn,
      delay: 0
    })

    return response.file
  }
}
