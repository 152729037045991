import { h } from "preact"
import TxtLoading from "./Txt"

export default () => (
  <div className="mortgage-calculator__loader-sm d-md-flex justify-content-between">
    <div>
      <TxtLoading height={50} width="50%" margin="0 auto 16px !important" />
    </div>
    <div>
      <TxtLoading height={25} width="100%" margin="0 0 16px !important" />
      <div className="d-md-flex justify-content-between">
        <TxtLoading height={24} width="55%" />
        <TxtLoading height={24} width="20%" />
      </div>
    </div>
  </div>
)
