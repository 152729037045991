import { h } from "preact"
import { Button, Box, Icon } from "@ui"
import { openModal } from "@helpers"
import { useAppContext } from "@contexts"
import iconSrc from "@assets/icons/custom-rate-banner.svg"

type Props = {
  title: string
  onClick: () => void
  subtitle: string
  ctaLabel: string
  href: string
  icon?: string
  className?: string
}

const DefaultBanner = ({
  title,
  onClick,
  subtitle,
  ctaLabel,
  href,
  icon = iconSrc,
  className = ""
}: Props) => {
  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  return (
    <Box
      hasShadow={false}
      className={`tw-mt-4 tw-px-6 tw-py-4 md:tw-flex tw-items-center ${className}`}
    >
      <div className="tw-flex tw-items-center tw-mr-4">
        <Icon
          className="tw-mr-6 tw-w-14"
          src={icon}
          size={[58, 63]}
          originalSize={[58, 63]}
        />
        <div>
          {title && (
            <h2 className="tw-text-base md:tw-text-lg tw-m-0 txt-dark">
              {title}
            </h2>
          )}
          {subtitle && (
            <p className="tw-text-xs md:tw-text-sm tw-m-0 tw-mt-2">
              {subtitle}
            </p>
          )}
        </div>
      </div>
      <Button
        href={href}
        onClick={(e: Event) => {
          onClick()
          openModal(e, "rate-table-subscription")
        }}
        adapt={!isMobile}
        noShadow
        className="tw-mt-4 md:tw-mt-0 md:tw-ml-auto tw-min-w-56"
      >
        {ctaLabel}
      </Button>
    </Box>
  )
}

export default DefaultBanner
