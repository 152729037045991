import { h } from "preact"
import * as PopperJS from "popper.js"
import clsx from "clsx"
import { useState, useEffect, useRef } from "preact/hooks"
import { createPortal } from "preact/compat"
import { Manager, Reference, Popper } from "react-popper"
import { Icon, If } from "@ui"
import TooltipInner from "./TooltipInner"
import InfoOutlineIcon from "@assets/icons/info-outline.svg"
import "@styles/components/_tooltip.scss"

interface Props {
  theme?: "dark" | "light"
  eventTrigger?: "click" | "hover" | null
  ttPlacement?: PopperJS.Placement
  ttClassName?: string
  className?: string
  ctaClassName?: string
  iconSize?: number
  iconBG?: string
  iconColor?: string
  icon?: string
  style?: any
  stringToHTML?: boolean
  maxWidth?: number
  textAlignment?: "center" | "left" | "right"
  iconWeight?: string
  reference?: React.ReactNode
  children?: React.ReactNode
  small?: boolean
  onClick?: (e: Event) => void
  showTooltip?: boolean
  hideTooltip?: boolean
  hideArrow?: boolean
}

const Tooltip = ({
  theme = "dark",
  eventTrigger = "click",
  ttPlacement = "bottom",
  ttClassName = "",
  className = "",
  ctaClassName = "",
  iconSize = 14,
  iconBG = "#B4C4D3",
  iconColor = "#fff",
  icon = "i",
  style = {},
  stringToHTML = false,
  maxWidth = 0,
  textAlignment = "center",
  iconWeight = "600",
  reference = null,
  children,
  small = false,
  onClick = () => {},
  showTooltip,
  hideTooltip,
  hideArrow = false
}: Props) => {
  const [show, setShow] = useState(false)
  const [ttClassNameLocal, setTTClassName] = useState(ttClassName)
  const [styles, setStyles] = useState({})

  useEffect(() => {
    if (maxWidth) {
      setStyles({ maxWidth: maxWidth })
    }
  }, [maxWidth])

  useEffect(() => {
    if (showTooltip) {
      setShow(true)
    }
  }, [showTooltip])

  useEffect(() => {
    if (hideTooltip) {
      setShow(false)
    }
  }, [hideTooltip])

  useEffect(() => {
    let ttC = `${ttClassName} tooltip`

    if (show) {
      ttC += " tooltip--show"
    }
    if (small) {
      ttC += " tooltip--small"
    }

    setTTClassName(ttC)
  }, [ttClassName, show, small])

  const ele = useRef<HTMLDivElement>(null)

  const hideTooltipClick = (target: HTMLElement) => {
    if (eventTrigger === "click" && !ele.current?.contains(target)) {
      setShow(false)
    }
  }

  const toggleTooltipClick = (e: Event) => {
    if (eventTrigger === "click") {
      setShow(!show)
    }
    onClick?.(e)
  }

  const toggleTooltipHover = () => {
    if (eventTrigger === "hover") {
      setShow(!show)
    }
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Enter") {
      setShow(!show)
    }
    onClick?.(e)
  }

  const wrapperClassName = clsx("tooltip-wrapper", className, {
    "no-icon": reference,
    "click-trigger": eventTrigger === "click"
  })

  return (
    <Manager>
      <div ref={ele} className={wrapperClassName} style={{ ...style }}>
        <Reference>
          {({ ref }) => (
            <span
              tabIndex={0}
              onMouseEnter={toggleTooltipHover}
              onMouseLeave={toggleTooltipHover}
              onClick={toggleTooltipClick}
              onKeyDown={handleKeyDown}
              ref={ref}
              className={`tooltip-trigger ${reference ? ctaClassName : ""}`}
            >
              {reference || (
                <If
                  condition={icon === "i"}
                  then={
                    <Icon
                      className={`is-info ${ctaClassName}`}
                      src={InfoOutlineIcon}
                      size={16}
                      originalSize={20}
                    />
                  }
                  else={
                    <i
                      style={{
                        color: iconColor,
                        background: iconBG,
                        fontWeight: iconWeight,
                        height: `${iconSize}px`,
                        width: `${iconSize}px`,
                        fontSize: `${iconSize - 4}px`
                      }}
                      className={`${icon === "i" ? "is-info" : "not-info"}${
                        icon === "$" ? " is-dollar" : ""
                      } ${ctaClassName}`}
                    >
                      {icon}
                    </i>
                  }
                />
              )}
            </span>
          )}
        </Reference>

        {show &&
          createPortal(
            <Popper placement={ttPlacement}>
              {({ ref, style, placement, arrowProps }) => (
                <div
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  className={`${ttClassNameLocal} bs-tooltip-${placement} no-events is-${theme}`}
                  role="tooltip"
                >
                  <TooltipInner
                    addEvent={eventTrigger === "click"}
                    stringToHTML={stringToHTML}
                    hideTooltipClick={hideTooltipClick}
                    styles={styles}
                    className={`tooltip-inner text-${textAlignment}`}
                  >
                    {children}
                  </TooltipInner>
                  <If
                    condition={!hideArrow}
                    then={
                      <div
                        ref={arrowProps.ref}
                        style={arrowProps.style}
                        className="arrow"
                      />
                    }
                  />
                </div>
              )}
            </Popper>,
            document.body
          )}
      </div>
    </Manager>
  )
}

export default Tooltip
