import { h } from "preact"
import { useEffect } from "preact/hooks"
import { ModalClose } from "@composite"
import ExitCtaSlider from "./ExitCtaSlider"
import ExitCtaForm from "./ExitCtaForm"
import { eventDispatch } from "@helpers"
import "@styles/components/_exit-cta.scss"

const ExitCTA = ({ show, hideWizardExitCTA, forceFinish }) => {
  useEffect(() => {
    if (show) {
      eventDispatch({
        name: "lead_workflow_exit_cta",
        category: "lead_workflow"
      })

      // Resize the modal since Exit CTA is bigger than others.
      window.postMessage({ action: "resize" }, "*")
    }
  }, [show])

  return (
    <div
      className={`exit-cta justify-content-center ${show ? "is-active" : ""}`}
    >
      <div className="exit-cta__slider">
        <ExitCtaSlider />
      </div>
      <div className="exit-cta__form">
        <div className="exit-cta__form-inner">
          <ModalClose
            handleCloseButtonClick={hideWizardExitCTA}
            isCross={true}
          />
          <div className="exit-cta__form-header">
            Create your personalized live rate dashboard and stay informed with
            rate updates.
          </div>
          <ExitCtaForm forceFinish={forceFinish} />
        </div>
      </div>
    </div>
  )
}

export default ExitCTA
