import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useSelector } from "react-redux"
import { If, Alert, Icon } from "@ui"
import InfoOutlineIcon from "@assets/icons/info-outline.svg"
import { RootStates } from "@types"

const FHAVAEligibleBanner = () => {
  const [txt, setTxt] = useState("")

  const { va_eligible, is_fha, formIsDirty } = useSelector(
    ({ rateTable }: RootStates) => {
      return {
        va_eligible: rateTable?.formData?.va_eligible,
        is_fha: !!rateTable?.formData?.is_fha,
        formIsDirty: !!rateTable?.formIsDirty
      }
    }
  )

  useEffect(() => {
    if (!formIsDirty) {
      if (va_eligible) {
        setTxt("Showing all VA eligible rates")
      } else if (is_fha) {
        setTxt("Showing all FHA eligible rates")
      } else {
        setTxt("")
      }
    }
  }, [va_eligible, is_fha, formIsDirty])

  return (
    <If
      condition={txt.length > 0}
      then={
        <Alert.Secondary className="tw-text-sm tw-flex tw-items-center">
          <Icon
            src={InfoOutlineIcon}
            size={20}
            originalSize={20}
            className="tw-mr-1"
          />
          {txt}
        </Alert.Secondary>
      }
    />
  )
}

export default FHAVAEligibleBanner
