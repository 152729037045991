import {
  SAVE_STATE_FROM_PARENT_MIDDLE,
  SAVE_WIZARD_DETAILS,
  API_MSG_PROMISE
} from "./constants"
import { setConfig, getConfig } from "./config"
import { store } from "./store"
import { getStorage } from "./helpers"
import apiHandler from "./api/api-handler"

// Close Modal window
export function closeModal() {
  window.parent.postMessage({ action: "modal-close" }, "*")
}

// If there is more than one modal window, go back to the previous one
export function modalBack() {
  window.parent.postMessage({ action: "modal-back" }, "*")
}

// Show blocking modal window
export function showBlockingModal() {
  window.parent.postMessage({ action: "showBlockingModal" }, "*")
}

// Get the iframe height and send it to the parent
export function resizeFrame() {
  const { body, documentElement } = window.document

  const height =
    body?.scrollHeight ||
    body?.offsetHeight ||
    body?.clientHeight ||
    documentElement?.clientHeight ||
    documentElement?.offsetHeight ||
    documentElement?.scrollHeight

  window.parent.postMessage({ action: "resize", height: height }, "*")
}

// This is used to scroll the parent page to an anchor from the iframe
export function scrollToAnchor(anchorOffset) {
  const config = getConfig()
  window.parent.postMessage(
    {
      action: "scroll-to-anchor",
      wrapperID: config.wrapperID,
      anchorOffset: anchorOffset
    },
    "*"
  )
}

// This is used when the mobile overlay is open and closed
export function openMobileOverlay() {
  window.parent.postMessage(
    {
      action: "open-mobile-overlay"
    },
    "*"
  )
}

export function closeMobileOverlay() {
  window.parent.postMessage(
    {
      action: "close-mobile-overlay"
    },
    "*"
  )
}

/**
 * Get config in parent from the child iframe
 * TODO: It could be better to use a promise instead of a callback
 */
export function getConfigFromChild(app_key, win, callback) {
  function receiveMessage({ data }) {
    if (data.action === "sendConfigFromParent") {
      callback(data)
    }
  }
  // Add a listener to get the parent response
  win.addEventListener("message", receiveMessage, false)

  const data = {
    action: "getConfigFromChild",
    app_key
  }

  // Send the message to the parent
  win.parent.postMessage(data, "*")
}

export function getCookiesFromChild(key, win) {
  return postMessagePromise("getCookiesFromChild", key, win)
}

// Used to get the parent URL
export function getParentURL(win) {
  return postMessagePromise("getParentURL", null, win)
}

// Used to send the config/state to the parent frame
export function saveConfigFromChild(data, app_key) {
  window.parent.postMessage(
    { action: "saveConfigFromChild", data, app_key },
    "*"
  )
}

// Used to send the config/state to the parent frame
export function saveRTConfigFromChild(data, app_key) {
  window.parent.postMessage(
    { action: "saveRTConfigFromChild", data, app_key },
    "*"
  )
}

// Sync rate table store data
export function rateTableSync(data) {
  store?.dispatch({
    type: SAVE_STATE_FROM_PARENT_MIDDLE,
    payload: data
  })
}

// Show the lead workflow Exit CTA
export function showWorkflowExitCTA() {
  store?.dispatch({
    type: SAVE_WIZARD_DETAILS,
    payload: { forceExitCTA: true }
  })
}

export function sendEvent(eventInfo) {
  window.parent.postMessage({ action: "sendEvent", eventInfo: eventInfo }, "*")
}

export function emitEvent(eventInfo) {
  window.parent.postMessage({ action: "emitEvent", eventInfo: eventInfo }, "*")
}

export function syncConfig(config) {
  setConfig(config)
}

// Check version from parent frame storage
export function checkParentVersion(app_key) {
  window.parent.postMessage({ action: "checkParentVersion", app_key }, "*")
}
// Set version from parent frame storage
export function setParentVersion(version) {
  window.parent.postMessage({ action: "setParentVersion", version }, "*")
}

// Set data to parent local storage
export function setParentStorage(data, app_key) {
  window.parent.postMessage({ action: "setParentStorage", data, app_key }, "*")
}

// Clear data to parent local storage
export function clearParentStorage(app_key) {
  window.parent.postMessage({ action: "clearParentStorage", app_key }, "*")
}

// Used to get the parent local storage
export function getParentStorage(app_key, win) {
  return postMessagePromise("getStorage", app_key, win)
}

// Tell the parent to open the bar
export function openBar(target) {
  parent.postMessage(
    {
      action: "bar-open",
      target
    },
    "*"
  )
}

/**
 * Post message to the parent frame with a promise
 */
let __seq = 0 // global sequence
export const postMessagePromise = (func, params, win) => {
  return new Promise(resolve => {
    let seq = ++__seq

    let event_ref = win.addEventListener(
      "message",
      function (resp) {
        if (resp && resp.data && resp.data.__seq && resp.data.__seq === seq) {
          win.removeEventListener("message", event_ref)
          resolve(resp.data.value)
        }
      },
      false
    )

    window.parent.postMessage(
      { action: "msgPromise", func, params, __seq: seq },
      "*"
    )
  })
}

// This function handle the API calls and returning a promise to the caller
const apiMsgPromise = function ({ params, ___seq }, source) {
  if (___seq) {
    apiHandler(params).then(value => {
      source.postMessage({ value, ___seq }, "*")
    })
  }
}

const getLeadWorkflowValues = function ({ params, ___seq }, source) {
  if (___seq) {
    const workflowState = getStorage(params.storageKey)

    source.postMessage({ value: workflowState.values.steps, ___seq }, "*")
  }
}

/**
 * Here the embed(iframe) listen to the messages from the parent
 */
export default function bridge(frame, onReady = () => {}) {
  frame.addEventListener("message", e => {
    const data = e.data
    switch (data.action) {
      case "resize":
        resizeFrame()
        break
      case "getLeadWorkflowValues":
        getLeadWorkflowValues(data, e.source)
        break
      case "mouseleavePage":
        showWorkflowExitCTA()
        break
      case "syncConfig":
        syncConfig(data.config)
        break
      case "rateTableSync":
        rateTableSync(data)
        break
      case "scrollToAnchor":
        scrollToAnchor(data.config)
        break
      case "showBlockingModal":
        showBlockingModal()
        break
      case API_MSG_PROMISE:
        apiMsgPromise(data, e.source)
        break
      default:
        break
    }
  })
}
