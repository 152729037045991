import { h } from "preact"
import { useSelector } from "react-redux"
import CountUp from "react-countup"
import { RootStates } from "@types"
import { If, TxtLoading } from "@ui"

const Title = () => {
  const { isLoginForm, cardsLength, pricingGridIsLoading, disable_pricing } =
    useSelector(({ wizard }: RootStates) => {
      const isLoginForm =
        wizard?.steps[wizard?.currentStep]?.id === "login_form"

      return {
        pricingGridIsLoading: wizard?.pricingGridIsLoading,
        disable_pricing: wizard?.disable_pricing,
        cardsLength: wizard?.card_details?.cards.length ?? 0,
        isLoginForm
      }
    })

  return (
    <h2 className="wizard__step-title wizard__animated wizard__animated-titles">
      <If
        condition={!!pricingGridIsLoading}
        then={
          <div className="wizard__step-title-loader">
            <TxtLoading height={38} margin="0 0 8px" />
            <TxtLoading height={38} width={150} />
          </div>
        }
        elseCondition={cardsLength > 0 && !disable_pricing}
        else={
          <strong>
            You have{" "}
            <If
              condition={isLoginForm}
              then={
                <span className="accent-color">
                  {" "}
                  <CountUp delay={0.5} duration={0.5} end={cardsLength} />{" "}
                </span>
              }
            />{" "}
            mortgage options that are ready for your review!
          </strong>
        }
        default={
          <strong>
            We'll help you find the home loan options that fit your needs.
          </strong>
        }
      />
    </h2>
  )
}

export default Title
