import { h } from "preact"
import { useSelector } from "react-redux"
import { createPortal, useEffect, useState } from "preact/compat"
import { LO, RootStates } from "@types"
import { useAppLink } from "@hooks"
import { SVG, ContactBtn } from "@ui"
import ehoLight from "@assets/icons/eho-light.svg"
import ehlLight from "@assets/icons/ehl-light.svg"

const LenderHeader = () => {
  const { name, brand_nmls, bank, picture, position, nmls, non_bank } =
    useSelector(({ app }: RootStates) => app?.lo as LO)

  const parsedAppLink = useAppLink()

  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    const targetNode = document.querySelector("#embed-body .lender-header")
    if (targetNode) {
      // Replace the target node with a placeholder div
      const replacementNode = document.createElement("div")
      targetNode.replaceWith(replacementNode)
      setContainer(replacementNode)
    }
  }, [])

  const ImgSrc = non_bank ? ehlLight : ehoLight

  if (!container) return null

  return createPortal(
    <header className="lender-header">
      <div className="container">
        <div className="tw-items-center tw-flex md:tw-px-4 tw-flex-col md:tw-flex-row tw-flex-auto lender-header__profile ">
          <div className="lender-header__profile-avatar">
            <img src={picture} alt={`${bank} picture`} />
          </div>
          <div className="lender-header__profile-data">
            <div className="lender-header__profile-data-company">
              {bank} NMLS #{brand_nmls}
              <SVG
                src={ImgSrc}
                className="lender-header__eoh-icon"
                width={28}
                height={28}
                viewBox={non_bank ? "0 0 38 38" : "0 0 200 200"}
              />
              <img
                src={ImgSrc}
                alt="Equal housing lender logo"
                className="lender-header__eoh-icon"
              />
            </div>
            <h2>{name}</h2>
            <p>
              {position} | NMLS #{nmls}
            </p>
          </div>
          <div className="lender-header__profile-actions">
            <a
              href={parsedAppLink}
              className="button button--adapt is-default button--no-shadow"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              Apply now
            </a>
            <ContactBtn outline noShadow adapt className="no-bg" />
          </div>
        </div>
      </div>
    </header>,
    container
  )
}

export default LenderHeader
