import { createContext } from "preact"
import { TabPath } from "@types"

interface TabContextType {
  activeTab: TabPath
  setTab: (newTab: TabPath) => void
}

const TabContext = createContext<TabContextType | undefined>(undefined)

export default TabContext
