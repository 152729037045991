export const objToUrlParam = (url: string, obj: any) => {
  let newURL = url
  // check if we have to use ? or &
  const firstUnion = url.indexOf("?") === -1 ? "?" : "&"
  newURL +=
    firstUnion +
    Object.entries(obj)
      .map(
        ([key, val]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(val as string)}`
      )
      .join("&")

  return newURL
}

export const getObjectFromUrl = (url: any, key: string) => {
  if (!url[key]) return null
  return JSON.parse(decodeURIComponent(url[key]))
}

export function getUrlVars(url = location.href) {
  let vars: any = {}
  const parts = url.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (m, key, value) => (vars[key] = Number(value) || value)
  )
  return vars
}

/**
 * Get the position of an element relative to the document
 * https://plainjs.com/javascript/styles/get-the-position-of-an-element-relative-to-the-document-24/
 */
export function offset(el: HTMLElement) {
  const rect = el.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

// Simple and minimal `string print format` implementation
export function sprintf(format: string, ...args: (number | string)[]) {
  let i = 0
  return format.replace(/%s/g, () => String(args[i++]))
}

// Phone validator
export const validatePhoneFormat = (phone: string) => {
  return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone)
}

// Fotmat phone number
export const numberToUSPhone = (phone: string) => {
  return phone.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
}

export const cleanUSPhone = (phone: string) => {
  return phone.replace(/\D/g, "")
}

// Email validator
export const validateEmailFormat = (email: string) => {
  let emailLower = email.toLowerCase()
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
    emailLower
  )
}

export const getKeyByValue = (object: { [x: string]: any }, value: any) => {
  return Object.keys(object).find(key => object[key] === value)
}

// Open modals form links
export const openModal = (e: Event, type?: string) => {
  e.preventDefault()
  parent.postMessage(
    { action: "modal-open", target: (e.target as HTMLLinkElement)?.href, type },
    "*"
  )
}

export const openModalFromBtn = (href: string, type?: string) => {
  parent.postMessage({ action: "modal-open", target: href, type }, "*")
}

export const wildcardTest = (wildcard: string, str: string) => {
  let w = wildcard.replace(/[.+^${}()|[\]\\]/g, "\\$&") // regexp escape
  const re = new RegExp(`^${w.replace(/\*/g, ".*").replace(/\?/g, ".")}$`, "i")
  return re.test(str) // remove last 'i' above to have case sensitive
}

export const checkIfNumeric = (val: any) => !Number.isNaN(parseInt(val, 10))

/**
 * Decode JWT response
 * @param token
 * @returns {object} decoded token
 */
export const decodeJwtResponse = (token: string) => {
  var base64Url = token.split(".")[1]
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

// Check if the current window is in an iframe
export const isInIframe = () => window !== top
