import { h } from "preact"
import { useSelector } from "react-redux"
import { RootStates } from "@types"
import { useAppContext } from "@contexts"
import { Picture, CheckList, GetMyQuoteBtn } from "@ui"

interface Props {
  className?: string
}

const InitWorkflowBanner = ({ className = "" }: Props) => {
  const LO_NAME = useSelector(({ app }: RootStates) => {
    return app?.lo?.name
  })

  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  return (
    <div
      className={`tw-flex tw-border tw-text-neutral-300 radius-base tw-py-6 md:tw-pb-0 tw-px-6 md:tw-px-10 lg:tw-px-12 tw-overflow-hidden gap-lg ${className}`}
    >
      <Picture
        className="tw-max-w-[170px] tw-h-[212px] tw-hidden md:tw-block"
        source="assets/banners/banner-phone-chat.webp"
        imgSrc="assets/banners/banner-phone-chat.png"
        alt="phone chat"
      />
      <div className="md:tw-flex tw-items-center gap-lg tw-w-full">
        <div>
          <h3 className="tw-text-black tw-font-bold tw-text-2xl tw-leading-8">
            Start your instant quote with {LO_NAME}
          </h3>
          <CheckList
            className="txt-dark tw-pt-4"
            listItems={[
              "Clarify doubts",
              "Get a free personalized rate quote",
              "Start an application"
            ]}
          />
        </div>

        <GetMyQuoteBtn
          className="tw-mt-6 md:tw-mt-0 tw-h-16 md:tw-w-56 md:tw-ml-auto"
          size="2xl"
          adapt={!isMobile}
        />
      </div>
    </div>
  )
}

export default InitWorkflowBanner
