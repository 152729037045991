import { h } from "preact"
import { Group } from "../../layout"
import { DecoratedInput, NumericInput, SelectCustom } from "../../inputs"

const loanTypes = [
  { value: 360, label: "30-Year Fixed" },
  { value: 180, label: "15-Year Fixed" }
]

interface Props {
  handleChange: (value: any, name: string, isNum?: boolean) => void
  loan_type: number
  interest_rate: number
}

const InterestRate = ({ handleChange, loan_type, interest_rate }: Props) => (
  <Group rounded>
    <div className="form__field-group">
      <SelectCustom
        size="lg"
        isMaterial
        label="Interest Rate"
        name="loan_type"
        placeholder="30-Year Fixed"
        options={loanTypes}
        value={loan_type}
        onChange={option => {
          handleChange(option.value, "loan_type", true)
        }}
      />
      <DecoratedInput
        className="value-field wiz-rate attached-percent-field"
        suffix="%"
        label=""
        size="lg"
      >
        <NumericInput
          name="interest_rate"
          handleInputChange={value => {
            handleChange(value, "interest_rate", true)
          }}
          value={interest_rate}
          min={0}
          max={100}
        />
      </DecoratedInput>
    </div>
  </Group>
)

export default InterestRate
