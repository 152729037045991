import { h } from "preact"
import { useSelector } from "react-redux"
import { openLeadWorkflowFromWithin } from "@iframeCommunication/utils"
import { Button } from "@ui"
import { MORTGAGE_TXT } from "@content"
import { selectGlobalModalIframeID } from "@selectors"

const { tagline, cta } = MORTGAGE_TXT.footer

const CalcCTA = () => {
  const iframeID = useSelector(selectGlobalModalIframeID)
  const handleOnClick = () => {
    openLeadWorkflowFromWithin(iframeID)
  }
  return (
    <div className="pre-qualify-section d-flex flex-md-row flex-column align-items-center justify-content-center">
      <h3>{tagline}</h3>
      <Button onClick={handleOnClick} adapt size="md" noShadow label={cta} />
    </div>
  )
}

export default CalcCTA
