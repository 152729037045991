import { loanCalcAPI } from "@api"

export const apiFactory = (app_key: string, nmls: string) => {
  return {
    getDefaultValues: () => {
      return loanCalcAPI.getDefaultValues(nmls)
    },
    getLoanCalc: ({
      homePrice,
      downPercentage,
      interestRate,
      loanTerm,
      loan_type,
      credit_score,
      force_rate,
      zipcode
    }: {
      homePrice: number
      downPercentage: number
      interestRate: number
      loanTerm: number
      loan_type: string
      credit_score: number
      force_rate: boolean
      zipcode: number
    }) => {
      const params = {
        list_price: homePrice,
        dpp: downPercentage,
        term: loanTerm,
        loan_type,
        app_key,
        zipcode,
        rate: interestRate ?? undefined,
        credit_score: credit_score ?? undefined,
        force_rate: force_rate ?? undefined
      }
      return loanCalcAPI.getLoanCalc(nmls, params)
    },
    getLoanCalcConfig: () => {
      return loanCalcAPI.getLoanCalcConfig()
    }
  }
}
