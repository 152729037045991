import { h } from "preact"
import "@styles/components/_chevron.scss"

type Props = {
  side?: "top" | "bottom" | "left" | "right"
  isActive?: boolean
  bold?: boolean
  className?: string
  animated?: boolean
  theme?: "dark" | "light" | "primary"
  size?: number
}

const Chevron = ({
  side = "bottom",
  isActive,
  bold,
  className = "",
  animated,
  size,
  theme
}: Props) => (
  <span
    {...(size ? { style: { height: `${size}px`, width: `${size}px` } } : {})}
    className={`chevron is-${theme} ${side} ${className} ${
      animated ? "is-animated" : ""
    } ${isActive ? "is-active" : ""} ${bold ? "is-bold" : ""}`}
  />
)

export default Chevron
