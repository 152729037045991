import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import { useDispatch, useSelector } from "react-redux"
import {
  LoanDetailsFormFields,
  RatesFormContextProps,
  RootStates
} from "@types"
import {
  rateFormSubmitted,
  rateFormSubmittedAll,
  updateRateForm
} from "@actions"
import RatesToolbar from "../RatesToolbar"
import {
  FEATURED_OPTION_TERM,
  MORTGAGE_TERMS_CONFIG_WITH_FEATURED
} from "@constants"
import { useRatesFormContext } from "@contexts"

const RateTableToolbar = () => {
  const { mortgage_terms, selectedTerm, featuredRatesTabEnabled } = useSelector(
    ({ rateTable }: RootStates) => ({
      mortgage_terms: rateTable?.rateTableConfig?.mortgage_terms,
      selectedTerm: rateTable?.formData.selectedTerm,
      featuredRatesTabEnabled:
        rateTable?.rateTableConfig?.featured_rates_tab_enabled ?? false
    })
  )

  const ratesFormContext = useRatesFormContext()
  const { formData } = ratesFormContext as RatesFormContextProps

  const dispatch = useDispatch()
  const [options, setOptions] = useState<
    Partial<typeof MORTGAGE_TERMS_CONFIG_WITH_FEATURED>
  >([])

  const handleOnChangeTerm = (quoteInfo: Partial<LoanDetailsFormFields>) => {
    if (quoteInfo.isAllTerms) {
      dispatch(rateFormSubmittedAll())
    } else {
      dispatch(
        updateRateForm({
          ...quoteInfo,
          selectedTerm: quoteInfo.loan_term || quoteInfo.arm_term
        })
      )
      dispatch(rateFormSubmitted(false))
    }
  }

  useEffect(() => {
    if (mortgage_terms) {
      const newOptions = featuredRatesTabEnabled ? [FEATURED_OPTION_TERM] : []

      setOptions([...newOptions, ...mortgage_terms])
    }
  }, [mortgage_terms, featuredRatesTabEnabled])

  return (
    <RatesToolbar
      loan_type={formData.loan_type}
      selectedTerm={selectedTerm}
      options={options}
      handleOnChangeTerm={handleOnChangeTerm}
    />
  )
}

export default RateTableToolbar
