import { h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { useAppLink } from "@hooks"
import { Box, If, TextAndListSkeleton, Button } from "@ui"
import BodyLoan from "./BodyLoan"
import { useAppContext } from "@contexts"

const ITEMS_TXT = [
  "Two most recent years W2s",
  "Two most recent years tax returns (personal & business if applicable)",
  "Two most recent paystubs",
  "Two most recent months bank statements",
  "Two most recent months 401K or retirement statement",
  "Copy of front of valid drivers license"
]

const ApplyToLoan = () => {
  const [isLoading, setLoading] = useState(true)

  // Simulate loading
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [])

  const parsedAppLink = useAppLink()

  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  return (
    <Box>
      <If
        condition={isLoading}
        then={
          <TextAndListSkeleton
            className={`${isMobile ? "tw-p-4" : "tw-p-8"}`}
          />
        }
        else={
          <div
            className={`tw-text-xl txt-dark ${isMobile ? "tw-p-4" : "tw-p-8"}`}
          >
            <BodyLoan items={ITEMS_TXT} isMobile={isMobile} />

            <Button
              className={`tw-mt-8 ${!isMobile ? "tw-w-44" : ""}`}
              href={parsedAppLink}
              target="_blank"
              adapt={!isMobile}
              noShadow
              size="2xl"
            >
              Apply now
            </Button>
          </div>
        }
      />
    </Box>
  )
}

export default ApplyToLoan
