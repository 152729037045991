import { h } from "preact"
import { LoanDetailsFormFields, OnChangeValue } from "@types"
import { ButtonGroup } from "../../inputs"

interface Props {
  value?: string
  label?: string
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
  size?: "sm" | "md" | "lg" | "xl"
  hideLabel?: boolean
}

const LoanTypeToggle = ({
  value = "purchase",
  handleOnChange,
  size = "md",
  hideLabel = false,
  label = "Loan Purpose"
}: Props) => (
  <ButtonGroup
    hideLabel={hideLabel}
    options={[
      {
        label: "Purchase",
        value: "purchase"
      },
      { label: "Refinance", value: "refinance" }
    ]}
    label={label}
    value={value}
    handleClick={(val: string) => {
      handleOnChange(val, "loan_purpose")
    }}
    size={size}
  />
)

export default LoanTypeToggle
