import { h } from "preact"
import { ScreenReaderOnly, Button, ArrowIcon } from "@ui"

interface BackBtnProps {
  onClick: () => void
  size?: "md"
}

const BackBtn = ({ onClick, size }: BackBtnProps) => (
  <Button link className="wizard__back-btn" onClick={onClick}>
    <ArrowIcon side="left" {...(size === "md" ? { size: [22, 17] } : {})} />
    <ScreenReaderOnly>Back</ScreenReaderOnly>
  </Button>
)

export default BackBtn
