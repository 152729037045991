import { h } from "preact"
import { useState } from "preact/hooks"
import Edit from "../Edit"
import View from "../View"
import { If } from "@ui"
import { LEAD_WORKFLOW_TXT } from "@content"
import { DescriptionListItems, FormData } from "@types"

interface Props {
  className?: string
  items: DescriptionListItems
  formFields: Partial<FormData>
  onChange: (value: any, key: string) => void
  onSave: () => void
  onCancel: () => void
}

const StepsAnswersBox = ({
  className = "",
  items,
  formFields,
  onChange,
  onSave,
  onCancel
}: Props) => {
  const [view, setView] = useState<"edit" | "view">("view")

  const toggleView = () => {
    setView(view === "edit" ? "view" : "edit")
  }

  return (
    <div
      className={`${className} tw-rounded-2xl tw-bg-[#0E2846] tw-p-8 tw-text-white tw-min-w-80`}
    >
      <h3 className="tw-text-lg tw-font-bold tw-m-0">
        {LEAD_WORKFLOW_TXT.STEPS_ANSWERS.TITLE}
      </h3>
      <If
        condition={view === "edit"}
        then={
          <Edit
            toggleView={toggleView}
            formFields={formFields}
            onChange={onChange}
            onSave={onSave}
            onCancel={onCancel}
          />
        }
        else={<View toggleView={toggleView} items={items} />}
      />
    </div>
  )
}

export default StepsAnswersBox
