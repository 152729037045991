import { h } from "preact"
import Wrapper from "./Wrapper"
import { AlertProps } from "./type"
import "@styles/components/_alert.scss"

const DEFAULT_CLASSNAME = ""

const Danger = ({ className = DEFAULT_CLASSNAME, ...rest }: AlertProps) => (
  <Wrapper
    {...rest}
    className={`${className} tw-text-red-900 tw-bg-red-100 tw-border-red-200`}
  />
)

const Info = ({ className = DEFAULT_CLASSNAME, ...rest }: AlertProps) => (
  <Wrapper
    {...rest}
    className={`${className} tw-text-blue-900 tw-bg-blue-100  tw-border-blue-200`}
  />
)

const Success = ({ className = DEFAULT_CLASSNAME, ...rest }: AlertProps) => (
  <Wrapper
    {...rest}
    className={`${className} tw-text-green-900 tw-bg-green-100 tw-border-green-200`}
  />
)

const Secondary = ({ className = DEFAULT_CLASSNAME, ...rest }: AlertProps) => (
  <Wrapper
    {...rest}
    className={`${className} tw-text-slate-800 tw-bg-zinc-100  tw-border-zinc-200`}
  />
)
const Alert = {
  Secondary,
  Danger,
  Info,
  Success
}

export default Alert
