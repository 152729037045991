import { h } from "preact"
import { SelectCustom } from "../../inputs"
import { CREDIT_SCORE_OPTIONS } from "@constants"
import { BasicUISize } from "@types"

interface Props {
  value: string
  handleOnChange: (value: string, name: string) => void
  isMaterial?: boolean
  name?: string
  label?: string
  size?: BasicUISize
  optionsClassName?: string
}

const CreditScoreRange = ({
  value,
  handleOnChange,
  isMaterial,
  name = "credit_score",
  label = "Credit Score Range",
  size = "md",
  optionsClassName = ""
}: Props) => (
  <SelectCustom
    isMaterial={isMaterial}
    optionsClassName={optionsClassName}
    name={name}
    options={CREDIT_SCORE_OPTIONS}
    value={value}
    onChange={option => {
      handleOnChange(option.value, name)
    }}
    label={label}
    size={size}
  />
)

export default CreditScoreRange
