import { h } from "preact"
import { If } from "@ui"
import { PurchaseFields, RefinanceFields } from "../LoanDetailsFieldsFullForms"
import { LoanDetailsFormFields, OnChangeValue } from "@types"
import { Button, Form } from "@ui"

interface Props {
  hasARMTerms: boolean
  formIsDirty: boolean
  formData: LoanDetailsFormFields
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
  onClickOverlaySubmitBtn: () => void
}

const MobileLoanDetailsFieldsForm = ({
  hasARMTerms,
  formIsDirty,
  formData,
  handleOnChange,
  onClickOverlaySubmitBtn
}: Props) => (
  <div className="pt-3">
    <div className="px-3">
      <Form.LoanTypeToggle
        handleOnChange={handleOnChange}
        value={formData.loan_purpose}
      />
    </div>
    <div className="loan-details-form__mobile">
      <If
        condition={formData.loan_purpose === "purchase"}
        then={
          <PurchaseFields
            hasARMTerms={hasARMTerms}
            formData={formData}
            handleOnChange={handleOnChange}
          />
        }
        else={
          <RefinanceFields
            hasARMTerms={hasARMTerms}
            formData={formData}
            handleOnChange={handleOnChange}
          />
        }
      />
    </div>
    <div className="loan-details-form__mobile-submit-btn">
      <Button
        noShadow
        size="md"
        className="text-capitalize loan-details-form__submit-btn"
        onClick={onClickOverlaySubmitBtn}
        label="Update rates"
        disabled={!formIsDirty}
      />
    </div>
  </div>
)

export default MobileLoanDetailsFieldsForm
