import { ComponentChildren, h } from "preact"
import { Icon } from "@ui"
import ImgCelebrating from "@assets/celebrating.svg"
import "@styles/components/_cta-footer.scss"

type Props = {
  children: ComponentChildren
  inSidebar?: boolean
  className?: string
}

const RegularFooter = ({ inSidebar, className = "", children }: Props) => {
  return (
    <div
      className={`cta-footer reviews-cta-footer ${className} ${
        inSidebar ? "in-sidebar px-0 pt-4 pb-0" : ""
      }`}
    >
      <div className="reviews-cta-footer__content">
        <Icon
          className="cta-footer__icon"
          src={ImgCelebrating}
          size={[94, 72]}
          originalSize={[94, 72]}
        />
        <div className="reviews-cta-footer__text">
          <p
            className={`cta-footer__text-description cta-footer__text-bold ${
              inSidebar ? "mx-0 mt-2" : ""
            }`}
          >
            Get customized rates tailored to your mortgage needs right here,
            right now.
          </p>
          <p
            className={`cta-footer__text-description d-none d-sm-block ${
              inSidebar ? "mx-0 mt-2" : ""
            }`}
          >
            Join our happy customers and experience the difference today.
          </p>
        </div>
      </div>
      <div className={inSidebar ? "py-4 px-3 w-100" : "text-nowrap"}>
        {children}
      </div>
    </div>
  )
}

export default RegularFooter
