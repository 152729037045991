import { h } from "preact"
import { css } from "emotion"
import styled from "preact-emotion"

export default () => (
  <div className="pricing-item is-loader p-1">
    <div className="pricing-item__header">
      <div className="loading-placeholder loading-placeholder--animated-background">
        <PriceMargin />
        <Price />
        <PriceMargin />

        <PriceBottomMargin />

        <HeaderTextMargin />
        <HeaderText />
        <HeaderTextMargin />
      </div>
    </div>

    <div className="pricing-item__features">
      <div className="loading-placeholder loading-placeholder--animated-background">
        <TextBottomMargin />
        <TextMargin />
        <Text width="100" />
        <TextMargin />
        <TextBottomMargin />
        <TextMargin />
        <Text width="75" />
        <TextMargin />
        <TextBottomMargin />
        <TextMargin />
        <Text width="120" />
        <TextMargin />
        <TextBottomMargin />
        <TextMargin />
        <Text width="80" />
        <TextMargin />
        <TextBottomMargin />
        <TextMargin />
        <TextBottomMargin />
      </div>
    </div>
  </div>
)

/*
 *  Classes
 */
const mask = css`
  background: #fff;
`
const headerMask = css`
  background: #f0f8fb;
`
const element = css`
  background: transparent;
`
const hMargin = css`
  flex: 1 0 1%;
`
const vMargin = css`
  flex: 1 0 100%;
`

const priceRow = css`
  height: 28px;
`

const textRow = css`
  height: 14px;
`

/*
 *  Components
 */
const PriceMargin = styled("div")`
  ${headerMask};
  ${hMargin};
  ${priceRow};
`
const Price = styled("div")`
  ${element};
  ${priceRow};
  width: 100px;
`
const PriceBottomMargin = styled("div")`
  ${headerMask};
  ${vMargin};
  height: 20px;
`
const HeaderTextMargin = styled("div")`
  ${headerMask};
  ${hMargin};
  ${textRow};
`
const HeaderText = styled("div")`
  background: rgba(0, 173, 52, 0.3);
  ${textRow};
  width: ${(props: { width?: number }) => (props.width || 70) + "px"};
`

const TextMargin = styled("div")`
  ${mask};
  ${hMargin};
  ${textRow};
`
const TextBottomMargin = styled("div")`
  ${mask};
  ${vMargin};
  height: 8px;
`
const Text = styled("div")`
  ${element};
  ${textRow};
  width: ${(props: { width?: number }) => (props.width || 70) + "px"};
`
