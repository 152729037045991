import { h } from "preact"
import { useSelector } from "react-redux"
import TooltipFull from "../../TooltipFull"
import { RootStates, PricingItem } from "@types"
import OfferDetailsTooltipContent from "./OfferDetailsTooltipContent"

interface Props {
  handleClickFees: ({
    e,
    openModal
  }: {
    e?: Event
    openModal?: boolean
  }) => void
  currentDetailsCard: PricingItem
}

const PricingItemOfferDetailsTooltip = ({
  handleClickFees,
  currentDetailsCard
}: Props) => {
  const disableFeesModal = useSelector(
    ({ rateTable }: RootStates) =>
      !!rateTable?.rateTableConfig?.fees_modal_disabled
  )

  if (disableFeesModal) return <span>Fees</span>

  const openOfferDetailsModal = (e: Event) => {
    handleClickFees({ e, openModal: true })
  }

  return (
    <TooltipFull
      eventTrigger="hover"
      theme="highlight"
      ttPlacement="top-start"
      ttClassName="tw-max-w-72"
      ttContentClassName="tw-h-auto"
      hideArrow
      ctaClassName="pricing-item__tooltip"
      onClick={e => {
        e.preventDefault()
        openOfferDetailsModal(e)
      }}
      content={
        <OfferDetailsTooltipContent
          openOfferDetailsModal={openOfferDetailsModal}
          currentDetailsCard={currentDetailsCard}
        />
      }
    >
      <span className="pricing-item__details-tooltip">Fees</span>
    </TooltipFull>
  )
}

export default PricingItemOfferDetailsTooltip
