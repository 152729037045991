import { h } from "preact"
import { useCallback } from "preact/hooks"
import { useSelector } from "react-redux"
import { RootStates, TabPath } from "@types"
import { useLoTabsContext } from "@contexts"
import MenuItem from "./MenuItem"
import "@styles/components/_menu.scss"

interface Props {
  className?: string
}

const Menu = ({ className = "" }: Props) => {
  const { activeTab, setTab } = useLoTabsContext()

  const isActive = (item: TabPath) => (activeTab === item ? "is-active" : "")

  const {
    loReviews,
    brandReviews,
    mountInlineLeadWorkflow,
    mountInlineAffordCalc,
    mountInlineCalc,
    mountInlineRateTable
  } = useSelector(({ app }: RootStates) => {
    const { widgets } = app

    return {
      loReviews: widgets?.loReviews,
      brandReviews: widgets?.brandReviews,
      mountInlineLeadWorkflow: widgets?.mountInlineLeadWorkflow,
      mountInlineAffordCalc: widgets?.mountInlineAffordCalc,
      mountInlineCalc: widgets?.mountInlineCalc,
      mountInlineRateTable: widgets?.mountInlineRateTable
    }
  })

  const handleOnClick = useCallback(
    (tab: TabPath) => {
      setTab(tab)
    },
    [setTab]
  )

  return (
    <nav className={`menu ${className}`}>
      <ul className="tw-flex menu__list">
        {mountInlineLeadWorkflow && (
          <MenuItem
            className={isActive("leadWorkflow")}
            tab="leadWorkflow"
            onClick={handleOnClick}
            label="Get Custom Offer"
          />
        )}
        {mountInlineRateTable && (
          <MenuItem
            className={isActive("rates")}
            tab="rates"
            onClick={handleOnClick}
            label="Today's Rates"
          />
        )}
        {(mountInlineAffordCalc || mountInlineCalc) && (
          <MenuItem
            className={isActive("calcs")}
            tab="calcs"
            onClick={handleOnClick}
            label="Calculators"
          />
        )}
        {(loReviews || brandReviews) && (
          <MenuItem
            className={isActive("reviews")}
            tab="reviews"
            onClick={handleOnClick}
            label="Reviews"
          />
        )}
        {mountInlineLeadWorkflow && (
          <MenuItem
            className={isActive("loan")}
            tab="loan"
            onClick={handleOnClick}
            label="Apply for Loan"
          />
        )}
      </ul>
    </nav>
  )
}

export default Menu
