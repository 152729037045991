import { DEFAULT_FORM_DATA } from "@constants"

export default {
  subscribed: false, // used by the switch
  subscribeSubmitted: false, // true if the subscription was submitted at least once
  formUpdated: false, // true if the form was updated from the last time subscription was submitted
  isFilterOpen: false,
  pricingIsLoading: -1, // -1: not yet initialized, 0: finished loading pricing, 1+: loading 1+ pricing
  isLoadingConfig: true,
  pricingLoading: false,
  isAllTerms: true,
  rateData: [],
  formData: { ...DEFAULT_FORM_DATA },
  rowSelected: null
}
