import { h, ComponentChildren } from "preact"

interface Props {
  children: ComponentChildren
  sm?: boolean
  className?: string
  flexWrap?: boolean
}

const Toolbar = ({ children, sm, className = "", flexWrap = true }: Props) => (
  <div
    className={`toolbar tw-flex tw-items-center tw-justify-start ${className} ${
      flexWrap ? "tw-flex-wrap" : ""
    } ${sm ? "is-sm" : ""} `}
  >
    {children}
  </div>
)

export default Toolbar
