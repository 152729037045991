import { h } from "preact"
import { useEffect } from "preact/hooks"
import { InlineReviews } from "../reviews"
import { useAppContext } from "@contexts"
import "@styles/components/_reviews.scss"

interface Props {
  bank: string
}

const RecentBrandReviews = ({ bank }: Props) => {
  const {
    reviews: { getReviews, brand: reviewsData }
  } = useAppContext()

  useEffect(() => {
    getReviews("brand")
  }, [])

  return (
    <div
      className={`recent-reviews ${
        reviewsData.noReviews && !reviewsData.isLoading ? "d-none" : ""
      }`}
      id="recent-reviews"
    >
      <h3 className="recent-reviews__title">Recent reviews of {bank}</h3>
      <InlineReviews
        className="recent-reviews__body"
        type="brand"
        showCta={false}
        reviewsViewMode="slide"
      />
    </div>
  )
}

export default RecentBrandReviews
