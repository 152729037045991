import { h } from "preact"
import { store } from "../../../../../store"
import { SelectCustom } from "../../inputs"
import { LOAN_PURPOSE_OPTIONS, PROPERTY_TYPE_OPTIONS } from "@constants"

interface Props {
  value?: (typeof LOAN_PURPOSE_OPTIONS)[number]["value"]
  optionsClassName?: string
  handleOnChange: (
    value: (typeof LOAN_PURPOSE_OPTIONS)[number]["value"],
    name: string
  ) => void
  isMaterial?: boolean
  showErrorWhenEmpty?: boolean
  name?: string
  label?: string
  size?: "sm" | "md" | "lg"
  notStacked?: boolean
  className?: string
}

const LoanPorpouse = ({
  showErrorWhenEmpty = true,
  value,
  handleOnChange,
  isMaterial = true,
  name = "loan_purpose",
  label = "Mortgage type",
  size = "md",
  optionsClassName = "",
  notStacked = false,
  className = ""
}: Props) => (
  <SelectCustom
    className={className}
    optionsClassName={optionsClassName}
    isMaterial={isMaterial}
    name={name}
    options={LOAN_PURPOSE_OPTIONS}
    value={value}
    onChange={option => {
      handleOnChange(option.value, name)
    }}
    label={label}
    size={size}
    showErrorWhenEmpty={showErrorWhenEmpty}
    notStacked={notStacked}
  />
)

export default LoanPorpouse
