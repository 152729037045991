import { h } from "preact"
import { useEffect } from "preact/hooks"
import { ReviewType } from "../../types/reviews"
import ReviewRatingBlockCompact from "../ReviewRatingBlockCompact"
import { If } from "@ui"
import { useAppContext } from "@contexts"
import "@styles/components/_reviews-list.scss"

interface Props {
  className?: string
  center?: boolean
  isDarkMode?: boolean
  currentColor?: boolean
  type: ReviewType
  limit?: number
  withSeparator?: boolean
  size?: "sm" | "md" | "lg"
  onClick?: () => void
}

const ReviewsList = ({
  className = "",
  center,
  isDarkMode,
  currentColor = true,
  type = "brand",
  limit = 0,
  withSeparator = false,
  size = "sm",
  onClick
}: Props) => {
  const {
    reviews: { getReviews, brand, lo }
  } = useAppContext()

  useEffect(() => {
    getReviews(type)
  }, [])

  const reviews = type === "brand" ? brand : lo

  const { isLoading, providers } = reviews

  if (!reviews && !isLoading) return null

  const handleClick = () => {
    onClick?.()
  }

  return (
    <div
      className={`reviews-list tw-relative ${
        isDarkMode ? "is-dark" : ""
      } ${className} ${onClick ? "tw-cursor-pointer" : ""}`}
      tabIndex={0}
      onClick={handleClick}
    >
      <If
        isLoading={isLoading}
        condition={!isLoading}
        then={
          <div
            className={`reviews-list__inner tw-flex tw-flex-wrap ${
              center ? "tw-justify-center" : "tw-justify-start"
            } ${withSeparator ? "has-separator tw-gap-14" : "tw-gap-12"} ${
              onClick ? "tw-pointer-events-none" : ""
            }`}
          >
            {providers?.map((provider, index) => {
              const providerReviews = reviews?.[provider]

              if (limit && index >= limit) return null

              if (!providerReviews) return null

              return (
                <ReviewRatingBlockCompact
                  size={size}
                  type={type}
                  provider={provider}
                  currentColor={currentColor}
                />
              )
            })}
          </div>
        }
      />
    </div>
  )
}

export default ReviewsList
