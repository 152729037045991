import { h } from "preact"
import Icon from "../Icon"
import checkIcon from "@assets/icons/check-circle-outline.svg"

type Props = {
  className?: string
  rowSelected?: boolean
  listItems: string[]
}

const CheckList = ({ className = "", listItems }: Props) => {
  return (
    <ul className={`tw-list-none tw-flex tw-flex-col gap-sm ${className}`}>
      {listItems.map(item => (
        <li className="tw-flex tw-items-center">
          <Icon
            src={checkIcon}
            size={[20, 20]}
            originalSize={[20, 20]}
            className="tw-mr-4"
          />
          <span>{item}</span>
        </li>
      ))}
    </ul>
  )
}

export default CheckList
