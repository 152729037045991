import { h } from "preact"
import { isFHA, numberToUSD } from "@helpers"
import { If, Badge } from "@ui"
import { useSelector } from "react-redux"
import { RootStates } from "@types"

interface Props {
  principalAndInterest: number
}

const PI = ({ principalAndInterest }: Props) => {
  const wizard = useSelector(({ wizard }: RootStates) => wizard)

  let showBadge = false
  let badgeText = ""
  if (wizard) {
    const loan_amount =
      wizard?.values?.steps.list_price ??
      0 - (wizard?.values?.steps.down_payment ?? 0)

    const isFHAPrice = isFHA(
      wizard?.values?.steps.residency_type ?? "primary_home",
      wizard?.values?.steps.credit_score ?? 0,
      loan_amount
    )

    const isVA = wizard?.values?.steps.military_eligible === "yes"
    showBadge = isFHAPrice || isVA

    badgeText = isVA ? "VA" : isFHAPrice ? "FHA" : ""
  }

  return (
    <div className="pricing-item__details is-tp">
      <strong>
        {numberToUSD(principalAndInterest, true)}
        <span className="pricing-item__details-posfix">/mo</span>
      </strong>
      <div className="d-flex align-items-center">
        <small className="pricing-item__label mr-2">P & I</small>
        <If
          condition={showBadge}
          then={
            <Badge theme="alt" pill>
              <span className="tw-uppercase">{badgeText}</span>
            </Badge>
          }
        />
      </div>
    </div>
  )
}

export default PI
