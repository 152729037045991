import { h } from "preact"
import "@styles/components/_avatar.scss"

type Props = {
  src: string
  alt: string
  rounded?: boolean
  size?: "md" | "lg"
  className?: string
}

const DEFAULT_CLASSNAME = ""
const DEFFAULT_SIZE = "md"

const Avatar = ({
  src,
  alt,
  rounded,
  size = DEFFAULT_SIZE,
  className = DEFAULT_CLASSNAME
}: Props) => (
  <div
    className={`avatar is-${size} ${rounded ? "is-rounded" : ""} ${className}`}
  >
    <img src={src} alt={alt} />
  </div>
)

export default Avatar
