import { ComponentChildren, h } from "preact"

interface Props {
  children: ComponentChildren
  id?: string
}

const ScreenReaderOnly = ({ children, id }: Props) => (
  <span className="tw-sr-only" {...(id ? { id } : {})}>
    {children}
  </span>
)

export default ScreenReaderOnly
