import { h } from "preact"
import { useMemo } from "preact/hooks"
import { Button, If } from "@ui"
import { formatFeeValue } from "@helpers"
import { FeeItem, PricingItem } from "@types"
import FundingFee from "../../OfferDetailsInner/partials/FundingFee"

interface Props {
  openOfferDetailsModal: (e: Event) => void
  currentDetailsCard: PricingItem
}

const OfferDetailsTooltipContent = ({
  openOfferDetailsModal,
  currentDetailsCard
}: Props) => {
  const { lenderFees, totalFees } = useMemo(() => {
    const points = currentDetailsCard?.pts ?? 0
    const itemFeeList = Array.isArray(currentDetailsCard?.feeList)
      ? currentDetailsCard.feeList
      : []

    const feesLocal = [
      {
        label: `${points < 0 ? "Credit" : "Points"} ${points}`,
        amount: Math.sign(points) * (currentDetailsCard.fee.amount ?? 0),
        type: ""
      } as FeeItem,
      ...itemFeeList.filter(
        fee => fee.type !== "ThirdParty" && fee.type !== "State"
      )
    ]

    if (points === 0 && currentDetailsCard.fee.amount) {
      feesLocal.push({
        label: currentDetailsCard.fee.title,
        amount: currentDetailsCard.fee.amount,
        type: ""
      } as FeeItem)
    }

    const totalFees = feesLocal.reduce(
      (acc, current) => acc + current.amount,
      0
    )

    return {
      lenderFees: feesLocal.slice(0, 4),
      totalFees
    }
  }, [currentDetailsCard])

  return (
    <div className="offer-details__list">
      <div className="fees-list">
        <div className="tw-text-2xl tw-font-bold">
          {formatFeeValue(totalFees)}
        </div>
        <div className="tw-text-sm tw-font-semibold">
          Estimated lender fees for this offer
        </div>
        <div
          className={`list ${
            lenderFees.length === 4 ? "is-shortened" : ""
          } tw-pt-4`}
        >
          {lenderFees.map(({ label, amount }) => (
            <div key={label} className="list__item">
              <div className="list__item--label tw-max-w-44">{label}</div>
              <div className="list__item--value">{formatFeeValue(amount)}</div>
            </div>
          ))}
        </div>
        <If
          condition={!!currentDetailsCard?.fundingFee}
          then={<FundingFee currentDetailsCard />}
        />
      </div>

      <Button link onClick={openOfferDetailsModal} className="tw-mt-4">
        <span className="tw-font-bold tw-text-sm blue-color">
          View all fees
        </span>
      </Button>
    </div>
  )
}

export default OfferDetailsTooltipContent
