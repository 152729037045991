import { h } from "preact"
import { Box } from "../.."
import CalcWrapper from "./Calc"
import { getConfig } from "../../../config"

const InlineAffordabilityCalculator = () => {
  const config = getConfig()
  return (
    <Box smallGutter className="inline-afford-calc">
      <CalcWrapper config={config} hideCTA />
    </Box>
  )
}

export default InlineAffordabilityCalculator
