import { useDispatch, useSelector } from "react-redux"
import { LeadWorkflowSteps } from "@types"
import { RootStates } from "@types"
import { useEffect, useState } from "preact/hooks"
import {
  getPricingBeforeLead,
  getWizardStepsAPI,
  saveWizardState,
  setLeadWorkflowClassNames,
  setWizardDetails,
  updateCurrentStep
} from "@actions"
import {
  evaluateConditions,
  setStorage,
  gtagWorkflowEnd,
  gtagWorkflowProgress,
  gtagWorkflowStart,
  initGTAG
} from "@helpers"

export function useLeadWorkflow() {
  const dispatch = useDispatch()

  const { classNames, config, app_key, values, currentStep, stepsLoaded } =
    useSelector(({ app, wizard }: RootStates) => {
      return {
        config: app,
        stepsLoaded: wizard?.stepsLoaded,
        currentStep: wizard?.currentStep ?? 0,
        values: wizard?.values,
        app_key: app.app_key,
        classNames: wizard?.classNames
      }
    })

  const storageKey = `lw-${app_key}`

  useEffect(() => {
    if (stepsLoaded) {
      return
    }
    dispatch(
      saveWizardState({
        app_key,
        bbToken: config.bbToken,
        storageKey
      })
    )

    // Init google analytics
    initGTAG()

    // determine if wizard is being started from the API
    if (config?.type === "api" && values?.steps && currentStep === 0) {
      gtagWorkflowStart()
    }

    // Get wizard steps
    dispatch(getWizardStepsAPI({ app_key, bbToken: config.bbToken }))
  }, [])

  useEffect(() => {
    if (stepsLoaded) {
      setStorage({ currentStep, values }, storageKey)
    }
  }, [currentStep, values, stepsLoaded])

  return {
    classNames
  }
}

export const useEvaluateCondition = () => {
  const dispatch = useDispatch()

  const [progress, setProgress] = useState(0)

  const { values, steps, currentStep } = useSelector(
    ({ wizard }: RootStates) => {
      return {
        currentStep: wizard?.currentStep ?? 0,
        values: wizard?.values,
        steps: wizard?.steps ?? []
      }
    }
  )

  const evaluateCondition = (isBack: boolean, valArrg?: LeadWorkflowSteps) => {
    const values_temp = valArrg ? valArrg : values?.steps ?? {}

    let add = 0
    let next = 1
    let flag = true
    if (
      currentStep === 0 &&
      !values_temp[steps[1].id as keyof LeadWorkflowSteps]
    ) {
      gtagWorkflowStart()
    }

    if (
      progress < 25 &&
      currentStep >= Math.ceil(steps.length / 4) - 1 &&
      currentStep < Math.ceil(steps.length / 2) - 1 &&
      !isBack
    ) {
      gtagWorkflowProgress(25)
      setProgress(25)
    } else if (
      progress < 50 &&
      currentStep >= Math.ceil(steps.length / 2) - 1 &&
      currentStep < Math.ceil(steps.length / 1.34) - 1 &&
      !isBack
    ) {
      gtagWorkflowProgress(50)
      setProgress(50)
    } else if (
      progress < 75 &&
      currentStep >= Math.ceil(steps.length / 1.34) - 1 &&
      !isBack
    ) {
      gtagWorkflowProgress(75)
      setProgress(75)
    }

    let nextStep

    while (flag) {
      add = isBack ? add - 1 : add + 1
      nextStep = steps[currentStep + add]
      if (nextStep?.condition) {
        if (evaluateConditions(nextStep.condition, values_temp)) {
          flag = false
        } else {
          next++
        }
      } else {
        flag = false
      }
    }

    // we are checking if the next step is the lead capture form
    // the assumption is that it has an id: login_form
    if (nextStep && nextStep.id == "login_form" && !isBack) {
      // call pricing
      dispatch(getPricingBeforeLead())
      gtagWorkflowEnd()
    }

    return isBack ? next * -1 : next
  }

  return evaluateCondition
}

/**
 * Update form UI (slides)
 */
export const useUpdateFormUI = () => {
  const dispatch = useDispatch()
  const { steps, currentStep, exit_cta_step } = useSelector(
    ({ wizard }: RootStates) => {
      return {
        currentStep: wizard?.currentStep ?? 0,
        steps: wizard?.steps ?? [],
        exit_cta_step: wizard?.exit_cta_step || -1
      }
    }
  )

  const updateFormUI = (next: number) => {
    const className = next > 0 ? "animate-out" : "animate-out--reverse"

    const newStep = currentStep + next
    const isEndStep = steps[newStep].id === "end"
    isEndStep && dispatch(updateCurrentStep(newStep))

    // Slide-out old slide
    dispatch(
      setLeadWorkflowClassNames({
        animateClass: isEndStep ? "" : className,
        isInClass: isEndStep ? "" : "wizard--animated-out"
      })
    )
    // Slide-in new slide after the old has gone
    setTimeout(() => {
      if (newStep < 0) {
        return
      }
      !isEndStep && dispatch(updateCurrentStep(newStep))

      dispatch(
        setLeadWorkflowClassNames({
          animateClass: isEndStep ? "" : "animate-in",
          isInClass: isEndStep ? "" : "wizard--animated-in"
        })
      )

      const isLastStep = ["code", "login_form", "end"].includes(
        steps[newStep].id
      )

      dispatch(
        setWizardDetails({
          isLastStep,
          exitCTAStepPassed: exit_cta_step > -1 && exit_cta_step <= newStep
        })
      )
    }, 500)
  }

  return updateFormUI
}
