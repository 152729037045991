import { h, JSX } from "preact"
import { PricingItem as PricingItemType } from "@types"
import { If } from "@ui"
import { useLenderFees } from "@hooks"
import {
  ApplyBtn,
  PricingItemBodyWizard,
  PricingItemHeaderCompact
} from "../PricingItem/partials"
import "@styles/components/_pricing-item.scss"

type Props = {
  item: PricingItemType
  index: number
  onClick?: () => void
  showBadge?: boolean
  isRow: boolean
  hideFeesModal?: boolean
  type?: "wizard"
  className?: string
  role?: JSX.HTMLAttributes["role"]
}

const LeadWorkflowPricingItem = ({
  item,
  index,
  onClick,
  showBadge,
  isRow,
  hideFeesModal,
  type = "wizard",
  className = "",
  role
}: Props) => {
  const handleClick = (e: Event) => {
    // If the user clicks the info button to summon the tooltip, this
    // function should be a no-op.
    if ((e.target as HTMLElement).className === "pricing-item__tooltip") {
      return
    }
    onClick?.()
  }

  const lenderFees = useLenderFees(item)

  return (
    <div
      className={`pricing-item is-${type} ${
        isRow ? "is-row" : ""
      } ${className}`}
      onClick={(e: any) => handleClick(e)}
      {...(onClick ? { tabIndex: 0 } : {})}
      {...(role ? { role: role } : {})}
    >
      {showBadge && index === 0 && <span class="pricing-item__badge"></span>}

      <PricingItemHeaderCompact rate={item.rate} apr={item.apr} />

      <PricingItemBodyWizard
        hideFeesModal={hideFeesModal}
        item={item}
        quote_id={item.quote_id}
        isRow={isRow}
        fees={lenderFees}
        type={type}
      />

      <If
        condition={!isRow}
        then={
          <div className="pricing-item__apply-block d-flex">
            <ApplyBtn type={type} className="pricing-item__apply-btn ml-auto" />
          </div>
        }
      />
    </div>
  )
}

export default LeadWorkflowPricingItem
