import { h } from "preact"
import { Marks as MarksType } from "@types"

interface MarksProps {
  marks: MarksType
  min: number
  max: number
  onChange: (value: number) => void
  onChangeComplete: (value: number) => void
}

const Marks = ({ marks, min, max, onChange, onChangeComplete }: MarksProps) => {
  const getPosition = (item: number) => {
    const total = max - min
    const pos = min !== 0 ? item - min : item
    return (pos * 100) / total
  }

  const renderItem = (item: number) => {
    const pos = getPosition(item)
    return (
      <div
        onClick={() => {
          onChange(item)
          onChangeComplete(item)
        }}
        className="ir__mark"
        style={{ left: `${pos}%` }}
      >
        <span className="ir__marks-label" style={pos < 10 ? { left: 0 } : {}}>
          {marks[item]}
        </span>
      </div>
    )
  }

  return (
    <div className="ir__marks">
      {Object.keys(marks).map(item => renderItem(Number(item)))}
    </div>
  )
}

export default Marks
