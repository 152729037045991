import { Fragment, h } from "preact"
import { List } from "@ui"
import svg from "@assets/icons/check-circle-outline.svg"

interface Props {
  items: string[]
  isMobile?: boolean
}

const BodyLoan = ({ items, isMobile }: Props) => (
  <Fragment>
    <p>
      It's easy to get prepared for your application. Depending on your unique
      financial situation, there are several documents you might need when
      applying for a home loan. Here's a list of the most common documents
      needed.
    </p>
    <List
      iconSize={20}
      originalIconSize={20}
      items={items}
      svg={svg}
      iconClassName="tw-mr-2 txt-success"
      className={`${isMobile ? "tw-pt-4" : "tw-pt-6"}`}
    />
  </Fragment>
)

export default BodyLoan
