import { h } from "preact"
import DynamicForm from "./DynamicForm"
import { DynamicFormProvider } from "./DynamicFormProvider"
import { Config } from "@types"

interface Props {
  config: Config
}

const InlineContactForm = ({ config }: Props) => (
  <DynamicFormProvider>
    <DynamicForm isInline lo={config.lo} config={config} />
  </DynamicFormProvider>
)

export default InlineContactForm
