import { h, ComponentChildren } from "preact"
import clsx from "clsx"
import BoxHeader from "./BoxHeader"
import { Loader } from "../loaders"
import "@styles/components/_box.scss"

type Props = {
  children?: ComponentChildren
  className?: string
  smallGutter?: boolean
  hasGutter?: boolean
  topBorder?: boolean
  title?: string
  subTitle?: string
  noClassName?: boolean
  isLoading?: boolean
  hasShadow?: boolean
  onClick?: () => void
}

const Box = ({
  children,
  className = "",
  smallGutter,
  hasGutter,
  topBorder,
  title,
  subTitle,
  noClassName,
  isLoading,
  hasShadow = false,
  onClick = () => {}
}: Props) => {
  const classNames = clsx(
    noClassName ? "" : "box",
    hasShadow && "has-shadow",
    hasGutter && "has-gutter",
    topBorder && !noClassName && "box--color-top-border",
    smallGutter && !noClassName && "box--small-gutter",
    className
  )

  return (
    <div className={classNames} onClick={onClick}>
      {isLoading && <Loader className="box__loader" />}
      {title && <BoxHeader title={title} subTitle={subTitle} />}
      {children}
    </div>
  )
}

export default Box
