import { h } from "preact"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { bbProcessEnv } from "@helpers"
import "@styles/components/_h-captcha.scss"

interface HCaptchaProps {
  onVerify: (token: string) => void
  onExpire?: () => void
  onError?: (error: string) => void
  size?: "normal" | "compact" | "invisible"
  theme?: "light" | "dark"
  tabIndex?: number
  languageCode?: string
  id?: string
  style?: string
}

const PreactHCaptcha = (props: HCaptchaProps) => (
  <HCaptcha
    id="h-captcha"
    sitekey={bbProcessEnv.CAPTCHA_SITE_KEY || ""}
    {...props}
  />
)

export default PreactHCaptcha
