import { ComponentChildren, h } from "preact"
import { useSelector } from "react-redux"
import { Button } from "@ui"
import { selectGlobalModalIframeID } from "@selectors"
import { openLeadWorkflowFromWithin } from "@iframeCommunication/utils"
import { UISize } from "@types"
import { useCallback } from "preact/hooks"

interface Props {
  size?: UISize
  className?: string
  label?: ComponentChildren
  adapt?: boolean
}

const GetMyQuoteBtn = ({
  size,
  className = "",
  label = "Get my quote",
  adapt = true
}: Props) => {
  const iframeID = useSelector(selectGlobalModalIframeID)
  const handleOnClick = useCallback(() => {
    openLeadWorkflowFromWithin(iframeID)
  }, [iframeID])

  return (
    <Button
      className={`tw-text-nowrap ${className}`}
      noShadow
      adapt={adapt}
      onClick={handleOnClick}
      size={size}
    >
      {label}
    </Button>
  )
}

export default GetMyQuoteBtn
