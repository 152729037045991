import { h } from "preact"
import { ScreenReaderOnly } from "@ui"

interface Props {
  phone: string
  type: string
}

const PhoneNumber = ({ phone, type }: Props) => (
  <div className="tw-flex">
    <dt className="font-normal tw-pr-1">
      <ScreenReaderOnly>{type}:</ScreenReaderOnly>
      <span aria-hidden="true">{type[0]}:</span>
    </dt>
    <dd>
      <a
        href={"tel:" + phone.replace(/\D/g, "")}
        target="_top"
        className="txt-dark"
      >
        {phone}
      </a>
    </dd>
  </div>
)

export default PhoneNumber
