import { h } from "preact"
import { numberToUSD } from "@helpers"
import Tooltip from "../../Tooltip"
import { If } from "@ui"

interface Props {
  className?: string
  onClick: (e: Event) => void
  value: number
  hasTooltip?: boolean
}

const PrincipalAndInterest = ({
  className = "",
  value,
  hasTooltip = true,
  onClick
}: Props) => {
  return (
    <div
      className={`pricing-item__details is-tp ${className}`}
      role="button"
      onClick={onClick}
    >
      <strong>
        {numberToUSD(value, true)}
        <span className="pricing-item__details-posfix">/mo</span>
      </strong>
      <small className="pricing-item__label accent-color">
        <If
          condition={!!hasTooltip}
          then={
            <Tooltip
              ctaClassName="pricing-item__tooltip"
              onClick={onClick}
              ttPlacement="top"
              icon="$"
              iconWeight="400"
              eventTrigger="hover"
              small
              reference={
                <span className="pricing-item__details-tooltip">P & I</span>
              }
            >
              View Payment Details
            </Tooltip>
          }
          else={<div className="pricing-item__label-notooltip">P & I</div>}
        />
      </small>
    </div>
  )
}

export default PrincipalAndInterest
