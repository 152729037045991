import { h } from "preact"
import RefinanceForm from "./Forms/RefinanceForm"
import PurchaseForm from "./Forms/PurchaseForm"
import MobileFormWrapper from "./Forms/mobile/FormWrapper"
import { LoanDetailsFormFields, LocationObj, OnChangeValue } from "@types"
import { If, Form } from "@ui"
import SubmitBtn from "./Forms/SubmitBtn"
import { useIsMobile } from "@contexts"

interface Props {
  hasARMTerms?: boolean
  isVertical?: boolean
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
  handleSubmit?: () => void
  formData: LoanDetailsFormFields
  numericInputChange: (value: string, e: Event) => void
  submitLabel: string
  showFullForm?: boolean
  disabledFields?: Array<keyof LoanDetailsFormFields>
}

const LoanDetailsFields = ({
  hasARMTerms = true,
  isVertical = false,
  handleOnChange,
  handleSubmit,
  formData,
  numericInputChange,
  submitLabel,
  showFullForm,
  disabledFields = []
}: Props) => {
  const isMobile = useIsMobile()

  return (
    <div
      className={`loan-details-form__fields ${isVertical ? "vertical" : ""}`}
    >
      <If
        condition={!disabledFields.includes("zipcode")}
        then={
          <Form.AutoZipcode
            onChange={(value: LocationObj) => {
              handleOnChange(value, "location")
            }}
            showErrorWhenEmpty
            initialValue={
              (formData.location as LocationObj)?.zipcode || formData.zipcode
            }
          />
        }
      />

      <If
        condition={formData.loan_purpose === "purchase"}
        then={
          <PurchaseForm
            formData={formData}
            numericInputChange={numericInputChange}
            handleOnChange={handleOnChange}
            showFullForm={showFullForm}
            disabledFields={disabledFields}
            hasARMTerms={hasARMTerms}
          />
        }
        else={
          <RefinanceForm
            formData={formData}
            numericInputChange={numericInputChange}
            handleOnChange={handleOnChange}
            showFullForm={showFullForm}
            disabledFields={disabledFields}
            hasARMTerms={hasARMTerms}
          />
        }
      />

      <If
        condition={isMobile && !showFullForm}
        then={<MobileFormWrapper hasARMTerms={hasARMTerms} />}
        else={
          <SubmitBtn
            label={submitLabel}
            onClick={handleSubmit}
            alwaysEnabled={showFullForm}
          />
        }
      />
    </div>
  )
}

export default LoanDetailsFields
