import { gaProxy } from "@helpers"
import { sendEvent, emitEvent } from "@message-bridge"
import { eventsAPI } from "../api"
import { capitalizeTheFirstLetterOfEachWord } from "./string"

//  params = {
//      actions: [
//          "sendExternal",
//          "sendInternal",
//          "emit"
//      ]
//      category: "rate_table",
//      name: "usage_limit_met",
//  }
const eventDispatch = async params => {
  // by default attempt all actions
  if (!params.actions || params.actions.length <= 0) {
    params.actions = ["sendExternal", "sendInternal", "emit"]
  }
  if (!params.name) {
    return null
  }
  if (!params.category) {
    return null
  }
  let friendlyCategory = capitalizeTheFirstLetterOfEachWord(params.category)
  let label = capitalizeTheFirstLetterOfEachWord(params.name)
  if (params.actions.includes("sendExternal")) {
    // sends event to parent GA/GTM
    if (
      typeof clientTracker != "undefined" &&
      Object.keys(clientTracker).length &&
      !clientTracker.internal
    )
      sendEvent({
        category: friendlyCategory,
        label: label,
        name: params.name,
        send_to: clientTracker.id
      })

    // sends event directly to GA (deprecated)
    if (
      typeof clientTracker != "undefined" &&
      Object.keys(clientTracker).length &&
      clientTracker.internal
    )
      gaProxy.sendEvent({
        trackerId: clientTracker.id,
        category: friendlyCategory,
        label: label,
        name: params.name
      })
  }
  if (params.actions.includes("sendInternal")) {
    // sends event to internal event tracking database
    eventsAPI.send({
      category: params.category,
      name: params.name
    })
    // sends event directly to internal GA
    if (typeof trackerID != "undefined" && trackerID)
      gaProxy.sendEvent({
        trackerId: trackerID,
        category: friendlyCategory,
        label: label,
        name: params.name
      })

    if (typeof gtag === "function")
      gtag("event", params.name, {
        category: friendlyCategory
      })
  }
  if (params.actions.includes("emit")) {
    // emits event in parent for use with eventListener
    emitEvent({
      category: params.category,
      name: params.name
    })
  }
}

export default eventDispatch
