import axios from "axios"
import { APICallWithCache, bbProcessEnv } from "@helpers"
import { FeeList, FeeErrorResponse } from "@types"
import { ONE_HOUR_IN_MILLISECONDS } from "@constants"

export default {
  get: async ({ quote_id }: { quote_id: string }): Promise<FeeList> => {
    // return [
    //   {
    //     label: "Loan Origination Fee",
    //     amount: 1500,
    //     type: "Lender"
    //   },
    //   {
    //     label: "Deed Fee (County)",
    //     amount: 27,
    //     type: "State"
    //   },
    //   {
    //     label: "Mortgage Fee (County)",
    //     amount: 257,
    //     type: "State"
    //   },
    //   {
    //     label: "Mortgage Intangible Tax (State)",
    //     amount: 800,
    //     type: "State"
    //   },
    //   {
    //     label: "Mortgage Tax (State)",
    //     amount: 1400,
    //     type: "State"
    //   },
    //   {
    //     label: "Deed Tax (State)",
    //     amount: 3000,
    //     type: "State"
    //   },
    //   {
    //     label: "Title Search Fee",
    //     amount: 345,
    //     type: "ThirdParty"
    //   },
    //   {
    //     label: "Appraisal",
    //     amount: 650,
    //     type: "ThirdParty"
    //   },
    //   {
    //     label: "Endorsement",
    //     amount: 675,
    //     type: "ThirdParty"
    //   },
    //   {
    //     label: "Settlement Fee",
    //     amount: 850,
    //     type: "ThirdParty"
    //   },
    //   {
    //     label: "Lender Title Policy",
    //     amount: 2075,
    //     type: "ThirdParty"
    //   }
    // ]

    let id = ""
    if (Array.isArray(quote_id)) {
      id = quote_id.join(",")
    } else {
      id = quote_id
    }

    try {
      const response: FeeList | FeeErrorResponse = await APICallWithCache({
        url: `${bbProcessEnv.API_URL}/api/v1/fees`,
        cacheEnabled: bbProcessEnv.REVIEWS_CACHE_ENABLE,
        params: {
          quote_id: id
        },
        expiresIn: ONE_HOUR_IN_MILLISECONDS / 3
      })

      return "status" in response ? [] : response
    } catch (error) {
      console.log(error)
      return []
    }
  }
}
