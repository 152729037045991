import { h, Component } from "preact"
import { Form, Loader, Icon } from "@ui"
import { closeModal } from "@message-bridge"
import { getConfig } from "../../../config"
import { leadAPI } from "@api"
import { setStorage } from "@helpers"
import successIcon from "@assets/icons/success-icon.svg"

class LeadCaptureModal extends Component {
  state = {
    isLoading: false,
    isSent: false,
    form: {
      email: "",
      credit_score: 650
    }
  }

  handleInputChange = e => {
    const name = e.target.name
    console.log(name, e.target.value)
    const formValues = Object.assign({}, this.state.form, {
      [name]: e.target.value
    })
    console.log(formValues)

    this.setState({ form: formValues })
  }

  handleSliderChange = value => {
    const formValues = Object.assign({}, this.state.form, {
      ["credit_score"]: value
    })
    this.setState({ form: formValues })
  }

  handleSubmit = e => {
    e.preventDefault()

    const config = getConfig()
    const formData = {
      email: this.state.form.email,
      credit_score: this.state.form.credit_score,
      owner: config.xloid,
      app_key: this.props.app_key,
      source: config.source
    }

    this.setState({ isLoading: true })

    leadAPI
      .leadManage({
        formData,
        srcComponent: "lead_capture_form"
      })
      .then(data => {
        console.log("leadEmail", formData.email)

        setStorage({ email: formData.email }, "leadEmail")

        this.setState({ isLoading: false, isSent: true })
        if (this.props.autohide) {
          this.props.hideLeadForm()
        } else {
          closeModal()
        }
      })
      .catch(err => {
        console.error(err)
        this.setState({ isLoading: false, isSent: false })
      })
  }

  render({ autohide }, { form, isLoading, isSent }) {
    return (
      <div className="contact-form tw-min-w-80 tw-p-0 txt-dark">
        {isLoading && <Loader className="contact-form__loader" />}
        {!isLoading && !isSent && (
          <div className="tw-w-full">
            <h3 className="tw-m-0 text-xl">Today's Rates</h3>

            <form
              className="form contact-form__form tw-max-w-full tw-mt-4"
              onSubmit={this.handleSubmit}
            >
              <Form.Row>
                <Form.Col>
                  <Form.Input
                    isMaterial
                    name="email"
                    value={form.email}
                    onInput={this.handleInputChange}
                    label="Email Address"
                    required
                  ></Form.Input>
                </Form.Col>
              </Form.Row>

              <Form.Row twoCol className="tw-items-center">
                <Form.Input
                  isMaterial
                  name="credit_score"
                  value={form.credit_score}
                  onInput={this.handleInputChange}
                  label="Credit Score"
                  required
                />

                <Form.InputRange
                  min={300}
                  max={850}
                  value={form.credit_score}
                  onChange={this.handleSliderChange}
                />
              </Form.Row>
              <span class="desc">Enter your approximate credit score</span>

              <Form.Row className="contact-form__form-footer is-last">
                <button className="button button--no-shadow">Get rates</button>
              </Form.Row>
            </form>
          </div>
        )}

        {!isLoading && isSent && !autohide && (
          <div className="contact-form__success">
            <Icon src={successIcon} size={90} originalSize={507.2} />
            <h3>Success!</h3>
            <p>
              I will be in touch with you soon to address your specific needs.
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default LeadCaptureModal
