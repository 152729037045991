import { h } from "preact"
import AccentColor from "../../../AccentColor"
import DotAnimation from "../../../DotAnimation"
import FadeInOut from "../../../FadeInOut"

interface Props {
  isValidating: boolean
}

const ValidatingAnimation = ({ isValidating }: Props) => (
  <FadeInOut
    className="form__field__loader-container"
    show={isValidating}
    defaultComponent=" "
    alternateComponent={
      <div className="d-inline-flex align-items-center justify-content-center">
        <AccentColor>Validating</AccentColor>
        <DotAnimation className="accent-color" />
      </div>
    }
  />
)

export default ValidatingAnimation
