import { h, ComponentChildren } from "preact"
import { useEffect, useRef } from "preact/hooks"

interface Props {
  addEvent: boolean
  className: string
  styles?: string
  hideTooltipClick: (target: HTMLElement) => void
  children: ComponentChildren
}

const TooltipInner: preact.FunctionalComponent<Props> = ({
  addEvent,
  className,
  styles = "",
  children,
  hideTooltipClick
}) => {
  const eleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleMousedown = (e: MouseEvent) => {
      if (eleRef.current?.contains(e.target as Node)) {
        // The click is inside the tooltip, continue what you are doing
        return
      }
      // The click is outside the tooltip node, hide it
      if (e.target instanceof HTMLElement) {
        hideTooltipClick(e.target)
      }
    }

    if (addEvent) {
      document.addEventListener("mousedown", handleMousedown, false)
      return () => {
        document.removeEventListener("mousedown", handleMousedown, false)
      }
    }
  }, [addEvent, hideTooltipClick])

  return (
    <div ref={eleRef}>
      <div style={styles} className={className}>
        {children}
      </div>
    </div>
  )
}

export default TooltipInner
