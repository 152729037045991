export default {
  title: "Today's Rates",
  subscriptionTitle: "Subscribe to rate tracking",
  CTALabel: "Get My Custom Offer",
  formToggleLabel: "Modify Preferences",
  subscriptionModal: {
    title: "You selected a great rate.",
    subTitle: "Now let us help you get the mortgage ball rolling.",
    btnLabel: "Proceed to dashboard",
    successTitle: "All set! You'll get your first rate email soon!",
    successTitleAlt:
      "All set! The first available mortgage professional will contact you soon!",
    successTxt:
      "We'll send you emails when your rate changes, so it's easier to keep an eye on the market",
    successTxtAlt: "Feel free to begin the application process below.",
    successBtnLabel: "Apply now!",
    changesTxt:
      "Looks like a few things have changed since last time. Should we update your info?",
    noChangesTxt:
      "Looks like nothing changed since last time. Do you want to update your info?",
    noChangeBtnLabel: "Update",
    changeBtnLabel: "Confirm & Update",
    keepBtnLabel: "Keep Previous Details",
    sidebar: {
      title: "Your loan officer will customize an offer tailored just for you!",
      list: [
        "Get personalized rates tailored to your unique circumstance.",
        "Work with the most qualified experts to help you on your home financing journey.",
        "Streamline your home loan and close in record time."
      ]
    }
  },
  headers: [
    {
      title: "Loan type"
    },
    {
      title: "Rate / APR",
      tooltip:
        "The rate is the amount you'll pay each year to borrow the money, expressed as a percentage.  APR takes the interest rate of the loan and adds it to certain fees paid by the borrower at closing, including the upfront costs displayed on each offer.  APR shows which loan is less expensive over the full term of the loan. <br><br>The APR shown here does not include private mortgage insurance (if applicable) and other fees charged by the lender and may be different than the APR disclosed by the lender on the loan estimate and closing disclosure."
    },
    {
      title: "Payment",
      tooltip:
        "The monthly payment shown includes both principal and interest payments.  It does not include private mortgage insurance (PMI), which the lender may require if your down payment is less than 20% percent of your purchase price."
    },
    {
      title: "Fees",
      tooltip:
        "The estimated fees used to calculate the APR include, but are not limited to, the origination fee, underwriting fee, and courier fee."
    }
  ],
  noResults: {
    title: "No rates available for your scenario",
    msg: "Try changing your loan scenario to view different rate results"
  }
}
