import { Fragment, h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import { Manager, Reference, Popper } from "react-popper"
import { createPortal } from "preact/compat"
import TooltipInner from "../Tooltip/TooltipInner"
import { If } from "@ui"
import { TooltipFullDesktopProps } from "@types"
import clsx from "clsx"

const Tooltip = ({
  theme = "default",
  ttPlacement = "bottom",
  ttClassName = "",
  ttContentClassName = "",
  className = "",
  ctaClassName = "",
  iconSize = 14,
  iconBG = "#B4C4D3",
  iconColor = "#fff",
  icon = "i",
  iconWeight = "600",
  children,
  onClick,
  title,
  subtitle,
  content = "",
  hideArrow = false,
  eventTrigger = "click",
  onShow,
  onHide
}: TooltipFullDesktopProps) => {
  const [show, setShow] = useState(false)
  const [ttClassNameLocal, setTTClassname] = useState(ttClassName)
  const popperRef = useRef<any>(null)
  const hideTimeoutRef = useRef<number | null>(null)

  useEffect(() => {
    let ttC = `${ttClassName} tooltip`

    if (show) {
      ttC += " tooltip--show"
    }

    setTTClassname(ttC)
  }, [ttClassName, show])

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (hideTimeoutRef.current) {
        window.clearTimeout(hideTimeoutRef.current)
      }
    }
  }, [])

  // Update tooltip position when content changes
  useEffect(() => {
    if (show) {
      popperRef.current?.()
    }
  }, [content, show])

  useEffect(() => {
    if (show) {
      onShow?.()
    } else {
      onHide?.()
    }
  }, [show])

  const ele = useRef<HTMLDivElement>(null)

  const hideTooltipClick = (target: HTMLElement) => {
    if (eventTrigger === "click" && !ele.current?.contains(target)) {
      setShow(false)
    }
  }

  const onClickRef = (e: Event) => {
    if (eventTrigger === "click") {
      setShow(!show)
    }
    onClick?.(e)
  }

  const onMouseEnter = () => {
    if (eventTrigger === "hover") {
      setShow(true)
    }
  }

  const onMouseLeave = (e: Event) => {
    if (eventTrigger === "hover") {
      // Check if we're moving to the tooltip content
      const relatedTarget = (e as MouseEvent).relatedTarget as HTMLElement
      const tooltipContent = document.querySelector(".tooltip-inner")
      if (tooltipContent?.contains(relatedTarget)) {
        return
      }

      // Clear any existing timeout
      if (hideTimeoutRef.current) {
        window.clearTimeout(hideTimeoutRef.current)
      }

      // Set a new timeout to hide the tooltip
      hideTimeoutRef.current = window.setTimeout(() => {
        setShow(false)
      }, 200) // 200ms delay
    }
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Enter") {
      setShow(!show)
    }
    onClick?.(e)
  }

  const wrapperClassName = clsx("tooltip-full__wrapper", className, {
    "click-trigger": eventTrigger === "click"
  })

  return (
    <Manager>
      <div ref={ele} className={wrapperClassName}>
        <Reference>
          {({ ref }) => (
            <span
              tabIndex={0}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={onClickRef}
              onKeyDown={handleKeyDown}
              ref={ref}
              className={`tooltip-trigger ${ctaClassName}`}
              role="button"
              aria-haspopup="true"
              aria-expanded={show}
              aria-describedby="tooltip-content"
            >
              {children || (
                <i
                  style={{
                    color: iconColor,
                    background: iconBG,
                    fontWeight: iconWeight,
                    height: `${iconSize}px`,
                    width: `${iconSize}px`,
                    fontSize: `${iconSize - 4}px`
                  }}
                  className={`${icon === "i" ? "is-info" : "not-info"}${
                    icon === "$" ? " is-dollar" : ""
                  }`}
                >
                  {icon}
                </i>
              )}
            </span>
          )}
        </Reference>

        {show &&
          createPortal(
            <Popper
              placement={ttPlacement}
              modifiers={{
                preventOverflow: {
                  enabled: true,
                  boundariesElement: "viewport"
                },
                flip: {
                  enabled: false // Disable flip to prevent the Popper from changing placement
                }
              }}
            >
              {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
                // Store the entire popper props object
                popperRef.current = scheduleUpdate
                return (
                  <div
                    ref={ref}
                    style={style}
                    data-placement={placement}
                    className={`${ttClassNameLocal} is-full bs-tooltip-${placement} tw-text-sm`}
                    role="tooltip"
                    id="tooltip-content"
                  >
                    <TooltipInner
                      addEvent={eventTrigger === "click"}
                      hideTooltipClick={hideTooltipClick}
                      className={`tooltip-inner is-${theme}`}
                      onMouseEnter={() => {
                        if (eventTrigger === "hover") {
                          // Clear any existing timeout when entering the tooltip
                          if (hideTimeoutRef.current) {
                            window.clearTimeout(hideTimeoutRef.current)
                            hideTimeoutRef.current = null
                          }
                        }
                      }}
                      onMouseLeave={(e: MouseEvent) => {
                        if (eventTrigger === "hover") {
                          // Check if we're moving back to the trigger
                          const relatedTarget = e.relatedTarget as HTMLElement
                          const trigger =
                            ele.current?.querySelector(".tooltip-trigger")
                          if (trigger?.contains(relatedTarget)) {
                            return
                          }

                          // Clear any existing timeout
                          if (hideTimeoutRef.current) {
                            window.clearTimeout(hideTimeoutRef.current)
                          }

                          // Set a new timeout to hide the tooltip
                          hideTimeoutRef.current = window.setTimeout(() => {
                            setShow(false)
                          }, 200) // 200ms delay
                        }
                      }}
                    >
                      <If
                        condition={!!title || !!subtitle}
                        then={
                          <Fragment>
                            <div className="tooltip-full__header">
                              <If
                                condition={!!title}
                                then={
                                  <strong className="tw-text-neutral-400 tw-uppercase tw-font-semibold">
                                    {title}
                                  </strong>
                                }
                              />
                              <If
                                condition={!!subtitle}
                                then={
                                  <strong className="tooltip-full__subtitle">
                                    {subtitle}
                                  </strong>
                                }
                              />
                            </div>
                          </Fragment>
                        }
                      />
                      <div
                        className={`tooltip-full__content tw-mt-2 ${ttContentClassName}`}
                      >
                        {content}
                      </div>
                    </TooltipInner>
                    <If
                      condition={!hideArrow}
                      then={
                        <div
                          ref={arrowProps.ref}
                          style={arrowProps.style}
                          className="arrow"
                        />
                      }
                    />
                  </div>
                )
              }}
            </Popper>,
            document.body
          )}
      </div>
    </Manager>
  )
}

export default Tooltip
