import { h } from "preact"
import { toFixed } from "@helpers"
import { PricingItem } from "@types"

interface Props {
  item: PricingItem
  className?: string
}

const IntAprAndPoints = ({ item, className = "" }: Props) => (
  <div
    className={`tw-flex tw-justify-between tw-items-center tw-gap-6 md:tw-gap-4 lg:tw-gap-8 tw-text-center tw-text-gray-600 tw-pb-4 md:tw-pb-0 ${className}`}
  >
    <div>
      <span className="tw-block tw-font-normal tw-text-neutral-500">
        Interest
      </span>
      <strong className="tw-block tw-text-xl tw-font-bold">
        {toFixed(item.rate, 2, true)}%
      </strong>
    </div>
    <div className="tw-h-9 tw-w-px tw-bg-zinc-200"></div>
    <div>
      <span className="tw-block tw-font-normal tw-text-neutral-500">APR</span>
      <strong className="tw-block tw-text-xl tw-font-bold">
        {toFixed(item.apr, 2, true)}%
      </strong>
    </div>
    <div className="tw-h-9 tw-w-px tw-bg-zinc-200"></div>
    <div>
      <span className="tw-block tw-font-normal tw-text-neutral-500">
        Points
      </span>
      <strong className="tw-block tw-text-xl tw-font-bold">
        {toFixed(item.pts, 2, true)}
      </strong>
    </div>
  </div>
)

export default IntAprAndPoints
