import { h } from "preact"
import { useMediaFlags } from "@contexts"
import Rating from "../Rating"
import Icon from "../Icon"
import defaultThumb from "@assets/avatar.svg"
import "@styles/components/_review-author.scss"
import { ReviewsViewMode } from "@types"
type Props = {
  rating: number
  ratingColor?: string
  brand?: string
  author: { img?: string | null; name: string }
  time: { datetime: number; relative_time: string }
  size?: "md" | "sm"
  reviewsViewMode?: ReviewsViewMode
}

const ReviewAuthor = ({
  rating,
  ratingColor,
  brand,
  author: { img, name },
  time: { datetime, relative_time },
  size = "md",
  reviewsViewMode
}: Props) => {
  const isMobile = useMediaFlags()
  return (
    <div
      className={`review-author is-${size} ${
        reviewsViewMode === "list" ? "review-author__list" : ""
      }`}
    >
      <div className={reviewsViewMode === "list" ? "d-block d-sm-none" : ""}>
        {img ? (
          <img
            className="review-author__thumb"
            src={img}
            alt={`${name} thumb`}
          />
        ) : (
          <Icon
            className="review-author__thumb"
            src={defaultThumb}
            size={36}
            originalSize={36}
          />
        )}
      </div>
      <div className="review-author__review">
        <strong className="review-author__name">{name}</strong>
        <div className="review-author__meta d-flex align-items-center">
          {rating && (
            <Rating
              color={ratingColor}
              rating={rating}
              size={isMobile ? 12 : 14}
            />
          )}

          {brand && (
            <span className="review-author__brand mr-1">
              {brand} Customer .
            </span>
          )}
          <time datetime={datetime.toString()}>{relative_time}</time>
        </div>
      </div>
    </div>
  )
}

export default ReviewAuthor
