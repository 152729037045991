import { ComponentChildren, h } from "preact"

interface Props {
  children: ComponentChildren
  label?: string
  compact?: boolean
  rounded?: boolean
  groupClassName?: string
}

const Group = ({
  children,
  label,
  compact,
  rounded,
  groupClassName = ""
}: Props) => (
  <div
    className={`form__field-group-wrapper ${compact ? "is-compact" : ""} ${
      rounded ? "is-rounded" : ""
    }`}
  >
    {label && <label className="form__label">{label}</label>}
    <div className={`form__field-group ${groupClassName}`}>{children}</div>
  </div>
)

export default Group
