import { h, Fragment } from "preact"
import { If } from "@ui"
import { useSelector } from "react-redux"
import { RootStates } from "@types"

const JornayaId = ({ disclaimer }: { disclaimer: string }) => {
  const { jornayaId } = useSelector(({ app }: RootStates) => {
    return {
      jornayaId: app.jornayaId
    }
  })

  return (
    <If
      condition={!!jornayaId}
      then={
        <Fragment>
          <input
            id="leadid_token"
            name="universal_leadid"
            type="hidden"
            value=""
          />
          <div>
            <input type="hidden" id="leadid_tcpa_disclosure" />
            <label htmlFor="leadid_tcpa_disclosure">
              <p className="wizard__disclaimer-text">{disclaimer}</p>
            </label>
          </div>
        </Fragment>
      }
    />
  )
}

export default JornayaId
