import { h, Fragment } from "preact"
import { GENERAL_TXT } from "@content"
import { Button, If, Form } from "@ui"
import { FormData, ResidencyType, PropertyType } from "@types"
import "@styles/components/_steps-answers-edit.scss"
import { LOAN_PURPOSE_OPTIONS } from "@constants"

interface Props {
  toggleView: () => void
  formFields: Partial<FormData & { down_percentage?: number }>
  onChange: (value: any, key: string) => void
  onSave: () => void
  onCancel: () => void
}

const Edit = ({
  toggleView,
  formFields,
  onChange,
  onSave,
  onCancel
}: Props) => {
  const handleCancel = () => {
    onCancel()
    toggleView()
  }

  const handleSave = () => {
    onSave()
    toggleView()
  }

  return (
    <div className="steps-answers-edit tw-pt-4">
      <div className="tw-flex tw-flex-col tw-space-y-4 narrow-inputs dark">
        {Object.keys(formFields).map(item => {
          return (
            <Fragment key={item}>
              <If
                condition={item === "credit_score"}
                then={
                  <Form.CreditScoreFull
                    isMaterial={false}
                    name={item}
                    value={formFields[item as keyof FormData] as number}
                    onChangeComplete={(value, key) => {
                      onChange(value, key)
                    }}
                    notStacked
                  />
                }
              />

              <If
                condition={item === "loan_purpose"}
                then={
                  <Form.LoanPorpouse
                    optionsClassName="dark"
                    isMaterial={false}
                    name={item}
                    value={formFields[item as keyof FormData] as string}
                    handleOnChange={(
                      value: (typeof LOAN_PURPOSE_OPTIONS)[number]["value"]
                    ) => {
                      onChange(value, item)
                    }}
                    notStacked
                  />
                }
              />

              <If
                condition={item === "list_price"}
                then={
                  <Form.ListPriceField
                    label="Home value"
                    isMaterial={false}
                    name={item}
                    value={formFields[item as keyof FormData] as number}
                    onChange={value => {
                      onChange(value, item)
                    }}
                    notStacked
                  />
                }
              />

              <If
                condition={item === "household_income"}
                then={
                  <Form.HouseholdIncomeField
                    isMaterial={false}
                    name={item}
                    value={formFields[item as keyof FormData] as number}
                    onChange={value => {
                      onChange(value, item)
                    }}
                    notStacked
                  />
                }
              />

              <If
                condition={item === "loan_balance"}
                then={
                  <Form.LoanAmountField
                    label="Mortgage balance"
                    isMaterial={false}
                    name={item}
                    value={formFields[item as keyof FormData] as number}
                    onChange={value => {
                      onChange(value, item)
                    }}
                    notStacked
                  />
                }
              />
              <If
                condition={item === "location"}
                then={
                  <Form.PlacesAutocompleteInput
                    isMaterial={false}
                    initialValue={formFields[item as keyof FormData] as string}
                    onPlaceSelected={value => {
                      onChange(value, item)
                    }}
                    notStacked
                  />
                }
              />

              <If
                condition={item === "residency_type"}
                then={
                  <Form.ResidencyTypeField
                    label="Residency usage"
                    optionsClassName="dark"
                    isMaterial={false}
                    name={item}
                    value={formFields[item as keyof FormData] as ResidencyType}
                    onChange={value => {
                      onChange(value, item)
                    }}
                    notStacked
                  />
                }
              />

              <If
                condition={item === "cash_out"}
                then={
                  <Form.CashOutField
                    isMaterial={false}
                    name={item}
                    value={formFields[item as keyof FormData] as number}
                    onChange={value => {
                      onChange(value, item)
                    }}
                    notStacked
                  />
                }
              />

              <If
                condition={item === "property_type"}
                then={
                  <Form.PropertyTypeField
                    label="Property type"
                    optionsClassName="dark"
                    isMaterial={false}
                    name={item}
                    handleOnChange={value => {
                      onChange(value, item)
                    }}
                    value={formFields[item as keyof FormData] as PropertyType}
                    notStacked
                  />
                }
              />

              <If
                condition={item === "down_payment"}
                then={
                  <Form.DownPaymentField
                    label="Down payment"
                    notStacked
                    isMaterial={false}
                    name={item}
                    value={formFields[item as keyof FormData] as number}
                    handleInputChange={value => {
                      onChange(value, item)
                    }}
                    handlePercentageChange={value => {
                      onChange(value, "down_percentage")
                    }}
                    maxValue={formFields.list_price ?? 0}
                    value_percentage={formFields.down_percentage ?? 0}
                  />
                }
              />
            </Fragment>
          )
        })}
      </div>
      <div className="tw-flex tw-gap-4 tw-pt-8">
        <Button
          outline
          onClick={handleCancel}
          className="tw-w-1/2 no-bg !tw-text-white hover:tw-bg-white/10 hover:tw-border-white/20"
        >
          {GENERAL_TXT.buttons.cancel}
        </Button>
        <Button
          noShadow
          isWhite
          className="tw-w-1/2 tw-text-[#0E2846] hover:!tw-bg-white/10 hover:!tw-border-white/20"
          onClick={handleSave}
        >
          {GENERAL_TXT.buttons.save}
        </Button>
      </div>
    </div>
  )
}

export default Edit
