import { useDispatch } from "react-redux"
import { getOfferDetails, setCurrentOfferDetailsCard } from "@actions"
import { openModal, openModalFromBtn } from "@helpers"
import { PricingItem } from "@types"

type MainClickActionsProps = {
  e?: Event
  tab?: string
  openModal?: boolean
}

const useRateTableRowActions = (item: PricingItem) => {
  const dispatch = useDispatch()

  const mainClickActions = ({ e, tab, openModal }: MainClickActionsProps) => {
    e?.stopPropagation()
    dispatch(setCurrentOfferDetailsCard(item))

    dispatch(
      getOfferDetails({
        quote_id: item.quote_id,
        callback: () => {
          if (openModal) {
            openOfferDetailsModal(tab)
          }
        }
      })
    )
  }

  const openOfferDetailsModal = (tab = "details") =>
    openModalFromBtn(
      `${location.origin}/external/offer_details_modal${
        tab ? `?tab=${tab}` : ""
      }`
    )

  const handleClickFees = ({ e, openModal = true }: MainClickActionsProps) => {
    mainClickActions({ e, openModal })
  }

  const handleClickPI = (e: Event) => {
    mainClickActions({ e, tab: "payment", openModal: true })
  }

  return {
    openOfferDetailsModal,
    handleClickFees,
    handleClickPI
  }
}

export default useRateTableRowActions
