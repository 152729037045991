import { h } from "preact"
import { useSelector } from "react-redux"
import { Button, Icon } from "@ui"
import { RootStates } from "@types"
import { bbProcessEnv } from "@helpers"
import arrowRight from "@assets/icons/arrow-right.svg"

const StepEndExitConfirm = () => {
  const lead_id = useSelector(({ wizard }: RootStates) => wizard?.lead_id)

  const dashboardLink = `${bbProcessEnv.DASHBOARD_URL}/new-subscription?lead_id=${lead_id}&active=0&source=embed`

  return (
    <div className="exit-cta__comfirmation">
      <div className="wizard-end__header text-center">
        <h1 className="wizard-end__header-title">Ready to go!</h1>
        <h2 className="wizard-end__header-subtitle">
          Check out your personalized rate dashboard designed exclusively for
          your needs.
        </h2>
        <Button
          className="wizard-end__apply-btn mt-4"
          href={dashboardLink}
          target="_blank"
          adapt
        >
          Go to my rate dashboard
          <Icon className="ml-2" originalSize={12} size={12} src={arrowRight} />
        </Button>
      </div>

      <div className="wizard__info-block is-full">
        <div className="wizard__info-block-title">Next steps</div>
        <ol className="wizard__info-block-list">
          <li>
            Visit your{" "}
            <a target="_blank" href={dashboardLink}>
              personalized dashboard
            </a>
          </li>
          <li>
            Check out the first rate subscription email sent to your inbox.
          </li>
        </ol>
      </div>
    </div>
  )
}

export default StepEndExitConfirm
