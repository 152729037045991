import { h } from "preact"
import { ReviewsCTAFooter } from "@composite"
import { Box, If } from "@ui"
import ReviewsWrapper from "../ReviewsWrapper"
import { ReviewType, ReviewsViewMode } from "@types"
import { useAppContext } from "@contexts"
import clsx from "clsx"

interface Props {
  type: ReviewType
  className?: string
  reviewsViewMode?: ReviewsViewMode
  showCta?: boolean
  hideTotalReviews?: boolean
}

const InlineReviews = ({
  type,
  className = "",
  showCta = true,
  hideTotalReviews = false,
  reviewsViewMode = "auto"
}: Props) => {
  const {
    reviews,
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  const reviewsData = reviews[type]

  const wrapperClassName = clsx(
    "inline-reviews",
    className,
    reviewsData.noReviews && !reviewsData.isLoading ? "tw-hidden" : ""
  )

  return (
    <div className={wrapperClassName}>
      <ReviewsWrapper
        type={type}
        reviewsViewMode={reviewsViewMode}
        hideTotalReviews={hideTotalReviews}
      />
      <If
        condition={showCta}
        then={
          <Box
            smallGutter
            className={
              reviewsData.isLoading ||
              (!reviewsData.noReviews && !reviewsData.isLoading)
                ? ""
                : "tw-hidden"
            }
          >
            <ReviewsCTAFooter />
          </Box>
        }
      />
    </div>
  )
}

export default InlineReviews
