import { h } from "preact"
import { Form } from "@ui"

const DynamicFormSelect = ({
  name,
  value,
  onChange,
  label,
  required,
  options
}) => (
  <Form.Row>
    <Form.Col>
      <Form.SelectCustom
        isMaterial
        showPlaceholder
        name={name}
        value={value}
        label={label}
        required={required}
        options={options}
        onChange={value => {
          onChange(value, name)
        }}
      />
    </Form.Col>
  </Form.Row>
)

export default DynamicFormSelect
