import { h } from "preact"
import { useEffect } from "preact/hooks"
import { useDispatch, useSelector } from "react-redux"
import { initRateTablePricing, updateRateForm } from "../../../actions/index"
import {
  RateTable,
  RateTableBanner,
  RateTableForm,
  InlineRateTable,
  LogoFooter,
  If,
  Box,
  RecentBrandReviews,
  RateTableSkeleton
} from "../.."
import { dispatchRateTableLoadedEvent } from "../../../helpers/rateTableEvents"
import { LoanDetailsFormFields, RootStates } from "@types"
import { RatesFormContextProvider, useAppContext } from "@contexts"
import { DEFAULT_FORM_DATA } from "@constants"
import { useRateTableFormSubmit } from "../../../hooks"

interface Props {
  hideReviews?: boolean
  noBrand?: boolean
  hideAlertButton?: boolean
}

const InlineRateTableWrapper = ({
  hideReviews,
  noBrand,
  hideAlertButton
}: Props) => {
  const dispatch = useDispatch()

  const {
    media: {
      flags: { isMobile }
    },
    reviews: { getReviews, brand }
  } = useAppContext()

  const { isLoadingConfig, bank, formData } = useSelector(
    ({ app: { lo }, rateTable }: RootStates) => {
      return {
        isLoadingConfig: rateTable?.isLoadingConfig,
        bank: lo?.bank ?? "",
        formData: rateTable?.formData as LoanDetailsFormFields
      }
    }
  )

  const syncForm = (newFormData: Partial<LoanDetailsFormFields>) => {
    dispatch(updateRateForm({ ...formData, ...newFormData }))
  }

  const handleSubmit = useRateTableFormSubmit()

  useEffect(() => {
    getReviews("brand")
    dispatch(initRateTablePricing())
    dispatchRateTableLoadedEvent()
  }, [])

  return (
    <Box hasGutter hasShadow={false}>
      <InlineRateTable className={`${isLoadingConfig ? "is-loading" : ""}`}>
        <If
          condition={!!isLoadingConfig}
          then={<RateTableSkeleton hideReviews />}
          else={
            <div class="inline-rate-table__body">
              <RatesFormContextProvider
                initialFormData={formData}
                defaultFormData={DEFAULT_FORM_DATA}
                handleSubmit={handleSubmit}
                syncForm={syncForm}
              >
                <RateTableForm anchor="recent-reviews" />
                <RateTable hideAlertButton={hideAlertButton} />
              </RatesFormContextProvider>
              <If condition={isMobile} then={<RateTableBanner />} />
              <If
                condition={!hideReviews && !brand.noReviews}
                then={<RecentBrandReviews bank={bank} />}
              />
            </div>
          }
        />
      </InlineRateTable>
      <If condition={!noBrand} then={<LogoFooter />} />
    </Box>
  )
}

export default InlineRateTableWrapper
