import { h } from "preact"
import { useIsMobile } from "@contexts"
import TooltipMobile from "./Mobile"
import DesktopTooltip from "./Desktop"
import {
  TooltipFullProps,
  TooltipFullDesktopProps,
  TooltipFullMobileProps
} from "@types"
import "@styles/components/_tooltip-full.scss"

// This component is used to render the tooltip in both mobile and desktop views.
// It is used in the GlobalModal component to render the tooltip in the correct view based on the device type.
// The desktop version supports more content and the UI is not just the black globe like the regular tooltip.
const TooltipFull = (props: TooltipFullProps) => {
  const isMobile = useIsMobile()

  if (isMobile) {
    return <TooltipMobile {...(props as TooltipFullMobileProps)} />
  }

  return <DesktopTooltip {...(props as TooltipFullDesktopProps)} />
}

export default TooltipFull
