import { h } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"
import clsx from "clsx"
import { Form } from "@ui"
import { useCodeInput } from "@hooks"

const BACKSPACE = "Backspace"
const ARROW_LEFT = "ArrowLeft"
const ARROW_RIGHT = "ArrowRight"
const TAB = "Tab"

interface CodeInputProps {
  className?: string
  makeFocus?: boolean
  value?: string
  codeLength?: number
  onInput: (code: string, submit: boolean) => void
}

const CodeInput = ({
  makeFocus = false,
  className = "",
  value = "",
  onInput,
  codeLength = 6
}: CodeInputProps) => {
  const [localValue, setLocalValue] = useState(value.padEnd(codeLength, " "))
  const inputRef = useRef<HTMLInputElement>(null)

  // on mount focus the input
  useEffect(() => {
    makeFocus && inputRef.current?.focus()
  }, [makeFocus])

  const {
    cursorPosition,
    setCursorPosition,
    focusClassName,
    handleOnBlur,
    handleOnFocus
  } = useCodeInput(codeLength, inputRef)

  useEffect(() => {
    // remove all non-digit characters
    const newValue = localValue.replace(/\D/g, "")
    // if the value has the same length as the code length and we are on the last digit submit the form
    const submit =
      newValue.length === codeLength && cursorPosition === codeLength - 1
    onInput(newValue, submit)
  }, [localValue])

  useEffect(() => {
    if (value === "") setLocalValue(value.padEnd(codeLength, " "))
  }, [value])

  const handleOnInput = (event: Event) => {
    const target = event.target as HTMLInputElement
    const pinCode = target.value
    const pinCodeLength = pinCode.length
    setCursorPosition(pinCodeLength)
    if (pinCodeLength >= codeLength) {
      target.blur()
    }
    setLocalValue(pinCode)
  }

  const updateValue = (position: number, insert?: string) =>
    localValue.slice(0, position) +
    (insert ? insert + localValue.slice(position + 1) : "")

  const handleOnKeyDown = (event: KeyboardEvent) => {
    let newValue
    switch (event.key) {
      case BACKSPACE:
        event.preventDefault()
        if (cursorPosition >= 0) {
          if (cursorPosition === value.length) {
            newValue = updateValue(cursorPosition - 1)
            setCursorPosition(cursorPosition - 1, false)
          } else {
            // if the position we are deleting is already empty, move the cursor to the left
            if (!localValue[cursorPosition].trim() && cursorPosition > 0) {
              setCursorPosition(cursorPosition - 1, false)
            } else {
              setCursorPosition(cursorPosition, false)
            }
            newValue = updateValue(cursorPosition, " ")
          }

          setLocalValue(newValue)
        }
        break
      case ARROW_LEFT:
        event.preventDefault()
        setCursorPosition(cursorPosition - 1)
        break
      case TAB:
        event.preventDefault()
        setCursorPosition(cursorPosition + 1)
        // if its the last digit, blur the input
        if (cursorPosition === codeLength - 1) {
          inputRef.current?.blur()
        }
        break
      case ARROW_RIGHT:
        event.preventDefault()
        setCursorPosition(cursorPosition + 1)
        break
      default:
        if (event.key.length === 1 && !isNaN(Number(event.key))) {
          event.preventDefault()
          newValue = updateValue(cursorPosition, event.key)

          setLocalValue(newValue)
          setCursorPosition(cursorPosition + 1)
        }

        break
    }
  }

  const handleInputPaste = (event: ClipboardEvent) => {
    const pastedText = event.clipboardData?.getData("text").trim() ?? ""

    setCursorPosition(pastedText.length)
    setLocalValue(pastedText)
  }

  const handleDigitClick = (index: number) => {
    inputRef.current?.focus()
    setCursorPosition(index)
  }

  const getDigitClassName = (index: number) => {
    return clsx("wizard__form-input-digit form__input", focusClassName[index], {
      "is-last": index === codeLength - 1,
      "is-typed": index < localValue.length && !!localValue[index].trim()
    })
  }

  return (
    <Form.Row className={className}>
      <Form.Col className="tw-relative">
        <div className="wizard__form-input-container">
          <input
            ref={inputRef}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern={`\\d{${codeLength}}`}
            className="wizard__form-input-code"
            maxLength={codeLength}
            value={localValue}
            onInput={handleOnInput}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            onPaste={handleInputPaste}
          />
          {Array.from({ length: codeLength }, (_, index) => (
            <div
              key={index}
              onClick={() => handleDigitClick(index)}
              className={getDigitClassName(index)}
            >
              {localValue[index]}
              {focusClassName[index] && (
                <div className="wizard__form-input-cursor" />
              )}
            </div>
          ))}
        </div>
      </Form.Col>
    </Form.Row>
  )
}

export default CodeInput
