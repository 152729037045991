export default {
  tooltip: {
    zipcode: "Enter the zipcode to help refine results.",
    annualIncome: "Combine all pre-tax income from you and your co-borrower.",
    monthlyDebt:
      "This amount is considered to be other monthly obligations you may have, such as credit cards, car payments, student loans, groceries, utilities, insurance, etc.",
    downPayment:
      "Suggested down payment is 20%. Going lower than that may require mortgage insurance",
    interestRate:
      "This calculator is intended for planning and education purposes only. The above figures are based on the numbers shown on the payment calculator. The interest rate, APR, and payment amount may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors. Chart data is for illustrative purposes only. Costs displayed here are not necessarily all inclusive and can vary based on numerous factors. Rates are subject to change without notice. The output of the tool is not a loan offer or solicitation, nor is it financial or legal advice and should not be used as your sole source of information."
  },
  footer: {
    disclaimer:
      "This calculator is intended for planning and education purposes only. It relies on assumptions and information provided by you regarding your goals, expectations and financial situation, and should not be used as your sole source of information. The output of the tool is not a loan offer or solicitation, nor is it financial or legal advice.",
    cta: "Get a custom quote"
  }
}
