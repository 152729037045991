import { Fragment, h } from "preact"
import { LoanDetailsFormFields, LocationObj, OnChangeValue } from "@types"
import { Form } from "@ui"
import FirstTimeHomebuyer from "../LoanDetailsFields/Forms/ExtraFields/FirstTimeHomebuyer"

interface Props {
  hasARMTerms: boolean
  formData: LoanDetailsFormFields
  handleOnChange: (
    value: OnChangeValue,
    name: keyof LoanDetailsFormFields
  ) => void
}

const PurchaseFields = ({ hasARMTerms, formData, handleOnChange }: Props) => {
  const numericInputChange = (value: string, e: Event) => {
    const fieldName = (e.target as HTMLInputElement)
      .name as keyof LoanDetailsFormFields
    handleOnChange(value, fieldName)
  }

  return (
    <Fragment>
      <Form.AutoZipcode
        onChange={(value: LocationObj) => {
          handleOnChange(value, "location")
        }}
        showErrorWhenEmpty
        initialValue={
          (formData.location as LocationObj)?.zipcode || formData.zipcode
        }
      />

      <Form.ListPriceField
        label="Purchase Price"
        onChange={numericInputChange}
        value={formData.list_price}
      />

      <Form.DownPaymentField
        maxValue={formData.list_price}
        handleInputChange={(value: any) => {
          handleOnChange(value, "down_payment")
        }}
        handlePercentageChange={(value: any) => {
          handleOnChange(value, "down_percentage")
        }}
        value={formData.down_payment}
        value_percentage={formData.down_percentage}
      />

      <Form.CreditScoreFull
        value={formData.credit_score ?? 0}
        name="credit_score"
        onChangeComplete={(value: number) =>
          handleOnChange(value, "credit_score")
        }
      />

      <Form.PropertyTypeField
        handleOnChange={(value: string) =>
          handleOnChange(value, "property_type")
        }
        value={formData.property_type}
      />

      <Form.ResidencyTypeField
        value={formData.residency_type}
        onChange={(value: string) => handleOnChange(value, "residency_type")}
      />

      <Form.LoanTypeDropDown
        value={formData.loan_type}
        va_funding_fee_type={formData.va_funding_fee_type}
        onChange={handleOnChange}
        hasARMOption={hasARMTerms}
        loan_purpose={formData.loan_purpose}
      />

      <FirstTimeHomebuyer
        value={formData.first_time_homebuyer}
        onChange={(option: { value: OnChangeValue }) =>
          handleOnChange(option.value, "first_time_homebuyer")
        }
      />
    </Fragment>
  )
}

export default PurchaseFields
