import { h } from "preact"
import { bbProcessEnv } from "@helpers"

interface Props {
  className?: string
  imgClassName?: string
  source: string
  imgSrc: string
  alt: string
}

const Picture = ({
  className = "",
  imgClassName = "",
  source,
  imgSrc,
  alt
}: Props) => (
  <picture className={className}>
    <source srcset={`${bbProcessEnv.PUBLIC_PATH}${source}`} type="image/webp" />
    <img
      className={imgClassName}
      src={`${bbProcessEnv.PUBLIC_PATH}${imgSrc}`}
      alt={alt}
    />
  </picture>
)

export default Picture
