const logical_operators = ["and", "or"]
let state
/*let state = {
  loan_amount: 400000,
  loan_purpose: "purchase",
  loan_term: 360
}

// ( state.loan_amount==500000 || state.loan_purpose=="refinance" || ( state.loan_amount==400000 && ( state.loan_term==180 || state.loan_term==360 ) ) )
let conditions = {
  or: {
    loan_amount: 500000, // "eq" is assumed when there is no operator
    loan_purpose: "refinance",
    and: {
      loan_amount: 400000,
      or: {
        loan_term: 180,
        loan_term: 360
      }
    }
  }
}

// ( state.loan_amount==500000 || loan_term>=360 )
let conditions2 = {
  or: {
    loan_amount: 500000,
    loan_term: [361, "lte"] // lte is the operator in this case
  }
}*/

function evaluateConditions(conditions, currentState = {}, op = "and") {
  state = currentState
  for (var key in conditions) {
    if (op == "and") {
      // check if key is an operator
      if (logical_operators.indexOf(key) > -1) {
        if (!evaluateConditions(conditions[key], currentState, key))
          return false
      } else {
        if (!evaluateConditional(key, currentState, conditions[key]))
          return false
      }
    }
    if (op == "or") {
      // check if key is an operator
      if (logical_operators.indexOf(key) > -1) {
        if (evaluateConditions(conditions[key], currentState, key)) return true
      } else {
        if (evaluateConditional(key, currentState, conditions[key])) return true
      }
    }
  }
  if (op == "and") {
    return true
  }
  if (op == "or") {
    return false
  }
}

function evaluateConditional(key, state, value) {
  let op = "eq"
  let val = ""
  if (Array.isArray(value)) {
    val = value[0]
    op = value[1] ? value[1] : op
  } else {
    val = value
  }
  if (op == "gt") return state[key] > val
  if (op == "gte") return state[key] >= val
  if (op == "lt") return state[key] < val
  if (op == "lte") return state[key] <= val
  if (op == "!eq") return state[key] != val
  if (op == "eq") return state[key] == val
  if (op == "!isset") return !state[key] ? true : false
  if (op == "isset") return state[key] ? true : false
  if (!op) return state[key] == val
}

// use empty string as initial logical operator
// Usage demo console.log(evaluateConditions(conditions2, ""))

export default evaluateConditions
