import { h } from "preact"
import { useSelector } from "react-redux"
import { Avatar } from "@ui"
import { LO, RootStates } from "@types"
import { useIsDesktop } from "@contexts"

interface Props {
  size?: "md" | "lg"
}

const HeaderLOAvatar = ({ size }: Props) => {
  const { picture, name } = useSelector(({ app }: RootStates) => ({
    picture: (app?.lo as LO).picture,
    name: (app?.lo as LO).name
  }))

  const isDesktop = useIsDesktop()

  return (
    <Avatar
      src={picture}
      alt={`${name} picture`}
      size={size ? size : !isDesktop ? "md" : "lg"}
      className="tw-ml-0"
    />
  )
}

export default HeaderLOAvatar
