import { h } from "preact"
import { Form, Subscribe } from "~/components"

const DynamicFormSubscribe = ({ name, value, onChange, label }) => {
  return (
    <Form.Row>
      <Form.Col>
        <Subscribe
          title={label}
          subscribed={value}
          updateSubscription={() => {
            onChange(name)
          }}
        />
      </Form.Col>
    </Form.Row>
  )
}

export default DynamicFormSubscribe
