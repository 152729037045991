import { ComponentChildren, h } from "preact"
import { useState } from "preact/hooks"
import { RootStates, TabPath } from "@types"
import { useSelector } from "react-redux"
import TabContext from "./context"

interface Props {
  children: ComponentChildren
}

const TabProvider = ({ children }: Props) => {
  const mountInlineLeadWorkflow = useSelector(({ app }: RootStates) => {
    return app.widgets?.mountInlineLeadWorkflow ?? false
  })

  const [activeTab, setTab] = useState<TabPath>(
    mountInlineLeadWorkflow ? "leadWorkflow" : "loan"
  )

  return (
    <TabContext.Provider value={{ activeTab, setTab }}>
      {children}
    </TabContext.Provider>
  )
}

export default TabProvider
