import { h } from "preact"
import { connect } from "react-redux"
import { useEffect, useState } from "preact/hooks"
import { validateEmailFormat, getStorage } from "@helpers"
import { leadAPI } from "@api"
import {
  If,
  Form,
  Loader,
  Icon,
  Button,
  PreactHCaptcha,
  CaptchaInvalidMsg
} from "@ui"
import successIcon from "@assets/icons/check-and-circle.svg"
import "@styles/components/_contact-form.scss"

const formPlaceholder = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
  interested_in: ""
}

const defaults = { showCloseBtn: true, hideKnownFields: {} }

const ConnectedContactForm = ({
  isDash,
  config,
  hasMsgBox,
  handleCloseButtonClick,
  showCloseBtn = defaults["showCloseBtn"],
  hideKnownFields = defaults["hideKnownFields"]
}) => {
  const [form, setForm] = useState(formPlaceholder)
  const [isLoading, setLoading] = useState(false)
  const [isSent, setSent] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [phoneInvalid, setPhoneInvalid] = useState(false)

  const [captchaSuccess, setCaptchaSuccess] = useState(true)
  const [captchaToken, setCaptchaToken] = useState(null)

  const handleCaptchaExpired = () => {
    setCaptchaToken(null)
  }

  const handleCaptchaVerificationSuccess = token => {
    setCaptchaToken(token)
  }

  useEffect(() => {
    const formProp = config?.form ?? {}
    let initialState = { ...form, ...formProp }

    const { email } = getStorage("leadEmail")

    if (email) {
      initialState = { ...initialState, email }
    }

    setForm(initialState)
  }, [])

  const handleInputChange = e => {
    setValue(e.target.value, e.target.name)
  }

  const setValue = (value, name) => {
    const formValues = {
      ...form,
      [name]: value
    }

    setForm(formValues)
  }

  const handleEmailBlur = e => {
    setEmailInvalid(!validateEmailFormat(e.target.value))
  }

  const handleSubmit = e => {
    e.preventDefault()

    // If captcha has been failed, set isValid to false
    if (!captchaToken) {
      setCaptchaSuccess(false)
      return
    }

    const formData = {
      captcha_token: captchaToken,
      name: `${form.firstName} ${form.lastName}`,
      name_first: form.firstName,
      name_last: form.lastName,
      email: form.email,
      phone: form.phone,
      owner: config.xloid,
      details: {
        interested_in: form.interested_in,
        message: form.message
      },
      source: config.source || "boomtown"
    }

    if (config.app_key) {
      formData.app_key = config.app_key
    } else if (config.subscription_id) {
      formData.subscription_id = config.subscription_id
    }

    setLoading(true)

    const key = isDash ? "leadContact" : "leadManage"

    leadAPI[key](
      isDash
        ? formData
        : {
            formData,
            srcComponent: "contact_form"
          },
      isDash ? config.bb_sid : null
    )
      .then(() => {
        setLoading(false)
        setSent(true)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
        setSent(false)
      })
  }

  const submitDisabled = !form.email || emailInvalid || phoneInvalid

  return (
    <div className="contact-form">
      {isLoading && <Loader className="contact-form__loader" />}
      {!isLoading && !isSent && (
        <div>
          <p className="contact-form__description">
            <b>I'm here to help!</b> — Tell me a little bit about yourself and
            your current lending needs.
          </p>

          <form className="form contact-form__form" onSubmit={handleSubmit}>
            {hideKnownFields["firstName"] ? null : (
              <Form.Row>
                <Form.Col>
                  <Form.Input
                    name="firstName"
                    value={form.firstName}
                    onInput={handleInputChange}
                    label="First Name"
                    isMaterial
                  />
                </Form.Col>
              </Form.Row>
            )}
            {hideKnownFields["lastName"] ? null : (
              <Form.Row>
                <Form.Col>
                  <Form.Input
                    name="lastName"
                    value={form.lastName}
                    onInput={handleInputChange}
                    label="Last Name"
                    isMaterial
                  />
                </Form.Col>
              </Form.Row>
            )}

            {hideKnownFields["email"] ? null : (
              <Form.Row>
                <Form.Col>
                  <Form.Input
                    className={`${emailInvalid ? "is-invalid" : ""}`}
                    name="email"
                    value={form.email}
                    onInput={handleInputChange}
                    onBlur={handleEmailBlur}
                    label="Email Address"
                    type="email"
                    required
                    isMaterial
                  />
                </Form.Col>
              </Form.Row>
            )}

            {hideKnownFields["phone"] ? null : (
              <Form.Row>
                <Form.Col>
                  <Form.PhoneNumber
                    isMaterial
                    className={`${phoneInvalid ? "is-invalid" : ""}`}
                    name="phone"
                    type="tel"
                    value={form.phone}
                    onChange={handleInputChange}
                    label="Phone number"
                    required={isDash}
                    aria-describedby="phone"
                    onValidationError={() => setPhoneInvalid(true)}
                    onValidationSuccess={() => setPhoneInvalid(false)}
                  />
                </Form.Col>
              </Form.Row>
            )}

            <Form.Row>
              <Form.Col>
                <Form.SelectCustom
                  isMaterial
                  showPlaceholder
                  name="interested_in"
                  value={form.interested_in}
                  label="I'm interested in"
                  options={[
                    {
                      label: "Getting pre-qualified",
                      value: "prequal"
                    },
                    {
                      label: "Applying for a home loan",
                      value: "apply"
                    }
                  ]}
                  onChange={option => {
                    setValue(option.value, "interested_in")
                  }}
                  required={isDash}
                />
              </Form.Col>
            </Form.Row>

            {hasMsgBox && (
              <Form.Row>
                <Form.Col>
                  <Form.Textarea
                    name="message"
                    value={form.message}
                    onInput={handleInputChange}
                    label="Message"
                    isMaterial
                    required={isDash}
                  />
                </Form.Col>
              </Form.Row>
            )}

            <If condition={!captchaSuccess} then={<CaptchaInvalidMsg />} />

            <Form.Row className="contact-form__form-footer is-last">
              <Button
                type="submit"
                label="Contact"
                noShadow
                disabled={submitDisabled}
              />
            </Form.Row>

            <div className="text-center py-4">
              <PreactHCaptcha
                onVerify={handleCaptchaVerificationSuccess}
                onExpire={handleCaptchaExpired}
              />
            </div>
          </form>
        </div>
      )}

      {!isLoading && isSent && (
        <div className="contact-form__success">
          <h3 className="d-flex align-items-center justify-content-center">
            <Icon
              className="mr-2"
              src={successIcon}
              size={[42, 41]}
              originalSize={[43, 42]}
            />
            <span>Success!</span>
          </h3>
          <p className="title-color">
            I will be in touch with you soon to address your specific needs.
          </p>
          {showCloseBtn ? (
            <Button
              onClick={handleCloseButtonClick}
              outline
              size="sm"
              adapt
              label="Close Window"
            />
          ) : null}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ app }, { config = {} }) => {
  return { config: { ...app, ...config } }
}

const ContactForm = connect(mapStateToProps)(ConnectedContactForm)

export default ContactForm
