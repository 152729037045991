import { h } from "preact"
import { Icon } from "@ui"
import { ICONS } from "@constants"
import { TabPath } from "@types"

interface Props {
  className?: string
  onClick: (tab: TabPath) => void
  label: string
  tab: TabPath
}

const MenuItem = ({ className = "", onClick, tab, label }: Props) => (
  <li className={`menu__item ${className}`}>
    <a href={`#${tab}`} onClick={() => onClick(tab)} className="menu__link">
      <Icon
        className="tw-relative tw-z-10"
        src={ICONS[tab].src}
        size={ICONS[tab].size}
        originalSize={ICONS[tab].size}
      />
      <span className="tw-relative tw-z-10">{label}</span>
    </a>
  </li>
)

export default MenuItem
