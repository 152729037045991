import { h } from "preact"
import "@styles/components/_radio-group.scss"

interface Props {
  options: { label: string; value: string }[]
  name: string
  value: string
  onChange: (value: string, name: string) => void
  label?: string
  className?: string
  optionsClassName?: string
  type: "radio" | "checkbox"
}

const RadioGroup = ({
  type = "radio",
  options,
  name,
  value,
  onChange,
  label,
  className = "",
  optionsClassName = ""
}: Props) => {
  return (
    <div className={`radio-group ${className}`}>
      {label && <label>{label}</label>}
      <div className={`radio-group__options d-flex gap-xs ${optionsClassName}`}>
        {options.map(option => {
          return (
            <label
              className={`radio-group__option ${
                value === option.value ? "is-checked" : ""
              }`}
              key={option.value}
            >
              <input
                type={type}
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={() => {
                  onChange(
                    type === "checkbox"
                      ? value === option.value
                        ? ""
                        : option.value
                      : option.value,
                    name
                  )
                }}
              />
              <span>{option.label}</span>
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default RadioGroup
