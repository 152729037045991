const FEATURED_OPTION_TERM = {
  label: "Featured",
  term: "all",
  type: "all"
}

const MORTGAGE_TERMS_CONFIG = [
  {
    type: "fixed",
    term: 30,
    label: "30yr Fixed"
  },
  {
    type: "fixed",
    term: 20,
    label: "20yr Fixed"
  },
  {
    type: "fixed",
    term: 15,
    label: "15yr Fixed"
  },
  {
    type: "arm",
    term: "10",
    label: "10y ARM"
  },
  {
    type: "arm",
    term: "7",
    label: "7y ARM"
  },
  {
    type: "arm",
    term: "5",
    label: "5y ARM"
  },
  {
    type: "arm",
    term: "3",
    label: "3y ARM"
  }
]

const MORTGAGE_TERMS_CONFIG_WITH_FEATURED = [
  FEATURED_OPTION_TERM,
  ...MORTGAGE_TERMS_CONFIG
]

const SORT_BY_OPTIONS = [
  { label: "Relevance", value: "relevance" },
  { label: "Low Rate", value: "low_rate" },
  { label: "Low APR", value: "low_apr" },
  { label: "Low Monthly Payment", value: "low_monthly_payment" },
  { label: "Low Upfront Costs", value: "low_upfront_costs" }
] as const

export {
  SORT_BY_OPTIONS,
  FEATURED_OPTION_TERM,
  MORTGAGE_TERMS_CONFIG,
  MORTGAGE_TERMS_CONFIG_WITH_FEATURED
}
