import { h } from "preact"

interface AccentColorProps {
  children: preact.ComponentChildren
  className?: string
}

const AccentColor = ({ children, className = "" }: AccentColorProps) => (
  <span className={`accent-color ${className}`}>{children}</span>
)

export default AccentColor
