import { h } from "preact"
import "@styles/components/_row-loader.scss"

const ColLoader = ({ cols = 3, row1Items = 2, row2Items = 3 }) => (
  <div className="pricing-col tw-flex gap-base">
    {[...Array(cols)].map(() => (
      <div className="pricing-item is-row-loader datagrid__row">
        <div className="pricing-item__header tw-flex tw-justify-between">
          {[...Array(row1Items)].map(item => (
            <div
              key={item}
              className="datagrid__loader"
              style={`width:${100 / row1Items - 10}%;`}
            ></div>
          ))}
        </div>
        <div className="pricing-item__body tw-flex">
          {[...Array(row2Items)].map(item => (
            <div
              key={item}
              className="datagrid__loader"
              style={`width: ${100 / row2Items}%;`}
            ></div>
          ))}
        </div>
      </div>
    ))}
  </div>
)

export default ColLoader
