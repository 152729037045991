import axios from "axios"
import { bbProcessEnv } from "../../helpers/bbProcessEnv"
import { getSubscriptionData, getConfig } from "./methods"

const ENDPOINT = `${bbProcessEnv.API_URL}/api/v1/subscriptions`
const HISTORY_ENDPOINT = `${bbProcessEnv.API_URL}/api/v1/subscription-quotes`

export default {
  get: getSubscriptionData,
  getConfig,
  async update(subscriptionData) {
    const url = ENDPOINT
    const params = { ...subscriptionData }

    if (params.quote_info.loan_purpose !== "refinance") {
      delete params.quote_info.cash_out
    }
    try {
      const response = await axios.post(url, {
        ...params
      })
      if (response.data.status !== "success") {
        console.error(response)
      }
      return response
    } catch (error) {
      console.error(error)
    }
  },
  // subscriptionData:{
  //    email,
  //    loid,
  //    lead_id
  //    quote_info:{
  // 		  credit_score
  // 		  loan_purpose
  // 		  amortization_type
  // 		  arm_term
  // 		  aus
  // 		  lock_period
  // 		  loan_amount
  // 		  down_payment
  // 		  lead_source
  // 		  loan_term
  // 		  loan_type
  // 		  list_price
  // 		  location:{
  //        county
  // 		    street
  // 		    list_price
  // 		    state
  //		    zipcode}
  //      }
  //    }
  // }
  async add(subscriptionData) {
    try {
      const response = await axios.post(ENDPOINT, {
        ...subscriptionData
      })
      if (response.data.status !== "success") {
        console.error(response)
      }
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async getSubscriptionQuote(id) {
    const url = ENDPOINT + "?id=" + id
    try {
      const response = await axios.get(url)
      if (response.data.status !== "success") {
        console.error(response)
      }
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getSubscriptionHistoryQuotes({
    subscription_id,
    timestamp_min,
    timestamp_max
  }) {
    const url = `${HISTORY_ENDPOINT}?subscription_id=${subscription_id}&timestamp_min=${timestamp_min}&timestamp_max=${timestamp_max}`
    try {
      const response = await axios.get(url)
      if (response.status !== 200) {
        console.error(response)
      }
      return response.data
    } catch (error) {
      console.error(error)
    }
  }
}
