import { h } from "preact"
import { useSelector } from "react-redux"
import { RootStates } from "@types"
import { Icon } from "@ui"
import PhoneNumber from "./PhoneNumber"
import PHONE_ICON from "@assets/icons/phone.svg"

const PhoneNumbers = () => {
  const { mobilePhone, officePhone } = useSelector(({ app }: RootStates) => {
    return {
      mobilePhone: app?.extraDetails?.mobilePhone,
      officePhone: app?.extraDetails?.officePhone
    }
  })

  return (
    <div className="tw-flex tw-items-center gap-sm">
      <Icon src={PHONE_ICON} size={20} originalSize={24} />
      <dl className="tw-flex tw-items-center gap-base tw-pt-6">
        {officePhone && <PhoneNumber phone={officePhone} type="Office" />}
        {mobilePhone && <PhoneNumber phone={mobilePhone} type="Mobile" />}
      </dl>
    </div>
  )
}

export default PhoneNumbers
