import { h } from "preact"
import { useDispatch } from "react-redux"
import {
  getOfferDetails,
  setCurrentOfferDetailsCard,
  toggleOfferDetailsModal
} from "@actions"
import { useGetTermLabel } from "@hooks"
import MortgageTerm from "./MortgageTerm"
import { PricingItem } from "@types"
import FeesAndPoints from "./FeesAndPoints"
import PrincipalAndInterest from "./PrincipalAndInterest"

interface Props {
  quote_id: number
  item: PricingItem
  hideFeesModal?: boolean
  fees: number
}

const PricingItemBodyDashMobile = ({
  quote_id,
  item,
  hideFeesModal,
  fees
}: Props) => {
  const dispatch = useDispatch()

  const term = useGetTermLabel(item)

  const mainClickActions = (e?: Event) => {
    e?.stopPropagation()
    dispatch(setCurrentOfferDetailsCard(item))

    dispatch(
      getOfferDetails({
        quote_id
      })
    )
  }

  const handleClickFees = ({
    e,
    openModal
  }: {
    e?: Event
    openModal?: boolean
  }) => {
    mainClickActions(e)
    dispatch(toggleOfferDetailsModal({ visible: true }))
  }

  const handleClickPI = (e: Event) => {
    mainClickActions(e)
    dispatch(toggleOfferDetailsModal({ visible: true, initialTab: "payment" }))
  }

  return (
    <div className="pricing-item__body">
      <div className="pricing-item__body-inner tw-flex tw-items-center tw-justify-between">
        <PrincipalAndInterest
          value={item.principalAndInterest}
          hasTooltip={false}
          onClick={handleClickPI}
        />

        <MortgageTerm term={term} />

        <FeesAndPoints
          fees={fees}
          hideFeesModal={hideFeesModal}
          handleClickFees={handleClickFees}
          hasTooltip={false}
          points={item.pts}
          currentDetailsCard={item}
        />
      </div>
    </div>
  )
}

export default PricingItemBodyDashMobile
