import { h } from "preact"
import { useSelector } from "react-redux"
import { useCallback } from "preact/hooks"
import { LocationObj, RateTableFormData, RootStates } from "@types"
import { dispatchRateTableCtaEvent } from "@helpers"
import { saveConfigFromChild } from "@message-bridge"
import { US_STATES } from "@constants"
import { toSentenceCase } from "@helpers"
import Banner from "../Default"

const mapFormFields = (formData: RateTableFormData) => {
  const {
    loan_purpose,
    property_type,
    residency_type,
    list_price,
    down_payment,
    credit_score,
    zipcode
  } = formData

  const city = (formData.location as LocationObj).city
  const USSatate = (formData.location as LocationObj).state

  const locationVal = `${city}, ${
    US_STATES[USSatate as keyof typeof US_STATES]
  }`

  return {
    steps: {
      loan_purpose,
      property_type,
      residency_type,
      list_price,
      down_payment,
      credit_score,
      location: locationVal
    },
    locations: {
      location: {
        value: locationVal,
        locality: city,
        administrative_area_level_1: USSatate,
        postal_code: zipcode
      }
    }
  }
}

const RateTableBanner = () => {
  const { formData, app_key, title, subtitle, ctaLabel } = useSelector(
    (state: RootStates) => {
      const { app_key } = state.app
      const { rateTableConfig, formData } = state.rateTable!

      return {
        formData,
        app_key,
        title: rateTableConfig?.rateflow_cta.header ?? "",
        subtitle: rateTableConfig?.rateflow_cta.subHeader ?? "",
        ctaLabel: toSentenceCase(rateTableConfig?.rateflow_cta.button ?? "")
      }
    }
  )

  const onClick = useCallback(() => {
    dispatchRateTableCtaEvent()
    const mappedForm = mapFormFields(formData)
    // send the values from the rate table to the lead workflow
    saveConfigFromChild({ wizard: { values: { ...mappedForm } } }, app_key)
  }, [app_key, formData])

  return (
    <Banner
      title={title}
      subtitle={subtitle}
      ctaLabel={ctaLabel}
      href="/external/personalize_rate_modal"
      onClick={onClick}
    />
  )
}

export default RateTableBanner
