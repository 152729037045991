import { h } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"
import { debounce, matchMediaHoverPointer } from "@helpers"
import { PricingItem } from "@types"
import { Button } from "@ui"
import { useGetTermLabel, useLenderFees } from "@hooks"
import { useAppContext } from "@contexts"
import {
  APRRateBlock,
  FeesAndPoints,
  PrincipalAndInterest
} from "../PricingItem/partials"
import MortgageTerm from "../PricingItem/partials/MortgageTerm"
import { useRateTableRowActions } from "@hooks"
import "@styles/components/_rate-table-row.scss"

type Props = {
  item: PricingItem
  onClick: () => void
  parentRef: HTMLDivElement | null
}

const RateTableRow = ({
  parentRef,
  item,
  item: { rate, apr, principalAndInterest, feeList },
  onClick
}: Props) => {
  const [showClassName, setShowClassName] = useState(false)

  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  const fees = useLenderFees(item)
  const term = useGetTermLabel(item)
  const { handleClickFees, handleClickPI } = useRateTableRowActions(item)

  const eleRef = useRef<HTMLDivElement>(null)

  const [hasHover, setHasHover] = useState(false)

  useEffect(() => {
    const newHasHover = matchMediaHoverPointer()
    setHasHover(newHasHover)
  }, [isMobile, eleRef.current])

  // This just called when the component is mounted
  useEffect(() => {
    setSize()

    window.addEventListener("resize", setSize)

    handleElementsWidth()

    return () => {
      window.removeEventListener("resize", setSize)
    }
  }, [])

  const setSameWidth = (clsName: string) => {
    const row = eleRef.current

    if (!parentRef || !row) return

    const el = row.getElementsByClassName(clsName)[0] as HTMLElement

    if (!el) return

    const elements = Array.from(
      parentRef.getElementsByClassName(clsName) as HTMLCollectionOf<HTMLElement>
    )
    const maxWidth = Math.max(...elements.map(el => el.clientWidth))
    el.style.width = `${maxWidth}px`
  }

  const setTermEleHeight = () => {
    const row = eleRef.current

    if (!row) return
    const ele = row.getElementsByClassName("is-term")[0] as HTMLElement
    if (!ele) return
    const eleFees = row.getElementsByClassName("is-fees")[0] as HTMLElement
    const eleInner = Array.from(
      row.getElementsByClassName(
        "is-term-inner"
      ) as HTMLCollectionOf<HTMLElement>
    )[0]
    ele.style.marginLeft = window.getComputedStyle(eleFees).marginLeft
    ele.style.height = window.getComputedStyle(eleFees).height
    eleInner.style.left = `calc(100% - ${eleInner.clientWidth}px)`
  }

  const handleElementsWidth = debounce(() => {
    setSameWidth("is-fees")
    setSameWidth("is-points")
    setSameWidth("is-tp")
    setTermEleHeight()
  }, 20)

  const setSize = debounce(() => {
    const row = eleRef.current
    if (!row) return

    const ele = row.getElementsByClassName("is-term")[0] as HTMLElement
    const parent = parentRef

    if (!isMobile && parent) {
      parent.style.width = `${parent.offsetWidth}px`
      parent.style.width = "auto"
      setTimeout(() => {
        parent.style.width = `${row.offsetWidth}px`
        const style = window.getComputedStyle(ele)
        ele.style.marginLeft = style.marginLeft
        setShowClassName(true)
      }, 5)
    } else {
      if (parent) {
        parent.style.width = "auto"
      }
      ele && (ele.style.marginLeft = "")
      setShowClassName(true)
    }
  }, 1)

  const handleClick = (e: Event) => {
    // If the user clicks the info button to summon the tooltip, this
    // function should be a no-op.
    if ((e.target as HTMLElement).className === "pricing-item__tooltip") {
      return
    }
    onClick?.()
  }

  const handleMouseEnter = () => {
    const target = eleRef.current
    target?.classList.add("is-hover")
    const ele = target?.getElementsByClassName("is-term")[0] as HTMLElement
    const eleInner = target?.getElementsByClassName(
      "is-term-inner"
    )[0] as HTMLElement
    const eleFees = target?.getElementsByClassName("is-fees")[0] as HTMLElement

    if (!ele || !eleInner || !eleFees) return
    ele.style.marginLeft = eleFees.style.marginLeft
    eleInner.style.left = "0px"
  }

  const handleMouseLeave = () => {
    setTimeout(() => {
      const target = eleRef.current
      target?.classList.remove("is-hover")
      const ele = target?.getElementsByClassName("is-term")[0] as HTMLElement
      const eleInner = target?.getElementsByClassName(
        "is-term-inner"
      )[0] as HTMLElement
      if (!ele || !eleInner) return
      const style = window.getComputedStyle(ele)
      ele.style.marginLeft = style.marginLeft
      eleInner.style.left = `calc(100% - ${eleInner.clientWidth}px)`
    }, 20)
  }

  return (
    <div
      ref={eleRef}
      className={`rt-row tw-flex tw-items-center fade ${
        showClassName ? "show" : ""
      } ${hasHover ? "" : "not-hover"}`}
      onClick={(e: Event) => handleClick(e)}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      tabIndex={0}
    >
      <APRRateBlock className="rt-row__col" rate={rate} apr={apr} />
      <FeesAndPoints
        className="rt-row__col"
        hasTooltip
        points={item.pts}
        fees={fees}
        handleClickFees={handleClickFees}
        currentDetailsCard={item}
      />
      <PrincipalAndInterest
        className="rt-row__col"
        value={principalAndInterest}
        onClick={handleClickPI}
      />
      <MortgageTerm term={term} className="rt-row__col" />

      <Button adapt size="sm" label="Get started" onClick={() => {}} noShadow />
    </div>
  )
}

export default RateTableRow
