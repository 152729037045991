import { h } from "preact"
import { connect } from "react-redux"
import { resetState } from "@actions"
import { Button } from "@ui"

const ConnectedResetWorkflowBtn = ({
  resetState,
  label = "Start over",
  className = ""
}) => (
  <Button
    onClick={resetState}
    outline
    size="sm"
    adapt
    pill
    label={label}
    className={className}
  />
)

function mapDispatchToProps(dispatch) {
  return {
    resetState: () => dispatch(resetState())
  }
}

const ResetWorkflowBtn = connect(
  null,
  mapDispatchToProps
)(ConnectedResetWorkflowBtn)

export default ResetWorkflowBtn
