import { h } from "preact"
import { useSelector } from "react-redux"
import { If } from "@ui"
import { RootStates } from "@types"

interface Props {
  className?: string
}

const Disclaimer = ({ className = "" }: Props) => {
  const disclaimer = useSelector(
    ({ wizard }: RootStates) => wizard?.disclaimers.final_result ?? ""
  )

  return (
    <If
      condition={disclaimer.length > 0}
      then={
        <div className={`wizard__disclaimer ${className}`}>
          <p className="wizard__disclaimer-text">{disclaimer}</p>
        </div>
      }
    />
  )
}

export default Disclaimer
