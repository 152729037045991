import { useState } from "preact/hooks"
import {
  sendMessageToIframe,
  useIframeCommunication
} from "@iframeCommunication"
import {
  CLOSE_GLOBAL_MODAL,
  CLOSE_LAST_ITEM_GLOBAL_MODAL,
  DEFAULT_MODAL_STATE,
  GLOBAL_MODAL_MOUNTED,
  TOGGLE_GLOBAL_MODAL,
  UPDATE_GLOBAL_MODAL_IFRAME_STATE
} from "@constants"
import { GlobalModalState, GlobalModalSubType, GlobalModalType } from "@types"
import { addScrollLock, removeScrollLock } from "@helpers"
import { css } from "@emotion/css"
import {
  setExitPopUpSeen,
  setExitPopUpSubscribed
} from "../../iframeCommunication/utils/exit-pop-up"
import { useEventQueue } from "@hooks"

const BASE_STYLES = `
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
`

type IframeAction =
  | typeof TOGGLE_GLOBAL_MODAL
  | typeof CLOSE_GLOBAL_MODAL
  | typeof CLOSE_LAST_ITEM_GLOBAL_MODAL

interface MessageParams {
  iframeID?: string
  type: GlobalModalType
  show?: boolean
  subType?: GlobalModalSubType
}

interface IframeMessage {
  action: IframeAction
  params: MessageParams
}

const NOT_ENABLED_MODAL_STYLES = { modalStyles: "" }

const useGlobalModalFrame = (
  currentAppIframeID: string, // iframeID of the current given by the current app
  isEnabled: boolean
) => {
  if (!isEnabled) return NOT_ENABLED_MODAL_STYLES

  const [modalState, setModalState] =
    useState<GlobalModalState>(DEFAULT_MODAL_STATE)

  // We use the event queue to ensure that the modal is mounted before we start sending messages
  const call = useEventQueue(GLOBAL_MODAL_MOUNTED)

  useIframeCommunication(message => {
    if (message.action === TOGGLE_GLOBAL_MODAL && message.params) {
      call(() => handleToggleGlobalModal(message as IframeMessage))
    } else if (message.action === CLOSE_GLOBAL_MODAL) {
      call(() => handleCloseGlobalModal())
    } else if (message.action === CLOSE_LAST_ITEM_GLOBAL_MODAL) {
      call(() => handlePopGlobalModal(message as IframeMessage))
    }
  })

  const handleToggleGlobalModal = (message: IframeMessage) => {
    const { params } = message
    const { type, show, subType, iframeID } = params

    // if show is true and iframeID and currentAppIframeID are different, ignore the message
    // If there is more than one widget on the page, we only want to show the modal for the widget that sent the message
    if (show && iframeID && iframeID !== currentAppIframeID) {
      return
    }

    if (!modalState.hasOwnProperty(type) || !subType) {
      console.error("Invalid modal type:", type)
      return
    }

    const currentTypeState = modalState[type] as GlobalModalSubType[]
    const shouldUpdate = show
      ? !currentTypeState.includes(subType)
      : currentTypeState.includes(subType)

    if (!shouldUpdate) return

    const updatedTypeState = show
      ? [...currentTypeState, subType]
      : currentTypeState.filter(item => item !== subType)

    if (show) {
      addScrollLock()

      // if subType is DASH_CONFIRM_MODAL save to local storage
      if (subType === "EXIT_POP_UP_MODAL") {
        setExitPopUpSeen()
      } else if (subType === "DASH_CONFIRM_MODAL") {
        // if we are showing the dashboard confirmation modal it means the user has subscribed
        setExitPopUpSubscribed()
      }
    } else {
      removeScrollLock()
    }

    setModalState(prevState => {
      const newState = {
        ...prevState,
        [type]: updatedTypeState
      }

      sendMessageToIframe(iframeID ?? currentAppIframeID, {
        action: UPDATE_GLOBAL_MODAL_IFRAME_STATE,
        params: { modalState: newState }
      })

      return newState
    })
  }

  const handleCloseGlobalModal = () => {
    setModalState(DEFAULT_MODAL_STATE)
    removeScrollLock()
  }

  const handlePopGlobalModal = (message: IframeMessage) => {
    const { params } = message
    const { type, iframeID } = params
    if (modalState[type].length > 1) {
      const newGlobalModal = modalState[type].slice(0, -1)

      setModalState(prevState => {
        const newState = {
          ...prevState,
          [type]: newGlobalModal
        }

        sendMessageToIframe(iframeID ?? currentAppIframeID, {
          action: UPDATE_GLOBAL_MODAL_IFRAME_STATE,
          params: { modalState: newState }
        })

        return newState
      })
    }
  }

  const show = Object.values(modalState).some(
    modalArray => modalArray.length > 0
  )

  const showModalStyle = show
    ? "z-index: 2147483648;"
    : "z-index: 0; opacity: 0; pointer-events: none; display: none;"

  return {
    modalStyles: css`
      ${BASE_STYLES} ${showModalStyle},
      label: ${currentAppIframeID}
    `
  }
}

export default useGlobalModalFrame
