import { h } from "preact"
import "@styles/components/_row-loader.scss"

const RowLoader = ({ rows = 3, items = 5 }) => (
  <div className="pricing-rows">
    {[...Array(rows)].map(() => (
      <div className="pricing-item tw-flex tw-items-center is-row-loader datagrid__row">
        {[...Array(items)].map(item => (
          <div
            key={item}
            className="datagrid__loader"
            style={`width: ${100 / items}%;`}
          ></div>
        ))}
      </div>
    ))}
  </div>
)

export default RowLoader
