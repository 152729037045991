import { h } from "preact"
import { useSelector } from "react-redux"
import { Button, Icon } from "@ui"
import ImgRightArrow from "@assets/icons/right-arrow-alt.svg"
import { RootStates } from "@types"
import "@styles/components/_dash-confirm.scss"

const DashConfirmInner = () => {
  const lead_id = useSelector(({ app }: RootStates) => app?.lead_id)

  const link = `${process.env.DASHBOARD_URL}/new-subscription?lead_id=${lead_id}&active=0&source=embed`

  return (
    <div className="dc-inner">
      <div className="dc-inner__main">
        <div className="dc-inner__main--top">
          <div className="dc-inner__main--top--title">Ready to go!</div>
          <div className="dc-inner__main--top--text">
            Check out your personalized rate dashboard designed exclusively for
            your needs.
          </div>
          <div className="dc-inner__main--top--btn">
            <Button href={link} target="_blank">
              Go to my dashboard
              <Icon
                className=""
                src={ImgRightArrow}
                size={20}
                originalSize={20}
              />
            </Button>
          </div>
        </div>

        <div className="dc-inner__main--bottom">
          <div className="dc-inner__main--bottom--title">Next steps</div>
          <div className="dc-inner__main--bottom--text">
            1. Visit your{" "}
            <a href={link} target="_blank">
              personalized dashboard
            </a>
          </div>
          <div className="dc-inner__main--bottom--text">
            2. You can expect a call from our first available mortgage expert
            shortly
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashConfirmInner
