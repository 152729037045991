import axios from "axios"
import { APICallWithCache, bbProcessEnv } from "@helpers"
import * as Sentry from "@sentry/react"

let count = 0

export default {
  getWizardSteps: async () => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/lead_workflow`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  getPricing: async (rateflow_id, formData) => {
    let url = `${bbProcessEnv.API_URL}/rateflow/price.json`
    const params = {}
    if (rateflow_id) {
      params.id = rateflow_id
    }

    try {
      const response = await APICallWithCache({
        url,
        params: {
          ...formData,
          ...params
        },
        method: "post",
        cacheEnabled: bbProcessEnv.PRICING_CACHE_ENABLE
      })

      if (response?.status === "error" || response.data?.status === "error") {
        console.error("Error", response?.message ?? response?.data?.message)
        return []
      }

      if (!Array.isArray(response)) {
        Sentry.captureException(
          new Error(
            "Pricing API response is not an array, fallback to empty array."
          ),
          {
            extra: {
              responseValue: response,
              rateflow_id,
              formData,
              count
            }
          }
        )
        count++
        return []
      }

      // Reset counter on successful array response
      count = 0
      return response
    } catch (error) {
      console.log(error)
    }
  },
  getFeesPdf: async params => {
    if (!params.quote_id) {
      return null
    }
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/loan-summary-pdf`,
        { params }
      )

      const { file, label } = response.data

      const pdfResponse = await axios({
        url: file,
        method: "GET",
        responseType: "blob" // important
      })

      const url = window.URL.createObjectURL(new Blob([pdfResponse.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `${label}.pdf`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.log("Error downloading loan summary:", error)
      throw error // Re-throw the error for handling in the calling code
    }
  }
}
