import zillowIconCurrentColor from "@assets/logos/zillow-logo-current-color.svg"
import yelpIconCurrentColor from "@assets/logos/yelp-logo-current-color.svg"
import googleIconCurrentColor from "@assets/logos/google-logo-current-color.svg"
import experienceIconCurrentColor from "@assets/logos/experience-logo-current-color.svg"
import zillowIcon from "@assets/logos/zillow-logo-color.svg"
import yelpIcon from "@assets/logos/yelp-logo-color.svg"
import googleIcon from "@assets/logos/google-logo-color.svg"
import experienceIcon from "@assets/logos/experience-logo-color.svg"

export const REVIEWS_PROVIDERS = [
  "google",
  "zillow",
  "experience",
  "yelp"
] as const

export const REVIEWS_ICONS = {
  yelp: {
    icon: yelpIcon,
    iconCurrentColor: yelpIconCurrentColor,
    iconSize: [20, 23],
    iconSizeMD: [26, 34],
    iconOriginalSize: [22, 25],
    iconCurrentColorOriginalSize: [22, 25]
  },
  google: {
    icon: googleIcon,
    iconCurrentColor: googleIconCurrentColor,
    iconSize: [20, 20],
    iconSizeMD: [30, 30],
    iconOriginalSize: [24, 24],
    iconCurrentColorOriginalSize: [24, 24]
  },
  zillow: {
    icon: zillowIcon,
    iconCurrentColor: zillowIconCurrentColor,
    iconSize: [20, 20],
    iconSizeMD: [28, 31],
    iconOriginalSize: [24, 24],
    iconCurrentColorOriginalSize: [24, 24]
  },
  experience: {
    icon: experienceIcon,
    iconCurrentColor: experienceIconCurrentColor,
    iconSize: [20, 26],
    iconSizeMD: [25, 32],
    iconOriginalSize: [612, 792],
    iconCurrentColorOriginalSize: [20, 23]
  }
}
