import { h, Fragment } from "preact"
import { useEffect, useRef } from "preact/hooks"
import StepInput from "./StepInput"
import BackBtn from "./BackBtn"
import { If } from "@ui"

const StepLoop = ({
  steps,
  onInput,
  onClickContinue,
  currentStep,
  values,
  onClickBack,
  validationRequired,
  forceExitCTA
}) => {
  const activeRef = useRef(null)
  useEffect(() => {
    if (activeRef.current) {
      activeRef.current.querySelector("h2")?.focus()
    }
  }, [currentStep, activeRef])

  const renderItem = (item, index) => {
    if (item.type === "form_verification") {
      const verification_type = values.login_form.form["verification_type"]
        ? "a text to your mobile phone"
        : "an e-mail to your inbox"
      item.title = `We've sent ${verification_type}. Please enter the 6 digit verification code below.`
    }

    const title =
      item.id === "login_form" && !validationRequired ? null : item.title

    const isActiveStep = !forceExitCTA && currentStep === index
    const activeClassName = isActiveStep ? "wizard__step--active" : ""

    const extraProps = {}

    if (isActiveStep) {
      extraProps.ref = activeRef
    }

    return (
      <div
        {...extraProps}
        key={item.id + index}
        className={`wizard__step accent-border-color ${activeClassName} is-step-${index} is-${item.type}`}
      >
        <div className="wizard__step-container">
          <If
            condition={currentStep > 0 && item.type !== "end"}
            then={<BackBtn onClick={onClickBack} />}
          />
          <StepInput
            index={index}
            title={title}
            step={item}
            onInput={onInput}
            goBack={onClickBack}
            onClickContinue={onClickContinue}
          />
        </div>
      </div>
    )
  }

  return <Fragment>{steps.map(renderItem.bind(this))}</Fragment>
}

export default StepLoop
