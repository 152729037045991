import { h } from "preact"
import { toFixed } from "@helpers"

interface Props {
  className?: string
  rate: number
  apr: number
}

const APRRate = ({ apr, rate, className = "" }: Props) => (
  <strong className={className}>
    {toFixed(rate, 3)}% / {toFixed(apr)}%
  </strong>
)

export default APRRate
