import { h } from "preact"
import { useState, useEffect, useRef, useMemo } from "preact/hooks"
import { Manager, Reference, Popper } from "react-popper"
import clsx from "clsx"
import { createPortal } from "preact/compat"
import { Tooltip } from "@composite"
import { Chevron, Icon } from "@ui"
import Option from "./Option"

const SelectCustom = ({
  isIconLabel = false,
  multiple = false,
  name,
  options,
  placeholder = "Please select",
  hideLabel = false,
  label,
  className = "",
  optionsClassName = "",
  labelClassName = "",
  tooltip = "",
  showPlaceholder = false,
  showRequired = false,
  required = false,
  transparent = false,
  notStacked = false,
  isMaterial = false,
  isMenu = false,
  size = "md", // sm, md, lg
  onFocus = e => {},
  onChange = (option, e) => {},
  onBlur = e => {},
  value,
  showErrorWhenEmpty = false,
  hasHelpTxt = false,
  inputAutoWidth = false,
  popperPlacement = "bottom-end"
}) => {
  const getValueArray = val => {
    var newVal = val.map(valItem => {
      return valItem.label || valItem
    })
    return newVal
  }

  const getValue = val => {
    let tmpVal = options.find(x => {
      const item = x.value !== undefined ? x.value : x
      return item === val || item === Number(val)
    })
    return tmpVal?.label || tmpVal || ""
  }

  // Local state
  const [materialClassName, setMaterialClassName] = useState([
    value?.length || typeof value === "number" || typeof value === "boolean"
      ? "is-typed"
      : ""
  ])
  const [optionsListClassName, setOptionsListClassName] = useState("d-none")
  const [showOptions, setShowOptions] = useState(false)
  const [readonly, setReadonly] = useState(false)
  const [valueLcl, setValue] = useState()
  const [eleID, setEleID] = useState("")

  const selectedOption = useMemo(
    () =>
      options.find(x => {
        const item = x.value || x
        return item === value || item === +value
      }),
    [options, value]
  )

  // Elements reference
  const wrapperRef = useRef(null)
  const ulRef = useRef(null)

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    const id = (typeof label === "string" && label.length && label) || name
    setEleID(id?.replace(/ +?/g, ""))

    // Remove listener
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  useEffect(() => {
    let val
    if (Array.isArray(value)) {
      val = getValueArray(value)
    } else {
      val = getValue(value)
    }
    setValue(val)

    toggleMaterialClassNameIfTyped(val)
  }, [value, options])

  const handleClickOutside = event => {
    if (
      wrapperRef.current?.contains(event.target) ||
      ulRef.current?.contains(event.target)
    ) {
      return
    } else {
      setOptionsListClassName("slide-out-top")
      setReadonly(false)

      setShowOptions(false)
    }
  }

  const toggleOptions = () => {
    setReadonly(!showOptions)
    setOptionsListClassName(showOptions ? "slide-out-top" : "slide-in-top-fast")
    setShowOptions(!showOptions)
  }

  const handleClick = (newValue, event) => {
    let val = newValue

    if (multiple) {
      const current = value || []
      let index
      if (val.label) {
        const item = current.find((element, i) => {
          index = i
          return element.label === val.label
        })
        index = item ? index : -1
      } else {
        index = current.indexOf(val)
      }

      if (index > -1) {
        val = [...current.slice(0, index), ...current.slice(index + 1)]
      } else {
        val = [val, ...current]
      }
    } else {
      toggleOptions()
    }

    toggleMaterialClassNameIfTyped(val)

    onChange?.(val, event)
  }

  const handleKeypress = e => {
    if (e.code === "Enter") {
      toggleOptions()
    }
    e.preventDefault()
    return false
  }

  const handleFocus = event => {
    toggleMaterialClassName("has-focus", true)
    setReadonly(true)

    onFocus?.(event)
  }

  const handleBlur = event => {
    toggleMaterialClassName("has-focus")
    setReadonly(false)

    onBlur?.(event)
  }

  const toggleMaterialClassNameIfTyped = val => {
    toggleMaterialClassName(
      "is-typed",
      (val !== undefined && val !== null && val !== "") ||
        (val.length !== undefined && val.length > 0) ||
        (val.value !== undefined && val.value !== null)
    )
  }

  const toggleMaterialClassName = (className, add) => {
    const tmpMaterialClassName = [...materialClassName]
    const index = tmpMaterialClassName.indexOf(className)
    if (add && index === -1) {
      tmpMaterialClassName.push(className)
    } else if (!add && index > -1) {
      tmpMaterialClassName.splice(index, 1)
    }
    setMaterialClassName([...tmpMaterialClassName])
  }

  const optionSelected = (e, option) => {
    if (option !== value) {
      handleClick(option, e)
    }
  }

  const handleKeyDown = (code, index) => {
    if (code === "Enter") {
      ulRef.current.children[index].click()
    }
  }

  const wrapperClassName = clsx(
    "form__select_custom form__field",
    `is-${size}`,
    className,
    materialClassName.join(" "),
    {
      "is-material": isMaterial,
      "is-stacked": !notStacked || isMaterial,
      "d-flex": notStacked && !isMaterial && !isIconLabel,
      "is-transparent": transparent,
      "is-required": required,
      "is-menu": isMenu,
      "is-icon-label": isIconLabel
    }
  )

  const optionsWrapperClassName = clsx(
    "form__select-options-wrapper",
    optionsClassName,
    { "has-help-txt": hasHelpTxt, "is-icon-label": isIconLabel }
  )

  const isInvalidClassName =
    showErrorWhenEmpty &&
    (value === null || value === undefined || value === "")
      ? "is-invalid"
      : ""

  const inputValue = Array.isArray(valueLcl) ? valueLcl.join(", ") : valueLcl

  return (
    <Manager>
      <div
        ref={wrapperRef}
        className={wrapperClassName}
        {...(isMenu ? { tabIndex: "0", onKeypress: handleKeypress } : {})}
      >
        {label && (
          <label
            htmlFor={eleID}
            className={`form__label ${labelClassName} ${
              hideLabel ? "tw-sr-only" : ""
            }`}
          >
            <span class="form__label-txt">{label}</span>
            {showRequired && <span className="text-danger"> *</span>}
            {tooltip && <Tooltip>{tooltip}</Tooltip>}
          </label>
        )}
        <div className="form__select-wrapper">
          <Reference>
            {({ ref }) => (
              <div className="form__select-input-wrapper" ref={ref}>
                {selectedOption?.icon && (
                  <div className="form__select-input-icon-wrapper">
                    <Icon
                      className="form__select-icon"
                      src={selectedOption.icon}
                      size={selectedOption.iconSize ?? 20}
                      originalSize={selectedOption.iconOriginalSize}
                    />
                  </div>
                )}
                <input
                  // if inputAutoWidth is true, set the style width of the input to the inputValue length
                  style={
                    inputAutoWidth
                      ? { width: `${(inputValue?.length ?? 0) + 4}ch` }
                      : {}
                  }
                  autoComplete="off"
                  name={name}
                  id={eleID}
                  onClick={toggleOptions}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onKeypress={handleKeypress}
                  className={`form__input ${isInvalidClassName} ${
                    readonly || isMenu
                      ? "form__input--readonly"
                      : "form__input--editable"
                  } ${isIconLabel ? "d-none" : ""}`}
                  value={inputValue}
                  type="text"
                  required={required}
                  placeholder={
                    !isMaterial && showPlaceholder && placeholder
                      ? placeholder
                      : null
                  }
                  tabIndex={readonly || isMenu ? -1 : 0}
                />
              </div>
            )}
          </Reference>

          <div
            className={`form__select-chevron ${isIconLabel ? "d-none" : ""}`}
            onClick={toggleOptions}
            onKeypress={handleKeypress}
          >
            <Chevron />
          </div>

          {showOptions &&
            createPortal(
              <Popper placement={popperPlacement}>
                {({ ref, style, placement }) => (
                  <div
                    className={optionsWrapperClassName}
                    ref={ref}
                    style={{
                      ...style,
                      width: isIconLabel
                        ? "auto"
                        : wrapperRef.current.offsetWidth,
                      top: isIconLabel ? 12 : style.top,
                      left: isIconLabel ? 2 : style.left
                    }}
                    data-placement={placement}
                  >
                    <ul
                      className={`form__select-options ${optionsListClassName}`}
                      aria-labelledby={eleID}
                      ref={ulRef}
                    >
                      {showPlaceholder && (
                        <li
                          className="form__select-option is-placeholder"
                          tabIndex="0"
                        >
                          {placeholder}
                        </li>
                      )}
                      {options.map((option, index) => {
                        return (
                          <Option
                            key={`${eleID}-${index}`}
                            index={index}
                            option={option}
                            handleKeyDown={handleKeyDown}
                            optionSelected={optionSelected}
                            value={valueLcl}
                          />
                        )
                      })}
                    </ul>
                  </div>
                )}
              </Popper>,
              document.body
            )}
        </div>
      </div>
    </Manager>
  )
}

export default SelectCustom
