const formatLocation = (location: string) =>
  location
    .split(/\s*,\s*/)
    .slice(0, 2)
    .join(", ")

const formatZipcode = (value: string) => value.replace(/\D/g, "")

const formatLocationOption = (val: string, location: string) => {
  let limit = ", USA"
  if (/^es\b/.test(navigator.language)) {
    limit = ", EE. UU."
  }
  location = location.substring(0, location.lastIndexOf(limit)) //remove the country
  // Escape special regex characters in the input value
  const escapedVal = val.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  const re = new RegExp(escapedVal, "gi") // reg exp based on the input value
  return location.replace(re, "<strong>$&</strong>")
}

export { formatLocation, formatLocationOption, formatZipcode }
