import { ComponentChildren, h } from "preact"
import "@styles/components/_inline-rate-table.scss"

interface Props {
  children: ComponentChildren
  className?: string
}

const InlineRateTable = ({ children, className = "" }: Props) => (
  <div className={`inline-rate-table ${className}`}>{children}</div>
)

export default InlineRateTable
