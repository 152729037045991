import { h } from "preact"
import { useEffect, useState } from "preact/hooks"
import Button from "../../ui/Button"
import { If } from "@ui"
import Chevron from "../../ui/Chevron"

interface Props {
  text?: string
  className?: string
  btnClassName?: string
  lengthLimit?: number
  interval?: number
  labelMore?: string
  labelLess?: string
  showBtnIcon?: boolean
}

const ExpandableContent = ({
  text = "",
  className = "",
  btnClassName = "",
  lengthLimit = 300,
  interval = 30,
  labelMore = "Read more",
  labelLess = "Show less",
  showBtnIcon = true
}: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [displayedText, setDisplayedText] = useState(
    `${text.slice(0, lengthLimit)}…`
  )

  const toggle = () => {
    setExpanded(prev => !prev)
  }

  useEffect(() => {
    let currentLength = expanded ? lengthLimit : text.length
    const targetLength = expanded ? text.length : lengthLimit
    const stepDirection = expanded ? 1 : -1 // Expand or collapse
    const stepSize = Math.ceil(Math.abs(targetLength - currentLength) / 4) // Divide into 4 steps

    // Skip the animation if no change is needed
    if (expanded && displayedText === text) return
    if (!expanded && displayedText === `${text.slice(0, lengthLimit)}…`) return

    const intervalId = setInterval(() => {
      if (
        (expanded && currentLength >= targetLength) || // Fully expanded
        (!expanded && currentLength <= targetLength) // Fully collapsed
      ) {
        clearInterval(intervalId)
        setDisplayedText(expanded ? text : `${text.slice(0, lengthLimit)}…`) // Ensure exact end state
      } else {
        currentLength += stepDirection * stepSize
        setDisplayedText(
          expanded
            ? text.slice(0, Math.min(currentLength, text.length))
            : text.slice(0, Math.max(currentLength, lengthLimit)) + "…"
        )
      }
    }, interval)

    return () => clearInterval(intervalId)
  }, [expanded, text, lengthLimit, interval])

  return (
    <div className={className}>
      <div dangerouslySetInnerHTML={{ __html: displayedText }} />

      <Button adapt link onClick={toggle} className={btnClassName}>
        <span className="tw-inline-block tw-pr-1">
          {expanded ? labelLess : labelMore}
        </span>
        <If
          condition={showBtnIcon}
          then={
            <Chevron
              className={expanded ? "tw-top-0.5" : ""}
              side={expanded ? "top" : "bottom"}
              animated
              theme="primary"
            />
          }
        />
      </Button>
    </div>
  )
}

export default ExpandableContent
