import { Fragment, h } from "preact"
import { Button, Icon, Picture } from "@ui"
import CHECK_THIN from "@assets/icons/check-thin.svg"
import "@styles/components/_subscription-banner.scss"

interface Props {
  className?: string
  subscribed: boolean
  updateSubscription: (subscribed: boolean) => void
}

const SubscriptionBanner = ({
  className = "",
  subscribed,
  updateSubscription
}: Props) => (
  <div
    className={`subscription-banner tw-flex tw-relative lg:tw-justify-between tw-items-end tw-px-8 md:tw-px-16 lg:tw-px-24 tw-w-full ${className}`}
  >
    <div className="tw-pb-9 lg:tw-pb-[70px]">
      <h3 className="txt-dark font-bolder tw-text-[1.875rem] md:tw-text-[2.5rem] tw-leading-tight tw-mb-0">
        Stay on top of rate changes. <br />
        Subscribe to weekly rate alerts.
      </h3>

      <ul className="txt-dark font-sm subscription-banner__list tw-hidden sm:tw-flex">
        <li>Rates delivered weekly</li>
        <li>Crunch the numbers</li>
        <li>Stay in the know</li>
      </ul>

      <Button
        className={`tw-w-52 tw-mt-6 md:tw-mt-10 gap-sm ${
          subscribed ? "tw-pointer-events-none" : ""
        }`}
        noShadow
        adapt
        noAction={subscribed}
        success={subscribed}
        onClick={() => updateSubscription(true)}
        size="2xl"
      >
        {subscribed ? (
          <Fragment>
            <Icon size={24} originalSize={24} src={CHECK_THIN} /> Subscribed
          </Fragment>
        ) : (
          "Get rate alerts"
        )}
      </Button>
    </div>

    <Picture
      className="tw-w-[325px] tw-h-[357px] tw-hidden xl:tw-block"
      source="assets/banners/banner-phone.webp"
      imgSrc="assets/banners/banner-phone.png"
      alt="phone with rates"
    />
  </div>
)

export default SubscriptionBanner
