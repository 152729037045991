import { useRef, useEffect } from "preact/hooks"

function isIOSBelow154() {
  const ua = window.navigator.userAgent
  const isIOS =
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.maxTouchPoints > 0 && navigator.platform === "MacIntel")

  if (!isIOS) return false

  const match = ua.match(/OS (\d+)_(\d+)/)
  if (!match) return false

  const majorVersion = parseInt(match[1], 10)
  const minorVersion = parseInt(match[2], 10)

  // Return true if iOS version is below 15.4
  return majorVersion < 15 || (majorVersion === 15 && minorVersion < 4)
}

const useGooglePlacesService = () => {
  const autocompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null)

  useEffect(() => {
    // Add polyfill for iOS < 15.4
    if (isIOSBelow154() && !window.HTMLDialogElement) {
      class DialogElement extends HTMLElement {
        open: boolean
        returnValue: string

        constructor() {
          super()
          this.open = false
          this.returnValue = ""
        }

        show() {
          this.open = true
        }

        close() {
          this.open = false
        }

        showModal() {
          this.open = true
        }
      }

      window.HTMLDialogElement = DialogElement
    }

    const checkGoogleAPI = () => {
      try {
        if (window.google?.maps?.places) {
          if (!autocompleteService?.current) {
            autocompleteService.current =
              new window.google.maps.places.AutocompleteService()
          }
          return true
        }
        return false
      } catch (error) {
        console.error("Error initializing Places API:", error)
        return false
      }
    }

    if (!checkGoogleAPI()) {
      const intervalId = setInterval(() => {
        if (checkGoogleAPI()) {
          clearInterval(intervalId) // Clear interval once Google API is loaded
        }
      }, 100) // Check every 100ms

      return () => clearInterval(intervalId) // Clean up on unmount
    }
  }, [])

  return { autocompleteService }
}

export default useGooglePlacesService
