import { h } from "preact"
import { Alert } from "@ui"

const SubmitErrorMsg = () => (
  <Alert.Danger id="submit-error-msg">
    There was an error, please try again later.
  </Alert.Danger>
)

export default SubmitErrorMsg
