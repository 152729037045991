import { h } from "preact"
import { useAppContext } from "@contexts"
import { GetMyQuoteBtn } from "@ui"

interface Props {
  btnAdapt?: boolean
  size?: "sm" | "md"
}

const CTAButton = ({ btnAdapt, size }: Props) => {
  const {
    media: {
      flags: { isMobile }
    }
  } = useAppContext()

  return (
    <GetMyQuoteBtn
      className="cta-button"
      adapt={!isMobile && btnAdapt}
      {...(size === "sm" ? { size: "sm" } : {})}
      {...(size === "md" ? { size: "md" } : {})}
    />
  )
}

export default CTAButton
