import { h, ComponentChildren, createContext } from "preact"
import { useContext, useEffect, useState } from "preact/hooks"
import {
  DynamicFormConfigResponse,
  DynamicFormFields,
  DynamicFormConfig
} from "@types"
import { contactFormAPI } from "@api"
import { gtagFormInit } from "./DynamicFormGtagFunctions"

interface DynamicFormContextProps {
  fields: DynamicFormFields
  submitLabel: string
  isLoading: boolean
  showLeftColumn: boolean
  bgColor: string
  captchaEnabled: boolean
  title: string
  subtitle: string
}

const DynamicFormContext = createContext<DynamicFormContextProps>({
  fields: [],
  title: "",
  subtitle: "",
  submitLabel: "",
  isLoading: true,
  showLeftColumn: false,
  bgColor: "",
  captchaEnabled: false
})

interface DynamicFormProviderProps {
  children: ComponentChildren
}

const DynamicFormProvider = ({ children }: DynamicFormProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState<DynamicFormConfig>()

  useEffect(() => {
    contactFormAPI
      .get()
      .then((data: DynamicFormConfigResponse | undefined) => {
        if (!data) {
          console.error("Failed to fetch contact form config")
          return
        }
        setConfig(data?.config)

        gtagFormInit()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <DynamicFormContext.Provider
      value={{
        isLoading,
        title: config?.title ?? "",
        subtitle: config?.subtitle ?? "",
        fields: config?.fields ?? [],
        submitLabel: config?.submit.label ?? "",
        showLeftColumn: config?.show_left_column ?? false,
        bgColor: config?.bg_color ?? "",
        captchaEnabled: config?.captcha_enabled ?? false
      }}
    >
      {children}
    </DynamicFormContext.Provider>
  )
}

const useDynamicForm = () => useContext(DynamicFormContext)

export { DynamicFormProvider, useDynamicForm }
