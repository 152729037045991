import { Fragment, h } from "preact"
import { useSelector } from "react-redux"
import { RootStates } from "@types"
import { TabsWithLeadWorkflowSkeleton } from "@ui"
import BTTabs from "./BTTabs"

const PrimaryBTTabs = () => {
  const leadWorkflowIsLoading = useSelector(({ app, wizard }: RootStates) => {
    const mountInlineLeadWorkflow =
      app.widgets?.mountInlineLeadWorkflow ?? false

    let leadWorkflowIsLoading = false

    if (mountInlineLeadWorkflow) {
      leadWorkflowIsLoading = wizard?.isLoading ?? false
    }

    return leadWorkflowIsLoading
  })

  return (
    <Fragment>
      <TabsWithLeadWorkflowSkeleton
        className={leadWorkflowIsLoading ? "" : "tw-hidden"}
      />
      <BTTabs className={leadWorkflowIsLoading ? "tw-hidden" : ""} />
    </Fragment>
  )
}

export default PrimaryBTTabs
