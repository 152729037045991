import axios from "axios"
import { getUrlVars, wildcardTest, bbProcessEnv } from "@helpers"
import { getConfig } from "../../config"
import { getParentURL } from "../../message-bridge"

export default {
  leadManage: async ({
    formData,
    srcComponent,
    lead_id = null,
    callback = payload => {}
  }) => {
    const { custom_params, leadDetails, cookies = {} } = getConfig()

    // check if there is extra lead params
    if (leadDetails) {
      formData.details = { ...formData.details, ...leadDetails }
    }

    if (
      typeof LeadiD != "undefined" ||
      document.getElementById("leadid_token")?.value
    ) {
      let leadId = ""
      // first try the global var LeadiD
      if (typeof LeadiD != "undefined") {
        leadId = LeadiD?.token
        if (leadId) {
          console.log("get found leadid using the global var")
        }
      }
      // then try polling the form element value
      const start_time = +new Date()
      while (!leadId) {
        console.log("polling for leadid...")
        leadId = document.getElementById("leadid_token")?.value
        if (+new Date() - start_time > 2500) {
          break
        }
      }
      // then try the cookie
      if (!leadId) {
        document?.cookie?.split(";").forEach(item => {
          if (item?.includes("leadid_token")) leadId = item?.split("=")[1]
        })
      } else {
        console.log(
          "we found leadid! it took " + (+new Date() - start_time) + " ms"
        )
      }
      if (leadId) formData.details["jornaya_lead_id"] = leadId
    }

    // Add source component
    formData.source_component = srcComponent

    if (lead_id) {
      formData.lead_id = lead_id
    }

    // Get the parent URL and then make the call
    return getParentURL(window).then(parent_url => {
      const urlVars = getUrlVars(parent_url)

      const params_to_send = { ...cookies, ...{ ...(urlVars ?? {}) } }
      if (custom_params && params_to_send) {
        formData = mergeCustomParams(custom_params, params_to_send, formData)
      }

      return leadManage(parent_url, formData, callback)
    })
  },
  leadResend: async formData => {
    try {
      const response = await axios.post(
        `${bbProcessEnv.API_URL}/lead-validate.json`,
        {
          auth_request_id: formData.id,
          ...formData
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  leadValidate: async (code, id) => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/lead-validate.json`,
        {
          params: {
            code: code,
            auth_request_id: id
          },
          withCredentials: true
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  leadContact: async (formData, bb_sid) => {
    try {
      const response = await axios.post(
        `${bbProcessEnv.API_URL}/api/v1/contact-lo?bb_sid=${bb_sid}`,
        {
          ...formData
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}

const leadManage = async function (parent_url, formData, callback) {
  formData.details.parent_url = parent_url

  try {
    const { data } = await axios.post(
      `${bbProcessEnv.API_URL}/lead-manage.json`,
      formData
    )

    const payload = { ...data, ...formData, new_lead_id: data.lead_id }

    if (!payload.lead_id) {
      payload.lead_id = data.lead_id
    }

    callback?.(payload)

    return data
  } catch (error) {
    console.log(error)
  }
}

const mergeCustomParams = (custom_params, params_to_send, formData) => {
  const vars = custom_params.filter(param => param.scope === "lead")
  const parentKeys = Object.keys(params_to_send)
  const customVars = {}

  for (let index = 0; index < vars.length; index++) {
    const key = vars[index].key
    // Check if it's a wildcard
    if (key.indexOf("*") === -1) {
      if (params_to_send[key]) {
        customVars[key] = params_to_send[key]
      }
    } else {
      // Check if the wildcard is in the parent params
      const wildParams = parentKeys.filter(parentKey =>
        wildcardTest(key, parentKey)
      )
      if (wildParams.length) {
        wildParams.forEach(wildParam => {
          if (params_to_send[wildParam]) {
            customVars[wildParam] = params_to_send[wildParam]
          }
        })
      }
    }
  }

  // Merge the custom vars with the forms vars
  formData = {
    ...formData,
    details: {
      ...formData.details,
      ...customVars
    }
  }
  return formData
}
