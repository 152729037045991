import { h } from "preact"
import If from "../../../If"
import Switch from "../Switch"

type Props = {
  className?: string
  name: string
  label: string
  helpText?: string
  sm?: boolean
  checked?: boolean
  handleOnChange: (checked: boolean, name: string) => void
}

const SwitchField = ({
  className = "",
  name,
  label,
  helpText,
  sm = true,
  checked = false,
  handleOnChange
}: Props) => (
  <div className={`toggle-group ${className}`}>
    <div className="d-flex flex-column mr-3">
      <span className="toggle-group__label">{label}</span>
      <If
        condition={!!helpText}
        then={<small className="help-text">{helpText}</small>}
      />
    </div>
    <Switch
      sm={sm}
      checked={checked}
      onChange={e =>
        handleOnChange((e.target as HTMLInputElement)?.checked, name)
      }
    />
  </div>
)

export default SwitchField
