import { Fragment, h } from "preact"
import { useState } from "preact/hooks"
import { useSelector } from "react-redux"
import { getFeesPdf } from "../../../../sagas/wizard"
import { RootStates } from "@types"
import { convertToNumber } from "@helpers"
import { Icon, Loader, Button, If } from "@ui"
import ArrowDownloadIcon from "@assets/icons/arrow-download.svg"

const ClosingCostPDF = () => {
  const {
    currentDetailsCard,
    insurance,
    taxes,
    subscription_id,
    hoaDues,
    closing_cost_credit
  } = useSelector(
    ({
      app: { currentDetailsCard, taxesAndInsurance, formData },
      subscriptionDash,
      mortgageCalc
    }: RootStates) => {
      const bb_sid = subscriptionDash?.bb_sid ?? ""

      const { taxes, insurance } = taxesAndInsurance ?? {}

      const totalPayment =
        convertToNumber(currentDetailsCard?.principalAndInterest) +
        convertToNumber(taxes) +
        convertToNumber(insurance) +
        convertToNumber(currentDetailsCard?.monthlyMI)

      return {
        currentDetailsCard,
        totalPayment,
        bb_sid,
        subscription_id:
          subscriptionDash && subscriptionDash.id ? subscriptionDash.id : 0,
        insurance: taxesAndInsurance?.insurance,
        taxes: taxesAndInsurance?.taxes,
        hoaDues: mortgageCalc?.hoaDues ?? 0,
        closing_cost_credit:
          formData?.loan_purpose === "refinance"
            ? 0
            : mortgageCalc?.closing_cost_credit ?? 0
      }
    }
  )

  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadPdf = async () => {
    try {
      setIsLoading(true)

      await getFeesPdf({
        quote_id: currentDetailsCard?.quote_id,
        subscription_id,
        card_hash:
          currentDetailsCard && currentDetailsCard.hash
            ? currentDetailsCard.hash
            : "",
        insurance,
        taxes,
        hoa_fees: hoaDues,
        closing_cost_credit
      })
    } catch (error) {
      console.error("Error:", error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Fragment>
      <Button
        adapt
        link
        className="tw-text-sm tw-flex tw-ml-auto tw-font-semibold tw-mb-2"
        onClick={handleDownloadPdf}
      >
        <Icon
          className="tw-mr-2"
          src={ArrowDownloadIcon}
          size={18}
          originalSize={[122.88, 120.89]}
        />
        Closing cost PDF
      </Button>
      <If
        condition={isLoading}
        then={
          <div className="tw-flex tw-justify-center">
            <Loader
              className="block-loader"
              label={
                <div className="loader-label tw-pt-4">
                  Your worksheet is being generated.
                  <br />
                  This might take a few seconds.
                </div>
              }
            />
          </div>
        }
      />
    </Fragment>
  )
}

export default ClosingCostPDF
