import { h } from "preact"
import { Form } from "@ui"
import { useAppContext } from "@contexts"
import { useCallback, useMemo } from "preact/hooks"
import { PricingItem } from "@types"

interface Props {
  item: PricingItem
  className?: string
}

const CompareCheckbox = ({ item, className = "" }: Props) => {
  const {
    comparisonTool: {
      addItemToCompare = () => {},
      removeItemToCompare = () => {},
      isItemInComparison = () => false,
      itemsToCompare = []
    } = {}
  } = useAppContext()

  const checked = useMemo(
    () => isItemInComparison(item),
    [item.hash, itemsToCompare, isItemInComparison]
  )

  const toggleItemToCompare = useCallback(() => {
    if (checked) {
      removeItemToCompare(item)
    } else {
      addItemToCompare(item)
    }
  }, [checked, item, addItemToCompare, removeItemToCompare])

  return (
    <Form.Checkbox
      className={`rt-checkbox ${className}`}
      name={`compare-${item.hash}`}
      id={`compare-${item.hash}`}
      checked={checked}
      onChange={toggleItemToCompare}
      solid
    >
      Compare
    </Form.Checkbox>
  )
}

export default CompareCheckbox
