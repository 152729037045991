import { Fragment, h } from "preact"
import RoundedRowsSkeleton from "../RoundedRows"
import TxtLoading from "../Txt"
import { useIsMobile } from "@contexts"
import { If } from "@ui"

interface Props {
  rows?: number
  hasTitle?: boolean
}

const RateCardsRowsSkeleton = ({ rows = 4, hasTitle = false }: Props) => {
  const isMobile = useIsMobile()

  return (
    <Fragment>
      <If
        condition={hasTitle}
        then={
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-1 tw-pb-6">
            <TxtLoading height={24} width={215} borderRadius={5} />
          </div>
        }
      />
      <RoundedRowsSkeleton rows={isMobile ? 2 : rows} />
    </Fragment>
  )
}

export default RateCardsRowsSkeleton
