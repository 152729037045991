import { purchaseEscrowMultiples, refinanceEscrowMultiples } from "@constants"
import { FeeItem, LoanPurpose } from "@types"
import { toFixed } from "./numbers"
/*
 * Calculations
 */
export const calcDownPayment = ({
  homePrice,
  downPercentage
}: {
  homePrice: number
  downPercentage: number
}) => Math.round((homePrice * downPercentage) / 100)

export const calcDownPercentage = ({
  homePrice,
  down_payment
}: {
  homePrice: number
  down_payment: number
}) => Math.round((down_payment / homePrice) * 10000) / 100

export const calcPropertyTax = ({
  homePrice,
  propertyTaxPercentage
}: {
  homePrice: number
  propertyTaxPercentage: number
}) => {
  return Number(toFixed((homePrice * propertyTaxPercentage) / 1200, 2))
}

export const calcPropertyTaxPercentage = ({
  homePrice,
  propertyTaxAmount
}: {
  homePrice: number
  propertyTaxAmount: number
}) => Math.round((propertyTaxAmount / homePrice) * 120000) / 100

export const calcHomeInsurance = ({
  homePrice,
  homeInsurancePercentage
}: {
  homePrice: number
  homeInsurancePercentage: number
}) => (homePrice * homeInsurancePercentage) / 1200

export const calcMortgageInsurance = ({
  homePrice,
  mortgageInsurancePercentage,
  downPercentage
}: {
  homePrice: number
  mortgageInsurancePercentage: { high: number; medium: number; low: number }
  downPercentage: number
}) => {
  let rate = 0.55
  let loanAmt = homePrice - (homePrice * downPercentage) / 100
  // check to see if value is ratio and convert to percentage
  if (downPercentage < 1) {
    downPercentage = downPercentage * 100
  }
  if (downPercentage <= 5) {
    rate = mortgageInsurancePercentage.high
  } else if (downPercentage > 5 && downPercentage <= 10) {
    rate = mortgageInsurancePercentage.medium
  } else if (downPercentage > 10 && downPercentage < 20) {
    rate = mortgageInsurancePercentage.low
  } else {
    rate = 0
  }
  return Math.round((loanAmt * rate) / 1200)
}

export const calcPercentage = (percentage: number, totalValue: number) =>
  (totalValue * percentage) / 100

export const calcTotalFeeAmount = (feeList: FeeItem[]) => {
  return feeList.reduce((acc, curr) => acc + curr.amount, 0)
}

export const calcPrePaidInterest = (principalAndInterest = 0) =>
  (principalAndInterest * 0.9) / 30

export const calcEscrowTotal = ({
  insurance = 0,
  taxes = 0,
  loanPurpose = "purchase"
}: {
  insurance?: number
  taxes?: number
  loanPurpose?: LoanPurpose
}) => {
  const escrowMultiples =
    loanPurpose === "refinance"
      ? refinanceEscrowMultiples
      : purchaseEscrowMultiples

  return (
    insurance * escrowMultiples.insEscrowMultiple +
    insurance * escrowMultiples.resEscrowMultiple +
    taxes * escrowMultiples.taxEscrowMultiple
  )
}

export const mapLoanProperties = ({
  dpp,
  home_ins,
  list_price,
  list_price_max,
  list_price_min,
  rate,
  prop_tax,
  term,
  mort_ins,
  conforming_limit,
  down_payment,
  loan_type,
  hoaDues,
  ...rest
}: {
  dpp: number
  home_ins: number
  list_price: number
  list_price_max: number
  list_price_min: number
  rate: number
  prop_tax: number
  term: number
  mort_ins: {
    high: number
    medium: number
    low: number
  }
  conforming_limit: number
  down_payment: number
  loan_type: string
  hoaDues: string
}) => {
  const dp =
    down_payment ||
    calcDownPayment({
      homePrice: list_price,
      downPercentage: dpp
    })

  const downPercentage = down_payment
    ? calcDownPercentage({
        homePrice: list_price,
        down_payment
      })
    : dpp

  const mappedObj = {
    conformingLimit: conforming_limit,
    downPercentage,
    homeInsurancePercentage: home_ins,
    homePrice: list_price,
    list_price_max,
    list_price_min,
    interestRate: rate,
    propertyTaxPercentage: prop_tax,
    loanTerm: term,
    mortgageInsurancePercentage: mort_ins,
    homeInsurance: calcHomeInsurance({
      homePrice: list_price,
      homeInsurancePercentage: home_ins
    }),
    mortgageInsurance: calcMortgageInsurance({
      homePrice: list_price,
      mortgageInsurancePercentage: mort_ins,
      downPercentage
    }),
    down_payment: dp,
    propertyTaxAmount: calcPropertyTax({
      homePrice: list_price,
      propertyTaxPercentage: prop_tax
    }),
    hoaDues: parseFloat(hoaDues ?? "0"),
    loan_type: loan_type ?? undefined
  }

  return { ...mappedObj, ...rest }
}

export const mapLoanProfile = ({
  apr,
  dpp,
  hash,
  label,
  list_price,
  mi,
  rate,
  term,
  umip,
  value
}: {
  apr: number
  dpp: number
  hash: string
  label: string
  list_price: number
  mi: number
  rate: number
  term: number
  umip: number
  value: number
}) => {
  dpp = dpp * 100 // /!\ dpp here is a real value, not a percentage!
  return {
    profileId: hash,
    profileLabel: label,
    downPercentage: dpp,
    homePrice: list_price,
    loanTerm: term,
    interestRate: rate,
    mortgageInsurance: mi,
    apr, // fully disclosed rate including fees and other costs
    umip, // upfront fee for FHA and USDA loans
    value, // monthly payment
    down_payment: calcDownPayment({
      homePrice: list_price,
      downPercentage: dpp
    })
  }
}

export const calculateTotalInterest = (
  loan_amount: number,
  loan_term: number,
  rate: number
) => {
  // Convert annual rate to monthly and loan term to months
  const monthly_rate = rate / 100 / 12
  const number_of_payments = loan_term * 12

  // Calculate the monthly payment using the formula
  const monthly_payment =
    (loan_amount *
      (monthly_rate * Math.pow(1 + monthly_rate, number_of_payments))) /
    (Math.pow(1 + monthly_rate, number_of_payments) - 1)

  // Calculate the total amount paid over the loan term
  const total_paid = monthly_payment * number_of_payments

  // Calculate the total interest paid
  const total_interest = total_paid - loan_amount

  return total_interest
}

/**
 * Calculates the yearly total principal paid and interest paid over the loan term.
 *
 * @param initialLoanAmount - The total amount borrowed (after down payment).
 * @param annualInterestRate - The annual interest rate as a percentage.
 * @param loanTerm - The total duration of the loan in years.
 * @returns An object containing two arrays: totalPrincipalPaid and totalInterestPaid.
 */
export function calculateYearlyPrincipalAndInterestPaid(
  initialLoanAmount: number,
  annualInterestRate: number,
  loanTerm: number,
  downPayment: number = 0 // Default to 0 if no down payment is provided
): { totalPrincipalPaid: number[]; totalInterestPaid: number[] } {
  const monthlyInterestRate = annualInterestRate / 12 / 100
  const totalMonths = loanTerm * 12

  // Calculate the monthly payment using the amortization formula
  const monthlyPayment =
    (initialLoanAmount *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, totalMonths)) /
    (Math.pow(1 + monthlyInterestRate, totalMonths) - 1)

  let remainingLoanBalance = initialLoanAmount
  const totalPrincipalPaid: number[] = []
  const totalInterestPaid: number[] = []

  let cumulativePrincipalPaid = 0
  let cumulativeInterestPaid = 0

  // Iterate over each year
  for (let year = 1; year <= loanTerm; year++) {
    let yearlyPrincipal = 0
    let yearlyInterest = 0

    // Iterate over each month in the current year
    for (let month = 1; month <= 12; month++) {
      const interestPayment = remainingLoanBalance * monthlyInterestRate
      const principalPayment = monthlyPayment - interestPayment

      yearlyPrincipal += principalPayment
      yearlyInterest += interestPayment

      remainingLoanBalance -= principalPayment
    }

    cumulativePrincipalPaid += yearlyPrincipal
    cumulativeInterestPaid += yearlyInterest

    // Round the cumulative principal and interest to avoid floating-point issues
    totalPrincipalPaid.push(Number(cumulativePrincipalPaid.toFixed(2)))
    totalInterestPaid.push(Number(cumulativeInterestPaid.toFixed(2)))
  }

  return { totalPrincipalPaid, totalInterestPaid }
}
