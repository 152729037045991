import { h } from "preact"
import SelectCustom from "../inputs/SelectCustom"
import { US_STATES } from "@constants"

const options = Object.keys(US_STATES).map(key => {
  return {
    value: key,
    label: US_STATES[key]
  }
})

const StateDropDown = ({
  value,
  isMaterial,
  name = "state",
  handleOnChange,
  label = "State",
  size = "md",
  required
}) => (
  <SelectCustom
    name={name}
    options={options}
    value={value}
    onChange={option => {
      handleOnChange(option.value, name)
    }}
    label={label}
    isMaterial={isMaterial}
    size={size}
    required={required}
  />
)
export default StateDropDown
