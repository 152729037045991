import { h } from "preact"
import starIcon from "@assets/icons/star.svg"
import Icon from "../Icon"
import "@styles/components/_star-loader.scss"

interface Props {
  className?: string
  size?: number
  width?: number | "auto"
}

const StarLoader = ({ className = "", width = 106, size = 18 }: Props) => (
  <div className={`star-loader ${className}`} style={{ width }}>
    <div className="star-loader__front tw-flex gap-xs loading-placeholder loading-placeholder--animated-background is-transparent">
      {[...Array(5)].map((_, i) => (
        <Icon key={i} src={starIcon} size={size} originalSize={16} />
      ))}
    </div>
    <div className="star-loader__back tw-flex gap-xs">
      {[...Array(5)].map((_, i) => (
        <Icon key={i} src={starIcon} size={size} originalSize={16} />
      ))}
    </div>
  </div>
)

export default StarLoader
