import { ComponentChildren, h } from "preact"
import { useSelector } from "react-redux"
import { Button } from "@ui"
import { selectGlobalModalIframeID } from "@selectors"
import { openContactModalFromWithin } from "@iframeCommunication/utils"
import { UISize } from "@types"
import { useCallback } from "preact/hooks"

interface Props {
  size?: UISize
  className?: string
  label?: ComponentChildren
  adapt?: boolean
  noShadow?: boolean
  isWhite?: boolean
  outline?: boolean
}

const ContactBtn = ({
  size,
  className = "",
  label = "Contact",
  adapt = true,
  noShadow = false,
  isWhite = false,
  outline = false
}: Props) => {
  const iframeID = useSelector(selectGlobalModalIframeID)
  const handleOnClick = useCallback(() => {
    openContactModalFromWithin(iframeID)
  }, [iframeID])

  return (
    <Button
      className={className}
      noShadow={noShadow}
      adapt={adapt}
      isWhite={isWhite}
      outline={outline}
      onClick={handleOnClick}
      size={size}
    >
      {label}
    </Button>
  )
}

export default ContactBtn
