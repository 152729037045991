import { h } from "preact"
import Button from "../Button"
import { HOME_PATH } from "@constants"
import Icon from "../Icon"
import plusIcon from "@assets/icons/plus.svg"

const RateCardComparisonEmpty = ({ className = "" }) => (
  <a className={`rate-card is-empty ${className}`} href={`#!${HOME_PATH}`}>
    <div className="rate-card__empty-btn">
      <Icon src={plusIcon} size={32} originalSize={32} />
      <span>Select a rate to compare</span>
    </div>
  </a>
)

export default RateCardComparisonEmpty
