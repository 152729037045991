import { h } from "preact"
import { Button, Form } from "@ui"

interface Props {
  disabled: boolean
  isLoading: boolean
  onClick: () => void
}

const VerifyBtn = ({ disabled, isLoading, onClick }: Props) => (
  <Form.Row>
    <Button
      disabled={disabled}
      onClick={onClick}
      className={`mt-2 ${disabled ? "no-events" : ""}`}
      noShadow
      label="Verify"
      isLoading={isLoading}
    />
  </Form.Row>
)

export default VerifyBtn
