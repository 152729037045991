import { h } from "preact"
import { REVIEWS_ICONS } from "@constants"
import { ProviderType, ReviewType } from "@types"
import { formatNumber, numberWithCommas } from "@helpers"
import { useAppContext } from "@contexts"
import { ScreenReaderOnly, Loader, If, Icon } from "@ui"
import starIcon from "@assets/icons/star.svg"
import "@styles/components/_review-rating-block-compact.scss"

type Props = {
  className?: string
  type: ReviewType
  provider: ProviderType
  currentColor?: boolean
  size?: "sm" | "md" | "lg"
}

const ReviewRatingBlockCompact = ({
  className = "",
  type,
  provider,
  currentColor = true,
  size = "sm"
}: Props) => {
  const {
    iconCurrentColor,
    icon,
    iconOriginalSize,
    iconSize,
    iconSizeMD,
    iconCurrentColorOriginalSize
  } = REVIEWS_ICONS[provider as keyof typeof REVIEWS_ICONS]

  const {
    reviews: { brand, lo }
  } = useAppContext()

  const reviewsData = type === "brand" ? brand : lo
  const isLoading = reviewsData.isLoading
  const providerReviews = reviewsData[provider]

  const TagName = providerReviews?.reviewURL ? "a" : "span"

  let strRating = providerReviews?.rating?.toString() ?? ""

  strRating = strRating.length === 1 ? strRating + ".0" : strRating.substr(0, 3)

  return (
    <div
      className={`review-rating-block__compact is-${size} ${className} ${
        isLoading
          ? "is-loading"
          : providerReviews && providerReviews.reviews
          ? ""
          : "tw-hidden"
      }`}
    >
      <If
        condition={isLoading}
        then={
          <Loader className="tw-flex tw-items-center review-rating-block__compact-loader" />
        }
        elseCondition={
          !isLoading && !!providerReviews && !!providerReviews?.reviews
        }
        else={
          <div className="review-rating-block__compact-item">
            <ScreenReaderOnly>
              {`${provider}, ${strRating} star rating`}
            </ScreenReaderOnly>

            <Icon
              className="tw-mr-2"
              src={currentColor ? iconCurrentColor : icon}
              size={size === "md" ? iconSizeMD : iconSize}
              originalSize={
                currentColor ? iconCurrentColorOriginalSize : iconOriginalSize
              }
            />

            <div className="review-item__main">
              <div
                aria-hidden="true"
                className="review-rating-block__compact-rating"
              >
                <Icon
                  src={starIcon}
                  size={size === "md" ? 20 : 16}
                  originalSize={16}
                />
                <div className="review-rating-block__compact-rating-label">
                  {formatNumber(providerReviews?.rating ?? 0, 1)}
                </div>
              </div>

              <TagName
                className="review-rating-block__compact-rating-total"
                {...(TagName === "a"
                  ? {
                      rel: "noreferrer noopener",
                      tabIndex: 0,
                      href: providerReviews?.reviewURL,
                      target: "_blank"
                    }
                  : {})}
              >
                {numberWithCommas(providerReviews?.totalReviews ?? 0)}{" "}
                {(providerReviews?.totalReviews as number) > 1 ||
                providerReviews?.totalReviews === 0
                  ? "reviews"
                  : "review"}
                <If
                  condition={TagName === "a"}
                  then={<ScreenReaderOnly>, opens in new tab</ScreenReaderOnly>}
                />
              </TagName>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default ReviewRatingBlockCompact
