import { Fragment, h } from "preact"
import { useState, useEffect, useMemo, useRef } from "preact/hooks"
import { If, ReviewBlockSkeleton } from "@ui"
import ReviewsTableHeader from "../ReviewsTableHeader"
import ReviewsTable from "../ReviewsTable"
import {
  ReviewType,
  ReviewsViewMode,
  FormattedReviews,
  ProviderTypeWithAllOption
} from "@types"
import { useAppContext } from "@contexts"
import { filterReviewByProvider } from "@helpers"

interface Props {
  type: ReviewType
  excerpt?: number
  ratingColor?: string
  hideProvider?: boolean
  hideReviewBorder?: boolean
  hideTotalReviews?: boolean
  reviewsViewMode?: ReviewsViewMode
  className?: string
}

const ReviewsWrapper = ({
  type,
  excerpt = 150,
  ratingColor,
  hideProvider,
  hideTotalReviews = false,
  hideReviewBorder,
  reviewsViewMode = "slide",
  className = ""
}: Props) => {
  const {
    reviews: { getReviews, lo, brand },
    media: {
      currentMedia,
      flags: { isMobile, isTablet }
    }
  } = useAppContext()

  const reviewsData = type === "brand" ? brand : lo

  const { isLoading } = reviewsData

  const eleRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const [provider, setProvider] = useState<ProviderTypeWithAllOption>("all")

  // reviews contains the reviews for the current provider, if current provider is all we flatten the array
  const reviews = useMemo(
    () => filterReviewByProvider(reviewsData, provider),
    [reviewsData.reviews, provider]
  )

  const narrowWidth = isMobile
  const shortHeight = useMemo(() => height > 0 && height < 800, [height])

  const viewMode = useMemo(() => {
    if (reviewsViewMode === "auto") {
      return narrowWidth || shortHeight ? "slide" : "list"
    }

    return reviewsViewMode
  }, [currentMedia, reviewsViewMode, narrowWidth, shortHeight])

  const setSize = () => {
    const height = eleRef.current?.offsetHeight
    if (height) setHeight(height)
  }

  useEffect(() => {
    getReviews(type)
    setSize()
    window.addEventListener("resize", setSize)

    return () => {
      window.removeEventListener("resize", setSize)
    }
  }, [])

  return (
    <div
      className={`reviews ${isLoading ? "is-loading" : ""} ${className}`}
      ref={eleRef}
    >
      <If
        condition={isLoading}
        then={
          <ReviewBlockSkeleton
            className="tw-mb-4"
            cols={isMobile ? 1 : isTablet ? 2 : 3}
          />
        }
        else={
          <Fragment>
            <ReviewsTableHeader
              type={type}
              currentProvider={provider}
              setProvider={setProvider}
              reviewsViewMode={viewMode}
              hideTotalReviews={hideTotalReviews}
            />
            <ReviewsTable
              excerpt={excerpt}
              reviewsViewMode={viewMode}
              ratingColor={ratingColor}
              hideProvider={hideProvider}
              hideReviewBorder={hideReviewBorder}
              reviews={reviews}
              mobileMode={narrowWidth}
            />
          </Fragment>
        }
      />
    </div>
  )
}

export default ReviewsWrapper
