import axios from "axios"
import { bbProcessEnv } from "@helpers"
import { RateCardResponse } from "@types"

export default {
  getConfig: async () => {
    try {
      const response = await axios.get<RateCardResponse>(
        `${bbProcessEnv.API_URL}/api/v1/rate-cards-config`
      )
      return response?.data?.details
    } catch (error) {
      console.log(error)
    }
  }
}
