export default {
  spamMsg:
    'Please check your "<strong>Spam</strong>" folder. If you find our email there, select the code message and click "Not Spam".',
  errorMsg: "There was an error with the validation code, please try again!",
  banner: {
    title: "Have the latest rates sent directly to your inbox.",
    label: "Subscribe for FREE!"
  },
  bannerSuccess: {
    title: "Success! You'll get your first email soon.",
    mobileTitle: "Success!",
    txt: "We'll send you emails when your rate changes."
  },
  cardTooltip:
    '"FEE" includes discount points and may include other estimated fees associated with the loan. Contact your home loan provider for an accurate fee estimate.',
  finalStep: {
    hasResults: {
      title:
        "Congrats %s! We've created a personalized rate dashboard for you!",
      subtitle:
        "View all rate options, track alerts, adjust preferences & more in your dashboard."
    },
    noResults: {
      title: "%s, your request has been received.",
      subtitle:
        "We'll help guide you through the home loan process. Our mortgage professional will be in touch with you by phone or email soon."
    }
  },
  STEPS_ANSWERS: {
    TITLE: "Your selected details",
    EDIT_BTN: "Edit details"
  }
}
