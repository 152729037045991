import { h } from "preact"
import { useSelector } from "react-redux"
import { Form } from "@ui"
import { LeadWorkflowSteps, OptionButton, RootStates } from "@types"

interface StepWelcomeProps {
  onInput: (e: InputEvent) => void
  stepKey: string
  options: OptionButton[]
  header?: string
  subheader?: string
}

const StepWelcome = ({
  onInput,
  stepKey,
  options,
  header,
  subheader
}: StepWelcomeProps) => {
  const values = useSelector(({ wizard }: RootStates) => wizard?.values)
  return (
    <div className="wizard__step-welcome">
      {header && (
        <h2 className="wizard__step-header wizard__animated wizard__animated-titles">
          {header}
        </h2>
      )}
      {subheader && (
        <h3 className="wizard__step-subheader wizard__animated wizard__animated-titles">
          {subheader}
        </h3>
      )}
      <Form.OptionButtons
        className="wizard__step-welcome-btns"
        buttonClassName="wizard__animated"
        onInput={onInput}
        name={stepKey}
        options={options}
        value={values?.steps[stepKey as keyof LeadWorkflowSteps]}
      />
    </div>
  )
}

export default StepWelcome
