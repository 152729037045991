import { Fragment, h } from "preact"
import { useEffect, useMemo, useRef, useState } from "preact/hooks"
import { useSelector } from "react-redux"
import {
  PricingCards,
  PricingItems,
  RootStates,
  RateCardTabs,
  RateCardTab
} from "@types"
import { getUrlVars } from "@helpers"
import { bbPricingAPI, rcAPI } from "@api"
import { If, RateCardsSkeleton, Form, Button } from "@ui"
import { useIsMobile } from "@contexts"
import RateCardsBody from "./Body"
import CardPricingGridHidden from "../Cards/CardPricingGridHidden"
import { RATE_CARDS_CARDS_MAX_RESULTS } from "@constants"

interface Props {
  closeLeadForm: () => void
  hideRateCards: boolean
}

const RateCards = ({ closeLeadForm, hideRateCards }: Props) => {
  const { loid, appKey } = useSelector(({ app }: RootStates) => ({
    loid: app.lo?.loid,
    appKey: app.app_key
  }))

  const isMobile = useIsMobile()

  const isFirstRender = useRef(true)

  const [tabs, setTabs] = useState<RateCardTabs>()
  const [showLeadForm, setShowLeadForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [items, setItems] = useState<PricingCards>([])
  const [loanPurpose, setLoanPurpose] = useState<"purchase" | "refinance">(
    "purchase"
  )
  const [cards, setCards] = useState<PricingItems>([])
  const [disableCTA, setDisableCTA] = useState(false)
  const [currentTab, setCurrentTab] = useState<RateCardTab>()
  const [currentRateflowLogID, setCurrentRateflowLogID] = useState<
    number | undefined
  >()
  const [max_results, setMaxResults] = useState(RATE_CARDS_CARDS_MAX_RESULTS)

  const showLeadCaptureForm = () => {
    setShowLeadForm(true)
  }

  useEffect(() => {
    const fetchConfig = async () => {
      const config = await rcAPI.getConfig()
      setTabs(config?.tabs)
      // Remove debug log
      setCurrentTab(config?.tabs?.[loanPurpose]?.[0])
      if (config?.max_results) {
        setMaxResults(config?.max_results)
      }
      if (config?.disable_cta) {
        setDisableCTA(config?.disable_cta)
      }
    }

    fetchConfig()
  }, [])

  useEffect(() => {
    if (!loid || !appKey) {
      console.warn("No loid or appKey")
      setIsFetching(false)
      setIsLoading(false)
      return
    }

    // Avoid fetching until the tab is set
    if (!currentTab) {
      return
    }

    // Check if there is global defaults
    const options = getUrlVars()

    const param = {
      profile_preference: currentTab?.type,
      app_key: appKey,
      loid: loid,
      pricing_grid_defaults: options || {},
      cacheEnabled: true,
      max_results: 9
    }

    const fetchData = async () => {
      try {
        const data = await bbPricingAPI.get(param)
        setItems(data?.pricing || [])

        let currentPricing = data?.pricing?.find(
          item => item.profile_preference === currentTab?.type
        )

        if (!currentPricing) {
          currentPricing = data?.pricing?.[0]
        }

        if (!currentPricing) {
          setIsFetching(false)
          setCurrentRateflowLogID(undefined)
          setCards([])
        } else if (currentPricing.rateflow_log_id === currentRateflowLogID) {
          setIsFetching(false)
        } else {
          setCurrentRateflowLogID(currentPricing.rateflow_log_id)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [loid, appKey, currentTab])

  useEffect(() => {
    setCurrentTab(tabs?.[loanPurpose][0])
    setIsFetching(true)
  }, [loanPurpose])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    if (currentRateflowLogID) {
      const fetchData = async () => {
        try {
          const data = await bbPricingAPI.logPublic({
            quote_id: currentRateflowLogID,
            include_request: true,
            include_cards: true,
            cacheEnabled: true
          })

          setCards(data?.cards || [])
        } catch (err) {
          console.log(err)
        } finally {
          setIsFetching(false)
          setIsLoading(false)
        }
      }
      fetchData()
    } else if (!isLoading) {
      setIsFetching(false)
    }
  }, [currentRateflowLogID, isLoading])

  // Memoize currentTabs to prevent unnecessary recalculations
  const currentTabs = useMemo(() => tabs?.[loanPurpose], [tabs, loanPurpose])

  const onClickTab = (tab: RateCardTab) => {
    setCurrentTab(tab)
    setIsFetching(true)
  }

  const sortedCards = useMemo(() => {
    // filter cards where price is higher than 98
    const filteredCards = [...cards].filter(card => card.price > 98)
    // order cards by interest rate from low to high
    const newCards = filteredCards.sort((a, b) => a.rate - b.rate)
    // limit to max_results
    return newCards.slice(0, max_results)
  }, [cards, max_results])

  return (
    <div className="tw-relative">
      <If
        condition={hideRateCards}
        then={
          <CardPricingGridHidden
            closeLeadForm={closeLeadForm}
            showLeadForm={showLeadForm}
            showLeadCaptureForm={showLeadCaptureForm}
          />
        }
      />

      <If
        condition={isLoading || !!hideRateCards}
        then={<RateCardsSkeleton />}
        else={
          <Fragment>
            <div className="sm:tw-flex tw-flex-col tw-items-center">
              <div className="tw-flex tw-justify-center tw-w-full">
                <Form.SwitchLabeledAccentColor
                  rounded
                  value={loanPurpose}
                  label="Loan Purpose"
                  options={[
                    { label: "Purchase", value: "purchase" },
                    { label: "Refinance", value: "refinance" }
                  ]}
                  handleClick={setLoanPurpose}
                  size={isMobile ? "md" : "lg"}
                />
              </div>
              <div className="tw-overflow-x-auto tw-overflow-y-hidden scrollbar-none">
                <div className="tw-flex gap-sm tw-mt-10">
                  {currentTabs?.map(tab => (
                    <Button
                      className={`tw-text-base tw-whitespace-nowrap ${
                        tab.type === currentTab?.type
                          ? "!tw-cursor-default"
                          : ""
                      }`}
                      key={tab.type}
                      size="md"
                      adapt
                      noShadow
                      isAlt={tab.type !== currentTab?.type}
                      onClick={() => {
                        if (tab.type !== currentTab?.type) {
                          onClickTab(tab)
                        }
                      }}
                    >
                      {tab.label}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            <RateCardsBody
              disableCTA={disableCTA}
              term={currentTab?.label || ""}
              cards={sortedCards}
              items={items}
              isFetching={isFetching}
              loanPurpose={loanPurpose}
            />
          </Fragment>
        }
      />
    </div>
  )
}

export default RateCards
