import { h } from "preact"
import { DescriptionListItem as DescriptionListItemType } from "@types"

interface Props extends DescriptionListItemType {
  numberWithoutDecimals?: boolean
}

const DescriptionListItem = ({ term, description }: Props) => (
  <div
    key={term}
    className="tw-flex tw-justify-between tw-gap-2 tw-py-2 border-color tw-border-b dark:tw-border-white/20 tw-font-semibold"
  >
    <dt className="tw-max-w-44 txt-dark dark:tw-text-white/55">{term}</dt>
    <dd className="txt-dark dark:tw-text-white tw-mb-0">
      {typeof description === "boolean"
        ? description
          ? "Yes"
          : "No"
        : description}
    </dd>
  </div>
)

export default DescriptionListItem
