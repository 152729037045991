import { h } from "preact"
import PrimaryBTBody from "../PrimaryBTBody"
import Menu from "../Menu"

interface Props {
  className?: string
}

const BTTabs = ({ className = "" }: Props) => (
  <div className={`tw-w-full ${className}`}>
    <Menu />
    <PrimaryBTBody />
  </div>
)

export default BTTabs
