import { updateDashForm } from "@actions"
import { useDispatch } from "react-redux"
import gtagHelper from "../subscription-dashboard/gtagHelper"
import { LoanDetailsFormFields } from "@types"

interface QuoteInfo extends LoanDetailsFormFields {
  loan_amount: number
  cash_out: number
}

function useDashFormSubmit() {
  const dispatch = useDispatch()

  const handleSubmit = (formData: LoanDetailsFormFields) => {
    const {
      location,
      loan_purpose,
      loan_type,
      property_type,
      credit_score,
      list_price,
      loan_term,
      arm_term,
      loan_amount,
      cash_out,
      down_payment,
      residency_type,
      first_time_homebuyer,
      va_funding_fee,
      va_funding_fee_type,
      waive_escrows
    } = formData

    // send loan_info_update event
    gtagHelper({ action: "loan_info_updated" })

    const quote_info: Partial<QuoteInfo> = {
      location,
      loan_purpose,
      loan_type,
      property_type,
      credit_score,
      list_price,
      residency_type,
      first_time_homebuyer,
      va_funding_fee,
      waive_escrows
    }

    if (loan_purpose === "refinance") {
      quote_info.loan_amount = loan_amount
      quote_info.cash_out = cash_out
    } else {
      quote_info.loan_amount = (list_price as number) - (down_payment as number)
    }

    if (loan_type === "arm") {
      quote_info.arm_term = arm_term
    } else {
      quote_info.loan_term = loan_term

      if (loan_type === "va") {
        quote_info.va_funding_fee_type =
          va_funding_fee_type === "" || !va_funding_fee_type
            ? "first_time"
            : va_funding_fee_type
      }
    }

    dispatch(updateDashForm({ quote_info }))
  }

  return handleSubmit
}

export default useDashFormSubmit
