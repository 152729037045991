import { ComponentChildren, h } from "preact"
import "@styles/components/_badge.scss"

interface Props {
  theme?: "primary" | "secondary" | "dark" | "alt"
  pill?: boolean
  children: ComponentChildren
  className?: string
}

const Badge = ({
  theme = "primary",
  pill = false,
  children,
  className = ""
}: Props) => (
  <span
    className={`bb-badge badge badge-${theme} ${
      pill ? "badge-pill" : ""
    } ${className}`}
  >
    {children}
  </span>
)

export default Badge
