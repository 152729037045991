import { h } from "preact"
import { Form, If } from "@ui"
import { ReviewType } from "@types"
import { useReviews } from "../context"

const ReviewTypeSwitch = () => {
  const { reviewType, setReviewType, hasBrandReviews, hasLoReviews } =
    useReviews()

  return (
    <If
      condition={hasBrandReviews && hasLoReviews}
      then={
        <Form.SwitchLabeled<ReviewType>
          value={reviewType}
          label="View mode"
          options={[
            {
              label: "My Reviews",
              value: "lo"
            },
            { label: "Company Reviews", value: "brand" }
          ]}
          handleClick={setReviewType}
        />
      }
    />
  )
}

export default ReviewTypeSwitch
