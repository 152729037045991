import { h } from "preact"
import type { ComponentChildren } from "preact"
import Button from "../Button"
import Icon from "../Icon"
import Pen from "@assets/icons/pen.svg"
import "@styles/components/_data-table.scss"

interface Props {
  onClickEdit?: () => void
  title?: string
  children: ComponentChildren
  w100?: boolean
  mobileStacked?: boolean
  className?: string
}

const DataTable = ({
  onClickEdit,
  title,
  children,
  w100,
  mobileStacked = true,
  className = ""
}: Props) => (
  <div
    className={`data-table ${
      mobileStacked ? "mobile-stacked" : ""
    } ${className} ${onClickEdit ? "is-editable" : ""}`}
  >
    {onClickEdit && (
      <Button
        className="data-table__edit-btn"
        link
        onClick={onClickEdit}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.code === "Enter") {
            onClickEdit()
          }
        }}
      >
        <Icon className="mr-1" src={Pen} originalSize={37} size={14} />
        <span>Edit</span>
      </Button>
    )}
    {title && <h3 className="title">{title}</h3>}
    <table className={`table ${w100 ? "w-100" : ""}`}>{children}</table>
  </div>
)

export default DataTable
