import { h } from "preact"
import ImgLoading from "./ImgLoading"
import TxtLoading from "./Txt"
import StarLoader from "./Star"

interface Props {
  width?: string
  ratingReversed?: boolean
}

const SmallReviewItem = ({
  width = "106px",
  ratingReversed = false
}: Props) => (
  <div className="tw-flex gap-base">
    <ImgLoading height={52} width={52} fullRounded />
    <div
      className={`tw-flex ${
        ratingReversed ? "tw-flex-col-reverse" : "tw-flex-col"
      }`}
    >
      <TxtLoading height={16} width={width} margin="8px 0" />
      <StarLoader />
    </div>
  </div>
)

export default SmallReviewItem
