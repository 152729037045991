import { h } from "preact"
import { useEffect, useRef } from "preact/hooks"

interface Props {
  addEvent?: boolean
  className?: string
  stringToHTML?: boolean
  styles?: h.JSX.CSSProperties
  children?: any
  hideTooltipClick?: (target: HTMLElement) => void
  onMouseLeave?: (e: MouseEvent) => void
  onMouseEnter?: () => void
}

const TooltipInner = ({
  addEvent,
  className,
  stringToHTML,
  styles,
  children,
  hideTooltipClick,
  onMouseLeave,
  onMouseEnter
}: Props) => {
  const ele = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (addEvent) {
      document.addEventListener("mousedown", handleMousedown, false)
      return () => {
        document.removeEventListener("mousedown", handleMousedown, false)
      }
    }
  }, [])

  const handleMousedown = (e: Event) => {
    const target = e.target as HTMLElement
    if (ele.current?.contains(target)) {
      // The click is inside the tooltip, continue what you are doing
      return
    }
    // The click is outside the tooltip node, hide it
    hideTooltipClick?.(target)
  }

  return (
    <div ref={ele}>
      {stringToHTML ? (
        <div
          style={styles}
          className={className}
          dangerouslySetInnerHTML={{ __html: children }}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
        />
      ) : (
        <div
          style={styles}
          className={className}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default TooltipInner
