import { MEDIA_QUERIES, MEDIA_QUERIES_HOVER_POINTER } from "@constants"
import { MediaSize } from "@types"

export const matchMediaSize = (): MediaSize => {
  let media: MediaSize = "XS"
  Object.keys(MEDIA_QUERIES).map((key: string) => {
    if (window.matchMedia(MEDIA_QUERIES[key as MediaSize]).matches) {
      media = key as MediaSize
    }
  })
  return media
}

export const matchMediaHoverPointer = () => {
  return window.matchMedia(MEDIA_QUERIES_HOVER_POINTER.YES).matches
}
