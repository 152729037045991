import { h } from "preact"
import { useRateTableToggleSubscription } from "@hooks"
import Banner from "./Banner"

interface Props {
  className?: string
}

const SubscriptionBanner = ({ className = "" }: Props) => {
  const { subscribed, updateSubscription } = useRateTableToggleSubscription()
  return (
    <Banner
      className={className}
      updateSubscription={updateSubscription}
      subscribed={subscribed}
    />
  )
}

export default SubscriptionBanner
